import axios, { AxiosInstance } from 'axios';
import { API_ROOT } from './constants';
import { store } from '../configureStore';
import { FORCED_LOGOUT } from '../Store/whoami/types';

import TimerHelper from './TimerHelper';

// Shared instance so we don't need to add the BASE URL on every request
const instance: AxiosInstance = axios.create({
  baseURL: API_ROOT,
});

instance.interceptors.response.use(undefined, (err) => {
  if (!axios.isCancel(err) && err.response && err.response.status === 401) {
    store.dispatch({
      type: FORCED_LOGOUT,
    });
  }
  if (err?.response?.data?.details) {
    err.message = err.response.data.details;
  }
  return Promise.reject(err);
});

// Requirements are that this should be per tab
// so user can have multiple tenants open in 1 browser.
//  As such, we are using in memory store vs localstorage.
export const setHeader = (str: string | undefined) => {
  // If undefined passed in, then clear the header
  if (str === undefined && instance.defaults.headers.common['chosen-tenant-id']) {
    delete instance.defaults.headers.common['chosen-tenant-id'];
  }
  // If str, then set this to the chosen tenant
  if (str) instance.defaults.headers.common['chosen-tenant-id'] = str;
};

export const getBaseUrl = () => {
  return API_ROOT;
};

// For use with Authenticated Endpoints that required JWT
export const authorized = (token: string) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // chosen-tenant-id
  TimerHelper.dispatchApiCall();
  return instance;
};
export default instance;
