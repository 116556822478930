// From https://vladi-strilets.medium.com/dispatch-redux-action-on-history-location-route-change-with-react-router-v5-9b487aabfef8

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RouteChange } from '../Store/history/reducer';
import Loading from '../Components/Loading';

// We can use this to initialize some items for the UI
//  such as get APP & USER Settings.
const HistoryListener: React.FunctionComponent<any> = ({ children }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  // Diabled Below
  // FIXME maybe???
  // This could trigger when app initializes
  //  But will could trigger multiple times afterwards as
  //  history or dispatches happen.
  // dispatch(RouteChange(history.location));

  // locations listener
  useEffect(() => {
    setIsFirstRender(false);

    return history.listen((location) => {
      // console.log('Location:', location.pathname);
      dispatch(RouteChange(history.location));
    });
  }, [history, dispatch]);

  // if is the first time render, show loading
  if (isFirstRender) {
    return <Loading fullView={true} />;
  }

  return children;
};

export default HistoryListener;
