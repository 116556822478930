import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  PatientGroupProps,
  PatientGroupsActionTypes,
  ADD_PATIENT_GROUP_SUCCESS,
  DELETE_PATIENT_GROUP_SUCCESS,
  EDIT_PATIENT_GROUP_SUCCESS,
  PATIENT_GROUPS_REQUESTED,
  PATIENT_GROUPS_SUCCESS,
  PATIENT_GROUPS_ERROR,
  PATIENT_GROUP_REQUESTED,
  PATIENT_GROUP_ERROR,
  PATIENT_GROUP_SUCCESS,
  PATIENT_GROUP_PEOPLE_BATCH_REQUESTED,
  PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
  PATIENT_GROUP_PEOPLE_BATCH_ERROR,
  PATIENT_GROUP_PEOPLE_RESET,
  PATIENT_GROUPS_EDIT_TOTALS_REQUESTED,
  PATIENT_GROUPS_EDIT_TOTALS_SUCCESS,
  PATIENT_GROUPS_EDIT_TOTALS_ERROR,
  PatientGroupTotalProps,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

import { dehydrate, rehydrate } from '../Utils/groupFiltersUtil';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const resetPatientGroupPeople = () => async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
  dispatch({ type: PATIENT_GROUP_PEOPLE_RESET });
};

export const getPatientGroup =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUP_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/patient_groups/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        let rslt = resp.data;
        if (rslt.group_filters && rslt.group_filters.filters) {
          rslt.group_filters.filters = rehydrate(rslt.group_filters.filters);
        }
        dispatch({
          type: PATIENT_GROUP_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group request cancelled');
        }
        dispatch({ type: PATIENT_GROUP_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getPatientGroups =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUPS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/patient_groups', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('patient group response ----', resp);
        const rslt = resp.data.data.map((r: PatientGroupProps) => {
          if (r.group_filters && r.group_filters.filters) {
            r.group_filters.filters = rehydrate(r.group_filters.filters);
          }
          r.patients = -1;
          return r;
        });
        dispatch({
          type: PATIENT_GROUPS_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        console.log(e);
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group request cancelled');
        }
        dispatch({ type: PATIENT_GROUPS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getPatientGroupsEditTotals =
  (token: string, editTotalsBody: Array<string | undefined>) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUPS_EDIT_TOTALS_REQUESTED });
    cancelReq = axios.CancelToken.source();
    // const patient_groups = editTotalsBody;
    // console.log('get patient totals request');
    authorized(token)
      .post('/portal/totals/patient_groups_edit', { patient_groups: editTotalsBody }, { params: { only_recent: true } })
      .then((resp) => {
        dispatch({
          type: PATIENT_GROUPS_EDIT_TOTALS_SUCCESS,
          payload: resp.data as PatientGroupTotalProps[],
        });
      })
      .catch((e) => {
        console.log(e);
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group edit totals request cancelled');
        }
        dispatch({ type: PATIENT_GROUPS_EDIT_TOTALS_ERROR, payload: e.message, ids: editTotalsBody });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const createPatientGroup =
  (token: string, patientGroupBody: Partial<PatientGroupProps>) => (dispatch: Dispatch) => {
    dispatch({ type: PATIENT_GROUP_REQUESTED });
    const { name, description, users, enabled, product_type } = patientGroupBody;
    authorized(token)
      .post('/patient_groups', { name, description, users, enabled, product_type })
      .then((resp) => {
        resp.data.id = resp.data.id.toUpperCase();
        dispatch({ type: ADD_PATIENT_GROUP_SUCCESS, payload: resp.data as PatientGroupProps });
      })
      .catch((error) => {
        console.error(error);
        let msg: any;
        if (error.response?.data) {
          msg = error.response.data.details || error.response.data.message;
        }
        // FIXME
        // At times a 500 error could come back with an empty data object or string
        //    from server.  If so, return standard message
        if (msg === undefined || msg === null) msg = error.message;
        dispatch({ type: PATIENT_GROUP_ERROR, payload: msg });
      });
  };

export const editPatientGroup = (token: string, patientGroup: PatientGroupProps) => (dispatch: Dispatch) => {
  dispatch({ type: PATIENT_GROUP_REQUESTED });
  // we are currently removing private_group_auth0_id
  // because this belongs to private group
  // when we start working with private groups we need to figure out when to use this value
  const { id, updated_dttm, group_filters, private_group_auth0_id, patients, is_default_group, ...rest } = patientGroup;
  if (group_filters && group_filters.filters) {
    group_filters.filters = dehydrate(group_filters.filters);
  }

  authorized(token)
    .put(`/patient_groups/${id}`, { ...rest, id, group_filters })
    .then((resp) => {
      // Add the patients count from the previous request so we don't render NULL / UNDEFINED
      // There is a sperate call in the view that updates this number after this update.
      let rslt = { ...resp.data, patients };
      if (rslt.group_filters && rslt.group_filters.filters) {
        rslt.group_filters.filters = rehydrate(rslt.group_filters.filters);
      }
      dispatch({ type: EDIT_PATIENT_GROUP_SUCCESS, payload: rslt });
    })
    .catch((error) => {
      console.error(error);
      let msg: any;
      if (error.response?.data) {
        msg = error.response.data.details || error.response.data.message;
      }
      // FIXME
      // At times a 500 error could come back with an empty data object or string
      //    from server.  If so, return standard message
      if (msg === undefined || msg === null) msg = error.message;
      dispatch({ type: PATIENT_GROUP_ERROR, payload: msg });
    });
};

// Get a group and its members by ID
export const getPatientGroupPeopleBatch =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUP_PEOPLE_BATCH_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .get(`/patient_groups/${id}/people`, { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('patient group people response ----', resp);
        dispatch({
          type: PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group people request cancelled');
        }
        dispatch({ type: PATIENT_GROUP_PEOPLE_BATCH_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const deletePatientGroup =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUP_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .delete(`/patient_groups/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('patient group response ----', resp);
        dispatch({
          type: DELETE_PATIENT_GROUP_SUCCESS,
          payload: id,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group request cancelled');
        }
        dispatch({ type: PATIENT_GROUP_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
