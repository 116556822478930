import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DarkTeal } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  appBar: {
    backgroundColor: '#FFF',
    color: theme.palette.text.primary,
    boxShadow: 'none',
  },
  skeletonLoaderCell: {
    padding: '0.7rem',
  },
  groupName: {
    color: DarkTeal,
    fontWeight: 'bold',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  clickableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
