import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import useStyles from './styles';

const ErrorMessage = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.root}>
      <Grid item xs={3}>
        <Typography variant="h1" className={classes.bolder}>
          Oops!
        </Typography>
        <Typography>Well, that was unexpected…</Typography>
        <Typography>Please contact your administrator or try again later.</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
