import { createContext, useContext } from 'react';

export interface SectionWrapperState {
  showMore: boolean;
  collapse: boolean;
}

/**
 * The initial Section Header state.
 */
export const initialSectionWrapperState: SectionWrapperState = {
  showMore: false,
  collapse: true,
};

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SectionWrapperProvider>.');
};

export interface SectionWrapperI extends SectionWrapperState {
  toggleShowMore: () => void;
  toggleCollapse: () => void;
  getState: () => void;
  className?: string;
  children?: any;
}

/**
 * @ignore
 */
export const initialContext = {
  ...initialSectionWrapperState,
  toggleShowMore: stub,
  toggleCollapse: stub,
  getState: stub,
};

export const SectionWrapperContext = createContext<SectionWrapperI>(initialContext);

/**
 * ```js
 * const {
 *   // Section state:
 *   showMore,
 *   collapse,
 *   // Section methods:
 *   toggleShowMore,
 *   toggleCollapse,
 * } = useSectionWrapper<SectionWrapperI>();
 * ```
 *
 * Use the `useSectionWrapper` hook in your components to access the Section state and methods.
 *
 */
export const useSectionWrapper = (): SectionWrapperI => useContext(SectionWrapperContext);

export default SectionWrapperContext;
