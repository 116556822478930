import { PatientAndSecurityGroupFilter } from '../patient_groups/types';

// helper function to see if the filter has any filters with the Product model
const containsProduct = (filters: PatientAndSecurityGroupFilter[]) =>
  filters.slice().some((fltr: PatientAndSecurityGroupFilter) => fltr.model === 'Product');

const containsRecommendationLabel = (filters: PatientAndSecurityGroupFilter[]) =>
  filters.slice().some((fltr: PatientAndSecurityGroupFilter) => fltr.model === 'RecommendationLabel');

// The Filler we need to make sure that the query is successful
const resultFiller = { model: 'Result', field: 'id', op: 'is_not_null' };
const recommendationFiller = { model: 'Recommendation', field: 'id', op: 'is_not_null' };

// Function to parse the filters before we send it back to the API
// We use this to add any special conditions that the API would need that the UI does not.
// Currently, only looking at if Model == Product
export const dehydrate = (filters: PatientAndSecurityGroupFilter[]) => {
  // Don't mutate
  const clonedFilter: PatientAndSecurityGroupFilter[] = JSON.parse(JSON.stringify(filters));
  // Check to see if we have a product filter in the array
  const hasProduct = containsProduct(clonedFilter);
  // Check to see if we have a recommendation label filter in the array
  const hasRecommendationLabel = containsRecommendationLabel(clonedFilter);

  // If so, then add the required "filter" to help assure that the filter can
  // actually use the Product Filter query and Recommendation query
  if (hasRecommendationLabel) clonedFilter.unshift(recommendationFiller);
  if (hasProduct || hasRecommendationLabel) clonedFilter.unshift(resultFiller);

  return clonedFilter;
};

// When we get back the filters, remove any fillers that might have been added to the filters
export const rehydrate = (filters: PatientAndSecurityGroupFilter[]) => {
  // Don't mutate
  let clonedFilter: PatientAndSecurityGroupFilter[] = JSON.parse(JSON.stringify(filters));
  const hasProduct = containsProduct(clonedFilter);
  const hasRecommendationLabel = containsRecommendationLabel(clonedFilter);

  // If has product or recommendation label, then remove the filler we added during "dehydration"
  if (hasProduct || hasRecommendationLabel)
    clonedFilter = clonedFilter.filter((fltr) => {
      return (fltr.model === resultFiller.model && fltr.field === resultFiller.field && fltr.op === resultFiller.op) ||
        (fltr.model === recommendationFiller.model &&
          fltr.field === recommendationFiller.field &&
          fltr.op === recommendationFiller.op)
        ? false
        : true;
    });
  return clonedFilter;
};
