import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { JvionAutocompleteFieldStateI } from './index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '1rem',
      width: 'calc(100% - 5px - 5px)',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiFormLabel-root': {
        width: '100%',
        textTransform: 'uppercase',
        '&.MuiInputLabel-shrink': {
          transform: 'scale(1)',
          fontSize: '10px',
        },
        '&.Mui-error': {
          color: theme.palette.warning.main,
        },
        '& > span': {
          visibility: 'hidden',
          float: 'right',
          position: 'relative',
          fontWeight: 'normal',
          '&:after': {
            content: '" required"',
            visibility: 'visible',
            color: '#8598A9',
            textTransform: 'lowercase',
          },
        },
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.warning.main,
      },
    },
    inputRoot: (props: JvionAutocompleteFieldStateI) => ({
      border: props.warning.active
        ? `1px solid ${theme.palette.warning.main}`
        : `1px solid ${theme.palette.accent?.border}`,
      borderRadius: theme.shape.borderRadius,
      '&.MuiAutocomplete-inputRoot': {
        padding: `0px 50px 1px 5px`,
      },
      '& .MuiAutocomplete-tag': {
        backgroundColor: '#5A667C',
        color: '#FFFFFF',
      },
      '& .MuiChip-deleteIcon': {
        color: '#8598A9',
      },
      '&:before': {
        borderBottom: `none`,
      },
    }),
    listbox: {
      // JCP-1083
      maxHeight: '11rem',
    },
  }),
);

export default useStyles;
