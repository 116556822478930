import { FunctionComponent, useEffect, useState, ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { Drawer, IconButton, Toolbar, Grid, Typography } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import GroupPatientTable from './GroupPatientTable';
import { getMyPatientGroupPeopleBatch } from '../../Store/my_patient_groups/actions';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import { RootStore } from '../../configureStore';

import useStyles from './styles';
import GroupPatientContentPane from './GroupPatientContentPane';
import ErrorMessage from '../../Components/ErrorMessage';

export interface GroupPatientLayoutProps {
  patientGroup: PatientGroupProps;
  children?: ReactElement;
  pageSize: number;
}

interface GroupPatientLayoutState {
  patientListOpen: boolean;
  pageNumber: number;
  personId?: string;
  personIndex?: number;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const GroupPatientLayout: FunctionComponent<GroupPatientLayoutProps> = (props: GroupPatientLayoutProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const MyPatientGroups = useSelector((state: RootStore) => state.my_patient_groups);
  const { people, error } = MyPatientGroups;
  const [state, setState] = useState<GroupPatientLayoutState>({
    patientListOpen: true,
    pageNumber: 1,
    personId: undefined,
    personIndex: undefined,
  });
  const { patientListOpen, pageNumber, personId, personIndex } = state;
  const { patientGroup, children, pageSize } = props;

  let query = useQuery();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      if (patientGroup.id) {
        let params: any = {
          page_size: pageSize,
          page_number: pageNumber,
          only_recent: true,
          // The line below messes with the API's default sorting logic. Keeping
          // it in in case this changes.
          // sort_by: 'last_name(asc),first_name(asc)',
        };
        if (query.get('patient-id')) {
          params['id[eq]'] = query.get('patient-id');
        }
        dispatch(
          getMyPatientGroupPeopleBatch(token, patientGroup.id, {
            params,
          }),
        );
      }
    });
  }, [getAccessTokenSilently, dispatch, pageNumber, pageSize, patientGroup, query]);

  useEffect(() => {
    if (people.people.length > 0) {
      if (personId === undefined) {
        setState((prevState) => ({
          ...prevState,
          personId: people.people[0].id,
          personIndex: 0,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        personId: undefined,
        personIndex: undefined,
      }));
    }
  }, [people.people, personId]);

  // When a user does a search
  // Then does another search, query changes but page does not relead
  // This useEffect covers this scenario.
  useEffect(() => {
    if (query.get('patient-id')) {
      if (personId !== undefined && personId !== query.get('patient-id')) {
        if (people.people.length) {
          setState((prevState) => ({
            ...prevState,
            personId: people.people[0].id,
            personIndex: 0,
          }));
        }
      }
    }
  }, [query, people.people, personId]);
  const handleDrawerToggle = () =>
    setState((prevState) => ({ ...prevState, patientListOpen: !prevState.patientListOpen }));

  const handleSelectPatient = (newPersonId: string, personIndex: number) => {
    setState((prevState) => ({ ...prevState, personId: newPersonId, personIndex: personIndex }));
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: patientListOpen,
            [classes.drawerClose]: !patientListOpen,
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: patientListOpen,
              [classes.drawerClose]: !patientListOpen,
            }),
          }}
        >
          {/* these are spacers that allow for global toolbar size changes without a broken layout */}
          <Toolbar variant="dense" />
          <div className={classes.drawerInner}>
            <div className={classes.drawerHeader}>
              <Typography
                className={clsx(classes.drawerHeaderTitle, {
                  [classes.drawerHeaderTitleClose]: !patientListOpen,
                })}
                variant="h3"
              >
                {patientGroup.name}
              </Typography>
              <IconButton onClick={handleDrawerToggle} size="large">
                {patientListOpen ? (
                  <>
                    <ChevronLeftIcon />
                  </>
                ) : (
                  <>
                    <ChevronRightIcon />
                  </>
                )}
              </IconButton>
            </div>
            <GroupPatientTable
              activePatientId={personId}
              patients={people}
              handleFetchMorePatients={() =>
                setState((prevState) => ({ ...prevState, pageNumber: prevState.pageNumber + 1 }))
              }
              handlePatientClick={handleSelectPatient}
            />
          </div>
        </Drawer>
        {error !== undefined && <ErrorMessage />}
        {personId !== undefined && personIndex !== undefined && !error && (
          <GroupPatientContentPane
            productType={patientGroup.product_type}
            patientId={personId}
            next={
              personIndex < people.people.length - 1
                ? () => handleSelectPatient(people.people[personIndex + 1].id, personIndex + 1)
                : undefined
            }
            prev={
              personIndex > 0
                ? () => handleSelectPatient(people.people[personIndex - 1].id, personIndex - 1)
                : undefined
            }
          >
            {children}
          </GroupPatientContentPane>
        )}
      </Grid>
    </>
  );
};

export default GroupPatientLayout;
