import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CoolGrey9C, CoolGrey5C } from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      height: theme.spacing(6), // Height of nav bar
    },
    appBar: {
      backgroundColor: '#FFF',
      color: theme.palette.text.primary,
      boxShadow: '1px 0px 4px rgba(0,0,0,.18)',
      // zIndex: 1400,
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      display: 'none',
      maxHeight: theme.spacing(5),
    },
    logoLoaded: {
      display: 'block',
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      zIndex: 1,
    },
    avatar: {
      height: 24,
      width: 24,
      fontSize: '.75rem',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    welcome: {
      fontSize: '0.9rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.25rem',
      textTransform: 'initial',
      fontWeight: 600,
      color: CoolGrey9C,
    },
    arrowDown: {
      color: CoolGrey5C,
    },
  }),
);

export default useStyles;
