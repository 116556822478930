import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  PatientGroupsActionTypes,
  MY_PATIENT_GROUP_PEOPLE_RESET,
  MY_PATIENT_GROUP_REQUESTED,
  MY_PATIENT_GROUPS_REQUESTED,
  MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED,
  PATIENT_GROUPS_CHART_TOTALS_REQUESTED,
  MY_PATIENT_GROUPS_SUCCESS,
  MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
  PATIENT_GROUPS_CHART_TOTALS_SUCCESS,
  MY_PATIENT_GROUP_ERROR,
  MY_PATIENT_GROUPS_ERROR,
  MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR,
  PATIENT_GROUPS_CHART_TOTALS_ERROR,
  DELETE_MY_PATIENT_GROUP_SUCCESS,
  MY_PATIENT_GROUP_SUCCESS,
} from './types';
import { cancelReq } from './actions';

const patientGroupsReducer = (state: State = initialState, action: PatientGroupsActionTypes): State => {
  switch (action.type) {
    case MY_PATIENT_GROUP_PEOPLE_RESET:
      return {
        ...state,
        patientGroup: undefined,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case MY_PATIENT_GROUP_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MY_PATIENT_GROUPS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PATIENT_GROUPS_CHART_TOTALS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MY_PATIENT_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroups: action.payload,
      };
    case MY_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        patientGroup: action.payload,
      };
    case MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS:
      let people;
      if (
        state.people.people &&
        state.people.people.length > 0 &&
        state.people.metadata &&
        state.people.metadata.page_number !== null &&
        state.people.metadata.page_number + 1 === action.payload.metadata.page_number
      ) {
        people = [...state.people.people, ...action.payload.data];
      } else {
        people = action.payload.data;
      }
      return {
        ...state,
        loading: false,
        people: {
          people,
          metadata: action.payload.metadata,
        },
      };
    case PATIENT_GROUPS_CHART_TOTALS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroupsChartTotals: action.payload,
      };
    case MY_PATIENT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        patientGroup: undefined,
        error: action.payload,
      };
    case DELETE_MY_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroup: undefined,
        patientGroups: state.patientGroups.filter((pg) => pg.id && pg.id !== action.payload),
      };
    case MY_PATIENT_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        patientGroups: [],
        error: action.payload,
      };
    case PATIENT_GROUPS_CHART_TOTALS_ERROR:
      return {
        ...state,
        loading: false,
        patientGroupsChartTotals: [],
        error: action.payload,
      };
    case MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default patientGroupsReducer;
