import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { RootStore } from '../../configureStore';
import ProductCard from './productCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useStyles from './styles';
import sortBy from 'lodash/sortBy';
import clsx from 'clsx';

export interface IProduct {
  id: string;
  name: string;
  product_type: string;
  description: string;
}

export interface IProductProps {
  productIds: string[];
  selectedProduct: string;
  personID: string;
  setSelectedProduct: (prd: string) => void;
  productType: 'INPATIENT' | 'COMMUNITY' | undefined;
}

const Products: FunctionComponent<IProductProps> = (props: IProductProps) => {
  const classes = useStyles();
  const { productIds, setSelectedProduct, selectedProduct, productType } = props;
  const products = useSelector((state: RootStore) => state.products);
  const results = useSelector((state: RootStore) => state.results.results);
  const [matchedProducts, setMatchedProducts] = useState<IProduct[]>([
    {
      id: '',
      name: '',
      product_type: '',
      description: '',
    },
  ]);
  const setUpSelectedProduct = useCallback(() => {
    const filteredProducts = products.products.filter(
      (p) => p.product_type.toUpperCase() === productType && productIds.includes(p.id),
    );
    const sortedProducts = sortBy(filteredProducts, (prod: IProduct) => prod.name);
    const firstProduct = sortedProducts[0];

    if (firstProduct && selectedProduct === '') setSelectedProduct(firstProduct.id);
    setMatchedProducts(sortedProducts);
  }, [productIds, productType, products.products, selectedProduct, setSelectedProduct]);

  useEffect(() => {
    setUpSelectedProduct();
  }, [productIds, setUpSelectedProduct]);

  return (
    <>
      <Grid container>
        <Typography className={classes.sectionTitle}>RISK PROFILE</Typography>
        <Tooltip
          className={classes.riskTooltip}
          title={
            <>
              Select a tile below to view
              <br />
              its recommendations and risk factors
            </>
          }
        >
          <HelpOutlineIcon fontSize="small" className={classes.icon} />
        </Tooltip>
      </Grid>
      <Grid className={classes.container} item xs={12} spacing={2} container direction="row">
        {matchedProducts.map((product, i) => {
          return (
            ///check selectedproduct against product.id
            <div
              key={product.id}
              className={clsx(classes.ProductGridWrapper, selectedProduct === product.id ? '' : classes.clickable)}
            >
              <Card onClick={() => setSelectedProduct(product.id)} data-testid="product-card">
                <ProductCard product={product} results={results} isSelected={selectedProduct === product.id} />
              </Card>
            </div>
          );
        })}
      </Grid>
    </>
  );
};

export default Products;
