import { ROUTE_CHANGE } from '../history/reducer';
import { State, initialState, RolesActionTypes, ROLES_REQUESTED, ROLES_SUCCESS, ROLES_ERROR } from './types';
import { cancelReq } from './actions';

const rolesReducer = (state: State = initialState, action: RolesActionTypes): State => {
  switch (action.type) {
    case ROLES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case ROLES_ERROR:
      return {
        ...state,
        loading: false,
        roles: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default rolesReducer;
