import { RouteChangeProps } from '../history/reducer';
import { MetadataProps } from '../Utils/types';
import { PatientGroupProps } from '../patient_groups/types';
import { PersonProps } from '../people/types';
import { PatientGroupPeopleBatchResponseProps } from '../patient_groups/types';

export interface PatientGroupChartProps {
  patient_group_id: string;
  numerator: number | null;
  denominator: number | null;
}

export type State = {
  patientGroups: PatientGroupProps[];
  patientGroupsChartTotals: PatientGroupChartProps[];
  patientGroup?: PatientGroupProps | undefined;
  people: {
    metadata?: MetadataProps;
    people: PersonProps[];
  };
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  patientGroups: [],
  patientGroupsChartTotals: [],
  patientGroup: undefined,
  people: {
    people: [],
  },
  loading: false,
  error: undefined,
};

export const MY_PATIENT_GROUP_PEOPLE_RESET = 'MY_PATIENT_GROUP_PEOPLE_RESET';
export const MY_PATIENT_GROUPS_REQUESTED = 'PATIENT_GROUPS_REQUESTED';
export const MY_PATIENT_GROUPS_SUCCESS = 'PATIENT_GROUPS_SUCCESS';
export const MY_PATIENT_GROUPS_ERROR = 'PATIENT_GROUPS_ERROR';
export const MY_PATIENT_GROUP_REQUESTED = 'PATIENT_GROUP_REQUESTED';
export const MY_PATIENT_GROUP_SUCCESS = 'PATIENT_GROUP_SUCCESS';
export const MY_PATIENT_GROUP_ERROR = 'PATIENT_GROUP_ERROR';
export const DELETE_MY_PATIENT_GROUP_SUCCESS = 'DELETE_MY_PATIENT_GROUP_SUCCESS';
export const MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED = 'MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED';
export const MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS = 'MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS';
export const MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR = 'MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR';
export const PATIENT_GROUPS_CHART_TOTALS_REQUESTED = 'PATIENT_GROUPS_CHART_TOTALS_REQUESTED';
export const PATIENT_GROUPS_CHART_TOTALS_SUCCESS = 'PATIENT_GROUPS_CHART_TOTALS_SUCCESS';
export const PATIENT_GROUPS_CHART_TOTALS_ERROR = 'PATIENT_GROUPS_CHART_TOTALS_ERROR';

interface PeopleReset {
  type: typeof MY_PATIENT_GROUP_PEOPLE_RESET;
}
interface MyPatientGroupsLoading {
  type: typeof MY_PATIENT_GROUPS_REQUESTED;
}
interface MyPatientGroupPeopleBatchLoading {
  type: typeof MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED;
}
interface PatientGroupChartTotalLoading {
  type: typeof PATIENT_GROUPS_CHART_TOTALS_REQUESTED;
}
interface MyPatientGroupLoading {
  type: typeof MY_PATIENT_GROUP_REQUESTED;
}
interface MyPatientGroupsSuccess {
  type: typeof MY_PATIENT_GROUPS_SUCCESS;
  payload: PatientGroupProps[];
}
interface MyPatientGroupSuccess {
  type: typeof MY_PATIENT_GROUP_SUCCESS;
  payload: PatientGroupProps;
}
interface MyPatientGroupPeopleBatchSuccess {
  type: typeof MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS;
  payload: PatientGroupPeopleBatchResponseProps;
}
interface PatientGroupChartTotalSuccess {
  type: typeof PATIENT_GROUPS_CHART_TOTALS_SUCCESS;
  payload: PatientGroupChartProps[];
}
interface DeleteMyPatientGroupSuccess {
  type: typeof DELETE_MY_PATIENT_GROUP_SUCCESS;
  payload: string;
}
interface MyPatientGroupsError {
  type: typeof MY_PATIENT_GROUPS_ERROR;
  payload: string;
}
interface MyPatientGroupError {
  type: typeof MY_PATIENT_GROUP_ERROR;
  payload: string;
}
interface MyPatientGroupPeopleBatchError {
  type: typeof MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR;
  payload: string;
}
interface PatientGroupChartTotalError {
  type: typeof PATIENT_GROUPS_CHART_TOTALS_ERROR;
  payload: string;
}

// Action Types
export type PatientGroupsActionTypes =
  | PeopleReset
  | MyPatientGroupLoading
  | MyPatientGroupPeopleBatchLoading
  | MyPatientGroupsLoading
  | PatientGroupChartTotalLoading
  | MyPatientGroupSuccess
  | MyPatientGroupsSuccess
  | MyPatientGroupPeopleBatchSuccess
  | PatientGroupChartTotalSuccess
  | MyPatientGroupsError
  | MyPatientGroupError
  | MyPatientGroupPeopleBatchError
  | PatientGroupChartTotalError
  | DeleteMyPatientGroupSuccess
  | RouteChangeProps;
