import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  HistoricalRecommendationsActionTypes,
  HISTORICAL_RECOMMENDATIONS_REQUESTED,
  HISTORICAL_RECOMMENDATIONS_SUCCESS,
  HISTORICAL_RECOMMENDATIONS_ERROR,
  RESET_HISTORICAL_RECOMMENDATIONS,
} from './types';
import { cancelReq } from './actions';

const recommendationsReducer = (state: State = initialState, action: HistoricalRecommendationsActionTypes): State => {
  switch (action.type) {
    case HISTORICAL_RECOMMENDATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case HISTORICAL_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendations: action.payload,
      };
    case HISTORICAL_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        loading: false,
        recommendations: [],
        error: action.payload,
      };
    case RESET_HISTORICAL_RECOMMENDATIONS:
      return initialState;
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default recommendationsReducer;
