import { RouteChangeProps } from '../history/reducer';

export interface RecommendationLabelProps {
  description: string;
  id: string;
  name: string;
  recommendation_type: 'Clinical' | 'Pharmaceutical';
  updated_dttm: number;
}

export type State = {
  recommendationLabels: RecommendationLabelProps[];
  recommendationLabel?: RecommendationLabelProps;
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  recommendationLabels: [],
  recommendationLabel: undefined,
  loading: false,
  error: undefined,
};

export const RECOMMENDATION_LABELS_REQUESTED = 'RECOMMENDATION_LABELS_REQUESTED';
export const RECOMMENDATION_LABELS_SUCCESS = 'RECOMMENDATION_LABELS_SUCCESS';
export const RECOMMENDATION_LABELS_ERROR = 'RECOMMENDATION_LABELS_ERROR';
export const RECOMMENDATION_LABEL_REQUESTED = 'RECOMMENDATION_LABEL_REQUESTED';
export const RECOMMENDATION_LABEL_SUCCESS = 'RECOMMENDATION_LABEL_SUCCESS';
export const RECOMMENDATION_LABEL_ERROR = 'RECOMMENDATION_LABEL_ERROR';

interface RecommendationLabelsLoading {
  type: typeof RECOMMENDATION_LABELS_REQUESTED;
}
interface RecommendationLabelLoading {
  type: typeof RECOMMENDATION_LABEL_REQUESTED;
}
interface RecommendationLabelsSuccess {
  type: typeof RECOMMENDATION_LABELS_SUCCESS;
  payload: RecommendationLabelProps[];
}
interface RecommendationLabelSuccess {
  type: typeof RECOMMENDATION_LABEL_SUCCESS;
  payload: RecommendationLabelProps;
}
interface RecommendationLabelsError {
  type: typeof RECOMMENDATION_LABELS_ERROR;
  payload: string;
}
interface RecommendationLabelError {
  type: typeof RECOMMENDATION_LABEL_ERROR;
  payload: string;
}
export type RecommendationLabelsActionTypes =
  | RecommendationLabelLoading
  | RecommendationLabelsLoading
  | RecommendationLabelSuccess
  | RecommendationLabelsSuccess
  | RecommendationLabelsError
  | RecommendationLabelError
  | RouteChangeProps;
