import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    padding: theme.spacing(2),
  },
  img: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  dialog: {
    maxWidth: '800px',
    borderRadius: '12px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '15px',
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      background: '#eeeeee',
      borderRadius: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      // outline: '1px solid slategrey',
      borderRadius: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    overflowX: 'hidden',
  },
  dialogTitleWrapper: {
    display: 'flex',
    margin: 0,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentRoot: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
  actions: {
    paddingBottom: 15,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  triggerBtnIcon: {
    // Using Important to override Material UI nested styling for Icon Buttons
    fontSize: '0.688rem!important',
  },
  closeButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.grey[500],
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& hr': {
      width: '133px',
      height: '2px',
      marginBottom: theme.spacing(2),
    },
  },
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#B8C4CE',
    textTransform: 'uppercase',
    width: '133px',
    height: '133px',
    fontSize: '54px',
    marginTop: '3rem',
    marginBottom: theme.spacing(5),
  },
  dateAddedTxt: {
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  dateAdded: {},
  fieldsWrapper: {
    paddingRight: theme.spacing(3),
  },
  checkBoxLabel: {
    color: ' #535353',
    fontWeight: 700,
    fontSize: '14px',
  },
  '.MuiCheckbox-colorPrimary': {
    color: ' #535353',
    height: '30px',
  },
}));

export default useStyles;
