import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  section: {
    marginTop: theme.spacing(3),
  },
  topDivSection: {
    display: 'flex',
    alignItems: 'baseline',
    paddingTop: '10px',
  },
}));

export default useStyles;
