import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { RootStore } from '../../../../configureStore';
import { editContactStatus } from '../../../../Store/people/actions';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface ContactStatusProps {
  defaultValue: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
}));

const ContactStatus: FunctionComponent<ContactStatusProps> = (props: ContactStatusProps) => {
  const { defaultValue } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const person = useSelector((state: RootStore) => state.people.person);

  const [canContact, setCanContact] = useState<string>(defaultValue);

  const classes = useStyles();

  const contactOptions = [
    { key: 'CAN_CONTACT', displayValue: 'CAN CONTACT' },
    { key: 'UNABLE_TO_CONTACT', displayValue: 'UNABLE TO CONTACT' },
    { key: 'DO_NOT_CONTACT', displayValue: 'DO NOT CONTACT' },
  ];

  const handleChange = (event: any) => {
    if (person) {
      setCanContact(event.target.value);
      getAccessTokenSilently().then((token) => {
        dispatch(editContactStatus(token, person.id, event.target.value));
      });
    }
  };

  return (
    <>
      <Typography aria-label="contact-status-label" className={classes.label}>
        Contact Status
      </Typography>
      <FormControl variant={'standard'}>
        <Select
          defaultValue={defaultValue}
          value={canContact}
          onChange={(e) => {
            handleChange(e);
          }}
          displayEmpty
          disableUnderline
        >
          {contactOptions.map((contactItem) => (
            <MenuItem value={contactItem.key} key={contactItem.key}>
              <Typography>{contactItem.displayValue}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ContactStatus;
