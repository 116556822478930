import { RouteChangeProps } from '../history/reducer';

export interface TenantFilterProps {
  alt_label_1?: string;
  alt_label_2?: string;
  column_name?: string;
  enabled: boolean;
  entity_name: string;
  filter_group?: string;
  filter_label?: string;
  filter_type: string;
  help_text?: string;
  id: string;
  name: string;
  product_type?: string;
  sort_order: number;
  updated_dttm?: number;
}

export type State = {
  tenantFilters: TenantFilterProps[];
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  tenantFilters: [],
  loading: false,
  error: undefined,
};

export const TENANT_FILTERS_REQUESTED = 'TENANT_FILTERS_REQUESTED';
export const TENANT_FILTERS_SUCCESS = 'TENANT_FILTERS_SUCCESS';
export const TENANT_FILTERS_ERROR = 'TENANT_FILTERS_ERROR';

interface TenantFiltersLoading {
  type: typeof TENANT_FILTERS_REQUESTED;
}
interface TenantFiltersSuccess {
  type: typeof TENANT_FILTERS_SUCCESS;
  payload: TenantFilterProps[];
}
interface TenantFiltersError {
  type: typeof TENANT_FILTERS_ERROR;
  payload: string;
}
export type TenantFiltersActionTypes =
  | TenantFiltersLoading
  | TenantFiltersSuccess
  | TenantFiltersError
  | RouteChangeProps;
