import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { RolesActionTypes, ROLES_REQUESTED, ROLES_SUCCESS, ROLES_ERROR, RoleProps } from './types';
import axios, { CancelTokenSource } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getRoles = (token: string) => async (dispatch: Dispatch<RolesActionTypes>) => {
  dispatch({ type: ROLES_REQUESTED });
  cancelReq = axios.CancelToken.source();

  authorized(token)
    .get('/roles', { cancelToken: cancelReq.token })
    .then((resp) => {
      // resp.data has metadata for things like pagination
      //               AND data with the array of Roles
      let payload = resp.data.data.sort((a: RoleProps, b: RoleProps) => {
        // JCP-1494 > Patient Viewer should be the first option
        // If seen, then move up, else leave sort alone.
        return a.name.match(/viewer/i) ? -1 : 0;
      });
      dispatch({ type: ROLES_SUCCESS, payload });
    })
    .catch((e) => {
      if (axios.isCancel(e)) {
        // If request cancelled by route change, don't show an error
        //  we will reset state below.
        return console.log(' ----- roles request cancelled');
      }
      dispatch({ type: ROLES_ERROR, payload: e.message });
    })
    .finally(() => {
      cancelReq = undefined;
    });
};
