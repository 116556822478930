import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';

import useStyles from './styles';

const NotFound: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.drawerContent}
    >
      <Grid item>
        <Typography align="center" className={classes.drawerHeading}>
          We didn't find anyone
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center" className={classes.noResultsBody}>
          Make sure your search terms are correct
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.noResultsIndent}>
          <strong>Search tip:</strong> You can specify the first and last names by separating them with a comma (e.g.
          Smith, John). The MRN search is initiated when at least one number is entered.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
