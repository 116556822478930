import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  CommentsActionTypes,
  COMMENT_REQUESTED,
  COMMENTS_REQUESTED,
  COMMENTS_SUCCESS,
  COMMENT_SUCCESS,
  COMMENT_ERROR,
  COMMENTS_ERROR,
  ADD_COMMENT_SUCCESS,
  RecommendationCommentsProps,
} from './types';
import { cancelReq } from './actions';

const commentsReducer = (state: State = initialState, action: CommentsActionTypes): State => {
  switch (action.type) {
    case COMMENT_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case COMMENTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        comment: action.payload,
      };
    case COMMENTS_SUCCESS:
      let recommendationComments: RecommendationCommentsProps = {};
      if (action.payload) {
        action.payload.forEach((comment) => {
          if (comment.recommendation_std_id) {
            recommendationComments[comment.recommendation_std_id] = recommendationComments[
              comment.recommendation_std_id
            ]
              ? [...recommendationComments[comment.recommendation_std_id], comment]
              : [comment];
          }
        });
      }
      return {
        ...state,
        loading: false,
        comments: action.payload,
        recommendationComments,
      };
    case ADD_COMMENT_SUCCESS:
      let updatedRecommendationComments: RecommendationCommentsProps = state.recommendationComments;
      if (action.payload && action.payload.recommendation_std_id) {
        if (updatedRecommendationComments[action.payload.recommendation_std_id]) {
          updatedRecommendationComments[action.payload.recommendation_std_id].push(action.payload);
        } else {
          updatedRecommendationComments[action.payload.recommendation_std_id] = [action.payload];
        }
      }
      return {
        ...state,
        loading: false,
        comment: action.payload,
        comments: [...state.comments, action.payload],
        recommendationComments: updatedRecommendationComments,
      };
    case COMMENT_ERROR:
      return {
        ...state,
        loading: false,
        comment: undefined,
        error: action.payload,
      };
    case COMMENTS_ERROR:
      return {
        ...state,
        loading: false,
        comments: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default commentsReducer;
