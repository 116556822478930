import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  warningIcon: {
    color: 'orange',
    fontSize: '3rem',
    marginRight: useTheme().spacing(2),
    '& > path:first-child': {
      opacity: 0,
    },
  },
}));

export default useStyles;
