import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  HistoricalRecommendationsActionTypes,
  HISTORICAL_RECOMMENDATIONS_REQUESTED,
  HISTORICAL_RECOMMENDATIONS_SUCCESS,
  HISTORICAL_RECOMMENDATIONS_ERROR,
  RESET_HISTORICAL_RECOMMENDATIONS,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

// Some patients don't have recommendations
// instead of loading all recommendations when recommendation_ids are an empty array
// just clear the recommendations array.
export const resetHistoricalRecommendations =
  () => async (dispatch: Dispatch<HistoricalRecommendationsActionTypes>) => {
    // console.log('reset');
    dispatch({ type: RESET_HISTORICAL_RECOMMENDATIONS });
  };

export const getHistoricalRecommendations =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<HistoricalRecommendationsActionTypes>) => {
    dispatch({ type: HISTORICAL_RECOMMENDATIONS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/recommendations', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('recommendation response ----', resp);
        dispatch({
          type: HISTORICAL_RECOMMENDATIONS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- recommendation request cancelled');
        }
        dispatch({ type: HISTORICAL_RECOMMENDATIONS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
