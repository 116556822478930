import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { JvionMultiSelectFieldProps } from '.';

const useStyles = makeStyles<Theme, JvionMultiSelectFieldProps>((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    marginTop: `-${theme.spacing(1)}`,
    maxHeight: 'initial',
    overflow: ({ showMore }) => (showMore ? 'auto' : 'hidden'),
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '15px',
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      background: '#eeeeee',
      borderRadius: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '12px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
  },
  search: {
    maxWidth: '80%',
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      // color: '#24265B',
      marginLeft: '16.5px',
      marginTop: '16.5px',
      marginBottom: '10px',
    },
    '& .MuiInputBase-input': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  list: {
    marginTop: '10px',
  },
  checkboxLabel: {
    margin: 2,
    fontSize: '16px',
    fontWeight: 500,
    // fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
  },
  checkboxContainer: {
    marginBottom: theme.spacing(0.5),
  },
  checkbox: {
    padding: 0,
    paddingRight: theme.spacing(0.5),
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export default useStyles;
