import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  PeopleActionTypes,
  PERSON_REQUESTED,
  PERSON_ERROR,
  PERSON_SUCCESS,
  PEOPLE_REQUESTED,
  PEOPLE_SUCCESS,
  PEOPLE_ERROR,
  CLEAR_PEOPLE,
  PEOPLE_BATCH_REQUESTED,
  PEOPLE_BATCH_SUCCESS,
  EDIT_CONTACT_STATUS_REQUESTED,
  EDIT_CONTACT_STATUS_SUCCESS,
  EDIT_CONTACT_STATUS_ERROR,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getPerson =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PeopleActionTypes>) => {
    dispatch({ type: PERSON_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/people/${id}`, { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('people response ----', resp);
        dispatch({
          type: PERSON_SUCCESS,
          // Needs the below data to work until PUT endpoint is set up in API.
          payload: { ...resp.data },
          // payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- people request cancelled');
        }
        dispatch({ type: PERSON_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getPeople =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PeopleActionTypes>) => {
    dispatch({ type: PEOPLE_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/people', { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('people response ----', resp);
        dispatch({
          type: PEOPLE_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- people request cancelled');
        }
        dispatch({ type: PEOPLE_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const editContactStatus =
  (token: string, id: string, newOption: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PeopleActionTypes>) => {
    dispatch({ type: EDIT_CONTACT_STATUS_REQUESTED });

    authorized(token)
      .put(`/people/${id}`, { contact_status: newOption })
      .then((resp) => {
        // console.log('people response ----', resp);
        dispatch({
          type: EDIT_CONTACT_STATUS_SUCCESS,
          payload: newOption,
        });
      })
      .catch((error) => {
        console.error(error);
        let msg: any;
        if (error.response?.data) {
          msg = error.response.data.details || error.response.data.message;
        }
        // FIXME
        // At times a 500 error could come back with an empty data object or string
        //    from server.  If so, return standard message
        if (msg === undefined || msg === null) msg = error.message;
        dispatch({ type: EDIT_CONTACT_STATUS_ERROR, payload: msg });
      });
  };

export const clearPeople =
  (options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PeopleActionTypes>) => {
    dispatch({ type: CLEAR_PEOPLE });
  };

export const getPeopleBatch =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PeopleActionTypes>) => {
    dispatch({ type: PEOPLE_BATCH_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .get(`/people`, { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        dispatch({
          type: PEOPLE_BATCH_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- people request cancelled');
        }
        dispatch({ type: PEOPLE_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
