import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      height: theme.spacing(6), // Height of nav bar
    },
    logo: {
      display: 'none',
      maxHeight: theme.spacing(5),
    },
    logoLoaded: {
      display: 'block',
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  }),
);

export default useStyles;
