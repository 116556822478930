import { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { RootStore } from '../../configureStore';
import { RecommendationProps } from '../../Store/recommendations/types';
import { editRecommendation } from '../../Store/recommendations/actions';
import { RecommendationLabelProps } from '../../Store/recommendation_labels/types';
import { getRecommendationLabels } from '../../Store/recommendation_labels/actions';
import { RecommendationStepProps } from '../../Store/recommendation_steps/types';
import { getRecommendationSteps } from '../../Store/recommendation_steps/actions';
import RecommendationGridItem from './recommendationGridItem';
import CommentCreateModal from '../../Containers/CommentCreateModal';
import { CommentProps, RecommendationCommentsProps } from '../../Store/comments/types';

import useStyles from './styles';

interface RecommendationViewProps {
  recommendationIds: string[];
  productId: string;
  personId: string;
  comments?: RecommendationCommentsProps;
  onRecommendationCommentClick: (recommendationStdId: string) => void;
}

interface UpdatingState {
  updating: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  apiError?: string;
}

const Recommendations: FunctionComponent<RecommendationViewProps> = (props: RecommendationViewProps) => {
  const classes = useStyles();
  const { recommendationIds, productId, personId, comments, onRecommendationCommentClick } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [updateState, setUpdateState] = useState<UpdatingState>({
    updating: false,
    isSubmitting: false,
    isSubmitted: false,
  });
  const recommendationsState = useSelector((state: RootStore) => state.recommendations);
  const recommendations = recommendationsState.recommendations;
  const recommendationLabels = useSelector((state: RootStore) => state.recommendation_labels.recommendationLabels);
  const recommendationSteps = useSelector((state: RootStore) => state.recommendation_steps.recommendationSteps);
  const [patientRecommendationLabels, setPatientRecommendationLabels] = useState<RecommendationLabelProps[]>([]);
  const [patientRecommendationSteps, setPatientRecommendationSteps] = useState<RecommendationStepProps[]>([]);
  const defaultCommentProps: Partial<CommentProps> = {
    comment_entity: 'RECOMMENDATION',
    person_id: personId,
    product_id: productId,
  };
  const [openCreate, setOpenCreate] = useState<{ open: boolean; newCommentProps: Partial<CommentProps> }>({
    open: false,
    newCommentProps: {},
  });

  const handleAddComment = (newCommentProps: Partial<CommentProps>) =>
    setOpenCreate({ open: true, newCommentProps: { ...defaultCommentProps, ...newCommentProps } });
  const handleCloseAddComment = () =>
    setOpenCreate({
      open: false,
      newCommentProps: {},
    });

  // Recommendation IDs we are working with
  const [currentRecIds, setCurrentRecIds] = useState<String[]>([]);

  // update recommendation API
  const onEditRecommendation = async (newRecommendation: Partial<RecommendationProps>) => {
    await setUpdateState((prevState) => ({ ...prevState, updating: true }));
    await getAccessTokenSilently().then((token) => {
      dispatch(editRecommendation(token, newRecommendation));
    });
  };

  // trigger refetch on success
  useEffect(() => {
    // We only care about this when the modal is open
    if (updateState.updating) {
      // If isSubmitting is false and patientGroupState.loading === true or securityGroupState.loading === true, then we just
      //     triggered a request to Create / Update a group
      if (!updateState.isSubmitting && recommendationsState.loading) {
        setUpdateState((prevState) => ({ ...prevState, isSubmitting: true }));
      }
      // If previously isSubmitting is true and recommendationsState.loading is false
      // This means we got a response from the comment create / update action
      if (updateState.isSubmitting && !recommendationsState.loading) {
        // If error, show it
        //   else, close modal and see new comment
        if (recommendationsState.error) {
          setUpdateState((prevState) => ({
            ...prevState,
            apiError: recommendationsState.error,
            isSubmitted: false,
            isSubmitting: false,
          }));
        } else {
          // on the surface it may look like isSubmitted is not really doing anything
          // but in the future it will allow us to easily show a submitted snack
          setUpdateState((prevState) => ({
            ...prevState,
            apiError: undefined,
            updating: false,
            isSubmitted: true,
            isSubmitting: false,
          }));
        }
      }
    }
  }, [updateState, recommendationsState]);

  // we need to increase recommendations call to all patien rec ids (instead of product specific ones)

  // get recommendations labels and steps
  useEffect(() => {
    // Keep track of the recommendation IDs we are querying
    if (currentRecIds !== recommendationIds) {
      setCurrentRecIds(recommendationIds);
      // Do a query ONLY if there are IDs being passed in
      // Default is to pass in an empty array from parent.
      if (recommendationIds.length) {
        const ids = recommendationIds.join(',');
        getAccessTokenSilently().then((token) => {
          dispatch(getRecommendationSteps(token, { params: { 'recommendation_id[in]': ids } })); // get recommendation steps
          // Labels don't change often, so only get them if they have not been loaded yet.
          if (recommendationLabels.length === 0) dispatch(getRecommendationLabels(token)); // get recommendation labels
        });
      }
    }
  }, [getAccessTokenSilently, dispatch, currentRecIds, recommendationIds, recommendationLabels]);

  // get patient recommmendation labels by filtering all recommendation labels. This method may need to be updated for performance
  useEffect(() => {
    if (recommendationLabels.length > 0 && recommendations.length > 0) {
      const labels = recommendationLabels.filter((recLabel) =>
        recommendations.some((patientRecommendation) => patientRecommendation.recommendation_label_id === recLabel.id),
      );
      setPatientRecommendationLabels(labels);
    }
  }, [recommendations, recommendationLabels]);

  // get patient recommmendation steps by filtering all recommendation steps. This method may need to be updated for performance
  useEffect(() => {
    if (recommendationSteps.length > 0 && recommendations.length > 0) {
      const steps = recommendationSteps.filter((recStep) =>
        recommendations.some((patientRecommendation) => patientRecommendation.id === recStep.recommendation_id),
      );
      setPatientRecommendationSteps(steps);
    }
  }, [recommendations, recommendationSteps]);

  return (
    <>
      <Grid container className={classes.recommendationsContainer}>
        <Typography className={classes.recommendationTitle}>RECOMMENDATIONS</Typography>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.gridHeaderContainer}>
            <Grid item className={clsx(classes.gridHeaderItem, classes.headerExpandMore)} xs={1}>
              {/* <IconButton disableRipple> */}
              <ExpandMoreIcon className={classes.expandMoreIcon} />
              {/* </IconButton> */}
            </Grid>
            <Grid item className={clsx(classes.gridHeaderItem, classes.description)} xs>
              DESCRIPTION
            </Grid>
            <Grid item className={clsx(classes.gridHeaderItem)} xs={2} md={1}>
              UPDATED
            </Grid>
            <Grid item className={clsx(classes.gridHeaderItem, classes.headerCircleIcon)} xs={1} md={1}>
              <CheckCircleOutlineIcon />
            </Grid>
            <Grid item className={clsx(classes.gridHeaderItem)} xs={4} md={3} lg={2}>
              STATUS
            </Grid>
            <Grid item className={clsx(classes.gridHeaderItem)} xs={2} md={2} lg={1}>
              NOTES
            </Grid>
          </Grid>
        </Grid>
        {recommendations.length > 0 ? (
          recommendations
            .filter((rec) => rec.parent_id === null && recommendationIds.includes(rec.id))
            .map((recommendation: RecommendationProps) => (
              <RecommendationGridItem
                key={recommendation.id}
                recommendation={recommendation}
                comments={
                  comments && comments[recommendation.recommendation_std_id]
                    ? comments[recommendation.recommendation_std_id].length
                    : 0
                }
                onChangeStatus={(status) => onEditRecommendation({ ...recommendation, status: status })}
                recommendationLabels={patientRecommendationLabels}
                recommendationSteps={patientRecommendationSteps}
                onRecommendationCommentClick={onRecommendationCommentClick}
                onAddComment={() => {
                  handleAddComment({
                    recommendation_id: recommendation.id,
                    result_id: recommendation.result_id,
                    recommendation_std_id: recommendation.recommendation_std_id,
                  });
                }}
              />
            ))
        ) : (
          <Grid container>
            <Grid item xs>
              No recommendations
            </Grid>
          </Grid>
        )}
      </Grid>
      <CommentCreateModal
        open={openCreate.open}
        commentDefaultProps={openCreate.newCommentProps}
        onClose={handleCloseAddComment}
      />
    </>
  );
};

export default Recommendations;
