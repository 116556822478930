import { UILabelProps } from '../../Store/ui_labels/types';

// Label Child Components
import FormatAge from './Components/FormatAge';
import ContactStatus from './Components/ContactStatus';

/*
  This is a formatter for UILabel values. It returns a function that formats and returns values on the 
  frontend. 

  CHILD_COMPONENT use child component such as process_age to format value
  META This would consist of a JSON Object which could be used to replace values.  
    For example given the key “COMMUNITY”, the value of this object could be “Community” 
    so it display in a more user friendly way.
  REGEX_STRING which would contain a string that will be converted to a REGEX pattern for a match
  MASK_STRING (aka Format) would contain a pattern to help replace string values, 
    such as a phone number or Date of Birth
*/

// keys are component names from database, values are paths to those components
const childComponents: { [key: string]: any } = {
  FormatAge: FormatAge,
  ContactStatus: ContactStatus,
};

export type GetUILabelValueFunction = (initialValue: any, config?: any) => string | React.ReactElement | string[];

export default function GetUILabelValue(label: UILabelProps): GetUILabelValueFunction {
  return (data: { [modelName: string]: any }, config: any = {}) => {
    const { regex_string, mask_string, child_component, meta } = label;
    // Gracefully handle no data, no model in data object or no column for model
    if (!data || !data[label.model_name] || !data[label.model_name][label.column_name]) return '';

    let defaultValue = data[label.model_name][label.column_name].toString();

    /*
      Note: there is no heirachy of value formaters and this component assumes OR 
      data returned from the API, i.e. only the one you want is defined. This means that if multiple
      formatters are defined only one value is returned and how that value is formated is arbitrary
      and based off of the order below. First defined is returned. is none defined original value is
      returned.
    */

    if (child_component) {
      if (Object.keys(childComponents).some((key) => key === child_component)) {
        // had to cast the child component as a certain type for typescript. This illustrates
        // that certain assumptions are made about how corresponding child components work
        // and are designed.
        const ChildComponent = childComponents[child_component];
        return <ChildComponent defaultValue={defaultValue} config={config} />;
      }
      return defaultValue;
    }

    if (meta) {
      // assuming old values are keys in this instance
      const metaObject = JSON.parse(meta);
      return metaObject[defaultValue] || defaultValue;
    }

    if (mask_string) {
      // Javascript doesnt have a format method on the string prototype. I think a replace is what
      // is desired here ? In this case the value provided by the DB needs to have two values
      //
      // Example:
      // phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"); // i.e. output: (444) 444-4444
      //
      // Proposed format from the database. JSON string { find: string; replace: string; }
      const { find, replace } = JSON.parse(mask_string);
      return defaultValue.replace(new RegExp(find), replace);
    }

    if (regex_string) {
      // I don't really understand the use case for this one. It returns
      // and array of matches. I believe this is what is wanted but I'm not sure.
      return defaultValue.match(new RegExp(regex_string));
    }

    return defaultValue;
  };
}
