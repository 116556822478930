import { FunctionComponent } from 'react';

interface FormatAgeProps {
  defaultValue: number;
}

const FormatAge: FunctionComponent<FormatAgeProps> = (props: FormatAgeProps) => {
  const { defaultValue } = props;

  let birth = new Date(defaultValue).getTime();
  let check = new Date().getTime();

  let milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;

  let ageInDays = (check - birth) / milliDay;

  return <>{Math.floor(ageInDays / 365.25)}</>;
};

export default FormatAge;
