import { FunctionComponent, useState, useEffect } from 'react';
import useStyles from '../GroupEditFilters/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { UserData } from '../../Store/users/types';
import { Button, Grid, Typography } from '@mui/material';
import { RootStore } from '../../configureStore';
import { useSelector, useDispatch } from 'react-redux';

import { PatientGroupProps } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import { getUsers } from '../../Store/users/actions';
import { getRoles } from '../../Store/roles/actions';
import JvionMultiSelectField from '../../Components/JvionMultiSelectField';
import SelectedUsersTable from './selectedUsersTable';

interface GroupUsersProps {
  handleEditGroup: (newValue: Partial<PatientGroupProps> | Partial<SecurityGroupProps>) => void;
  groupType: 'patient' | 'security';
  groups: PatientGroupProps[] | SecurityGroupProps[];
  selectedUsersIds: string[];
  onAddUsers: (ids: string[]) => void;
  onRemoveUsers: (ids: string[]) => void;
}

interface UsersState {
  tempSelectedUsersIds: string[];
  selectedUsers: UserData[];
  unselectedUsers: UserData[];
  isLoaded: boolean; // this prevents an unwanted flash on user update
}

const GroupUsers: FunctionComponent<GroupUsersProps> = (props: GroupUsersProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const usersState = useSelector((state: RootStore) => state.users);
  const { selectedUsersIds, groupType, onAddUsers, onRemoveUsers, groups } = props;
  const [state, setState] = useState<UsersState>({
    tempSelectedUsersIds: [],
    selectedUsers: [],
    unselectedUsers: [],
    isLoaded: false,
  });
  const { tempSelectedUsersIds, selectedUsers, unselectedUsers, isLoaded } = state;

  useEffect(() => {
    // isLoaded prevents an unwanted flash on update
    // updates state when api return changes. Errors are handled by parent component and
    // in the case of failure the props will not be updated in child
    if (
      (!usersState.loading && usersState.users.length > 0 && !isLoaded) ||
      selectedUsersIds.length !== selectedUsers.length
    ) {
      // optimization for faster lookup
      const selectedUsersDictionary: { [key: string]: boolean } = {};
      selectedUsersIds.forEach((id) => (selectedUsersDictionary[id] = true));

      setState((prevState) => ({
        ...prevState,
        selectedUsers: usersState.users.filter((user: UserData) => selectedUsersDictionary[user.id as string]),
        unselectedUsers: usersState.users.filter((user: UserData) => !selectedUsersDictionary[user.id as string]),
        tempSelectedUsersIds: [],
        isLoaded: true,
      }));
    }
  }, [selectedUsersIds, selectedUsers.length, usersState, isLoaded]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(
        getUsers(token, {
          params: { include_references: 'roles,patient_groups', sort_by: 'family_name(asc),given_name(asc)' },
        }),
      );
    });
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(getRoles(token));
    });
  }, [dispatch, getAccessTokenSilently]);

  const handleAddUsers = () => {
    onAddUsers(unselectedUsers.map((user) => user.id));
  };

  const handleRemoveUsers = (users: UserData[]) => {
    onRemoveUsers(users.map((user) => user.id));
  };

  const handleAddUser = (id: string) => {
    onAddUsers([id]);
  };

  return (
    <Grid container>
      <Grid item sm={4}>
        <Typography variant="h2" className={classes.filterByHeader}>
          Find Users
        </Typography>

        <Grid container className={classes.filterByContainer}>
          <JvionMultiSelectField
            placeholder="Search by Name"
            fuzzy={true}
            options={unselectedUsers.map((user) => ({
              value: user.id,
              label: `${user.family_name}, ${user.given_name}`.trim(),
            }))}
            handleAddUser={handleAddUser}
            handleBulkAdd={handleAddUsers}
            showMore={15}
            selectOptions={true}
            selected={tempSelectedUsersIds}
          />
        </Grid>
      </Grid>
      <Grid item sm={8} className={classes.contentRight}>
        <Grid container alignItems="center" justifyContent="space-between" className={classes.header}>
          <Typography variant="h2" className={classes.filterByHeader}>
            Users with Access to Group
          </Typography>
          <Button
            sx={{
              pt: 1,
              pb: 1,
              pl: 0.75,
              pr: 0.75,
              fontSize: '0.6875rem', // 11px
              fontWeight: 'bold',
            }}
            onClick={() => handleRemoveUsers(selectedUsers)}
            data-testid="remove-all-users"
          >{`Remove all users (${selectedUsers.length})`}</Button>
        </Grid>
        <SelectedUsersTable
          selectedUsers={selectedUsers}
          groups={groups}
          groupType={groupType}
          handleRemoveUsers={handleRemoveUsers}
        />
      </Grid>
    </Grid>
  );
};

export default GroupUsers;
