import { FunctionComponent, useState } from 'react';
import { Box, Grid, InputBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Search } from '@mui/icons-material';
import { CoolGrey1C, CoolGrey5C, primaryNavBarHeight, secondaryNavBarHeight } from '../../theme';
// Imports only the drawer component, not the items
import PageHeaderDrawer from '../../Containers/PageHeaderDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(4),
      paddingRight: 0,
      backgroundColor: theme.palette.common.white,
      height: secondaryNavBarHeight,
      position: 'sticky',
      top: primaryNavBarHeight,
      zIndex: 1101,
    },
    toolbarItemContainer: {
      borderLeft: `2px solid ${CoolGrey1C}`,
      padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
    },
    search: {
      flexGrow: 1,
      minWidth: '250px',
      '& .MuiInputBase-input': {
        height: '1rem',
        lineHeight: '1rem',
        '&::-webkit-input-placeholder': {
          color: '#000000',
          fontSize: '13px',
          fontWeight: '700',
          opacity: 1,
          letterSpacing: '0px',
        },
      },
    },
    searchIcon: {
      color: CoolGrey5C,
      marginRight: theme.spacing(1.25),
    },
  }),
);

interface PageHeaderProps {
  title?: string;
  headText: string;
}

const PageHeader: FunctionComponent<PageHeaderProps> = (props: PageHeaderProps) => {
  const classes = useStyles();
  const { title, headText } = props;

  // Drawer open state
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Toggles the PageHeaderDrawer component
  const toggleDrawer = (openClose?: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    openClose ? setIsOpen(openClose) : setIsOpen(!isOpen);
  };

  return (
    <Grid container className={classes.root} alignItems="center" justifyContent="space-between">
      <Box>
        {title && <Typography variant="subtitle1">{title}</Typography>}
        <Typography variant="h5">{headText}</Typography>
      </Box>
      <Box display="flex">
        <Box className={classes.toolbarItemContainer} display="flex" alignItems="center">
          <Search className={classes.searchIcon} onClick={toggleDrawer()} />
          <InputBase
            placeholder="Search by Patient Name, MRN or ID"
            className={classes.search}
            onClick={toggleDrawer()}
          />
          <PageHeaderDrawer isOpen={isOpen} toggleDrawer={() => toggleDrawer()} />
        </Box>
      </Box>
    </Grid>
  );
};

export default PageHeader;
