import { RouteChangeProps } from '../history/reducer';
import { MetadataProps } from '../Utils/types';

export interface RiskFactorsProps {
  id: string;
  name: string;
  risk_factor_type: string;
  risk_factor_sub_type: string | null;
  description: string;
  product_id: string;
  person_id: string;
  result_id: string;
  risk_factor_rank: number;
  updated_dttm: number;
}

export interface RiskFactorsResponseProps {
  data: RiskFactorsProps[];
  metadata: MetadataProps;
}

export type State = {
  riskFactors: RiskFactorsProps[];
  loading: boolean;
  metadata?: MetadataProps;
  error?: string;
};

export const initialState: State = {
  riskFactors: [],
  loading: false,
  error: undefined,
};

export const RISK_FACTORS_REQUESTED = 'RISK_FACTORS_REQUESTED';
export const RISK_FACTORS_SUCCESS = 'RISK_FACTORS_SUCCESS';
export const RISK_FACTORS_BATCH_SUCCESS = 'RISK_FACTORS_BATCH_SUCCESS';
export const RISK_FACTORS_ERROR = 'RISK_FACTORS_ERROR';
export const RISK_FACTORS_BATCH_REQUESTED = 'RISK_FACTORS_BATCH_REQUESTED';
export const RESET_RISK_FACTORS = 'RESET_RISK_FACTORS';

interface RiskFactorsLoading {
  type: typeof RISK_FACTORS_REQUESTED;
}
interface RiskFactorsBatchSuccess {
  type: typeof RISK_FACTORS_BATCH_SUCCESS;
  payload: RiskFactorsResponseProps;
}
interface RiskFactorsSuccess {
  type: typeof RISK_FACTORS_SUCCESS;
  payload: RiskFactorsProps[];
}
interface RiskFactorsError {
  type: typeof RISK_FACTORS_ERROR;
  payload: string;
}

interface ResetRiskFactors {
  type: typeof RESET_RISK_FACTORS;
}
export type RiskFactorsActionTypes =
  | RiskFactorsLoading
  | RiskFactorsSuccess
  | RiskFactorsError
  | RiskFactorsBatchSuccess
  | ResetRiskFactors
  | RouteChangeProps;
