import Box from '@mui/material/Box';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import useStyles from './styles';

interface CircularProgressWithLabelProps {
  value: number;
  maxValue: number;
  color: string;
}

export default function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const { value, maxValue, color } = props;
  const classes = useStyles({ color });

  return (
    <Box>
      {/* The svg generated by this library will not support the given design. The 
      generated code has been copied and adapted below to meet the specs. Would
      like to comeback and re-look at this
      <div style={{ width: 75, height: 75 }} className={classes.root}>
        <CircularProgressbar value={value} maxValue={maxValue} text={`${value} OF ${maxValue}`} />
      </div> */}
      <div className={classes.root}>
        <svg className="CircularProgressbar" viewBox="0 0 100 100" data-testid="CircularProgressbar">
          <path
            className="CircularProgressbar-trail"
            d="
              M 50,50
              m 0,-46
              a 46,46 0 1 1 0,92
              a 46,46 0 1 1 0,-92
            "
            strokeWidth="8"
            fillOpacity="0"
            style={{ strokeDasharray: '289.027px, 289.027px', strokeDashoffset: '0px' }}
          ></path>
          <path
            className="CircularProgressbar-path"
            d="
              M 50,50
              m 0,-46
              a 46,46 0 1 1 0,92
              a 46,46 0 1 1 0,-92
            "
            strokeWidth="8"
            fillOpacity="0"
            style={{
              strokeDasharray: '289.027px, 289.027px',
              strokeDashoffset: `${289.027 * ((maxValue - value) / maxValue)}px`,
            }}
          ></path>
          <text className="CircularProgressbar-text CircularProgressbar-value" x="50" y="45">
            {value}
          </text>
          <text className="CircularProgressbar-text" x="50" y="70">
            OF {maxValue}
          </text>
        </svg>
      </div>
    </Box>
  );
}
