import { FunctionComponent, ReactElement } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupIcon from '@mui/icons-material/Group';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { RootStore } from '../../configureStore';
import SideNavItem from './SideNavItem';
import { MENU_OPEN } from '../../Store/whoami/types';
import { isRouteAccessible, getRouteByPath, JvionRouteProps } from '../../Routes';
import patientRoutes, { patientsPaths } from '../../Routes/PatientsRoutes';
import usersRoutes, { usersPaths } from '../../Routes/UsersRoutes';
import useStyles from './styles';

import { ReactComponent as Logo } from '../../Images/jvion-logo.svg';
import JLogo from '../../Images/Icons/jvion-j.js';
import MyGroupsIcon from '../../Images/Icons/myGroupsIcon.js';

interface SideNavLayoutProps {
  contentClassName?: string;
  children?: ReactElement | ReactElement[];
}

export const SideNavLayout: FunctionComponent<SideNavLayoutProps> = (props: SideNavLayoutProps) => {
  const classes = useStyles();
  const { menuOpen, whoami } = useSelector((state: RootStore) => state.current_user);
  const dispatch = useDispatch();
  const handleDrawerToggle = () =>
    dispatch({
      type: MENU_OPEN,
      payload: !menuOpen,
    });
  const { contentClassName, children } = props;

  const isSideNavItemAccessible: (routes: JvionRouteProps[], path: string) => boolean = (routes, path) =>
    !!(
      whoami &&
      getRouteByPath(routes, path) &&
      isRouteAccessible(
        getRouteByPath(routes, path) as JvionRouteProps,
        whoami.roles.map((route) => route.name),
      )
    );

  return (
    <div className={classes.root}>
      <Drawer
        data-testid="menu"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: menuOpen,
          [classes.drawerClose]: !menuOpen,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: menuOpen,
            [classes.drawerClose]: !menuOpen,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            color="primary"
            onClick={handleDrawerToggle}
            aria-label="closebutton"
            data-testid="toggle"
            size="large"
            component="span"
          >
            {menuOpen ? <ChevronLeftIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </IconButton>
        </div>
        <div className={classes.drawerInner}>
          <Toolbar variant="dense" />
          <List className={classes.sideNavList}>
            {isSideNavItemAccessible(patientRoutes, patientsPaths.MyGroups) && (
              <SideNavItem
                icon={<MyGroupsIcon />}
                label="My Groups"
                link={patientsPaths.MyGroups}
                altActivePaths={[patientsPaths.PatientGroups.slice(0, patientsPaths.PatientGroups.lastIndexOf('/'))]}
              />
            )}
          </List>

          <List
            className={clsx(classes.drawerFooter, classes.sideNavList, {
              [classes.drawerOpen]: menuOpen,
              [classes.drawerClose]: !menuOpen,
            })}
          >
            {isSideNavItemAccessible(usersRoutes, usersPaths.Users) && (
              <SideNavItem icon={<GroupIcon />} label="Users" link={usersPaths.Users} />
            )}

            {isSideNavItemAccessible(usersRoutes, usersPaths.GroupManagement) && (
              <SideNavItem icon={<VerifiedUserIcon />} label="Group Management" link={usersPaths.GroupManagement} />
            )}

            <Grid container alignItems="center" justifyContent="center" className={classes.bottomSection}>
              {menuOpen ? (
                <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
                  <Typography variant="caption">Powered by&nbsp;&nbsp;</Typography>
                  <Logo className={classes.logo} />
                </Grid>
              ) : (
                <JLogo className={classes.logo} />
              )}
            </Grid>
          </List>
        </div>
      </Drawer>
      <main className={clsx(classes.content, contentClassName)}>{children}</main>
    </div>
  );
};

export default SideNavLayout;
