import { ReactElement, FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
  }),
);

interface PageContentProps {
  children: (ReactElement | undefined)[] | ReactElement;
}

const PageContent: FunctionComponent<PageContentProps> = (props: PageContentProps) => {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};

export default PageContent;
