import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DarkPurple } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(2),
    padding: '10px 0 10px 0',
    flexGrow: 1,
    color: DarkPurple,
    fontWeight: 'bold',
    fontSize: '14px',
  },
  arrow: {
    borderLeft: '.5px solid',
    borderRadius: 0,
  },
}));

export default useStyles;
