import { ComponentType, FunctionComponent, useMemo } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../Components/Loading';
import { RouteProps, Route } from 'react-router-dom';

export const ProtectedRoute: FunctionComponent<RouteProps> = ({ component, ...args }: RouteProps) => {
  const Component = useMemo(() => {
    return withAuthenticationRequired(component as ComponentType, {
      onRedirecting: () => <Loading fullView={true} />,
    });
  }, [component]);
  return <Route component={Component} {...args} />;
};
