import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { WhoamiActionTypes, WHOAMI_REQUESTED, WHOAMI_SUCCESS, WHOAMI_ERROR } from './types';
import axios, { CancelTokenSource } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getWhoami = (token: string) => async (dispatch: Dispatch<WhoamiActionTypes>) => {
  dispatch({ type: WHOAMI_REQUESTED });
  cancelReq = axios.CancelToken.source();
  authorized(token)
    .get('/portal/whoami', { cancelToken: cancelReq.token })
    .then((resp) => {
      dispatch({
        type: WHOAMI_SUCCESS,
        payload: resp.data,
      });
    })
    .catch((e) => {
      if (axios.isCancel(e)) {
        return console.log(' ----- whoami request cancelled');
      }
      dispatch({ type: WHOAMI_ERROR, payload: e.message });
    })
    .finally(() => {
      cancelReq = undefined;
    });
};
