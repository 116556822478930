import { FunctionComponent } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

import { PersonProps } from '../../Store/people/types';
import { State as PatientsResponse } from '../../Store/patient_groups/types';
import { dateFormatter } from '../../../src/Components/DateTimeRender';

import useStyles from './styles';

interface GroupPatientTableProps {
  activePatientId?: string;
  patients: PatientsResponse['people'];
  handleFetchMorePatients: () => void;
  handlePatientClick: (id: string, idx: number) => void;
}

const GroupPatientTable: FunctionComponent<GroupPatientTableProps> = (props: GroupPatientTableProps) => {
  const classes = useStyles();
  const { activePatientId, patients, handleFetchMorePatients, handlePatientClick } = props;

  return (
    <TableContainer
      className={classes.tableContainer}
      // This is here to make sure the waypoint is invisible on render for endless scroll
      // WARNING styling will affect these values and this formula will need to be updated
      style={
        patients.metadata &&
        (patients.metadata?.next_page !== null ||
          (patients.metadata?.page_number !== null && patients.metadata?.page_number > 1)) &&
        patients.metadata?.page_size !== null
          ? { maxHeight: 42 + 37 * (patients.metadata?.page_size - 1) }
          : {}
      }
      data-testid="patient-table-container"
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>PATIENT</TableCell>
            <TableCell className={classes.dobHeader}>DOB</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.people.map((person: PersonProps, idx: number) => (
            <TableRow
              key={person.id}
              data-testid="patient-row"
              onClick={() => handlePatientClick(person.id, idx)}
              className={clsx(classes.clickableRow, {
                [classes.rowActive]: activePatientId === person.id,
              })}
            >
              <TableCell style={{ display: 'flex' }}>
                <Typography className={classes.patientName}> {`${person.last_name}, ${person.first_name}`}</Typography>
                {patients.metadata &&
                  patients.metadata.total_records !== null &&
                  patients.people.length < patients.metadata.total_records &&
                  patients.people.length - 1 === idx && (
                    <span data-testid="patient-waypoint">
                      <Waypoint onEnter={handleFetchMorePatients} />
                    </span>
                  )}
                {/* <StarsIcon className={classes.starIcon} /> */}
              </TableCell>
              <TableCell className={classes.dob}>{dateFormatter({ date: person.date_of_birth })}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupPatientTable;
