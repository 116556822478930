import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import JvionTextField from '../../Components/JvionTextField';
import { createSecurityGroup } from '../../Store/security_groups/actions';
import { createPatientGroup } from '../../Store/patient_groups/actions';
import { PatientGroupProps, ProductTypeEnum } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import { RootStore } from '../../configureStore';

import useStyles from './styles';

interface GroupCreateModalProps {
  onClose: () => void;
  onSuccess: (
    group: PatientGroupProps | SecurityGroupProps,
    groupType: 'patient' | 'security',
    productType: ProductTypeEnum,
  ) => void;
  open: boolean;
  disableSecurityGroups?: boolean;
}

interface InititalGroupStateI {
  name: string;
  description: string;
  groupType: string;
  productType: ProductTypeEnum;
}

const initialGroupState: InititalGroupStateI = {
  name: '',
  description: '',
  groupType: 'patient',
  productType: 'INPATIENT',
};

interface GroupCreateModalState extends InititalGroupStateI {
  error: {
    name: string;
    description: string;
  };
  isSubmitting: boolean;
  apiError?: string | undefined;
}

const GroupCreateModal: FunctionComponent<GroupCreateModalProps> = (props: GroupCreateModalProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { onClose, open, onSuccess, disableSecurityGroups } = props;
  const [state, setState] = useState<GroupCreateModalState>({
    ...initialGroupState,
    error: {
      name: '',
      description: '',
    },
    isSubmitting: false,
    apiError: undefined,
    productType: 'INPATIENT',
  });
  const { name, description, groupType, error, isSubmitting, apiError, productType } = state;
  const securityGroupState = useSelector((state: RootStore) => state.security_groups);
  const patientGroupState = useSelector((state: RootStore) => state.patient_groups);

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, ...initialGroupState }));
    onClose();
  };

  const handleNameChange = (newName: string) => {
    if (newName.length <= 75) {
      setState((prevState) => ({
        ...prevState,
        name: newName,
        error: { ...error, name: '' },
      }));
    } else {
      setState((prevState) => ({ ...prevState, error: { ...error, name: 'Maximum length 75 characters' } }));
    }
  };

  const handleDescriptionChange = (newDescription: string) => {
    if (newDescription.length <= 500) {
      setState((prevState) => ({ ...prevState, description: newDescription, error: { ...error, description: '' } }));
    } else {
      setState((prevState) => ({ ...prevState, error: { ...error, description: 'Maximum length 500 characters' } }));
    }
  };

  const handleGroupChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setState((prevState) => ({ ...prevState, groupType: value }));
  };
  const handleproductTypeChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setState((prevState) => ({ ...prevState, productType: value as 'INPATIENT' | 'COMMUNITY' }));
  };

  const handleSubmit = async () => {
    const payload = {
      name,
      description,
      enabled: true,
      product_type: productType,
    };
    if (name.length === 0 || description.length === 0) {
      setState((prevState) => ({
        ...prevState,
        error: {
          name: name.length === 0 ? 'Name required' : '',
          description: description.length === 0 ? 'Description required' : '',
        },
      }));
      return;
    }
    if (groupType === 'patient') {
      const token = await getAccessTokenSilently();
      dispatch(createPatientGroup(token, payload));
    }
    if (groupType === 'security') {
      const token = await getAccessTokenSilently();
      dispatch(createSecurityGroup(token, payload));
    }
  };

  useEffect(() => {
    // We only care about this when the modal is open
    if (open) {
      // If isSubmitting is false and patientGroupState.loading === true or securityGroupState.loading === true, then we just
      //     triggered a request to Create / Update a group
      if (!isSubmitting && (patientGroupState.loading || securityGroupState.loading)) {
        setState((prevState) => ({ ...prevState, isSubmitting: true }));
      }
      // If previously isSubmitting was true and now it's false
      //  The means we got a response from the group create / update action
      if (
        isSubmitting &&
        ((groupType === 'patient' && !patientGroupState.loading) ||
          (groupType === 'security' && !securityGroupState.loading))
      ) {
        // If error, show it
        //   else, close modal and see new group

        if (patientGroupState.error || securityGroupState.error) {
          setState((prevState) => ({
            ...prevState,
            apiError: patientGroupState.error || securityGroupState.error,
            isSubmitting: false,
          }));
        } else {
          setState((prevState) => ({ ...prevState, apiError: undefined, isSubmitting: false }));
          if (patientGroupState.patientGroup && groupType === 'patient' && productType === 'INPATIENT') {
            onSuccess(patientGroupState.patientGroup, 'patient', 'INPATIENT');
          } else if (patientGroupState.patientGroup && groupType === 'patient' && productType === 'COMMUNITY') {
            onSuccess(patientGroupState.patientGroup, 'patient', 'COMMUNITY');
          } else if (securityGroupState.securityGroup && groupType === 'security') {
            onSuccess(securityGroupState.securityGroup, 'security', undefined);
          }
        }
      }
    }
  }, [open, patientGroupState, securityGroupState, isSubmitting, onSuccess, groupType, productType]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: classes.dialog,
        }}
        fullWidth
        open={open}
        onClose={handleClose}
        data-testid="group-create-modal"
        aria-labelledby="form-dialog-init-group-form"
      >
        <DialogContent className={classes.content}>
          <Grid container justifyContent="center">
            <Typography variant="h6">Create New Group</Typography>
          </Grid>
          {apiError && (
            <Grid container justifyContent="center">
              <Alert
                severity="error"
                data-testid="form-alert"
                onClose={() =>
                  setState((prevState) => {
                    return { ...prevState, apiError: undefined };
                  })
                }
              >
                {apiError}
              </Alert>
            </Grid>
          )}
          <Grid container>
            <Grid item sm={12}>
              <JvionTextField
                fullWidth
                label="Group Name"
                required
                inputProps={{
                  'data-testid': 'name',
                }}
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
                error={error.name.length > 0}
                helperText={error.name.length ? error.name : false}
              />
            </Grid>
            <Grid item sm={12}>
              <JvionTextField
                fullWidth
                multiline
                label="Group Description"
                required
                inputProps={{
                  'data-testid': 'description',
                }}
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                error={error.description.length > 0}
                helperText={error.description.length ? error.description : false}
              />
            </Grid>
          </Grid>

          <Grid className={classes.selectGroupTypeContainer} container alignItems="center">
            {!disableSecurityGroups && (
              <>
                <Grid item sm={4}>
                  <Typography variant="body2">
                    <strong>Group Type</strong>
                  </Typography>
                </Grid>
                <Grid item sm={8}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="groupType"
                      name="groupType"
                      value={groupType}
                      onChange={handleGroupChange}
                      className={classes.radioGroupWithTooltip}
                    >
                      <FormControlLabel
                        value="patient"
                        control={<Radio className={classes.radio} color="primary" />}
                        label="Patient Group"
                        labelPlacement="end"
                        className={classes.radioLabelWithTooltip}
                      />
                      <Tooltip
                        className={classes.radioTooltip}
                        disableFocusListener
                        title="A patient group is used to organize similar patients."
                      >
                        <IconButton disableRipple disableFocusRipple size="large">
                          <InfoIcon fontSize="small" color="secondary" />
                        </IconButton>
                      </Tooltip>

                      <FormControlLabel
                        value="security"
                        control={<Radio className={classes.radio} color="primary" />}
                        label="Security Group"
                        labelPlacement="end"
                        className={classes.radioLabelWithTooltip}
                      />
                      <Tooltip
                        className={classes.radioTooltip}
                        disableFocusListener
                        title="A security group is used to limit the patients a user can access."
                      >
                        <IconButton size="small">
                          <InfoIcon fontSize="small" color="secondary" />
                        </IconButton>
                      </Tooltip>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          {groupType === 'patient' && (
            <Grid container>
              <Grid item sm={4}>
                <Typography variant="body2">
                  <strong>Setting</strong>
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="groupType"
                    name="groupType"
                    value={productType}
                    onChange={handleproductTypeChange}
                  >
                    <FormControlLabel
                      value="INPATIENT"
                      control={<Radio className={classes.radio} color="primary" />}
                      label="Inpatient"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="COMMUNITY"
                      control={<Radio className={classes.radio} color="primary" />}
                      label="Community"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Button
              data-testid="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Get Started
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupCreateModal;
