import { memo } from 'react';
import { Switch, Redirect, RouteProps } from 'react-router-dom';
import { ProtectedRoute } from './Components/ProtectedRoute';

import { RoleNames } from '../Store/roles/types';
import TopNavRoutes from './TopNavBarRoutes';
import PatientsRoutes from './PatientsRoutes';
import UsersRoutes from './UsersRoutes';
import { useSelector } from 'react-redux';
import { RootStore } from '../configureStore';
import _ from 'lodash';

export interface JvionRouteProps extends RouteProps {
  roles?: RoleNames[];
}

export const isRouteAccessible: (route: JvionRouteProps, roles: RoleNames[]) => boolean = (route, roles) =>
  route?.roles ? _.intersection(roles, route.roles).length > 0 : true;

export const getRouteByPath: (routes: JvionRouteProps[], path: string) => JvionRouteProps | undefined = (
  routes,
  path,
) => routes.find((route) => route.path === path);

export default memo(() => {
  const routes: JvionRouteProps[] = [...TopNavRoutes, ...PatientsRoutes, ...UsersRoutes];
  const currentUserRoles = useSelector((state: RootStore) => state.current_user.whoami?.roles);

  // Note: we have to define atleast the root route for everyone our else
  // the authentication flow is broken
  const filteredRoutes = routes.filter((route) =>
    currentUserRoles
      ? isRouteAccessible(
          route,
          currentUserRoles.map((role) => role.name),
        )
      : route.path === '/',
  );

  return (
    <Switch>
      {filteredRoutes.map((route, idx) => (
        <ProtectedRoute exact path={route.path} component={route.component} key={`${idx}-${route.path}`} />
      ))}
      <Redirect to="/" />
    </Switch>
  );
});
