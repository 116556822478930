import { FunctionComponent, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { RiskFactorsProps } from '../../Store/risk_factors/types';
import SectionWrapper from '../SectionWrapper';
import SectionHeader from '../SectionHeader';
import SectionPrimaryContent from '../SectionPrimaryContent';
import SectionShowMore from '../SectionShowMore';
import { RootStore } from '../../configureStore';

import useStyles from './styles';

export interface RiskProfileProps {
  selectedProduct: string;
}

export interface FilterOptions {
  value: string;
}

const RiskProfile: FunctionComponent<RiskProfileProps> = (props: RiskProfileProps) => {
  const { selectedProduct } = props;

  const [sortedRisks, setSortedRisks] = useState<RiskFactorsProps[]>([]);
  const [allRisksFactors, setAllRisksFactors] = useState([...sortedRisks]);
  const [toggled, setToggled] = useState({ showMore: false, collapse: true });

  const [activeFilterButtonIndex, setActiveFilterButtonIndex] = useState(0);
  const riskFactors = useSelector((state: RootStore) => state.risk_factors.riskFactors);

  const classes = useStyles();

  const riskProfileCollapseLabel: string = 'riskProfileShowMoreCollapse';

  const filterRiskFactors = (filterOption: string, index: number) => {
    setActiveFilterButtonIndex(index);
    let r: RiskFactorsProps[] = [];
    const newState = [...sortedRisks];
    switch (filterOption) {
      case 'Clinical':
        r = newState.filter((rFactor) => rFactor.risk_factor_type.toLocaleLowerCase().startsWith('clinical'));
        return setAllRisksFactors(r);
      case 'Socioeconomic':
        r = newState.filter((rFactor) => rFactor.risk_factor_type.toLowerCase().startsWith('socio'));
        return setAllRisksFactors(r);
      default:
        return setAllRisksFactors(newState);
    }
  };

  useEffect(() => {
    setActiveFilterButtonIndex(0);
    // Sort once
    const tmpRisks = sortBy(riskFactors, (risk: RiskFactorsProps) => risk.risk_factor_rank);
    // Use later to filter the Risks to show.  i.e. Source
    setSortedRisks([...tmpRisks]);
    // What will be displayed
    setAllRisksFactors([...tmpRisks]);
  }, [riskFactors]);

  let options: any = [{ value: 'All' }, { value: 'Clinical' }, { value: 'Socioeconomic' }];
  const renderFilterOptions = () =>
    options.map((opt: any, i: number) => (
      <Button
        data-testid={opt.value}
        key={opt.value}
        disableFocusRipple
        className={i === activeFilterButtonIndex ? classes.activeFilterOption : classes.inactiveFilterOption}
        onClick={(e) => filterRiskFactors(opt.value, i)}
      >
        <span className={classes.filterText}>{opt.value}</span>
      </Button>
    ));

  const filteredRows = allRisksFactors.filter((rFactor) => rFactor.product_id === selectedProduct);

  const rowsToRender = toggled.showMore === false ? filteredRows.slice(0, 5) : filteredRows;

  return (
    <SectionWrapper className={classes.section} getState={setToggled} collapseLabel={riskProfileCollapseLabel}>
      <SectionHeader variant="span">
        <Grid container className={classes.filterOptionContainer}>
          <Typography className={classes.sectionTitle}>RISK FACTORS</Typography>
          <Grid container className={classes.filterOptions}>
            {renderFilterOptions()}
          </Grid>
        </Grid>
      </SectionHeader>
      <SectionPrimaryContent>
        {riskFactors.length === 0 ? (
          <Typography>No Risks Found</Typography>
        ) : (
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow className={classes.descriptionRow}>
                  <TableCell colSpan={2} className={classes.description}>
                    DESCRIPTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsToRender.map((r) => {
                  return (
                    <TableRow key={r.id} data-testid={'riskFactors'}>
                      <TableCell>
                        <Typography className={classes.riskDescriptionText}>{r.description}</Typography>
                      </TableCell>

                      <TableCell align="right" className={classes.riskFactorTypes}>
                        <Chip
                          label={r.risk_factor_type.startsWith('Socio') ? 'Socioeconomic' : r.risk_factor_type}
                        ></Chip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {filteredRows.length > 5 && <SectionShowMore>{}</SectionShowMore>}
          </TableContainer>
        )}
      </SectionPrimaryContent>
    </SectionWrapper>
  );
};

export default RiskProfile;
