import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  multiSelectOverride: {
    overflow: 'auto',
    width: '100%',
    maxWidth: 'unset',
    maxHeight: '11rem',
  },
}));

export default useStyles;
