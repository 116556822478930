import { FunctionComponent, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Skeleton from '@mui/material/Skeleton';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { OrderingState } from './index';
import { RootStore } from '../../configureStore';
import { getTenantFilters } from '../../Store/tenant_filters/actions';
import {
  PatientGroupProps,
  PatientAndSecurityGroupFilter,
  OrderedPatientGroup,
} from '../../Store/patient_groups/types';
import { SecurityGroupProps, OrderedSecurityGroup } from '../../Store/security_groups/types';
import GroupPatientCounter from '../../Components/GroupPatientCounter';

import useStyles from './styles';

interface GroupManagementTableProps {
  groupType: 'patient' | 'security';
  productType?: 'INPATIENT' | 'COMMUNITY' | null;
  headers: { id: keyof PatientGroupProps | keyof SecurityGroupProps | 'patients'; label: string; sortable: boolean }[];
  groups: OrderedPatientGroup[] | OrderedSecurityGroup[];
  handleGroupClick: (editGroupId: string, editGroupType: 'patient' | 'security') => void;
  handleGroupDelete: (deleteGroupId: string, editGroupType: 'patient' | 'security') => void;
  ordering: OrderingState;
  onSort: (newOrdering: OrderingState) => void;
  loading: boolean;
}

const GroupManagementTable: FunctionComponent<GroupManagementTableProps> = (props: GroupManagementTableProps) => {
  const classes = useStyles();
  const { groups, groupType, handleGroupClick, handleGroupDelete, ordering, onSort, headers, loading } = props;
  const { orderBy, order } = ordering;
  const [page, setPage] = useState<number>(0);
  const tenantFiltersState = useSelector((state: RootStore) => state.tenant_filters);

  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const rowsPerPage = 15;
  const pagedGroups: OrderedPatientGroup[] | OrderedSecurityGroup[] = groups.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  useEffect(() => {
    // Only fetch once if has not already fetched tenant filters
    if (!hasInitialized && !tenantFiltersState.tenantFilters.length) {
      setHasInitialized(true);
      getAccessTokenSilently().then((token) => dispatch(getTenantFilters(token)));
    }
  }, [dispatch, getAccessTokenSilently, hasInitialized, tenantFiltersState.tenantFilters.length]);

  useEffect(() => {
    setPage(0);
  }, [props.groupType]);

  const renderFilterChips = (filters: PatientAndSecurityGroupFilter[]) => {
    let chips: any = [];
    let sortedFilters: any = [];
    let spansArray: any = [];
    const highestIndex = 3;

    if (filters) {
      filters
        .map((f, idx) => {
          const { model, field, value } = f;
          const found = tenantFiltersState.tenantFilters.find(
            (f) => f.entity_name === model && f.filter_group === 'patient_group' && f.column_name === field,
          );
          const fieldLabel: any = found ? found.filter_label : model;

          return { name: fieldLabel, value: value, sortOrder: found ? found.sort_order : 1 };
        })
        .sort((a: any, b: any) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .forEach((f, idx) => {
          if (idx <= highestIndex) {
            sortedFilters.push(f);
          } else {
            spansArray.push(f);
          }
        });

      sortedFilters.forEach((i: any, idx: number) => {
        const { name, value } = i;

        chips.push(
          <Box mr={1} component="span" key={name + idx}>
            <Chip label={`${name}: ${value ? value.length : 0}`} />
          </Box>,
        );
      });

      let spans = spansArray.map((f: any, idx: number) => {
        const { name, value } = f;
        return (
          <span key={name + idx}>
            <span key={name + idx}>{`${name} : ${value?.length}`}</span> <br />
          </span>
        );
      });
      if (spans.length) {
        chips.push(
          <Tooltip style={{ height: '1rem' }} title={spans} key={'more'}>
            <Box mr={1} component="span">
              <Chip label="..." />
            </Box>
          </Tooltip>,
        );
      }
    }
    return chips;
  };

  return (
    <TableContainer data-testid="group-table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => (
              <TableCell key={idx}>
                {header.sortable ? (
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={() => onSort({ orderBy: header.id, order } as OrderingState)}
                    data-testid="sort-button"
                  >
                    {header.label}
                    {orderBy === header.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && pagedGroups.length === 0
            ? Array(15)
                .fill('')
                .map((number, index) => (
                  <TableRow key={`group-management-table-row-skeleton-loader-${index}`} data-testid="group-row">
                    <TableCell className={classes.skeletonLoaderCell}>
                      <Skeleton height={25} />
                    </TableCell>
                    <TableCell className={classes.skeletonLoaderCell}>
                      <Skeleton height={25} />
                    </TableCell>
                    <TableCell className={classes.skeletonLoaderCell}>
                      <Skeleton height={25} />
                    </TableCell>
                    <TableCell className={classes.skeletonLoaderCell}>
                      <Skeleton height={25} />
                    </TableCell>
                    <TableCell align="right" className={classes.skeletonLoaderCell}>
                      <IconButton size="large">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            : pagedGroups.map((group: OrderedPatientGroup | OrderedSecurityGroup) => (
                <TableRow
                  key={group.id}
                  data-testid="group-row"
                  hover
                  className={classes.clickableRow}
                  onClick={() => handleGroupClick(group.id as string, groupType)}
                >
                  <TableCell className={classes.groupName} data-testid="group-name">
                    {group.name}
                  </TableCell>
                  <TableCell>
                    <GroupPatientCounter patientCount={group.patients} />
                  </TableCell>
                  <TableCell>{group.users}</TableCell>
                  <TableCell>
                    {group.group_filters &&
                      group.group_filters.filters &&
                      renderFilterChips(group.group_filters.filters)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      data-testid="group-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGroupDelete(group.id as string, groupType);
                      }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        labelRowsPerPage="Showing"
        count={groups.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        data-testid="group-pagination"
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </TableContainer>
  );
};

export default GroupManagementTable;
