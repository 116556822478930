import React, { ReactElement, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import ActionAlerts from '../../Components/AlertBox';

import Skeleton from '@mui/material/Skeleton';

import { UserData } from '../../Store/users/types';

// import { Link } from 'react-router-dom';

export type Order = 'asc' | 'desc';

export interface UsersHeader {
  disablePadding: boolean;
  id: keyof UserData;
  label: string;
  numeric: boolean;
  formatValue?: (props: { column: keyof UserData; user: UserData }) => string | ReactElement | (JSX.Element | null)[];
  className?: string;
}

export interface UserTableProps {
  users: UserData[];
  headers: UsersHeader[];
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  updateSelected: (event: React.MouseEvent<unknown>, name: string) => void;
  selected: string[];
  page: number;
  rowsPerPage: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserData) => void;
  totalUsers: number;
  loading: boolean;
  classes: any;
}

const UsersTable = (props: UserTableProps) => {
  const {
    classes,
    users,
    headers,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    onSelectAllClick,
    onChangePage,
    onRequestSort,
    updateSelected,
    loading,
    totalUsers,
  } = props;

  const createSortHandler = (property: keyof UserData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const renderedHeaders = useMemo(() => {
    const numSelected = selected.length;
    const rowCount = users ? users.length : 0;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              data-testid="users-select-all"
              color="secondary"
            />
          </TableCell>
          {headers.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'checkbox'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={headCell.className ? headCell.className : ''}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                data-testid="sort-button"
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, order, orderBy, selected]);

  return (
    <div className={classes.tableRoot}>
      <TableContainer>
        <Table
          id="usersTable"
          data-testid="users-table"
          className={classes.table}
          aria-labelledby="usersTable"
          size={'medium'}
          aria-label="users table"
        >
          {renderedHeaders}
          <TableBody data-testid="users-table-body">
            {loading
              ? Array(20)
                  .fill('')
                  .map((number, index) => (
                    <TableRow key={`tablerow-${index}-skeleton-loader`}>
                      <TableCell padding="checkbox" className={classes.skeletonSpaced}>
                        <Skeleton height={25} />
                      </TableCell>
                      {headers.map((h, index) => {
                        return (
                          <TableCell key={`loading-header-${index}`} className={classes.skeletonSpaced}>
                            <Skeleton height={25} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              : users.map((user, index) => {
                  const isItemSelected = isSelected(user.id);
                  const labelId = `enhanced-table-checkbox-${user.id}-${index}`;
                  return (
                    <TableRow
                      hover
                      className={classes.clickableRow}
                      key={user.id}
                      selected={isItemSelected}
                      data-testid="user-row"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          role="checkbox"
                          aria-checked={isItemSelected}
                          onClick={(event) => {
                            updateSelected(event, user.id);
                          }}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="secondary"
                        />
                      </TableCell>
                      {loading
                        ? headers.map((h, index) => {
                            return (
                              <TableCell key={`loading-header-${index}`} className={classes.skeletonSpaced}>
                                <Skeleton height={25} />
                              </TableCell>
                            );
                          })
                        : headers.map((h) => {
                            let val =
                              typeof h.formatValue === 'function' ? h.formatValue({ column: h.id, user }) : user[h.id];
                            return (
                              <TableCell
                                key={user.id + '-' + h.id}
                                align="left"
                                className={h.className ? h.className : ''}
                              >
                                {h.formatValue ? h.formatValue({ column: h.id, user }) : val}
                              </TableCell>
                            );
                          })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        labelRowsPerPage="Showing"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={onChangePage}
      />
      {users.length === 0 && (
        <ActionAlerts
          disabled="true"
          alertSeverity="warning"
          alertMessage="Uh oh. We were not able to find any users."
        ></ActionAlerts>
      )}
    </div>
  );
};

export default UsersTable;
