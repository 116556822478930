import { RouteChangeProps } from '../history/reducer';
import { RecommendationProps } from '../recommendations/types';

export type State = {
  recommendations: RecommendationProps[];
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  recommendations: [],
  loading: false,
  error: undefined,
};

export const RESET_HISTORICAL_RECOMMENDATIONS = 'RESET_HISTORICAL_RECOMMENDATIONS';
export const HISTORICAL_RECOMMENDATIONS_REQUESTED = 'HISTORICAL_RECOMMENDATIONS_REQUESTED';
export const HISTORICAL_RECOMMENDATIONS_SUCCESS = 'HISTORICAL_RECOMMENDATIONS_SUCCESS';
export const HISTORICAL_RECOMMENDATIONS_ERROR = 'HISTORICAL_RECOMMENDATIONS_ERROR';

interface HistoricalRecommendationsReset {
  type: typeof RESET_HISTORICAL_RECOMMENDATIONS;
}

interface HistoricalRecommendationsLoading {
  type: typeof HISTORICAL_RECOMMENDATIONS_REQUESTED;
}

interface HistoricalRecommendationsSuccess {
  type: typeof HISTORICAL_RECOMMENDATIONS_SUCCESS;
  payload: RecommendationProps[];
}

interface HistoricalRecommendationsError {
  type: typeof HISTORICAL_RECOMMENDATIONS_ERROR;
  payload: string;
}

export type HistoricalRecommendationsActionTypes =
  | HistoricalRecommendationsLoading
  | HistoricalRecommendationsSuccess
  | HistoricalRecommendationsError
  | RouteChangeProps
  | HistoricalRecommendationsReset;
