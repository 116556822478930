import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

interface MultiSelectOptionsProps {
  handleBulkAdd: () => void;
  selectedLength: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnTitle: {
      textTransform: 'uppercase',
      fontSize: '0.85rem',
    },
    button: {
      paddingLeft: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
  }),
);

export const MultiSelectOptions = (props: MultiSelectOptionsProps) => {
  const classes = useStyles();
  const { handleBulkAdd, selectedLength } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography className={classes.columnTitle}>&nbsp;&nbsp;users</Typography>
      <Button
        onClick={() => selectedLength > 0 && handleBulkAdd()}
        data-testid={'multi-select-filter-bulk-add'}
      >{`add all users (${selectedLength})`}</Button>
    </Grid>
  );
};
