import { FunctionComponent, useEffect } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import GetAppIcon from '@mui/icons-material/GetApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import { RootStore } from '../../configureStore';
import { clearPeople, getPerson } from '../../Store/people/actions';
import PatientViewSection from '../PatientViewSection';
// import getUILabels from '../../Utils/UILabels';
import getUILabelsArray from '../../Utils/UILabels/uiLabelsArray';

import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

import { ProductTypeEnum } from '../../Store/patient_groups/types';
import { dateFormatter } from '../../Components/DateTimeRender';

interface GroupPatientContentPaneProps {
  patientId: string;
  prev?: () => void;
  next?: () => void;
  productType: ProductTypeEnum;
}

const GroupPatientContentPane: FunctionComponent<GroupPatientContentPaneProps> = (
  props: GroupPatientContentPaneProps,
) => {
  const classes = useStyles();
  const { patientId, prev, next, productType } = props;
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const people = useSelector((state: RootStore) => state.people);
  const uiLabels = useSelector((state: RootStore) => state.ui_labels.uiLabels);

  // This is how you would get labels with defaults
  // const uiLabelsByModel = useSelector((state: RootStore) => state.ui_labels.uiLabelsByModel);

  // const { date_of_birth, gender, external_id } = getUILabels(
  //   ['date_of_birth', 'date_of_birth', 'gender', 'external_id'],
  //   {
  //     model_name: 'Person',
  //     component_type: 'patient_info',
  //     section_name: 'header',
  //     tenantUILabels: uiLabelsByModel,
  //   },
  // );

  const tenantUILabels = getUILabelsArray({
    component_type: 'patient_info',
    section_name: 'header',
    tenantUILabels: uiLabels,
  });

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(getPerson(token, patientId));
    });
    return () => {
      dispatch(clearPeople());
    };
  }, [getAccessTokenSilently, dispatch, patientId]);

  const { person } = people;

  const getAge = (date: string | undefined) => {
    if (date !== undefined) {
      let birth = new Date(date).getTime();
      let check = new Date().getTime();

      let milliDay = 1000 * 60 * 60 * 24; // a day in milliseconds;

      let ageInDays = (check - birth) / milliDay;

      return Math.floor(ageInDays / 365.25);
    } else {
      return;
    }
  };

  let dateOfBirthOptions = [
    people?.person?.date_of_birth ? dateFormatter({ date: people.person.date_of_birth }) : '',
    getAge(people?.person?.date_of_birth),
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }} className={classes.content}>
      <Box alignItems="center" justifyContent="space-between" className={classes.patientPagination}>
        <Grid className={classes.header}>
          <Grid item xs={12} container direction="row" className={classes.headerGridItem}>
            {people.loading ? (
              <Skeleton variant="rectangular" height={25} width={300} className={classes.skeletonTitle} />
            ) : (
              <Typography className={classes.title}>
                {`${people?.person?.first_name} ${people?.person?.last_name}`}
                {/* <StarsIcon className={classes.starIcon} /> */}
              </Typography>
            )}
            <Box borderLeft={1} borderRight={1} display="flex" className={classes.GreyText}>
              <Box borderRight={1} className={classes.GreyText}>
                <IconButton disabled={!prev} onClick={prev} data-testid="prev-patient" size="large">
                  <ChevronLeft />
                </IconButton>
              </Box>
              <Box>
                <IconButton disabled={!next} onClick={next} data-testid="next-patient" size="large">
                  <ChevronRight />
                </IconButton>
              </Box>
              <Box borderLeft={1} className={classes.border}>
                <IconButton className={classes.iconGroup} size="large">
                  <GetAppIcon className={clsx(classes.downloadIcon, classes.disabledIcon)} />
                </IconButton>
              </Box>
              <Box borderLeft={1} className={classes.border}>
                <IconButton className={classes.iconGroup} size="large">
                  <SettingsIcon className={clsx(classes.settingsIcon, classes.disabledIcon)} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Divider />
          <Grid item xs={12} container direction="row">
            {tenantUILabels.map((tenantUILabel) => (
              <Grid item className={classes.headerEntry} key={tenantUILabel.id}>
                <Typography className={classes.headerLabel}>{tenantUILabel.label_text}</Typography>
                {people.loading ? (
                  <Typography className={classes.headerRow}>
                    <Skeleton variant="rectangular" width={50} />
                  </Typography>
                ) : (
                  <Typography className={classes.headerRow}>
                    {/* This will become this once we have data for date of birth formaters
                    {person && tenantUILabel.formatValue(person[tenantUILabel.column_name as keyof PersonProps])} 
                  */}
                    {tenantUILabel.column_name === 'date_of_birth'
                      ? dateOfBirthOptions.shift()
                      : person && tenantUILabel.formatValue({ Person: person })}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      {person !== null && person !== undefined && (
        <PatientViewSection
          peopleLoading={people.loading}
          person={person}
          patientId={patientId}
          productType={productType}
        />
      )}
    </Box>
  );
};

export default GroupPatientContentPane;
