import { RouteChangeProps } from '../history/reducer';

export interface TenantSettingProps {
  id: string;
  name: string;
  value: string;
  updated_dttm: number;
}

export type State = {
  tenantSettings: TenantSettingProps[];
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  tenantSettings: [],
  loading: false,
  error: undefined,
};

export const TENANT_SETTINGS_REQUESTED = 'TENANT_SETTINGS_REQUESTED';
export const TENANT_SETTINGS_SUCCESS = 'TENANT_SETTINGS_SUCCESS';
export const TENANT_SETTINGS_ERROR = 'TENANT_SETTINGS_ERROR';

interface TenantSettingsLoading {
  type: typeof TENANT_SETTINGS_REQUESTED;
}
interface TenantSettingsSuccess {
  type: typeof TENANT_SETTINGS_SUCCESS;
  payload: TenantSettingProps[];
}
interface TenantSettingsError {
  type: typeof TENANT_SETTINGS_ERROR;
  payload: string;
}
export type TenantSettingsActionTypes =
  | TenantSettingsLoading
  | TenantSettingsSuccess
  | TenantSettingsError
  | RouteChangeProps;
