import { createContext } from 'react';
import { initialTenantSwitchingState, TenantSwitchingStateI } from './tenant-switching-state';

export interface TenantSwitchingContextI extends TenantSwitchingStateI {
  hasMultiTenants: () => boolean;
  switchTenant: () => void;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <TenantSwitchingProvider>.');
};

const initialContext = {
  ...initialTenantSwitchingState,
  hasMultiTenants: stub,
  switchTenant: stub,
};

/**
 * The TenantSwitching Context
 */
const TenantSwitchingContext = createContext<TenantSwitchingContextI>(initialContext);

export default TenantSwitchingContext;
