import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
  },

  arrow: {
    borderLeft: '.5px solid',
    borderRadius: 0,
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
  labelRow1: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-start',
    paddingRight: '32px',
    marginBottom: theme.spacing(3),
  },
  showMoreRows: {
    marginBottom: theme.spacing(3),
  },
  dataRow: {
    paddingTop: '5px',
    paddingBottom: '5px',
    justifyContent: 'space-between',
    paddingRight: '32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  moreInfoWrapper: {
    marginLeft: '27px',
    marginBottom: theme.spacing(1),
  },
  moreInfo: {
    fontSize: '0.6875rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
  },
}));

export default useStyles;
