import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  UILabelsByModel,
  UILabelsActionTypes,
  UI_LABELS_REQUESTED,
  UI_LABELS_SUCCESS,
  UI_LABELS_ERROR,
} from './types';
import { cancelReq } from './actions';

const uiLabelsReducer = (state: State = initialState, action: UILabelsActionTypes): State => {
  switch (action.type) {
    case UI_LABELS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UI_LABELS_SUCCESS:
      // This makes a dictionary of options by model name and then column name.
      // Organizing this first makes lookups a lot faster
      let uiLabelsByModel: UILabelsByModel = {};
      action.payload.forEach((label) => {
        if (uiLabelsByModel[label.model_name]) {
          if (uiLabelsByModel[label.model_name][label.column_name]) {
            uiLabelsByModel[label.model_name][label.column_name] = [
              ...uiLabelsByModel[label.model_name][label.column_name],
              label,
            ];
          } else {
            uiLabelsByModel[label.model_name][label.column_name] = [label];
          }
        } else {
          uiLabelsByModel[label.model_name] = { [label.column_name]: [label] };
        }
      });

      return {
        ...state,
        loading: false,
        uiLabels: action.payload,
        uiLabelsByModel,
      };
    case UI_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        uiLabels: [],
        uiLabelsByModel: {},
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default uiLabelsReducer;
