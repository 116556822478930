import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  RecommendationStepProps,
  RecommendationStepsActionTypes,
  EDIT_RECOMMENDATION_STEP_SUCCESS,
  RECOMMENDATION_STEPS_REQUESTED,
  RECOMMENDATION_STEPS_SUCCESS,
  RECOMMENDATION_STEPS_ERROR,
  RECOMMENDATION_STEP_REQUESTED,
  RECOMMENDATION_STEP_ERROR,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getRecommendationSteps =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<RecommendationStepsActionTypes>) => {
    dispatch({ type: RECOMMENDATION_STEPS_REQUESTED });
    if (cancelReq) cancelReq.cancel('canceled');
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/recommendation_steps', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('recommendation steps response ----', resp);
        dispatch({
          type: RECOMMENDATION_STEPS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- recommendation steps request cancelled');
        }
        dispatch({ type: RECOMMENDATION_STEPS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const editRecommendationStep =
  (token: string, recommendationStep: RecommendationStepProps) => (dispatch: Dispatch) => {
    dispatch({ type: RECOMMENDATION_STEP_REQUESTED });
    const { id, ...rest } = recommendationStep;

    authorized(token)
      .put(`/recommendation_steps/${id}`, rest)
      .then((resp) => {
        dispatch({ type: EDIT_RECOMMENDATION_STEP_SUCCESS, payload: recommendationStep });
      })
      .catch((error) => {
        console.error(error);
        let msg: any;
        if (error.response?.data) {
          msg = error.response.data.details || error.response.data.message;
        }
        // FIXME
        // At times a 500 error could come back with an empty data object or string
        //    from server.  If so, return standard message
        if (msg === undefined || msg === null) msg = error.message;
        dispatch({ type: RECOMMENDATION_STEP_ERROR, payload: msg });
      });
  };
