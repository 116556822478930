import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import ReduxThunk, { ThunkMiddleware } from 'redux-thunk';
import createRootReducer from './Store';

export const history = createBrowserHistory();
const RootReducer = createRootReducer(history);
export type RootStore = ReturnType<typeof RootReducer>;

export default function configureStore(preloadedState?: any) {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    RootReducer,
    preloadedState,
    composeEnhancer(applyMiddleware(ReduxThunk as ThunkMiddleware, routerMiddleware(history))),
  );

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for Store
    module.hot.accept('./Store', () => {
      store.replaceReducer(RootReducer);
    });
  }

  return store;
}

export const store = configureStore();
