import { ROUTE_CHANGE } from '../history/reducer';
import {
  USER_REQUESTED,
  ADD_USER_SUCCESS,
  USER_ERROR,
  USERS_ERROR,
  USERS_REQUESTED,
  USERS_SUCCESS,
  EDIT_USER_SUCCESS,
  State,
  initialState,
  UsersActionTypes,
} from './types';
import { cancelReq } from './actions';

export const usersReducer = (state: State = initialState, action: UsersActionTypes): State => {
  switch (action.type) {
    case USERS_REQUESTED:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: undefined,
      };
    case USER_REQUESTED:
      return { ...state, userLoading: true, error: undefined };
    case ADD_USER_SUCCESS:
      const newArray = [...state.users, action.payload];
      return {
        ...state,
        userLoading: false,
        user: action.payload,
        users: newArray,
        userError: undefined,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        userError: undefined,
        userLoading: false,
        users: state.users.slice().map((u) => {
          return u.id === action.payload.id ? action.payload : u;
        }),
        user: action.payload,
      };
    case USER_ERROR:
      return {
        ...state,
        userLoading: false,
        userError: action.payload,
      };
    case USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default usersReducer;
