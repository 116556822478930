import { FunctionComponent, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import useStyles from './styles';

import { SideNavLayout } from '../../Containers/SideNavWrapper';
import PatientGroupContainer from '../../Containers/PatientGroupContainer';
import GroupCreateModal from '../../Containers/GroupCreateModal';
import GroupEditModal from '../../Containers/GroupEditModal';
import PageHeader from '../../Components/PageHeader';
import PageContent from '../../Components/PageContent';

import { RootStore } from '../../configureStore';
import {
  getMyPatientGroups,
  deleteMyPatientGroup,
  getMyPatientGroupsProgressChartData,
} from '../../Store/my_patient_groups/actions';
import { PatientGroupProps, ProductTypeEnum } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import ConfirmModal from '../../Components/ConfirmModal';

interface MyGroupsState {
  group?: PatientGroupProps;
  productType?: ProductTypeEnum;
  initGroupOpen: boolean;
  editGroupOpen: boolean;
  deleteGroupOpen: boolean;
  apiError?: string;
  isSubmitting: boolean;
  showDeleteSuccess: boolean;
}

const MyGroups: FunctionComponent = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const myPatientGroupState = useSelector((state: RootStore) => state.my_patient_groups);
  const [state, setState] = useState<MyGroupsState>({
    initGroupOpen: false,
    editGroupOpen: false,
    deleteGroupOpen: false,
    productType: 'INPATIENT',
    isSubmitting: false,
    showDeleteSuccess: false,
  });

  const { initGroupOpen, group, editGroupOpen, deleteGroupOpen, isSubmitting, apiError, showDeleteSuccess } = state;

  //fetch my patient groups
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(
        getMyPatientGroups(token, {
          params: { enabled: true, sort_by: 'is_default_group(desc),name(asc)' },
        }),
      );
    });
  }, [dispatch, getAccessTokenSilently]);

  const handleClickGroupDelete = (deleteGroupId: string) => {
    let groupObject: PatientGroupProps;
    groupObject = myPatientGroupState.patientGroups.filter(
      (group) => group.id === deleteGroupId,
    )[0] as PatientGroupProps;

    setState((prevState) => ({
      ...prevState,
      group: groupObject,
      deleteGroupOpen: true,
    }));
  };

  const handleClickGroupDeleteConfirm = () => {
    if (group && group.id) {
      getAccessTokenSilently().then((token) => {
        dispatch(deleteMyPatientGroup(token, group.id as string));
      });
    }
  };

  const handleNewGroupSuccess = (
    newGroup: PatientGroupProps | SecurityGroupProps,
    newGroupType: 'patient' | 'security',
    productType?: ProductTypeEnum,
  ) => {
    setState((prevState) => ({
      ...prevState,
      product_type: productType,
      group: newGroup as PatientGroupProps,
      initGroupOpen: false,
      editGroupOpen: true,
    }));
  };

  // this is here to make sure the delete is successfull if not update error state
  useEffect(() => {
    if (!isSubmitting && myPatientGroupState.loading) {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: true,
      }));
    }
    if (isSubmitting && !myPatientGroupState.loading) {
      if (myPatientGroupState.error) {
        setState((prevState) => ({
          ...prevState,
          apiError: myPatientGroupState.error,
          isSubmitting: false,
        }));
      } else {
        let partialState: any = { apiError: undefined, isSubmitting: false };
        if (deleteGroupOpen) {
          partialState = { ...partialState, deleteGroupOpen: false, showDeleteSuccess: true };
        }
        setState((prevState) => ({ ...prevState, ...partialState }));
      }
    }
  }, [myPatientGroupState, isSubmitting, deleteGroupOpen]);

  // totals if the totals length doesnt match groups and thier are groups to be matched
  useEffect(() => {
    if (
      myPatientGroupState.patientGroups.length > 0 &&
      myPatientGroupState.patientGroups.filter((pg) => pg.patients === -1).length !== 0
    ) {
      const ids = myPatientGroupState.patientGroups.map((pg) => pg.id);
      getAccessTokenSilently().then((token) => {
        dispatch(getMyPatientGroupsProgressChartData(token, ids));
      });
    }
  }, [myPatientGroupState.patientGroups, dispatch, getAccessTokenSilently]);

  const handleToastClose = () =>
    setState((prevState) => ({ ...prevState, showDeleteSuccess: false, group: undefined }));

  const getPatientGroupChartValues = (patientGroupId: string, fieldType: string) => {
    const returnValue = myPatientGroupState.patientGroupsChartTotals.filter((pgc) => {
      return pgc.patient_group_id === patientGroupId;
    });
    if (returnValue[0] !== undefined) {
      return fieldType === 'numerator' ? returnValue[0].numerator : returnValue[0].denominator;
    } else {
      return -1;
    }
  };

  // Comment
  return (
    <SideNavLayout>
      <PageHeader headText="My Patient Groups" />
      <PageContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h2">All Patient Groups</Typography>
          {/* <Box pb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setState((prevState) => ({ ...prevState, initGroupOpen: true }))}
            >
              + New Group
            </Button>
          </Box> */}
        </Grid>

        <Divider />

        <Box pt={2}>
          <Grid container spacing={3}>
            {myPatientGroupState && myPatientGroupState.loading
              ? Array(6)
                  .fill('')
                  .map((item, index) => (
                    <Grid item xs={12} sm={4} key={`mygroup-skeleton-loader-${index}`}>
                      <Skeleton variant="rectangular" height={50} className={classes.skeletonSpaced} />
                      <Skeleton variant="rectangular" height={120} />
                    </Grid>
                  ))
              : myPatientGroupState.patientGroups
                  .sort((a, b) =>
                    b.is_default_group > a.is_default_group
                      ? 1
                      : b.is_default_group === a.is_default_group
                      ? a.name > b.name
                        ? 1
                        : -1
                      : -1,
                  )
                  .map((patientGroup) => (
                    // added random colors and numbers here in replacement of data
                    <PatientGroupContainer
                      value={getPatientGroupChartValues(patientGroup.id, 'numerator')}
                      maxValue={getPatientGroupChartValues(patientGroup.id, 'denominator')}
                      key={patientGroup.id + patientGroup.name}
                      handleDelete={handleClickGroupDelete}
                      {...patientGroup}
                    ></PatientGroupContainer>
                  ))}
          </Grid>
        </Box>

        {/* Modals */}
        <GroupCreateModal
          open={initGroupOpen}
          onClose={() => setState((prevState) => ({ ...prevState, initGroupOpen: false }))}
          onSuccess={handleNewGroupSuccess}
          disableSecurityGroups={true}
        />
        {group && (
          <GroupEditModal
            open={editGroupOpen}
            onClose={() => setState((prevState) => ({ ...prevState, editGroupOpen: false }))}
            groupType={'patient'}
            group={group}
            productType={(group as PatientGroupProps).product_type || undefined}
            groups={myPatientGroupState.patientGroups}
          />
        )}
        {group && (
          <ConfirmModal
            open={deleteGroupOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                deleteGroupOpen: false,
                isSubmitting: false,
                apiError: undefined,
              }))
            }
            confirmButtonClick={handleClickGroupDeleteConfirm}
            confirmButtonText="Delete"
            errorMessage={apiError}
            isSubmitting={isSubmitting}
          >
            <>
              <Box>Are you sure you want to delete this group?</Box>
              <Box>"{group.name}"</Box>
            </>
          </ConfirmModal>
        )}

        {/* Toast */}
        {group && (
          <Snackbar open={showDeleteSuccess} autoHideDuration={6000} onClose={handleToastClose}>
            <Alert onClose={handleToastClose} severity="success">
              Success! "{group.name}" deleted.
            </Alert>
          </Snackbar>
        )}
      </PageContent>
    </SideNavLayout>
  );
};

export default MyGroups;
