import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  RecommendationStepsActionTypes,
  RECOMMENDATION_STEP_REQUESTED,
  RECOMMENDATION_STEPS_REQUESTED,
  RECOMMENDATION_STEPS_SUCCESS,
  RECOMMENDATION_STEP_ERROR,
  RECOMMENDATION_STEPS_ERROR,
  EDIT_RECOMMENDATION_STEP_SUCCESS,
} from './types';
import { cancelReq } from './actions';

const recommendationStepsReducer = (state: State = initialState, action: RecommendationStepsActionTypes): State => {
  switch (action.type) {
    case RECOMMENDATION_STEP_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RECOMMENDATION_STEPS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RECOMMENDATION_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendationSteps: action.payload,
      };
    case EDIT_RECOMMENDATION_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendationStep: action.payload,
        recommendationSteps: state.recommendationSteps.map((rs) => {
          return rs.id === action.payload.id ? action.payload : rs;
        }),
      };
    case RECOMMENDATION_STEP_ERROR:
      return {
        ...state,
        loading: false,
        recommendationStep: undefined,
        error: action.payload,
      };
    case RECOMMENDATION_STEPS_ERROR:
      return {
        ...state,
        loading: false,
        recommendationSteps: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default recommendationStepsReducer;
