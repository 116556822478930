import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import JvionTextField from '../../Components/JvionTextField';
import JvionSelectField, { SelectObject } from '../../Components/JvionSelectField';
import { useAuth0 } from '@auth0/auth0-react';
import JvionAutocompleteField from '../../Components/JvionAutocompleteField';
import Divider from '@mui/material/Divider';

import useStyles from './styles';
import UserModel, { UserModelFields } from './UserModel';
import { RootStore } from '../../configureStore';
import { createUser, editUser } from '../../Store/users/actions';

import { UserProps } from '../../Store/users/types';
import { IconButton, Typography } from '@mui/material';

import dateFormat from 'date-fns/format';

export interface UserFormProps {
  variant: 'create' | 'edit';
  user?: UserProps;
  handleSuccessResp?: () => void;
  linkLabel?: string;
}

const UserFormButton: FunctionComponent<UserFormProps> = (props: UserFormProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const userState = useSelector((state: RootStore) => state.users);
  const roleState = useSelector((state: RootStore) => state.roles);
  const securityGroupState = useSelector((state: RootStore) => state.security_groups.securityGroups);
  const patientGroupState = useSelector((state: RootStore) => state.patient_groups.patientGroups);
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState<(string | number)[]>([]);
  const [selectedPatientGroups, setSelectedPatientGroups] = useState<(string | number)[]>([]);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [state, setState] = useState({
    modalOpen: false,
    isSubmitting: false,
    sendInvite: false,
    apiError: undefined,
    alertOpen: false,
    initialized: false,
  });
  const [avtInitials, setAvtInitials] = useState('');
  const [userStatus, setUserStatus] = useState<string[]>([]);
  const userStatusOptions = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  let ButtonText = props.variant === 'create' ? 'Add User' : 'Save';

  let UserForForm = useMemo(() => {
    if (props.variant === 'edit' && props.user !== undefined) {
      const { given_name, family_name, email, security_groups, patient_groups, roles, blocked } = props.user;
      setSelectedSecurityGroups(security_groups || []);
      setSelectedPatientGroups(patient_groups || []);
      setSelectedRole(roles || []);
      setUserStatus(blocked ? ['inactive'] : ['active']);
      let initials = given_name && given_name !== null ? given_name.slice(0, 1) : '';
      initials += family_name && family_name !== null ? family_name.slice(0, 1) : '';
      setAvtInitials(initials);
      setState((prevState) => {
        return {
          ...prevState,
          sendInvite: false,
        };
      });
      return UserModel({ given_name, family_name, email, blocked });
    } else {
      setAvtInitials('');
      setState((prevState) => {
        return {
          ...prevState,
          sendInvite: true,
        };
      });
      return UserModel({ given_name: '', family_name: '', email: '', blocked: false });
    }
  }, [props.variant, props.user]);

  const handleClose = () => {
    setState((prevState) => {
      return { ...prevState, apiError: undefined, isSubmitting: false, modalOpen: false, alertOpen: false };
    });
  };

  const handleOpen = (event: React.MouseEvent) => {
    // For the link, don't try to go to URL
    event.preventDefault();
    event.stopPropagation();
    setState((prevState) => {
      return { ...prevState, apiError: undefined, isSubmitting: false, modalOpen: true, alertOpen: false };
    });
  };

  useEffect(() => {
    setState((prevState) => {
      return { ...prevState, apiError: undefined, isSubmitting: false, alertOpen: false };
    });
  }, []);

  const renderInitials = () => {
    setAvtInitials(`${UserForForm.given_name.value.slice(0, 1)}${UserForForm.family_name.value.slice(0, 1)}`);
  };

  const onSecurityGroupChange = (resp: (number | string)[]) => {
    setSelectedSecurityGroups(resp);
  };

  const onPatientGroupChange = (resp: (number | string)[]) => {
    setSelectedPatientGroups(resp);
  };

  useEffect(() => {
    // We only care about this when the modal is open
    if (state.modalOpen === true) {
      // If isSubmitting is false and userLoading === true, then we just
      //     triggered a request to Create / Update a user
      if (state.isSubmitting === false && userState.userLoading === true) {
        setState((prevState) => {
          return { ...prevState, apiError: undefined, alertOpen: false, isSubmitting: true };
        });
      }
      // If previously isSubmitting was true and now it's false
      //  The means we got a response from the User Create / Update action
      if (state.isSubmitting === true && userState.userLoading === false) {
        // If error, show it
        //   else, close modal and see new user
        if (userState.userError) {
          setState((prevState) => {
            return { ...prevState, apiError: userState.userError, alertOpen: true, isSubmitting: false };
          });
        } else {
          handleClose();
          if (props.handleSuccessResp) props.handleSuccessResp();
          setState((prevState) => {
            return { ...prevState, apiError: undefined, alertOpen: false, isSubmitting: false };
          });
        }
      }
    }
  }, [userState.userLoading, userState.userError, userState.user, state.isSubmitting, state.modalOpen, props]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let hasErrors = false;
    Object.keys(UserForForm).forEach((key) => {
      let myVal = UserForForm[key as UserModelFields];
      let error;
      if (key === 'email') {
        error = myVal.validation({ emailString: myVal.value });
      } else {
        error = myVal.validation({ field: key, string: myVal.value });
      }
      if (error) hasErrors = true;

      UserForForm[key as UserModelFields].errors = error === undefined ? [] : [error];
    });

    // If error, stop from
    // and trigger to re-render
    if (hasErrors) {
      return setState((prevState) => {
        return { ...prevState };
      });
    }

    const token = await getAccessTokenSilently();
    const email = UserForForm.email.value;
    const given_name = UserForForm.given_name.value;
    const family_name = UserForForm.family_name.value;
    const security_groups = selectedSecurityGroups as string[];
    const patient_groups = selectedPatientGroups as string[];
    const roles = selectedRole.length ? selectedRole : undefined;
    const blocked = UserForForm.blocked.value;
    // Stub for now
    const picture =
      'https://static.tweaktown.com/content/7/5/7502_999_star-wars-battlefront-gaming-graphics-performance-tweak-guide.jpg';
    if (props.variant === 'create') {
      dispatch(
        createUser(token, {
          email,
          given_name,
          family_name,
          picture,
          security_groups,
          patient_groups,
          roles,
          verify_email: state.sendInvite,
        }),
      );
    } else {
      dispatch(
        editUser(token, {
          ...props.user,
          email,
          given_name,
          family_name,
          picture,
          security_groups,
          patient_groups,
          roles,
          verify_email: state.sendInvite,
          blocked,
        }),
      );
    }
  };

  const handleUserStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const status = e.target.value;
    setUserStatus([status as string]);
    return (UserForForm.blocked.value = status === 'inactive' ? true : false);
  };

  const RolesOptions: Array<SelectObject> = useMemo(() => {
    // If the line below isn't here, the app will default to saying props is undefined
    const temporaryRoles = props?.user?.roles || [];
    if (roleState.roles.length > 0 && temporaryRoles.length === 0) {
      setSelectedRole([roleState.roles[0].id]);
    }
    return roleState.roles.map((itm: any) => {
      return { label: itm.name, value: itm.id };
    });
  }, [props?.user?.roles, roleState.roles]);

  const TriggerButton = useMemo(() => {
    if (
      state.initialized === false &&
      props.variant === 'edit' &&
      state.modalOpen &&
      avtInitials === '' &&
      (UserForForm.given_name || UserForForm.family_name)
    ) {
      renderInitials();
      setState((prevState) => {
        return {
          ...prevState,
          intitialized: true,
        };
      });
    }
    if (props.variant === 'edit') {
      const linkLabel = props.user ? props.user.full_name : 'Edit User';
      return (
        <Link href="#" data-testid="toggle-link" onClick={handleOpen}>
          {linkLabel}
        </Link>
      );
    } else {
      return (
        <Button
          data-testid="toggle-button"
          variant="contained"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon className={classes.triggerBtnIcon} />}
        >
          {ButtonText}
        </Button>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.variant, ButtonText, props.user, classes.triggerBtnIcon]);

  return (
    <div>
      {TriggerButton}
      {state.modalOpen === true && (
        <Dialog
          maxWidth="md"
          PaperProps={{
            className: classes.dialog,
          }}
          fullWidth
          open={state.modalOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-user-form"
        >
          <div id="form-dialog-user-form" className={classes.dialogTitleWrapper}>
            <Typography variant="h6">{props.variant === 'create' ? 'Add User' : 'Edit User'}</Typography>
            <IconButton
              disableRipple
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>

          <form data-testid="user-form" noValidate onSubmit={handleSubmit}>
            <DialogContent className={classes.contentRoot}>
              {state.modalOpen && state.alertOpen && (
                <Alert
                  severity="error"
                  data-testid="user-form-alert"
                  onClose={() =>
                    setState((prevState) => {
                      return { ...prevState, apiError: undefined, alertOpen: false };
                    })
                  }
                >
                  {state.apiError}
                </Alert>
              )}
              <Grid container spacing={0}>
                <Grid item sm={12} md={4} className={classes.avatarWrapper}>
                  <Avatar className={classes.avatar}>{avtInitials}</Avatar>
                  {props.variant === 'edit' && (
                    <>
                      <Divider />
                      <Typography className={classes.dateAddedTxt}>Date Added</Typography>
                      <Typography className={classes.dateAdded}>
                        {dateFormat(props.user?.created_dttm || +new Date(), 'MM/dd/yyyy')}
                      </Typography>
                    </>
                  )}
                </Grid>

                <Grid item sm={12} md={8} className={classes.fieldsWrapper}>
                  <Grid container direction="row" spacing={0}>
                    <Grid item sm={12} md={6}>
                      <JvionTextField
                        fullWidth
                        label="FIRST NAME"
                        required
                        inputProps={{
                          'data-testid': 'user-first-name',
                        }}
                        autoFocus
                        defaultValue={UserForForm.given_name.defaultValue}
                        onChange={(e) => (UserForForm.given_name.value = e.target.value)}
                        onKeyUp={(e) => renderInitials()}
                        error={UserForForm.given_name.errors.length ? true : false}
                        helperText={
                          UserForForm.given_name.errors.length ? UserForForm.given_name.errors.join(', ') : false
                        }
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <JvionTextField
                        fullWidth
                        label="LAST NAME"
                        required
                        inputProps={{
                          'data-testid': 'user-last-name',
                        }}
                        defaultValue={UserForForm.family_name.defaultValue}
                        onChange={(e) => (UserForForm.family_name.value = e.target.value)}
                        onKeyUp={(e) => renderInitials()}
                        error={UserForForm.family_name.errors.length ? true : false}
                        helperText={
                          UserForForm.family_name.errors.length ? UserForForm.family_name.errors.join(', ') : false
                        }
                      />
                    </Grid>
                    {/* <Grid item sm={12} md={6}></Grid> */}
                  </Grid>
                  <Grid container direction="row">
                    <Grid item sm={12}>
                      <JvionTextField
                        fullWidth
                        label="EMAIL"
                        required
                        inputProps={{
                          'data-testid': 'user-email',
                        }}
                        defaultValue={UserForForm.email.defaultValue}
                        onChange={(e) => (UserForForm.email.value = e.target.value)}
                        error={UserForForm.email.errors.length ? true : false}
                        helperText={UserForForm.email.errors?.length ? UserForForm.email.errors.join(', ') : false}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={0}>
                    <Grid item sm={12} md={6}>
                      <JvionSelectField
                        fullWidth
                        required
                        value={selectedRole.map((s) => RolesOptions.find((ro) => ro.value === s))[0]?.value}
                        disabled={roleState.loading || roleState.error !== undefined}
                        options={RolesOptions}
                        onChange={(e) => setSelectedRole([e.target.value as string])}
                        label="Role"
                      />
                    </Grid>
                    {props.variant === 'edit' && (
                      <Grid item sm={12} md={6}>
                        <JvionSelectField
                          fullWidth
                          required
                          value={
                            userStatus.map((status) => userStatusOptions.find((opt) => opt.value === status))[0]?.value
                          }
                          options={userStatusOptions}
                          onChange={(e) => handleUserStatus(e)}
                          label="Status"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container direction="row">
                    <Grid item sm={12}>
                      <JvionAutocompleteField
                        limit={5}
                        label="Security Groups"
                        selectedIds={selectedSecurityGroups}
                        options={securityGroupState.map((securityGroup) => ({
                          key: securityGroup.id || securityGroup.name,
                          value: securityGroup.name,
                        }))}
                        onChangeSelected={onSecurityGroupChange}
                      ></JvionAutocompleteField>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item sm={12}>
                      <JvionAutocompleteField
                        limit={10}
                        label="Patient Groups"
                        selectedIds={selectedPatientGroups}
                        options={patientGroupState.map((patientGroup) => ({
                          key: patientGroup.id || patientGroup.name,
                          value: patientGroup.name,
                        }))}
                        onChangeSelected={onPatientGroupChange}
                      ></JvionAutocompleteField>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item sm={12}>
                      <FormControlLabel
                        classes={{ label: classes.checkBoxLabel }}
                        control={
                          <Checkbox
                            style={{ color: '#3AC0C7' }}
                            checked={state.sendInvite}
                            onChange={(e) =>
                              setState((prevState) => {
                                return { ...prevState, sendInvite: e.target.checked };
                              })
                            }
                            name="send-invite"
                          />
                        }
                        label="Send Invite"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Typography>
                <Button
                  data-testid="cancel-button"
                  onClick={handleClose}
                  // variant="button"
                >
                  Cancel
                </Button>
              </Typography>
              <Button
                disabled={state.isSubmitting}
                data-testid="submit-button"
                type="submit"
                variant="contained"
                color="primary"
              >
                {ButtonText}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
};

export default UserFormButton;
