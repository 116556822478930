import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import PatientInfo from '../../Components/PatientInfo';
import RiskProfile from '../../Components/RiskProfile';
import Recommendations from '../../Components/Recommendations';
import Products from '../../Components/Products';

import { getRecommendations, resetRecommendations } from '../../Store/recommendations/actions';
import { resetHistoricalRecommendations } from '../../Store/historical_recommendations/actions';
import { ResultProps } from '../../Store/results/types';
import { getRiskFactors, resetRiskFactors } from '../../Store/risk_factors/actions';
import { getComments } from '../../Store/comments/actions';
import HistorySection from '../../Components/HistorySection';
import { RootStore } from '../../configureStore';
import { ProductTypeEnum } from '../../Store/patient_groups/types';
import { getEncounters, resetEncounters } from '../../Store/encounters/actions';
import { getInsurances } from '../../Store/insurances/actions';
import { getResults } from '../../Store/results/actions';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import useStyles from './styles';

export interface PatientViewSectionProps {
  peopleLoading: boolean;
  person: any;
  patientId: string;
  productType: ProductTypeEnum;
}

interface StateInterface {
  results: string;
  scores: string;
  comments: string;
}

export const PatientViewSection: FunctionComponent<PatientViewSectionProps> = (props: PatientViewSectionProps) => {
  const classes = useStyles();
  const { person, patientId, productType, peopleLoading } = props;
  const historyRef = useRef<HTMLDivElement>(null);
  // Start with a blank patient ID so it triggers the initial get for results + scores + comments
  // Minimize race condition
  const [fetchingPersonInfo, setFetchingPersonInfo] = useState<StateInterface>({
    results: '',
    scores: '',
    comments: '',
  });
  const [productIds, setProductIds] = useState<string[]>([]);
  const [recommendationIdsByProductId, setRecommendationIdsByProductId] = useState<{ [key: string]: string[] }>({});
  const [recommendationIds, setRecommendationIds] = useState<string[]>([]);
  const [riskFactorIds, setRiskFactorIds] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const commentsState = useSelector((state: RootStore) => state.comments);
  const encountersState = useSelector((state: RootStore) => state.encounters);
  const results = useSelector((state: RootStore) => state.results.results);
  const [recommendationStdId, setRecommendationStdId] = useState<string | undefined>(undefined);
  const [encounterIds, setEncounterIds] = useState<string[]>([]);
  // this is used to force an update after recommendation status changes because we need to fetch
  // historical data from the api.
  const [updateRecommendations, setUpdateRecommendations] = useState<boolean>(false);
  const [updateRiskFactors, setUpdateRiskFactors] = useState<boolean>(false);
  const [fetchResults, setFetchResults] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  // pull data off of results
  useEffect(() => {
    if (results && results.length > 0) {
      let prodIds: string[] = []; // temp product ids
      let recIdsByProdId: { [key: string]: string[] } = {}; // temp recommendation ids by product id
      let recIds: string[] = []; // temp recommendation ids
      let ecntrIds: string[] = []; // temp encounter ids
      let riskFIds: string[] = []; // temp array of risk factors from Result
      results.forEach((res: ResultProps) => {
        if (!prodIds.includes(res.product_id)) {
          prodIds.push(res.product_id);
        }
        if (res.recommendations && res.recommendations.length > 0) {
          recIdsByProdId[res.product_id] = res.recommendations;
          recIds = [...recIds, ...res.recommendations];
        }
        if (res.risk_factors && res.risk_factors.length > 0) {
          riskFIds = [...riskFIds, ...res.risk_factors];
        }
        if (res.encounter_id) ecntrIds.push(res.encounter_id);
      });
      setProductIds(prodIds);
      setRecommendationIdsByProductId(recIdsByProdId);
      setRecommendationIds(recIds);
      setRiskFactorIds(riskFIds);
      setEncounterIds(ecntrIds);
      setUpdateRecommendations(true);
      setUpdateRiskFactors(true);
    }
  }, [results]);

  // fetch results
  useEffect(() => {
    setSelectedProduct('');
    setFetchResults(false);
    getAccessTokenSilently().then((token) => {
      dispatch(
        getResults(token, {
          params: {
            person_id: patientId,
            include_references: 'recommendations,risk_factors',
            only_recent: true,
          },
        }),
      );
    });
  }, [getAccessTokenSilently, patientId, dispatch, fetchResults]);

  // fetch risk factors
  useEffect(() => {
    if (updateRiskFactors && riskFactorIds.length) {
      setUpdateRiskFactors(false);
      const ids = riskFactorIds.join(',');
      getAccessTokenSilently().then((token) => {
        dispatch(
          getRiskFactors(token, {
            params: { person_id: patientId, 'id[in]': ids },
          }),
        );
      });
    } else {
      dispatch(resetRiskFactors());
    }
  }, [getAccessTokenSilently, patientId, dispatch, updateRiskFactors, riskFactorIds]);

  // fetch comments
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(
        getComments(token, {
          params: { 'person_id[in]': patientId },
        }),
      );
    });
  }, [getAccessTokenSilently, patientId, dispatch]);

  // fetch recommendations
  useEffect(() => {
    if (updateRecommendations) {
      setUpdateRecommendations(false);
      if (recommendationIds && recommendationIds.length) {
        getAccessTokenSilently().then((token) => {
          const ids = recommendationIds.join(',');
          const params = {
            params: {
              'id[in]': ids,
              fetch_history: 'true',
              sort_by: 'recommendation_rank(asc)',
            },
          };
          dispatch(getRecommendations(token, params));
        });
      } else {
        dispatch(resetRecommendations());
      }
    }
  }, [recommendationIds, getAccessTokenSilently, dispatch, updateRecommendations, patientId]);

  // fetch encounter
  useEffect(() => {
    if (encounterIds && encounterIds.length > 0) {
      getAccessTokenSilently().then((token) => {
        dispatch(
          getEncounters(token, {
            params: {
              'id[in]': encounterIds.join(','),
            },
          }),
        );
      });
    }
  }, [getAccessTokenSilently, dispatch, encounterIds]);

  // fetch insurances
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(
        getInsurances(token, {
          params: {
            'person_id[in]': patientId,
            sort_by: 'priority(asc)',
          },
        }),
      );
    });
  }, [getAccessTokenSilently, dispatch, patientId]);

  useEffect(() => {
    // This happens first when a patientID changes
    // First reset the other values then trigger the fetch
    if (fetchingPersonInfo.results !== patientId) {
      dispatch(resetRecommendations());
      dispatch(resetRiskFactors());
      dispatch(resetHistoricalRecommendations());
      dispatch(resetEncounters());
      setSelectedProduct('');
      setProductIds([]);
      setRecommendationIdsByProductId({});
      setRecommendationIds([]);
      setRiskFactorIds([]);
      setEncounterIds([]);
      setUpdateRecommendations(true);
      setRecommendationStdId(undefined);
      setFetchingPersonInfo((prev) => {
        return { ...prev, results: patientId };
      });
    }
  }, [getAccessTokenSilently, patientId, fetchingPersonInfo.results, dispatch]);

  const handleRecommendationCommentClick = (newRecommendationStdId: string) => {
    historyRef && historyRef.current && historyRef.current.scrollIntoView({ behavior: 'smooth' });
    setRecommendationStdId(newRecommendationStdId);
  };
  // here is the function to clear recommendation id
  const handleClearRecommendationId = () => setRecommendationStdId(undefined);

  // Use the latest encounter for the person object
  const encounter = encountersState.encounters.filter((e) => e.person_id === patientId)[0];

  return (
    <>
      {peopleLoading ? (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} className={classes.skeletonSpaced} />
          <Skeleton variant="rectangular" height={100} />
        </Grid>
      ) : (
        <PatientInfo patient={person} encounter={encounter} productType={productType} />
      )}
      {peopleLoading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.spinnerContainer}
        >
          <Grid item>
            <CircularProgress size={110} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Products
            productType={productType}
            personID={patientId}
            selectedProduct={selectedProduct}
            productIds={productIds}
            setSelectedProduct={(prd: string) => setSelectedProduct(prd)}
          />
          <Recommendations
            productId={selectedProduct}
            personId={patientId}
            comments={commentsState.recommendationComments}
            recommendationIds={recommendationIdsByProductId[selectedProduct] || []}
            onRecommendationCommentClick={handleRecommendationCommentClick}
          />
          <RiskProfile selectedProduct={selectedProduct} />
          <div ref={historyRef}>
            <HistorySection
              personID={patientId}
              recommendation_std_id={recommendationStdId}
              onClearRecommendationId={handleClearRecommendationId}
            ></HistorySection>
          </div>
        </>
      )}
    </>
  );
};
export default PatientViewSection;
