import { FunctionComponent, useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import JvionTextField from '../../Components/JvionTextField';
import { createComment } from '../../Store/comments/actions';
import { CommentProps } from '../../Store/comments/types';
import { RootStore } from '../../configureStore';
import useStyles from './styles';
import Alert from '@mui/material/Alert';

interface CommentCreateModalProps {
  onClose: () => void;
  onSuccess?: (comment: Partial<CommentProps>) => void;
  open: boolean;
  commentDefaultProps?: Partial<CommentProps>;
}

interface CommentCreateModalState {
  text: string;
  error: {
    text: string;
  };
  isSubmitted: boolean;
  isSubmitting: boolean;
  apiError?: string;
}

const CommentCreateModal: FunctionComponent<CommentCreateModalProps> = (props: CommentCreateModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { onClose, onSuccess, open, commentDefaultProps } = props;
  const [state, setState] = useState<CommentCreateModalState>({
    text: '',
    error: {
      text: '',
    },
    isSubmitted: false,
    isSubmitting: false,
  });
  const { text, error, isSubmitting, apiError } = state;
  const commentState = useSelector((state: RootStore) => state.comments);

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      text: '',
      error: { text: '' },
    }));
    onClose();
  };

  const handleTextChange = (newText: string) => {
    if (newText.length <= 5000) {
      setState((prevState) => ({
        ...prevState,
        text: newText,
        error: { text: '' },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        error: { text: 'Looks like you have a lot to say. Unfortunately, the limit is 5,000 characters.' },
      }));
    }
  };

  const handleSubmit = async () => {
    if (text.length === 0) {
      setState((prevState) => ({
        ...prevState,
        error: {
          text: 'Text required',
        },
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));
    const token = await getAccessTokenSilently();
    dispatch(createComment(token, commentDefaultProps ? { ...commentDefaultProps, text } : { text }));
    setState((prevState) => ({ ...prevState, isSubmitted: true, isSubmitting: true }));
    onClose();
  };

  useEffect(() => {
    // We only care about this when the modal is open
    if (open) {
      // If previously isSubmitting is true and commentState.loading is false
      // This means we got a response from the comment create / update action
      if (isSubmitting && !commentState.loading) {
        // If error, show it
        //   else, close modal and see new comment
        if (commentState.error) {
          setState((prevState) => ({
            ...prevState,
            apiError: commentState.error,
            isSubmitted: false,
            isSubmitting: false,
          }));
        } else {
          // on the surface it may look like isSubmitted is not really doing anything
          // but in the future it will allow us to easily show a submitted snack
          setState((prevState) => ({
            ...prevState,
            text: '',
            error: { text: '' },
            apiError: undefined,
            isSubmitted: true,
            isSubmitting: false,
          }));

          if (commentState.comment) {
            onSuccess && onSuccess(commentState.comment);
          }
        }
      }
    }
  }, [open, commentState, isSubmitting, onSuccess, onClose]);

  return (
    <>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: classes.dialog,
        }}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-init-comment-form"
        data-testid="comment-create-modal"
      >
        <DialogContent className={classes.content}>
          <Grid container justifyContent="center">
            <Typography variant="h6">Create New Note</Typography>
          </Grid>
          {apiError && (
            <Grid container justifyContent="center">
              <Alert
                severity="error"
                data-testid="comment-create-form-error"
                onClose={() =>
                  setState((prevState) => {
                    return { ...prevState, apiError: undefined };
                  })
                }
              >
                {apiError}
              </Alert>
            </Grid>
          )}
          <Grid container>
            <Grid item sm={12}>
              <JvionTextField
                fullWidth
                label="Note"
                required
                inputProps={{
                  'data-testid': 'comment-create-input',
                }}
                className={classes.input}
                autoFocus
                multiline
                rows={4}
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                error={error.text.length > 0}
                helperText={error.text.length ? error.text : false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Button
              data-testid="comment-create-submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommentCreateModal;
