import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0, User as Auth0User } from '@auth0/auth0-react';
import { authorized, setHeader } from '../../Utils/AxiosInstance';

import Loading from '../../Components/Loading';
import TenantSwitchingContext from './tenant-switching-context';
import { initialTenantSwitchingState, TenantSwitchingStateI } from './tenant-switching-state';
import { Tenant } from './tenant-switching-state';

import ModalSelector from './ModalSelector';

// Items to hide
const getTenantsFromUser = (user: Auth0User) => {
  const assignedKey = Object.keys(user).find((k) => k.match(/assigned_tenants/));
  return assignedKey ? user[assignedKey] : [];
};

/**
 * The main configuration to instantiate the `TenantSwitchingProvider`.
 */
export interface TenantSwitchingProviderOptions {
  /**
   * The child nodes your Provider has wrapped
   */
  children: React.ReactNode;
}

/**
 * ```jsx
 * <TenantSwitchingProvider>
 *   <MyApp />
 * </TenantSwitchingProvider>
 * ```
 *
 * Provides the TenantSwitchingContext to its child components.
 */

// IF more than the number defined below is found in a users
// assigned_tenants attribute or found in the MyTenants response
// is what triggers this component to show modal and button to select
// a tenant.
const MinNumberOfTenants = 1;
const TenantSwitchingProvider = (opts: TenantSwitchingProviderOptions): JSX.Element => {
  const { children } = opts;
  const { user, isLoading: Auth0IsLoading, getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState<TenantSwitchingStateI>(initialTenantSwitchingState);

  //
  const fetchTenants = useCallback(async () => {
    let token = await getAccessTokenSilently();
    authorized(token)
      .get('portal/my_available_tenants')
      .then((resp) => {
        // Compile list of tenants
        // Sort list by Tenant Name
        // Alphabetical in descending order.
        const tenants: Tenant[] = resp.data.responses
          .sort((a: Tenant, b: Tenant) => (a.tenant_name.toLowerCase() > b.tenant_name.toLowerCase() ? 1 : -1))
          .map((t: Tenant) => {
            return { tenant_id: t.tenant_id, tenant_name: t.tenant_name };
          });
        // We only look for if there is more than 1 tenant
        const isSelectingTenant = tenants.length > MinNumberOfTenants;
        setState((prevState) => {
          return {
            ...prevState,
            tenants,
            isSelectingTenant,
            isLoading: false,
          };
        });
      });
  }, [getAccessTokenSilently]);

  // When app initializes.
  useEffect(() => {
    // This is after app loads and Auth0 is done it's initial check for user
    if (state.hasInitialized === false && Auth0IsLoading === false) {
      // Try to fetch tenant info from user if user is logged in.
      // should return a list of tenants assigned to user
      const isLoading = user && getTenantsFromUser(user).length > MinNumberOfTenants ? true : false;

      // Based on above, if isLoading (or we know there is a list of tenants user could be assigned to)
      //   then go fetch the list
      if (isLoading) fetchTenants();
      // Break here as we also already checked for user
      // POTENTIAL RACE CONDITION
      // We are clearing any tenants array, but fetchTenants sets a new array
      return setState((prevState) => {
        return {
          ...prevState,
          hasInitialized: true,
          tenants: [],
          isLoading,
        };
      });
    }
  }, [fetchTenants, Auth0IsLoading, state.hasInitialized, state.tenants, user]);

  // ON app close, clear any tenants
  useEffect(() => {
    return () => {
      setHeader(undefined);
    };
  }, []);

  // Triggers from the Modal Select drop down.
  const onSelectedTenant = async (selectedTenant: Tenant | undefined) => {
    setHeader(selectedTenant ? selectedTenant.tenant_id : undefined);
    setState((prevState) => {
      return {
        ...prevState,
        isSelectingTenant: false,
        selectedTenant,
      };
    });
  };

  // Should we show a "Switch Tenant" button / Link
  const hasMultiTenants = () => {
    return state.tenants.length > MinNumberOfTenants ? true : false;
  };

  // Function to trigger the modal allowing user to switch tenants
  const switchTenant = () => {
    setState((prevState) => {
      return {
        ...prevState,
        isSelectingTenant: true,
      };
    });
  };

  return (
    <TenantSwitchingContext.Provider
      value={{
        ...state,
        hasMultiTenants,
        switchTenant,
      }}
    >
      {Auth0IsLoading || state.hasInitialized === false || state.isLoading === true ? (
        <Loading data-testid="loading" fullView={true} />
      ) : state.isSelectingTenant ? (
        <ModalSelector
          open={state.isSelectingTenant}
          options={state.tenants}
          currentTenant={state.selectedTenant ? state.selectedTenant.tenant_id : undefined}
          selectedTenant={onSelectedTenant}
          handleClose={() => {
            setState((prevState) => {
              return {
                ...prevState,
                isSelectingTenant: false,
              };
            });
          }}
        />
      ) : (
        children
      )}
    </TenantSwitchingContext.Provider>
  );
};

export default TenantSwitchingProvider;
