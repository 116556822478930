import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  SecurityGroupsActionTypes,
  SECURITY_GROUP_REQUESTED,
  SECURITY_GROUPS_REQUESTED,
  SECURITY_GROUPS_SUCCESS,
  SECURITY_GROUP_ERROR,
  SECURITY_GROUPS_ERROR,
  ADD_SECURITY_GROUP_SUCCESS,
  EDIT_SECURITY_GROUP_SUCCESS,
  SECURITY_GROUP_DELETE_SUCCESS,
  SECURITY_GROUP_PEOPLE_RESET,
  SECURITY_GROUP_PEOPLE_BATCH_REQUESTED,
  SECURITY_GROUP_PEOPLE_BATCH_SUCCESS,
  SECURITY_GROUP_PEOPLE_BATCH_ERROR,
  SECURITY_GROUPS_EDIT_TOTALS_REQUESTED,
  SECURITY_GROUPS_EDIT_TOTALS_SUCCESS,
  SECURITY_GROUPS_EDIT_TOTALS_ERROR,
} from './types';
import { cancelReq } from './actions';

const securityGroupsReducer = (state: State = initialState, action: SecurityGroupsActionTypes): State => {
  switch (action.type) {
    case SECURITY_GROUP_PEOPLE_RESET:
      return {
        ...state,
        securityGroup: undefined,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case SECURITY_GROUP_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SECURITY_GROUPS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SECURITY_GROUPS_EDIT_TOTALS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SECURITY_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        securityGroups: action.payload,
      };
    case ADD_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        securityGroup: action.payload,
        securityGroups: [...state.securityGroups, action.payload],
      };
    case EDIT_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        securityGroup: action.payload,
        securityGroups: state.securityGroups.map((sg) => {
          return sg.id === action.payload.id ? action.payload : sg;
        }),
      };
    case SECURITY_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        securityGroups: state.securityGroups.filter((sg) => sg.id !== action.payload),
      };
    case SECURITY_GROUPS_EDIT_TOTALS_SUCCESS:
      const securityGroups = state.securityGroups.map((sg) => {
        // Use find instead of filter
        // No need to loop through the entire array each time
        // Just find the first match and stop
        const st = action.payload.find((st) => st.id === sg.id);
        if (st) {
          sg.patients = st.numerator === null ? -2 : st.numerator;
        }
        return sg;
      });
      return {
        ...state,
        loading: false,
        securityGroups,
        securityGroupsTotal: action.payload,
      };
    case SECURITY_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        securityGroup: undefined,
        error: action.payload,
      };
    case SECURITY_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        securityGroups: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    case SECURITY_GROUP_PEOPLE_BATCH_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SECURITY_GROUP_PEOPLE_BATCH_SUCCESS:
      let people;
      if (
        state.people.people &&
        state.people.people.length > 0 &&
        state.people.metadata &&
        state.people.metadata.page_number !== null &&
        state.people.metadata.page_number + 1 === action.payload.metadata.page_number
      ) {
        people = [...state.people.people, ...action.payload.data];
      } else {
        people = action.payload.data;
      }
      return {
        ...state,
        loading: false,
        people: {
          people,
          metadata: action.payload.metadata,
        },
      };
    case SECURITY_GROUP_PEOPLE_BATCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case SECURITY_GROUPS_EDIT_TOTALS_ERROR:
      // What was this for???
      // securityGroups: state.securityGroups.map((sg) => {
      //   return { ...sg, editTotals: undefined };
      // })
      return {
        ...state,
        loading: false,
        securityGroups: state.securityGroups.map((sg) => {
          // If the Security Group was part of a 500 error
          // Then mark it as a ERROR
          if (action.ids.indexOf(sg.id) > -1) {
            sg.patients = -2;
          }
          return sg;
        }),
        error: action.payload,
      };
    default:
      return state;
  }
};

export default securityGroupsReducer;
