import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { PatientGroupProps } from '../patient_groups/types';
import {
  PatientGroupsActionTypes,
  MY_PATIENT_GROUP_PEOPLE_RESET,
  MY_PATIENT_GROUPS_REQUESTED,
  MY_PATIENT_GROUP_REQUESTED,
  MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED,
  PATIENT_GROUPS_CHART_TOTALS_REQUESTED,
  MY_PATIENT_GROUPS_SUCCESS,
  MY_PATIENT_GROUP_SUCCESS,
  MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
  PATIENT_GROUPS_CHART_TOTALS_SUCCESS,
  MY_PATIENT_GROUPS_ERROR,
  MY_PATIENT_GROUP_ERROR,
  MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR,
  PATIENT_GROUPS_CHART_TOTALS_ERROR,
  DELETE_MY_PATIENT_GROUP_SUCCESS,
  PatientGroupChartProps,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

import { rehydrate } from '../Utils/groupFiltersUtil';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const resetMyPatientGroupPeople = () => async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
  dispatch({ type: MY_PATIENT_GROUP_PEOPLE_RESET });
};

export const getMyPatientGroups =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    // let patientGroupIds: Array<string | undefined> = [];
    dispatch({ type: MY_PATIENT_GROUPS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/my_patient_groups`, {
        ...options,
        cancelToken: cancelReq.token,
      })
      .then((resp) => {
        const rslt = resp.data.data.map((r: PatientGroupProps) => {
          r.patients = -1;
          return r;
        });
        dispatch({
          type: MY_PATIENT_GROUPS_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- my patient group request cancelled');
        }
        dispatch({ type: MY_PATIENT_GROUPS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getMyPatientGroup =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    // let patientGroupIds: Array<string | undefined> = [];
    dispatch({ type: MY_PATIENT_GROUPS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/my_patient_groups/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        let rslt = resp.data;
        if (rslt.group_filters && rslt.group_filters.filters) {
          rslt.group_filters.filters = rehydrate(rslt.group_filters.filters);
        }
        dispatch({
          type: MY_PATIENT_GROUP_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- my patient group request cancelled');
        }
        dispatch({ type: MY_PATIENT_GROUP_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getMyPatientGroupPeopleBatch =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: MY_PATIENT_GROUP_PEOPLE_BATCH_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .get(`/patient_groups/${id}/people`, { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('patient group people response ----', resp);
        dispatch({
          type: MY_PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group people request cancelled');
        }
        dispatch({ type: MY_PATIENT_GROUP_PEOPLE_BATCH_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getMyPatientGroupsProgressChartData =
  (token: string, progressChartRequestBody: Array<string | undefined>) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: PATIENT_GROUPS_CHART_TOTALS_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .post(
        '/portal/totals/progress_chart_totals',
        {
          patient_groups: progressChartRequestBody,
        },
        { params: { only_recent: true } },
      )
      .then((resp) => {
        // console.log('patient group response ----', resp);
        dispatch({
          type: PATIENT_GROUPS_CHART_TOTALS_SUCCESS,
          payload: resp.data as PatientGroupChartProps[],
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- patient group chart totals request cancelled');
        }
        dispatch({ type: PATIENT_GROUPS_CHART_TOTALS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const deleteMyPatientGroup =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    dispatch({ type: MY_PATIENT_GROUP_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .delete(`/my_patient_groups/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('patient group response ----', resp);
        dispatch({
          type: DELETE_MY_PATIENT_GROUP_SUCCESS,
          payload: id,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- my patient group request cancelled');
        }
        dispatch({ type: MY_PATIENT_GROUP_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
