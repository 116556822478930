import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  ResultsActionTypes,
  RESULTS_REQUESTED,
  RESULTS_SUCCESS,
  RESULTS_ERROR,
  ResultProps,
} from './types';

import { cancelReq } from './actions';

const resultsReducer = (state: State = initialState, action: ResultsActionTypes): State => {
  switch (action.type) {
    case RESULTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RESULTS_SUCCESS:
      let rslt: ResultProps[] = [];
      if (action.payload) {
        action.payload.forEach((res: ResultProps) => {
          if (res.risk_percentile <= 100) {
            let percentile_quartile_text = undefined;
            if (res.risk_percentile < 25) percentile_quartile_text = '4th Quartile';
            if (res.risk_percentile < 50 && res.risk_percentile >= 25) percentile_quartile_text = '3rd Quartile';
            if (res.risk_percentile >= 50 && res.risk_percentile < 75) percentile_quartile_text = '2nd Quartile';
            if (res.risk_percentile >= 75) percentile_quartile_text = 'Top Quartile';

            rslt.push({ ...res, percentile_quartile_text } as ResultProps);
          } else {
            rslt.push(res);
          }
        });
      }
      return {
        ...state,
        loading: false,
        results: rslt,
      };
    case RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        results: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default resultsReducer;
