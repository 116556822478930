import { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

import { RootStore } from '../../configureStore';

import Loading from '../Loading';
import useStyles from './styles';

interface TenantLogoProps {
  isAuthenticated: boolean;
}

const TenantLogo = (props: TenantLogoProps) => {
  const classes = useStyles();
  const { isAuthenticated } = props;
  const [imageState, setImageState] = useState<{ loading: boolean; error: boolean }>({
    loading: true,
    error: false,
  });
  const { loading, error } = imageState;
  const tenantSettingsState = useSelector((state: RootStore) => state.tenant_settings);

  const tenantName: string = tenantSettingsState.tenantSettings.find((t) => t.name.match(/tenant_name/i))?.value || '';
  let tenantLogo: string | null = tenantSettingsState.tenantSettings.find((t) => t.name.match(/logo/i))?.value || null;

  // If not authenticated, return an empty div
  if (!isAuthenticated) {
    return <></>;
  }

  // If we have a logo url, then render image
  // But if it's still retrieving the Image, then show spinner
  if (tenantLogo && error === false) {
    return (
      <>
        {loading && <Loading size={24} />}
        <img
          src={tenantLogo}
          alt={tenantName}
          className={clsx(classes.logo, {
            [classes.logoLoaded]: !loading && !error,
          })}
          onLoad={() => setImageState((prevState) => ({ ...prevState, loading: false }))}
          onError={() => setImageState((prevState) => ({ ...prevState, loading: false, error: true }))}
        />
      </>
    );
  }

  // Default is to render Tenant Name
  return (
    <Typography className={classes.title} variant="h6" noWrap>
      {tenantName}
    </Typography>
  );
};

export default TenantLogo;
