import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useStyles from './styles';

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

function tabProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'data-testid': `tab-${index}`,
  };
}

export default function FullWidthTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    props.parentCallback(newValue);
  };

  return (
    <div>
      <AppBar position="static" className={classes.root}>
        <Tabs value={value} onChange={handleChange} indicatorColor="secondary">
          <Tab label="All" {...tabProps(0)} />
          <Tab label="Notes" {...tabProps(1)} />
          <Tab label="Actions" {...tabProps(2)} />
        </Tabs>
      </AppBar>
    </div>
  );
}
