import React, { FunctionComponent } from 'react';

import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import { useSectionWrapper } from '../SectionWrapper/SectionWrapperContext';

export interface SectionPrimaryContentI {
  children: React.ReactNode;
}
const SectionPrimaryContent: FunctionComponent<SectionPrimaryContentI> = (props: SectionPrimaryContentI) => {
  const { children } = props;
  const { collapse } = useSectionWrapper();

  return (
    <>
      <Divider />
      <Collapse in={collapse}>{children}</Collapse>
    </>
  );
};

export default SectionPrimaryContent;
