import { useAuth0 } from '@auth0/auth0-react';

import React, { FunctionComponent, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../Store/users/actions';
import { getRoles } from '../../Store/roles/actions';
import { getSecurityGroups } from '../../Store/security_groups/actions';
import { getPatientGroups } from '../../Store/patient_groups/actions';
import { RootStore } from '../../configureStore';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import { UserData, formatValueProps } from '../../Store/users/types';
import UsersTable, { UsersHeader, Order } from './UsersTable';
import { formatDateTimeObject } from '../../Components/DateTimeRender';
import InputSearchField from '../../Components/InputSearchField';
import UsersBulkActions from '../../Containers/UsersBulkActions';
import { SideNavLayout } from '../../Containers/SideNavWrapper';
import UsersViewTopBar from '../../Containers/UsersViewTopBar';

import { authorized } from '../../Utils/AxiosInstance';
import parseEmailResp, { RespAlertsI } from './parseEmailResp';
import UserFormButton from '../../Containers/UserFormButton';
import { Box, Chip } from '@mui/material';

import useStyles from './styles';

export interface IErrorProps {
  error: string;
  message: string;
}

export interface IOrdering {
  orderBy: string;
  order: Order;
}

const UsersView: FunctionComponent = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const users = useSelector((state: RootStore) => state.users);
  const roles = useSelector((state: RootStore) => state.roles);
  const securityGroups = useSelector((state: RootStore) => state.security_groups);
  const patientGroups = useSelector((state: RootStore) => state.patient_groups);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [usersToRender, setUsersToRender] = useState<UserData[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const [respAlerts, setRespAlerts] = useState<RespAlertsI[]>([]);

  const [ordering, setOrdering] = useState<IOrdering>({
    order: 'asc',
    orderBy: 'full_name', // Default Sort Column goes here...
  });

  // A state used to inform us if the users get request has been completed
  const [initUserGet, setInitUserGet] = useState<boolean>(false);
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(getUsers(token, { params: { include_references: 'roles,security_groups,patient_groups' } }));
      dispatch(getRoles(token));
      dispatch(getSecurityGroups(token));
      dispatch(getPatientGroups(token));
      setInitUserGet(true);
    });
  }, [dispatch, getAccessTokenSilently]);

  const userUpdtedSuccessfully = () => {
    setRespAlerts([
      {
        id: +new Date(),
        msg: 'User updated successfully.',
        severtity: 'success',
      },
    ]);
  };

  const headCells: UsersHeader[] = [
    {
      id: 'full_name',
      numeric: false,
      disablePadding: false,
      label: 'NAME',
      formatValue: formatValue,
      className: classes.userNameCell,
    },
    { id: 'blocked', numeric: false, disablePadding: false, label: 'STATUS', formatValue: formatValue },
    { id: 'roles', numeric: false, disablePadding: false, label: 'ROLE', formatValue: formatValue },
    {
      id: 'security_groups',
      numeric: false,
      disablePadding: false,
      label: 'SECURITY GROUPS',
      formatValue: formatValue,
    },
    {
      id: 'patient_groups',
      numeric: false,
      disablePadding: false,
      label: 'PATIENT GROUPS',
      formatValue: formatValue,
    },
    { id: 'formattedLastLogin', numeric: false, disablePadding: false, label: 'LAST LOGIN', formatValue: formatValue },
  ];

  function formatValue({ column, user }: formatValueProps) {
    switch (column) {
      case 'formattedLastLogin':
        return user.last_login === null || user.last_login === undefined
          ? 'N/A'
          : formatDateTimeObject({ dateTime: user.last_login });
      case 'full_name':
        let avt = user.given_name && user.family_name ? [user.given_name[0], user.family_name[0]].join('') : '';
        return (
          <div className={classes.avatarWrapper}>
            <Avatar className={classes.avatar}>{avt}</Avatar>
            <UserFormButton variant={'edit'} user={user} handleSuccessResp={userUpdtedSuccessfully} />
          </div>
        );
      case 'roles':
        return user.roles && roles.roles.length
          ? user.roles.map((userRole) => roles.roles.filter((role) => role.id === userRole)[0].name).join(', ')
          : '-';
      case 'security_groups':
        return user.security_groups && securityGroups.securityGroups.length
          ? user.security_groups.map((userSecurityGroup) => {
              if (securityGroups.securityGroups.some((securityGroup) => userSecurityGroup === securityGroup.id)) {
                return (
                  <Box mr={1} component="span" key={user.id + '-' + userSecurityGroup}>
                    <Chip
                      label={
                        securityGroups.securityGroups.filter(
                          (securityGroup) => userSecurityGroup === securityGroup.id,
                        )[0].name
                      }
                      className={classes.chip}
                    />
                  </Box>
                );
              } else {
                return null;
              }
            })
          : '-';
      case 'patient_groups':
        return user.patient_groups && patientGroups.patientGroups.length
          ? user.patient_groups.map((userPatientGroup) => {
              if (patientGroups.patientGroups.some((patientGroup) => userPatientGroup === patientGroup.id)) {
                return (
                  <Box mr={1} component="span" key={user.id + '-' + userPatientGroup}>
                    <Chip
                      label={
                        patientGroups.patientGroups.filter((patientGroup) => userPatientGroup === patientGroup.id)[0]
                          .name
                      }
                      className={classes.chip}
                    />
                  </Box>
                );
              } else {
                return null;
              }
            })
          : '-';
      case 'blocked':
        return user.blocked === true ? 'Inactive' : 'Active';
      default:
        return '-';
    }
  }

  const updateSelected = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let filteredUsers = usersToRender;
    let usersForTable = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    if (event.target.checked) {
      setSelected(usersForTable.map((u: UserData) => u.id));
    } else {
      setSelected([]);
    }
  };

  const updateRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected([]);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let arr = [...users.users];
    // If search String, then filter
    if (searchString && searchString.length) {
      arr = arr.filter((item: UserData) => {
        // Need to customise this to include wide range of columns for search
        return (
          item.email.toLocaleLowerCase().includes(searchString) ||
          item.full_name?.toLocaleLowerCase().includes(searchString)
        );
      });
    }
    const orderedData = arr.sort((a, b) =>
      ordering.order === 'asc'
        ? a[ordering.orderBy as keyof UserData] < b[ordering.orderBy as keyof UserData]
          ? -1
          : 1
        : a[ordering.orderBy as keyof UserData] > b[ordering.orderBy as keyof UserData]
        ? -1
        : 1,
    );
    setUsersToRender(orderedData);
  }, [ordering, searchString, users.users]);

  const handleOrderingUpdate = (property: keyof UserData) => {
    if (ordering.orderBy === property) {
      setOrdering(() => {
        return {
          order: ordering.order === 'asc' ? 'desc' : 'asc',
          orderBy: property,
        };
      });
    } else {
      setOrdering(() => {
        return {
          order: 'asc',
          orderBy: property,
        };
      });
    }
  };
  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UserData) => {
    setPage(0);
    handleOrderingUpdate(property);
  };

  const updateSearchValue = (value: any) => {
    if (selected.length) setSelected([]);
    setPage(0);
    setSearchString(value.toLowerCase()); // Ensures that results are not case-sensitive.
  };

  // Wrap this in a useCallback block
  //  so it's not re-assigned on each new rendering call.
  //  This will prevent re-rending of nested container???
  const send_invites = async (event: React.MouseEvent<unknown>) => {
    //Clear any previous Alerts.
    setRespAlerts([]);
    const token = await getAccessTokenSilently();
    authorized(token)
      .post('/portal/users/resend_user_verification_emails', { users: selected })
      .then((resp) => {
        parseEmailResp({ resp, selected, setRespAlerts, setSelected });
      })
      .catch((err) => {
        parseEmailResp({ resp: err.response, selected, setRespAlerts, setSelected });
      });
  };

  const send_password_reset = async (event: React.MouseEvent<unknown>) => {
    const token = await getAccessTokenSilently();
    authorized(token)
      .post('/portal/users/reset_user_passwords', { users: selected })
      .then((resp) => {
        parseEmailResp({ resp, selected, setRespAlerts, setSelected });
      })
      .catch((err) => {
        console.log('---- err: ', err);
        parseEmailResp({ resp: err.response, selected, setRespAlerts, setSelected });
      });
  };

  // This will be used with the global search feature
  const filteredUsers = usersToRender;
  const usersForTable = usersToRender.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // const [filteredUsers, setFilteredUsers] = useState(users.users);

  const totalUsers = filteredUsers ? filteredUsers.length : 0;

  return (
    <SideNavLayout>
      <UsersViewTopBar />
      <div className={classes.root}>
        {respAlerts &&
          respAlerts.length > 0 &&
          respAlerts.map((alert) => {
            return (
              <Alert
                severity={alert.severtity}
                key={alert.id}
                data-testid="resp-alert"
                className={classes.actionsContainer}
                onClose={() => {
                  const newAlerts = respAlerts.filter((s) => s.id !== alert.id);
                  setRespAlerts(newAlerts);
                }}
              >
                {alert.msg}
              </Alert>
            );
          })}

        <Grid container justifyContent="space-between" className={classes.actionsContainer}>
          <Grid item>
            <UsersBulkActions
              selected={selected}
              send_invites={send_invites}
              send_password_reset={send_password_reset}
            />
          </Grid>
          <Grid item>
            <Grid container>
              <InputSearchField
                updateSearchValue={updateSearchValue}
                placeHolder="Search name, email"
              ></InputSearchField>
            </Grid>
          </Grid>
        </Grid>
        <UsersTable
          updateSelected={updateSelected}
          selected={selected}
          headers={headCells}
          users={usersForTable}
          onSelectAllClick={onSelectAllClick}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={(e, n) => {
            setPage(n);
            setSelected([]);
          }}
          onChangeRowsPerPage={updateRowsPerPage}
          onRequestSort={onRequestSort}
          order={ordering.order}
          orderBy={ordering.orderBy}
          totalUsers={totalUsers}
          loading={initUserGet === false || users.loading || roles.loading}
          classes={classes}
        ></UsersTable>
      </div>
    </SideNavLayout>
  );
};

export default UsersView;
