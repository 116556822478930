import { FunctionComponent, useState, useEffect } from 'react';
import useStyles from './styles';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { TenantFilterProps } from '../../Store/tenant_filters/types';
import { RootStore } from '../../configureStore';

import FilterWrapper from '../GroupEditFilterWrapper';
import { PatientGroupProps, PatientAndSecurityGroupFilter, ProductTypeEnum } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import GroupEditFiltersPatientTable from './GroupEditFiltersPatientTable';

// This is for existing Filter Object that might not have a Tenant Filter
export interface BaseFilterObjectI {
  filterObject: PatientAndSecurityGroupFilter;
  index: number;
}

// Filter Object with a matching Tenant Filter
export interface CombinedFilterProps extends BaseFilterObjectI {
  tenantFilter: TenantFilterProps;
}

interface FiltersProps {
  addFilter?: (filter: TenantFilterProps) => void;
  removeFilter?: (indexId: number) => void;
  groupObject: PatientGroupProps | SecurityGroupProps;
  updateGroup: (newValue: Partial<PatientGroupProps> | Partial<SecurityGroupProps>) => void;
  productType?: ProductTypeEnum;
  groupType: 'patient' | 'security';
}

// Purpose of this component is to keep reference of ALL the filters being applied
//  to a Patient / Security Group.
// This will also be the middle item between when a Filter has been
//   * Added
//   * Removed
//   * Or the Filter value has changed

// Within this, it also stiches Known Filters that are being applied and remove it from
//  the list of Available Filters that a user can add.
const Filters: FunctionComponent<FiltersProps> = (props: FiltersProps) => {
  const classes = useStyles();
  const { groupObject, groupType, addFilter, removeFilter, productType, updateGroup } = props;
  //we are now fetching all filters from App.tsx to ensure filters are already loading when editing groups
  const TenantFiltersState = useSelector((state: RootStore) => state.tenant_filters);
  // IF Group Object is of type Security Group
  // Then secondary check defaults to true
  // IF Group Object is of Patient Group
  // Then all Filters that have a value for "product_type"
  //  will be checked if that string starts with or is of the Product Type passed in.
  // USE STATE otherwise, when filtering on Redux, the useEffect goes to infinite loop
  //  seeing changes of (filtered list vs non-filtered)
  const [TenantFilters] = useState<TenantFilterProps[]>([
    ...TenantFiltersState.tenantFilters.filter((f) => {
      let found = true;
      if (groupType === 'patient' && productType && f.product_type && f.product_type.length) {
        found = f.product_type.toUpperCase().startsWith(productType);
      }
      return f.filter_group && f.filter_group.startsWith(groupType) && found;
    }),
  ]);
  const [showMore, setShowMore] = useState<boolean>(TenantFilters.length > 15);
  // In the case where an existing filter is attached to the object, but no tenant filter exists
  const [missingTenantFilters, setMissingTenantFilters] = useState<BaseFilterObjectI[]>([]);
  // When we match a Filter with the Tenant Filter
  const [combinedFilters, setCombinedFilters] = useState<CombinedFilterProps[]>([]);
  // When something changes like Adding / Removing a Filter from the Group
  // Then stich the Filter that exist to a TenantFilter
  //    which also removes it from the list of Availble Filters to Add.
  useEffect(() => {
    if (groupObject.group_filters?.filters?.length !== undefined && groupObject.group_filters?.filters?.length >= 0) {
      let comboFinds: CombinedFilterProps[] = [];
      let missingTFilters: BaseFilterObjectI[] = [];
      groupObject.group_filters.filters.forEach((fltr, index) => {
        let found = TenantFilters.find((itm) => {
          return (
            fltr.model &&
            itm.column_name &&
            itm.entity_name.toLowerCase() === fltr.model.toLowerCase() &&
            itm.column_name.toLowerCase() === fltr.field.toLowerCase()
          );
        });

        // Existing filter and it's index
        let base = {
          filterObject: fltr,
          index,
        };
        if (found) {
          comboFinds.push({
            ...base,
            tenantFilter: found,
          });
        } else {
          missingTFilters.push(base);
        }
      });
      setCombinedFilters(comboFinds.sort((a, b) => (a.tenantFilter.sort_order > b.tenantFilter.sort_order ? 1 : -1)));
      // These are fitlers that might no longer be valid or were added by mistake.
      setMissingTenantFilters(missingTFilters);
    }
  }, [groupObject?.updated_dttm, groupObject.group_filters?.filters, TenantFilters]);

  // Selected filters dictionary
  const selectedFiltersDictionary: { [key: string]: boolean } = {};
  combinedFilters.forEach((itm) => (selectedFiltersDictionary[itm.tenantFilter.id] = true));

  // Group Filters
  let unselectedFilters: TenantFilterProps[] = [];
  let selectedFilters: TenantFilterProps[] = [];

  TenantFilters.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)).forEach((filter) => {
    selectedFiltersDictionary[filter.id] ? selectedFilters.push(filter) : unselectedFilters.push(filter);
  });

  // Show More
  if (showMore) {
    unselectedFilters = unselectedFilters.slice(0, 15);
  }

  const handleShowMore = () => setShowMore(false);

  // Handle the update here because we have access to all the filters here.
  // It's this or pass the FilterObject down.
  const handleUpdate = (val: PatientAndSecurityGroupFilter, index: number) => {
    let cloned = JSON.parse(JSON.stringify(groupObject));
    let found = cloned.group_filters?.filters[index];
    if (found) {
      cloned!.group_filters!.filters[index] = val;
      updateGroup(cloned);
    }
  };

  return (
    <Grid container>
      <Grid item sm={4}>
        <Typography variant="h2" className={classes.header}>
          Select Filters
        </Typography>
        {combinedFilters.length > 0 && (
          <Grid container data-testid="selected-filters">
            {combinedFilters.map((grp) => (
              <FilterWrapper
                key={grp.tenantFilter.id}
                onRemoveFilter={() => {
                  if (removeFilter) removeFilter(grp.index);
                }}
                PatientGroupObject={groupType === 'patient' ? (groupObject as PatientGroupProps) : undefined}
                TenantFilter={grp.tenantFilter}
                FilterObject={grp.filterObject}
                onChange={(newVal) => handleUpdate(newVal, grp.index)}
              />
            ))}
          </Grid>
        )}
        {missingTenantFilters.length > 0 && (
          <Grid container data-testid="missing-tenant-filters">
            {missingTenantFilters.map((fltr) => {
              return (
                <div
                  className={classes.noFindWrapper}
                  key={`no-tenant-filter-${fltr.index}`}
                  data-testid="missing-tenant-filter"
                >
                  <Typography variant="h6" className={classes.noFindTitle}>
                    Invalid Filter
                  </Typography>
                  <Typography className={classes.noFindText}>
                    {Object.values(fltr.filterObject)
                      .map((s) => s + '')
                      .join(' - ')}
                  </Typography>
                  {removeFilter && (
                    <Button variant="outlined" onClick={() => removeFilter(fltr.index)} data-testid="remove-filter">
                      Remove
                    </Button>
                  )}
                </div>
              );
            })}
          </Grid>
        )}
        {unselectedFilters.length > 0 && (
          <Grid container>
            <Grid item sm={12}>
              <Typography variant="h2" className={classes.filterByHeader}>
                Filter By:
              </Typography>
            </Grid>
            <Grid item sm={12} data-testid="unselected-filters">
              {unselectedFilters.map((filter) => (
                <FilterWrapper key={filter.id} onAddFilter={addFilter} TenantFilter={filter} />
              ))}
            </Grid>
            {showMore && (
              <Grid item sm={12}>
                <Button onClick={handleShowMore} color="primary" data-testid="show-more">
                  Show More
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item sm={8} className={classes.contentRight}>
        <GroupEditFiltersPatientTable group={groupObject} groupType={groupType} />
      </Grid>
    </Grid>
  );
};

export default Filters;
