import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  WhoamiActionTypes,
  WHOAMI_REQUESTED,
  WHOAMI_SUCCESS,
  WHOAMI_ERROR,
  LOGOUT,
  FORCED_LOGOUT,
  MENU_OPEN,
} from './types';
import { cancelReq } from './actions';

const whoamiReducer = (state: State = initialState, action: WhoamiActionTypes): State => {
  switch (action.type) {
    case WHOAMI_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case WHOAMI_SUCCESS:
      return {
        ...state,
        loading: false,
        whoami: action.payload,
      };
    case WHOAMI_ERROR:
      return {
        ...state,
        loading: false,
        whoami: undefined,
        error: action.payload,
      };
    case FORCED_LOGOUT:
      return {
        ...state,
        forcedLogout: true,
      };
    case MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default whoamiReducer;
