import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';

import SectionWrapperContext, { initialContext } from './SectionWrapperContext';
import useStyles from './styles';

export interface SectionWrapperProviderOptions {
  children?: any;
  className?: string;
  getState?: (val: any) => void;
  collapseLabel?: string;
}

/**
 * ```jsx
 * <SectionWrapperProvider>
 *   <SectionHeader>
 *    Title of Page
 *   </SectionHeader>
 *   <SectionPrimaryContent>
 *     <div>
 *        Your Content ....
 *     </div>
 *     <SectionShowMore />
 *   </SectionPrimaryContent>
 * </SectionWrapperProvider>
 * ```
 *
 * Provides the SectionWrapperContext to its child components.
 */
const SectionWrapperProvider = (props: SectionWrapperProviderOptions): JSX.Element => {
  const { children, className, getState, collapseLabel } = props;
  const classes = useStyles();

  // const [showMore, setShowMore] = useState<boolean>(false);
  // const [collapse, setCollapse] = useState(true);

  // Checks to see if the collapse section has a collapse label.
  // This collapse label will be the key referenced when attempting to
  // get the collapse's close/open status from localStorage.
  const [state, setState] = useState(
    collapseLabel
      ? {
          getState: initialContext.getState,
          toggleCollapse: initialContext.toggleCollapse,
          toggleShowMore: initialContext.toggleShowMore,
          // If the localStorage item hasn't been created yet, default
          // the showMore value to false.
          showMore: JSON.parse(localStorage.getItem(collapseLabel) || 'false'),
          collapse: true,
        }
      : initialContext,
  );

  const sendState = useCallback(
    (opts) => {
      if (getState) {
        getState(opts);
      }
    },
    [getState],
  );

  const toggleShowMore = useCallback(() => {
    const newState = { collapse: state.collapse, showMore: !state.showMore };
    // Send the update before we update the state
    //  so parent component get update before transition / animation starts
    //  else it will be jerky.
    sendState(newState);
    setState({ ...state, ...newState });
  }, [state, sendState]);

  const toggleCollapse = useCallback(() => {
    const newState = { collapse: !state.collapse, showMore: state.showMore };
    // Send the update before we update the state
    //  so parent component get update before transition / animation starts
    //  else it will be jerky.
    sendState(newState);
    setState({ ...state, ...newState });
  }, [state, sendState]);

  useEffect(() => {
    const { showMore, collapse } = state;
    sendState({ showMore, collapse });
  }, [sendState, state]);

  // Makes sure that the localStorage item for this collapseLabel is
  // set to whatever showMore's last state was before the page closes.
  useEffect(() => {
    return () => {
      collapseLabel && localStorage.setItem(collapseLabel, JSON.stringify(state.showMore));
    };
  }, [collapseLabel, state.showMore]);

  return (
    <SectionWrapperContext.Provider
      value={{
        ...state,
        toggleShowMore,
        toggleCollapse,
      }}
    >
      <Grid className={clsx(classes.root, className)}>{children}</Grid>
    </SectionWrapperContext.Provider>
  );
};

export default SectionWrapperProvider;
