import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  ProductsActionTypes,
  PRODUCTS_REQUESTED,
  PRODUCTS_SUCCESS,
  PRODUCTS_ERROR,
  // PRODUCTS_BATCH_SUCCESS,
} from './types';
import { cancelReq } from './actions';

const productsReducer = (state: State = initialState, action: ProductsActionTypes): State => {
  switch (action.type) {
    case PRODUCTS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        loading: false,
        products: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default productsReducer;
