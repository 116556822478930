import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  InsurancesActionTypes,
  INSURANCES_REQUESTED,
  INSURANCES_SUCCESS,
  INSURANCES_ERROR,
  RESET_INSURANCES,
} from './types';
import { cancelReq } from './actions';

const insurancesReducer = (state: State = initialState, action: InsurancesActionTypes): State => {
  switch (action.type) {
    case INSURANCES_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case INSURANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        insurances: action.payload,
      };
    case INSURANCES_ERROR:
      return {
        ...state,
        loading: false,
        insurances: [],
        error: action.payload,
      };
    case RESET_INSURANCES:
      return initialState;
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default insurancesReducer;
