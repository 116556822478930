import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CoolGrey1C, CoolGrey5C, primaryNavBarHeight, secondaryNavBarHeight } from '../../theme';

// other colors in design. How are these chosen? (Teal, Blue)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(4),
      paddingRight: 0,
      backgroundColor: theme.palette.common.white,
      height: secondaryNavBarHeight,
      position: 'sticky',
      top: primaryNavBarHeight,
      zIndex: 1101,
    },
    toolbarItemContainer: {
      borderLeft: `2px solid ${CoolGrey1C}`,
      padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
    },
    search: {
      flexGrow: 1,
      '& .MuiInputBase-root': {
        '&:before': {
          borderBottom: 'none',
        },
        '& .MuiInputBase-input::-webkit-input-placeholder': {
          opacity: 1,
          fontSize: '0.75rem',
          fontWeight: 900,
        },
      },
    },
    searchIcon: {
      color: CoolGrey5C,
    },
    startSearchIcon: {
      marginTop: '1rem',
      color: CoolGrey1C,
      fontSize: '14rem',
    },
    startSearchContainer: {
      height: '70vh',
      paddingRight: '1.2rem',
    },
    startSearchHeader: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: CoolGrey5C,
    },
    startSearchBody: {
      color: CoolGrey5C,
      fontSize: '0.8rem',
    },
    searchbarGridWidth: {
      width: '100%',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    headerDrawerBox: {
      paddingTop: theme.spacing(1),
      height: `calc(100% - ${primaryNavBarHeight * 2}px)`,
      width: 350,
      flexGrow: 1,
    },
    headerDrawerContents: {
      marginTop: theme.spacing(1.25),
    },
    drawerHeading: {
      fontSize: '1.2rem',
      fontWeight: 500,
      paddingTop: '1.5rem',
    },
    drawerBody: {
      fontSize: '0.9rem',
    },
    noResultsBody: {
      marginRight: '0.7rem',
    },
    noResultsIndent: {
      textIndent: '1rem',
      fontSize: '0.9rem',
    },
    resultsContent: {
      paddingRight: '1.5rem',
      fontSize: '0.8rem',
      width: '100%',
    },
    drawerContent: {
      width: '100%',
    },
    patientCount: {
      marginBottom: '0.7rem',
    },
    individualResult: {
      marginTop: '0.7rem',
      marginBottom: '0.7rem',
    },
    skeletonLoader: {
      marginBottom: '0.4rem',
      width: '100%',
    },
    resultDate: {
      fontSize: '0.7rem',
    },
  }),
);

export default useStyles;
