import { MouseEvent, useState, FunctionComponent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { RootStore } from '../../configureStore';
import TenantLogo from '../../Components/TenantLogo';

// import Loading from '../../Components/Loading';
// import DemoLogo from '../../Images/demo-logo';

import useStyles from './styles';

import useTenantSwitching from '../../HOC/TenantSwitching/useTenantSwitching';

const TopNavBar: FunctionComponent = () => {
  const classes = useStyles();
  const { logout, isAuthenticated } = useAuth0();
  const currentUser = useSelector((state: RootStore) => state.current_user.whoami);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasMultiTenants, switchTenant } = useTenantSwitching();
  const showTenantsButton = hasMultiTenants();

  // const [imageState, setImageState] = useState<{ loading: boolean; error: boolean }>({
  //   loading: true,
  //   error: false,
  // });
  // const { loading, error } = imageState;

  // TODO: remove demo data once this is returned by the whoami endpoint
  // const companyName: string = 'Hope Valley Health';
  // let companyLogo: string | null = DemoLogo;

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <TenantLogo isAuthenticated={isAuthenticated} />
          {/* {isAuthenticated && companyLogo && (
            <>
              {loading && <Loading size={24} />}
              <img
                src={companyLogo}
                alt={companyName}
                className={clsx(classes.logo, {
                  [classes.logoLoaded]: !loading && !error,
                })}
                onLoad={() => setImageState((prevState) => ({ ...prevState, loading: false }))}
                onError={() => setImageState((prevState) => ({ ...prevState, loading: false, error: true }))}
              />
            </>
          )} */}
          {/* {isAuthenticated && (!companyLogo || error) && (
            <Typography className={classes.title} variant="h6" noWrap>
              {companyName}
            </Typography>
          )} */}

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {isAuthenticated && currentUser && (
              <Button
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Box display="flex" alignItems="center" justifyContent="center" className={classes.avatar}>
                  {currentUser.given_name.charAt(0) + currentUser.family_name.charAt(0)}
                </Box>
                <Typography className={classes.welcome} variant="body2" noWrap>
                  Welcome, {currentUser?.given_name}
                </Typography>
                <KeyboardArrowDown className={classes.arrowDown} />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id="primary-search-account-menu"
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {showTenantsButton && <MenuItem onClick={() => switchTenant()}>Switch Tenant</MenuItem>}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default TopNavBar;
