import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  TenantSettingsActionTypes,
  TENANT_SETTINGS_REQUESTED,
  TENANT_SETTINGS_SUCCESS,
  TENANT_SETTINGS_ERROR,
} from './types';
import { cancelReq } from './actions';

const tenantSettingsReducer = (state: State = initialState, action: TenantSettingsActionTypes): State => {
  switch (action.type) {
    case TENANT_SETTINGS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case TENANT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantSettings: action.payload,
      };
    case TENANT_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        tenantSettings: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default tenantSettingsReducer;
