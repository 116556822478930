import { FunctionComponent } from 'react';
import { Button, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import useStyles from './styles';

import { PatientGroupProps } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import JvionInlineTextField from '../../Components/JvionInlineTextField';

export interface GroupEditModalHeaderProps {
  handleClose: () => void;
  handleEditGroup: (newValue: Partial<PatientGroupProps> | Partial<SecurityGroupProps>) => void;
  handleContinue: () => void;
  handleBack: () => void;
  handleConfirm: () => void;
  currentStep: number;
  groupType: 'patient' | 'security';
  group?: PatientGroupProps | SecurityGroupProps;
  allowContinue: boolean; //will go away with group.filters
  isSubmitting: boolean;
}

const GroupEditModalHeader: FunctionComponent<GroupEditModalHeaderProps> = (props: GroupEditModalHeaderProps) => {
  const classes = useStyles();
  const {
    currentStep,
    handleEditGroup,
    handleClose,
    handleBack,
    group,
    groupType,
    allowContinue,
    handleContinue,
    isSubmitting,
    handleConfirm,
  } = props;

  const onSaveTitle = (newValue: string) => {
    handleEditGroup({ name: newValue });
  };

  const onSaveDescription = (newValue: string) => {
    handleEditGroup({ description: newValue });
  };

  return (
    <>
      <DialogTitle>
        <Grid className={classes.headerTop} container justifyContent="space-between" alignItems="center">
          <Typography variant="h2" className={classes.headerText}>{`${
            currentStep === 1 ? 'Edit' : 'Share'
          } ${groupType} group`}</Typography>
          <IconButton
            className={classes.close}
            aria-label="close"
            onClick={handleClose}
            data-testid="group-edit-modal-close"
            disabled={isSubmitting}
            size="large"
          >
            <>
              <Typography variant="button" className={classes.closeText}>
                Close
              </Typography>
              <CloseIcon className={classes.closeIcon} />
            </>
          </IconButton>
        </Grid>
        <Grid className={classes.headerBottom} container justifyContent="space-between">
          <Grid item md={6}>
            <JvionInlineTextField
              value={group ? group.name : ''}
              onSave={onSaveTitle}
              className={classes.header}
              data-testid="group-edit-modal-title"
            />
            <JvionInlineTextField
              value={group ? group.description : ''}
              multiline
              onSave={onSaveDescription}
              data-testid="group-edit-modal-description"
            />
          </Grid>
          <Grid item>
            {currentStep === 2 && (
              <Button onClick={handleBack} color="primary" data-testid="group-edit-modal-back">
                Back
              </Button>
            )}
            <Button
              onClick={currentStep === 1 ? handleContinue : handleConfirm}
              disabled={!allowContinue || isSubmitting}
              variant="contained"
              color="primary"
              data-testid="group-edit-modal-continue"
            >
              {currentStep === 1 ? 'Continue' : 'Finish'}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
    </>
  );
};

export default GroupEditModalHeader;
