import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'unset',
    backgroundColor: 'unset',
    '& .MuiTabs-root': {
      // For use with the Patient Info View > Comments Section that is wrapped with our SectionShowMore component
      marginBottom: `-${theme.spacing(1.25)}`,
      marginTop: `-${theme.spacing(1.25)}`,
      '& .MuiTab-root': {
        minWidth: theme.spacing(3),
      },
    },
  },
  statusDropdown: {
    visibility: 'hidden',
    width: '100%',
    height: 0,
  },
  buttonStyle: {
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
