import { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import SectionWrapper from '../SectionWrapper';
import SectionHeader from '../SectionHeader';
import SectionPrimaryContent from '../SectionPrimaryContent';
import CommentCreateModal from '../../Containers/CommentCreateModal';
import FullWidthTabs from '../FixedTabs';
import HistoryRecordWrapper, { HistoryRecordWrapperProps } from '../HistoryRecordWrapper';
import SectionShowMore from '../SectionShowMore';
import { CommentProps } from '../../Store/comments/types';
import { RootStore } from '../../configureStore';
import { RecommendationProps } from '../../Store/recommendations/types';

import useStyles from './styles';
import {
  getHistoricalRecommendations,
  resetHistoricalRecommendations,
} from '../../Store/historical_recommendations/actions';
import { useAuth0 } from '@auth0/auth0-react';

export interface HistorySectionProps {
  personID: string;
  recommendation_std_id?: string;
  onClearRecommendationId: () => void;
}

const emptyMessageForNotes = 'There is no previous activity. Update a recommendation to start the activity thread!';
const emptyMessageForActions = 'There is no previous activity. Add a note to start the activity thread!';

const HistorySection: FunctionComponent<HistorySectionProps> = (props: HistorySectionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { personID, recommendation_std_id, onClearRecommendationId } = props;
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);
  const [toggled, setToggled] = useState({ showMore: false, collapse: true });
  const [filteredRecords, setFilteredRecords] = useState<HistoryRecordWrapperProps[]>([]);
  const historicalRecommendations = useSelector((state: RootStore) => state.historicalRecommendations.recommendations);
  const recommendationLabels = useSelector((state: RootStore) => state.recommendation_labels.recommendationLabels);
  const recommendations = useSelector((state: RootStore) => state.recommendations.recommendations);
  const comments = useSelector((state: RootStore) => state.comments.comments);
  const [recommendationDescription, setRecommendationDescription] = useState<string>('');
  // Setting the below to -1, will help assure that the component fetches new information for the person.
  const [latestRecommendation, setLatestRecommendation] = useState<number>(-1);
  const [openCreate, setOpenCreate] = useState<{ open: boolean; newCommentProps: Partial<CommentProps> }>({
    open: false,
    newCommentProps: {},
  });

  const historySectionCollapseLabel: string = 'historySectionShowMoreCollapse';

  // supports show more functionality
  const rowsToRender =
    toggled.showMore === false && filteredRecords.length ? filteredRecords.slice(-5) : filteredRecords;

  const defaultCommentProps: Partial<CommentProps> = {
    comment_entity: 'PERSON',
    person_id: personID,
  };

  const handleDelete = () => {
    setRecommendationDescription('');
    onClearRecommendationId();
  };

  const handleCloseAddComment = () =>
    setOpenCreate({
      open: false,
      newCommentProps: {},
    });

  const handleAddComment = (newCommentProps: Partial<CommentProps>) =>
    setOpenCreate({ open: true, newCommentProps: { ...defaultCommentProps, ...newCommentProps } });

  // Filter records
  useEffect(() => {
    // filters out recommendation results as well as system generated ones without user
    const filterFilteredRecord = (type: 'RECOMMENDATION' | 'COMMENT', item: CommentProps | RecommendationProps) => {
      if (
        recommendation_std_id &&
        (type === 'COMMENT'
          ? (item as CommentProps).recommendation_std_id !== recommendation_std_id
          : (item as RecommendationProps).id !== recommendation_std_id &&
            (item as RecommendationProps).parent_id !== recommendation_std_id)
      )
        return false;
      if (!item.user || item.user === null || !item.user.family_name || !item.user.given_name) return false;
      return true;
    };
    // formats filtered recors to meet specs of Historical record wrapper
    const formatFilteredRecord = (type: 'RECOMMENDATION' | 'COMMENT', item: CommentProps | RecommendationProps) => {
      const recommendation =
        type === 'COMMENT'
          ? historicalRecommendations.find(
              (rec) => rec.recommendation_std_id === (item as CommentProps).recommendation_std_id,
            )
          : (item as RecommendationProps);
      const recommendationLabel = recommendationLabels.find(
        (recL) => recL.id === recommendation?.recommendation_label_id,
      );
      return {
        type: type,
        text: type === 'COMMENT' ? (item as CommentProps).text : undefined,
        entity: type === 'COMMENT' ? (item as CommentProps).comment_entity : undefined,
        user: item.user,
        product_id: item.product_id,
        recommendationDescription: recommendationLabel?.description,
        recommendation: recommendation,
        updated_dttm: item.updated_dttm,
      };
    };

    // sorts filtered records
    const sortFilteredRecords = (a: HistoryRecordWrapperProps, b: HistoryRecordWrapperProps) =>
      new Date(a.updated_dttm).getTime() > new Date(b.updated_dttm).getTime() ? 1 : -1;

    switch (selectedTabValue) {
      case 0:
        setFilteredRecords(
          [
            ...comments
              .filter((item) => filterFilteredRecord('COMMENT', item))
              .map((item) => formatFilteredRecord('COMMENT', item)),
            ...historicalRecommendations
              .filter((item) => filterFilteredRecord('RECOMMENDATION', item))
              .map((item) => formatFilteredRecord('RECOMMENDATION', item)),
          ].sort(sortFilteredRecords),
        );
        break;
      case 1:
        setFilteredRecords(
          comments
            .filter((item) => filterFilteredRecord('COMMENT', item))
            .map((item) => formatFilteredRecord('COMMENT', item))
            .sort(sortFilteredRecords),
        );
        break;
      case 2:
        setFilteredRecords(
          historicalRecommendations
            .filter((item) => filterFilteredRecord('RECOMMENDATION', item))
            .map((item) => formatFilteredRecord('RECOMMENDATION', item))
            .sort(sortFilteredRecords),
        );
        break;
      default:
        break;
    }
    return () => {
      setFilteredRecords([]);
    };
  }, [comments, historicalRecommendations, recommendationLabels, selectedTabValue, recommendation_std_id]);

  // get recommendation label name
  useEffect(() => {
    if (recommendation_std_id) {
      const recommendation = historicalRecommendations.find(
        (rec) => rec.recommendation_std_id === recommendation_std_id,
      );
      const recommendationLabel = recommendationLabels.find(
        (recL) => recL.id === recommendation?.recommendation_label_id,
      );
      setRecommendationDescription(recommendationLabel ? recommendationLabel.description : '');
    }
    return () => {
      setRecommendationDescription('');
    };
  }, [recommendation_std_id, historicalRecommendations, recommendationLabels, setRecommendationDescription]);

  useEffect(() => {
    // When Recommendations are updated, or component first loads, then get the
    //   historical recommendations for this PersonID.
    // DO NOT sort the recommendations so they do not get mutated.  Sort a copy of the array.
    const lastRecommendation = [...recommendations].sort((a, b) => b.updated_dttm - a.updated_dttm)[0];
    if (lastRecommendation && lastRecommendation.updated_dttm !== latestRecommendation) {
      setLatestRecommendation(lastRecommendation.updated_dttm);
      getAccessTokenSilently().then((token: string) => {
        dispatch(
          getHistoricalRecommendations(token, {
            params: { person_id: personID, fetch_history: true },
          }),
        );
      });
    }
    // If there are no recommendations, then clear them.
    if (lastRecommendation === undefined && historicalRecommendations.length) {
      dispatch(resetHistoricalRecommendations());
      // Reset the latest recommendation to always fetch fresh data.
      if (latestRecommendation !== -1) setLatestRecommendation(-1);
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    historicalRecommendations.length,
    latestRecommendation,
    personID,
    recommendations,
  ]);

  return (
    <SectionWrapper className={classes.section} getState={setToggled} collapseLabel={historySectionCollapseLabel}>
      <SectionHeader variant="span">
        <FullWidthTabs parentCallback={setSelectedTabValue}></FullWidthTabs>
      </SectionHeader>
      <SectionPrimaryContent>
        <div className={classes.topDivSection}>
          {filteredRecords.length > 5 && <SectionShowMore showPreviousTag={true}>{}</SectionShowMore>}
          {recommendation_std_id && recommendationDescription && (
            <Box p={1}>
              <Chip
                label={
                  recommendationDescription.length > 60 ? (
                    <Tooltip title={recommendationDescription}>
                      <Typography>{recommendationDescription.slice(0, 60).trim()}...</Typography>
                    </Tooltip>
                  ) : (
                    recommendationDescription
                  )
                }
                onDelete={handleDelete}
                data-testid="recommendation-filter-chip"
              ></Chip>
            </Box>
          )}
        </div>
        <Box p={2}>
          {rowsToRender.map((row, idx) => (
            <HistoryRecordWrapper {...row} key={`record_${idx}`} />
          ))}
          {filteredRecords.length === 0 && (selectedTabValue !== 2 ? emptyMessageForActions : emptyMessageForNotes)}
          <div className={classes.section}>
            {selectedTabValue !== 2 && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                data-testid="add-note"
                onClick={() => {
                  handleAddComment({
                    person_id: personID,
                  });
                }}
              >
                <AddIcon></AddIcon>Add Note
              </Button>
            )}
          </div>
        </Box>
      </SectionPrimaryContent>
      <CommentCreateModal
        open={openCreate.open}
        commentDefaultProps={openCreate.newCommentProps}
        onClose={handleCloseAddComment}
      />
    </SectionWrapper>
  );
};

export default HistorySection;
