import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { UILabelsActionTypes, UI_LABELS_REQUESTED, UI_LABELS_SUCCESS, UI_LABELS_ERROR } from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getUILabels =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<UILabelsActionTypes>) => {
    dispatch({ type: UI_LABELS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/ui_labels', { params: { enabled: 'true' }, cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('ui labels response ----', resp);
        dispatch({
          type: UI_LABELS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- ui labels request cancelled');
        }
        dispatch({ type: UI_LABELS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
