import { RouteChangeProps } from '../history/reducer';
import { PatientGroupProps } from '../patient_groups/types';

export type State = {
  patientGroups: PatientGroupProps[];
  patientGroup?: PatientGroupProps | undefined;
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  patientGroups: [],
  patientGroup: undefined,
  loading: false,
  error: undefined,
};

export const PATIENT_SEARCH_REQUESTED = 'PATIENT_SEARCH_REQUESTED';
export const PATIENT_SEARCH_SUCCESS = 'PATIENT_SEARCH_SUCCESS';
export const PATIENT_SEARCH_ERROR = 'PATIENT_SEARCH_ERROR';
export const CLEAR_PATIENT_SEARCH = 'CLEAR_PATIENT_SEARCH';

interface PatientSearchLoading {
  type: typeof PATIENT_SEARCH_REQUESTED;
}
interface PatientSearchSuccess {
  type: typeof PATIENT_SEARCH_SUCCESS;
  payload: PatientGroupProps[];
}
interface PatientSearchError {
  type: typeof PATIENT_SEARCH_ERROR;
  payload: string;
}

interface ClearPatientSearch {
  type: typeof CLEAR_PATIENT_SEARCH;
  payload: string;
}

// Action Types
export type PatientGroupsActionTypes =
  | PatientSearchLoading
  | PatientSearchSuccess
  | PatientSearchError
  | ClearPatientSearch
  | RouteChangeProps;
