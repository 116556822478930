export interface Tenant {
  tenant_id: string;
  tenant_name: string;
}

export interface TenantSwitchingStateI {
  error?: Error;
  hasInitialized: boolean;
  isLoading: boolean;
  isSelectingTenant: boolean;
  selectedTenant?: Tenant;
  tenants: Tenant[];
}

/**
 * The initial Tenant Switching state.
 */
export const initialTenantSwitchingState: TenantSwitchingStateI = {
  hasInitialized: false,
  tenants: [],
  isLoading: true,
  isSelectingTenant: false,
};
