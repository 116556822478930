import Users from '../Views/Users';
import GroupManagement from '../Views/GroupManagement';
import UserActivity from '../Views/UserActivity';
import { JvionRouteProps } from '.';

export enum usersPaths {
  Users = '/users',
  GroupManagement = '/group-management',
  UserActivity = '/user-activity',
}

const usersRoutes: JvionRouteProps[] = [
  {
    path: usersPaths.Users,
    component: Users,
    roles: ['User Administrator'],
  },
  {
    path: usersPaths.GroupManagement,
    component: GroupManagement,
    roles: ['User Administrator', 'Patient Administrator'],
  },
  {
    path: usersPaths.UserActivity,
    component: UserActivity,
    roles: ['User Administrator'],
  },
];

export default usersRoutes;
