import { RouteChangeProps } from '../history/reducer';
import { MetadataProps } from '../Utils/types';

export type RoleNames = 'User Administrator' | 'Patient Administrator' | 'Patient Viewer';

export type State = {
  roles: RoleProps[];
  role?: RoleProps | undefined;
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  roles: [],
  role: undefined,
  loading: false,
  error: undefined,
};

export interface RoleProps {
  id: string;
  name: RoleNames;
  description?: string;
  updated_dttm?: number;
}

export interface RolesResponseProps {
  metadata: MetadataProps;
  data: RoleProps[];
}

export const ROLES_REQUESTED = 'ROLES_REQUESTED';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_ERROR = 'ROLES_ERROR';

interface RolesLoading {
  type: typeof ROLES_REQUESTED;
}
interface RolesSuccess {
  type: typeof ROLES_SUCCESS;
  payload: RoleProps[];
}
interface RolesError {
  type: typeof ROLES_ERROR;
  payload: string;
}
export type RolesActionTypes = RolesLoading | RolesSuccess | RolesError | RouteChangeProps;
