import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {},
  content: {},
  input: {
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.warning.main,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.warning.main,
    },
    '& .MuiInputBase-root.Mui-error .MuiInputBase-input': {
      borderColor: theme.palette.warning.main,
    },
  },
}));

export default useStyles;
