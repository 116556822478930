import { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

import { SideNavLayout } from '../../Containers/SideNavWrapper';
import GroupEditModal from '../../Containers/GroupEditModal';
import GroupCreateModal from '../../Containers/GroupCreateModal';
import GroupManagementTable from './GroupManagementTable';
import PageHeader from '../../Components/PageHeader';
import PageContent from '../../Components/PageContent';
import ConfirmModal from '../../Components/ConfirmModal';

import { PatientGroupProps, OrderedPatientGroup, ProductTypeEnum } from '../../Store/patient_groups/types';
import { SecurityGroupProps, OrderedSecurityGroup } from '../../Store/security_groups/types';
import {
  getSecurityGroups,
  deleteSecurityGroup,
  getSecurityGroupsEditTotals,
} from '../../Store/security_groups/actions';
import { getPatientGroups, deletePatientGroup, getPatientGroupsEditTotals } from '../../Store/patient_groups/actions';
import { RootStore } from '../../configureStore';

import { hasPermissionFor } from '../../Utils/UserHelper';

import useStyles from './styles';

export interface OrderingState {
  orderBy: 'name' | 'patients' | 'users';
  order: 'asc' | 'desc';
}

export interface GroupManagementState {
  group?: PatientGroupProps | SecurityGroupProps;
  groupType: 'patient' | 'security';
  initGroupOpen: boolean;
  editGroupOpen: boolean;
  deleteGroupOpen: boolean;
  ordering: OrderingState;
  orderedGroups: OrderedPatientGroup[] | OrderedSecurityGroup[];
  isSubmitting: boolean;
  productType: ProductTypeEnum;
  apiError?: string;
  showDeleteSuccess: boolean;
}

const GroupManagement: FunctionComponent = () => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const securityGroupState = useSelector((state: RootStore) => state.security_groups);
  const patientGroupState = useSelector((state: RootStore) => state.patient_groups);
  const currentUserState = useSelector((state: RootStore) => state.current_user);
  const currentUser = currentUserState.whoami;
  const [state, setState] = useState<GroupManagementState>({
    groupType: 'patient',
    productType: 'INPATIENT',
    initGroupOpen: false,
    editGroupOpen: false,
    deleteGroupOpen: false,
    ordering: {
      orderBy: 'name',
      order: 'asc',
    },
    orderedGroups: [],
    isSubmitting: false,
    showDeleteSuccess: false,
  });
  const {
    deleteGroupOpen,
    editGroupOpen,
    group,
    groupType,
    initGroupOpen,
    orderedGroups,
    ordering,
    productType,
    isSubmitting,
    apiError,
    showDeleteSuccess,
  } = state;

  const CurrentUserIsAdmin = useMemo(() => {
    // If user is not assigned, then return false
    if (currentUser === undefined) return false;
    return hasPermissionFor(currentUser, 'User Administrator');
  }, [currentUser]);

  // fetch groups
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      dispatch(getPatientGroups(token, { params: { include_references: 'users', sort_by: 'name' } }));
      // if (CurrentUserIsAdmin) {
      dispatch(getSecurityGroups(token, { params: { include_references: 'users', sort_by: 'name' } }));
      // }
    });
  }, [CurrentUserIsAdmin, dispatch, getAccessTokenSilently]);

  // totals if the totals length doesnt match groups and thier are groups to be matched
  useEffect(() => {
    if (
      patientGroupState.patientGroups.length > 0 &&
      groupType === 'patient' &&
      patientGroupState.loading === false &&
      patientGroupState.patientGroups.filter((pg) => pg.patients === -1).length !== 0
    ) {
      const pgIds = patientGroupState.patientGroups.map((pg) => pg.id);
      getAccessTokenSilently().then((token) => {
        dispatch(getPatientGroupsEditTotals(token, pgIds));
      });
    }
  }, [patientGroupState.patientGroups, groupType, dispatch, getAccessTokenSilently, patientGroupState.loading]);

  // totals if the totals length doesnt match groups and thier are groups to be matched
  useEffect(() => {
    if (
      securityGroupState.securityGroups.length > 0 &&
      groupType === 'security' &&
      securityGroupState.loading === false &&
      securityGroupState.securityGroups.filter((sg) => sg.patients === -1).length !== 0
    ) {
      const sgIds = securityGroupState.securityGroups.map((sg) => sg.id);
      getAccessTokenSilently().then((token) => {
        dispatch(getSecurityGroupsEditTotals(token, sgIds));
      });
    }
  }, [securityGroupState.securityGroups, groupType, dispatch, getAccessTokenSilently, securityGroupState.loading]);

  const handleClickGroupEdit = (editGroupId: string, editGroupType: 'patient' | 'security') => {
    let { productType } = state;
    let groupObject: any;
    if (editGroupType === 'patient') {
      groupObject = patientGroupState.patientGroups.filter((group) => group.id === editGroupId)[0] as PatientGroupProps;
      productType = groupObject.product_type || undefined;
    } else {
      groupObject = securityGroupState.securityGroups.filter(
        (group) => group.id === editGroupId,
      )[0] as SecurityGroupProps;
      productType = undefined;
    }
    setState((prevState) => ({
      ...prevState,
      groupType: editGroupType,
      group: groupObject,
      editGroupOpen: true,
      productType,
    }));
  };

  const handleClickGroupDelete = (deleteGroupId: string, deleteGroupType: 'patient' | 'security') => {
    let groupObject: PatientGroupProps | SecurityGroupProps;
    if (deleteGroupType === 'patient') {
      groupObject = patientGroupState.patientGroups.filter(
        (group) => group.id === deleteGroupId,
      )[0] as PatientGroupProps;
    } else {
      groupObject = securityGroupState.securityGroups.filter(
        (group) => group.id === deleteGroupId,
      )[0] as SecurityGroupProps;
    }
    setState((prevState) => ({
      ...prevState,
      groupType: deleteGroupType,
      group: groupObject,
      deleteGroupOpen: true,
    }));
  };

  const handleClickGroupDeleteConfirm = () => {
    if (group && group.id && groupType === 'patient') {
      getAccessTokenSilently().then((token) => {
        dispatch(deletePatientGroup(token, group.id as string));
      });
    } else if (group && group.id && groupType === 'security') {
      // We no longer have a need to ask for specific Endpoint tokens.
      // Gateway will do this check for us.
      getAccessTokenSilently().then((token) => {
        dispatch(deleteSecurityGroup(token, group.id as string));
      });
    }
  };

  const formatValues = useCallback(
    (groups: PatientGroupProps[] | SecurityGroupProps[]) =>
      groups.map((group: PatientGroupProps | SecurityGroupProps) => {
        return {
          ...group,
          users: group.users ? group.users.length : 0,
        };
      }),
    [],
  );

  // If there is a selected Patient Group and API has reponded with new information
  //  then send the update downstream
  useEffect(() => {
    // Should only trigger when request is sent and when it ends
    if (patientGroupState.loading === false && groupType === 'patient') {
      if (group) {
        patientGroupState.patientGroups.forEach((grp) => {
          // We only care if Item in Array is a match and updated DTTM is newer
          if (group.id === grp.id && group.updated_dttm !== grp.updated_dttm) {
            setState((prevState) => ({
              ...prevState,
              group: grp,
            }));
          }
        });
      }
      setState((prevState) => ({
        ...prevState,
        ordering: {
          orderBy: 'name',
          order: 'asc',
        },
        orderedGroups: formatValues(patientGroupState.patientGroups),
      }));
    }
  }, [patientGroupState.loading, groupType, group, patientGroupState.patientGroups, formatValues]);

  // If there is a selected Security Group and API has reponded with new information
  //  then send the update downstream
  useEffect(() => {
    // Should only trigger when request is sent and when it ends
    if (securityGroupState.loading === false && groupType === 'security') {
      if (group) {
        securityGroupState.securityGroups.forEach((grp) => {
          // We only care if Item in Array is a match and updated DTTM is newer
          if (group.id === grp.id && group.updated_dttm !== grp.updated_dttm) {
            setState((prevState) => ({
              ...prevState,
              group: grp,
            }));
          }
        });
      }
      setState((prevState) => ({
        ...prevState,
        ordering: {
          orderBy: 'name',
          order: 'asc',
        },
        orderedGroups: formatValues(securityGroupState.securityGroups),
      }));
    }
  }, [securityGroupState.loading, groupType, group, securityGroupState.securityGroups, formatValues]);

  // make sure the delete is successful if not update error state
  useEffect(() => {
    if (
      !isSubmitting &&
      (groupType === 'patient' || groupType === 'security') &&
      (patientGroupState.loading || securityGroupState.loading)
    ) {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
    }
    if (
      isSubmitting &&
      ((groupType === 'patient' && !patientGroupState.loading) ||
        (groupType === 'security' && !securityGroupState.loading))
    ) {
      if (patientGroupState.error || securityGroupState.error) {
        setState((prevState) => ({
          ...prevState,
          apiError: patientGroupState.error || securityGroupState.error,
          isSubmitting: false,
        }));
      } else {
        let partialState: any = { apiError: undefined, isSubmitting: false };
        if (deleteGroupOpen) {
          partialState = { ...partialState, deleteGroupOpen: false, showDeleteSuccess: true };
        }
        setState((prevState) => ({ ...prevState, ...partialState }));
      }
    }
  }, [groupType, patientGroupState, securityGroupState, isSubmitting, deleteGroupOpen]);

  const headers: {
    id: keyof PatientGroupProps | keyof SecurityGroupProps;
    label: string;
    sortable: boolean;
  }[] = [
    {
      id: 'name',
      label: 'GROUP',
      sortable: true,
    },
    {
      id: 'patients',
      label: '# OF PATIENTS',
      sortable: true,
    },
    {
      id: 'users',
      label: 'ASSIGNED USERS',
      sortable: true,
    },
    {
      id: 'group_filters',
      label: 'FILTERS',
      sortable: false,
    },
  ];

  const handleNewGroupSuccess = (
    newGroup: PatientGroupProps | SecurityGroupProps,
    newGroupType: 'patient' | 'security',
  ) => {
    setState((prevState) => ({
      ...prevState,
      groupType: newGroupType,
      group: newGroup,
      initGroupOpen: false,
      editGroupOpen: true,
    }));
  };

  const handleSort = useCallback(
    (newOrdering?: OrderingState) => {
      const newOrderBy: 'name' | 'patients' | 'users' = (newOrdering && newOrdering.orderBy) || 'name';
      const newOrder =
        newOrdering && ordering.orderBy === newOrdering.orderBy
          ? newOrdering.order === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';
      setState((prevState) => ({
        ...prevState,
        ordering: {
          orderBy: newOrderBy,
          order: newOrder,
        },
        orderedGroups: prevState.orderedGroups.sort((a, b) => {
          const tempA = typeof a[newOrderBy] === 'string' ? (a[newOrderBy] as string).toLowerCase() : a[newOrderBy];
          const tempB = typeof b[newOrderBy] === 'string' ? (b[newOrderBy] as string).toLowerCase() : b[newOrderBy];
          return newOrder === 'asc' ? (tempA < tempB ? -1 : 1) : tempA > tempB ? -1 : 1;
        }),
      }));
    },
    [ordering.orderBy],
  );

  const handleToastClose = () =>
    setState((prevState) => ({ ...prevState, showDeleteSuccess: false, group: undefined }));

  return (
    <SideNavLayout>
      <PageHeader headText="Group Management" />
      <PageContent>
        <Grid container justifyContent="flex-end">
          <Box pb={2}>
            <Button
              variant="contained"
              color="primary"
              data-testid="new-patient-group"
              onClick={() => setState((prevState) => ({ ...prevState, initGroupOpen: true }))}
            >
              New Group
            </Button>
          </Box>
        </Grid>
        <Paper>
          <AppBar position="relative" className={classes.appBar}>
            <Tabs
              indicatorColor="primary"
              value={groupType}
              onChange={(event, value) => {
                setState((prevState) => ({ ...prevState, groupType: value as 'patient' | 'security' }));
              }}
            >
              <Tab label="Patient Groups" value="patient" data-testid="patient-group-tab" />
              {CurrentUserIsAdmin && <Tab label="Security Groups" value="security" data-testid="security-group-tab" />}
            </Tabs>
          </AppBar>
          <GroupManagementTable
            loading={patientGroupState.loading}
            productType={productType}
            groupType={groupType}
            groups={orderedGroups}
            ordering={ordering}
            handleGroupClick={handleClickGroupEdit}
            handleGroupDelete={handleClickGroupDelete}
            onSort={handleSort}
            headers={headers}
          />
        </Paper>
        {/* Modals */}
        <GroupCreateModal
          open={initGroupOpen}
          onClose={() => setState((prevState) => ({ ...prevState, initGroupOpen: false }))}
          onSuccess={handleNewGroupSuccess}
          disableSecurityGroups={!CurrentUserIsAdmin}
        />
        {group && (
          <GroupEditModal
            open={editGroupOpen}
            onClose={() => {
              setState((prevState) => ({ ...prevState, editGroupOpen: false }));
            }}
            groupType={groupType}
            group={group}
            productType={(group as PatientGroupProps).product_type || undefined}
            groups={groupType === 'patient' ? patientGroupState.patientGroups : securityGroupState.securityGroups}
          />
        )}
        {group && (
          <ConfirmModal
            open={deleteGroupOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                deleteGroupOpen: false,
                isSubmitting: false,
                apiError: undefined,
              }))
            }
            confirmButtonClick={handleClickGroupDeleteConfirm}
            confirmButtonText="Delete"
            errorMessage={apiError}
            isSubmitting={isSubmitting}
          >
            <>
              <Box>Are you sure you want to delete this group?</Box>
              <Box>"{group.name}"</Box>
            </>
          </ConfirmModal>
        )}
        {/* Toast */}
        {group && (
          <Snackbar open={showDeleteSuccess} autoHideDuration={6000} onClose={handleToastClose}>
            <Alert onClose={handleToastClose} severity="success">
              Success! "{group.name}" deleted.
            </Alert>
          </Snackbar>
        )}
      </PageContent>
    </SideNavLayout>
  );
};

export default GroupManagement;
