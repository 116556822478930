import { RouteChangeProps } from '../history/reducer';

export interface UserMiniProps {
  id: string;
  auth0_id: string;
  email: string;
  given_name: string;
  family_name: string;
}

export interface UserProps {
  id?: string;
  email: string;
  given_name: string | null;
  family_name: string | null;
  security_groups?: string[];
  patient_groups?: string[];
  picture: any;
  full_name?: string;
  roles?: string[];
  verify_email?: boolean;
  blocked: boolean;
  created_dttm?: number | undefined; // Need this to show "Created At" in Edit User Form
  assigned_tenants?: string[];
}

export interface UserData {
  id: string;
  email: string;
  auth0_id: string;
  auth0_connection_id: string;
  updated_dttm: number; // It's assigned as a number because Date is in milliseconds
  created_dttm: number; // It's assigned as a number because Date is in milliseconds
  picture: any;
  given_name: string | null;
  family_name: string | null;
  full_name?: string; // For rendering users table and sorting on field
  blocked: boolean;
  logins_count: number;
  last_login: any;
  last_ip: string;
  blank: string;
  status: string;
  formattedLastLogin: string | Date;
  security_groups?: string[];
  patient_groups?: string[];
  roles: string[];
  assigned_tenants?: string[];
}

export interface formatValueProps {
  column: string;
  user: UserData;
}

export interface State {
  users: UserData[];
  user?: UserData;
  loading: boolean;
  error: any;
  userLoading: boolean;
  userError: any;
}

export const initialState: State = {
  users: [],
  user: undefined,
  loading: false,
  error: undefined,
  userLoading: false,
  userError: undefined,
};

export const USERS_REQUESTED = 'USERS_REQUESTED';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_ERROR = 'USERS_ERROR';

export const USER_REQUESTED = 'USER_REQUESTED';
// export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESSFUL';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESSFUL';
// export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const USER_ERROR = 'USER_ERROR';

interface UsersLoading {
  type: typeof USERS_REQUESTED;
}
interface UserLoading {
  type: typeof USER_REQUESTED;
}
interface UsersSuccess {
  type: typeof USERS_SUCCESS;
  payload: UserData[];
}
interface AddUserSuccess {
  type: typeof ADD_USER_SUCCESS;
  payload: UserData;
}
interface EditUserSuccess {
  type: typeof EDIT_USER_SUCCESS;
  payload: UserData;
}
interface UsersError {
  type: typeof USERS_ERROR;
  payload: string;
}
interface UserError {
  type: typeof USER_ERROR;
  payload: string;
}
export type UsersActionTypes =
  | AddUserSuccess
  | EditUserSuccess
  | UserError
  | UserLoading
  | UsersError
  | UsersLoading
  | UsersSuccess
  | RouteChangeProps;
