import { FunctionComponent, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { SideNavLayout } from '../../Containers/SideNavWrapper';
import GroupPatientLayout from '../../Containers/GroupPatientLayout';
import Loading from '../../Components/Loading';

import { getMyPatientGroup, resetMyPatientGroupPeople } from '../../Store/my_patient_groups/actions';
import { RootStore } from '../../configureStore';
import { ProductTypeEnum } from '../../Store/patient_groups/types';

import useStyles from './styles';
import { Box } from '@mui/material';
import PageHeader from '../../Components/PageHeader';
import ErrorMessage from '../../Components/ErrorMessage';

interface PatientGroupsParams {
  id?: string;
}

interface PatientGroupsState {
  loading: boolean;
  error: string | undefined;
  productType: ProductTypeEnum;
}

const PatientGroups: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<PatientGroupsParams>();
  const myPatientGroupState = useSelector((state: RootStore) => state.my_patient_groups);
  const [state, setState] = useState<PatientGroupsState>({
    loading: false,
    error: undefined,
    productType: undefined,
  });
  const { loading, error } = state;

  // When closing the Patiet Groups view
  // reset the people so it does not leak to the next view
  useEffect(() => {
    return () => {
      dispatch(resetMyPatientGroupPeople());
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      // If ID changed, then reset people for Patient Groups
      // We don't want stale content
      dispatch(resetMyPatientGroupPeople());
      setState({ loading: true, error: undefined, productType: undefined });
      getAccessTokenSilently().then((token) => {
        dispatch(getMyPatientGroup(token, id));
      });
    }
  }, [getAccessTokenSilently, id, dispatch]);

  useEffect(() => {
    if (myPatientGroupState.patientGroup && id === myPatientGroupState.patientGroup.id)
      setState({ loading: false, error: undefined, productType: myPatientGroupState?.patientGroup?.product_type });
    else if (myPatientGroupState.error)
      setState({ loading: false, error: myPatientGroupState.error, productType: undefined });
  }, [id, myPatientGroupState]);

  return (
    <SideNavLayout contentClassName={classes.fullWidth}>
      {loading ? (
        <Box className={classes.fullView}>
          <Loading />
        </Box>
      ) : !error ? (
        myPatientGroupState.patientGroup && (
          <>
            <PageHeader headText={myPatientGroupState.patientGroup.name} />
            <GroupPatientLayout patientGroup={myPatientGroupState.patientGroup} pageSize={100}>
              <Typography className={classes.root}>Group Single</Typography>
            </GroupPatientLayout>
          </>
        )
      ) : (
        <Box className={classes.fullView}>
          <ErrorMessage />
        </Box>
      )}
    </SideNavLayout>
  );
};

export default PatientGroups;
