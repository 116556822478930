import { FunctionComponent } from 'react';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularIndeterminate from '../Loading';
import Tooltip from '@mui/material/Tooltip';
import { WarningRounded } from '@mui/icons-material';

interface GroupPatientCounterProps {
  patientCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  warning: {
    color: theme.palette.error.main,
  },
}));

const GroupPatientCounter: FunctionComponent<GroupPatientCounterProps> = (props: GroupPatientCounterProps) => {
  const classes = useStyles();
  const { patientCount } = props;

  // NOTE: patient count key
  //   -2: error
  //   -1: loading
  //   >-1: count

  return (
    <div className={classes.root}>
      {patientCount === -1 ? (
        <CircularIndeterminate size={20} data-testid="group-patient-count-loading" />
      ) : patientCount === -2 ? (
        <Tooltip
          title="Math is hard! But you can help by double-checking the group's filters."
          data-testid="group-patient-count-error"
        >
          <WarningRounded className={classes.warning} />
        </Tooltip>
      ) : (
        <Typography data-testid="group-patient-count-success">{patientCount}</Typography>
      )}
    </div>
  );
};

export default GroupPatientCounter;
