const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });

// Zero & Many are used in other languages
// Has no purpose for us other than Typescript complaining about it missing.
const suffixes = {
  zero: '',
  one: 'ST',
  two: 'ND',
  few: 'RD',
  other: 'TH',
  many: '',
};
const ordinal = (number: number) => {
  // const suffix = suffixes[english_ordinal_rules.select(number)];
  // return (number + ' ' + suffix).trim();
  return suffixes[english_ordinal_rules.select(number)];
};

export default ordinal;
