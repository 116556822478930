import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  },
  appBar: {
    backgroundColor: '#FFF',
    color: theme.palette.text.primary,
    boxShadow: '1px 1px 5px 2px #ccc',
    zIndex: theme.zIndex.drawer + 1,
  },
  avatarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: 'inherit',
  },
  tableRoot: {
    width: '100%',
  },
  table: {
    '& tr td:last-of-type, & tr th:last-of-type': {
      whiteSpace: 'nowrap',
    },
  },
  userNameCell: {
    width: '14%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionsContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  skeletonSpaced: {
    marginBottom: '0.2rem',
  },
  clickableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
