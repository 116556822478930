import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { DrawerLeftBG, DrawerLeftSelectedBG, DrawerFontColor, DrawerRightBG, DrawerRightSelectedBG } from '../../theme';

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      // color: DrawerFontColor,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      top: 10,
      minHeight: 500,
    },
    drawerFooter: {
      position: 'absolute',
      bottom: 0,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(6),
    },
    drawerInner: {
      minHeight: '390px',
      height: '100%',
      overflowX: 'hidden',
      position: 'relative',
      background: `linear-gradient(to right, ${DrawerLeftBG}, ${DrawerLeftBG} ${theme.spacing(
        6,
      )}, ${DrawerRightBG} ${theme.spacing(6)})`,
    },
    paper: {
      minHeight: '100vh',
      backgroundColor: DrawerLeftBG,
      borderRightColor: theme.palette.background.default,
      overflow: 'initial',
    },
    drawerHeader: {
      position: 'absolute',
      right: `-${theme.spacing(3)}`,
      top: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 0),
      minHeight: theme.spacing(6),
      zIndex: 10,
      width: theme.spacing(6),
      height: theme.spacing(6),
      '& .MuiIconButton-label': {
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 0 3px rgba(0,0,0,.25)',
      },
      '& .MuiSvgIcon-root, & .MuiTypography-root': {
        color: DrawerLeftSelectedBG,
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 0 3px rgba(0,0,0,.25)',
      },
    },
    content: {
      flexGrow: 1,
    },
    sideNavList: {
      padding: 0,
    },
    sideNavItem: {
      textDecoration: 'none',
      color: DrawerFontColor,
      '& .MuiListItem-root': {
        padding: 0,
        '&:hover': {
          backgroundColor: DrawerLeftSelectedBG,
        },
      },
    },
    sideNavItemIcon: {
      minWidth: theme.spacing(6),
      height: theme.spacing(6),
      color: DrawerFontColor,
      alignItems: 'center',
      justifyContent: 'center',
    },
    sideNavItemLabel: {
      fontWeight: 600,
      paddingLeft: theme.spacing(2),
    },
    sideNavItemActive: {
      '& .MuiListItem-root, & .MuiListItem-root:hover': {
        backgroundColor: DrawerRightSelectedBG,
        color: DrawerFontColor,
      },
      '& .MuiListItemIcon-root': {
        backgroundColor: DrawerLeftSelectedBG,
      },
    },
    bottomSection: {
      padding: theme.spacing(2),
      color: DrawerFontColor,
      backgroundColor: DrawerLeftSelectedBG,
    },
    logo: {
      height: theme.spacing(3),
    },
  }),
);

export default useStyles;
