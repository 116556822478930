import { RouteChangeProps } from '../history/reducer';
import { MetadataProps } from '../Utils/types';

export interface PersonProps {
  address_line_1: string | null;
  address_line_2: string | null;
  contact_status: string;
  city: string | null;
  date_of_birth: string;
  email: string | null;
  external_id: string;
  first_name: string;
  gender: string;
  home_phone: string;
  id: string;
  language: string;
  last_name: string;
  marital_status: string;
  middle_name: string | null;
  mobile_phone: string | null;
  postal_code: string | null;
  race: string;
  state_province: string;
}

export interface PeopleResponseProps {
  data: PersonProps[];
  metadata: MetadataProps;
}

export type State = {
  people: PersonProps[];
  person: PersonProps | null;
  loading: boolean;
  metadata?: MetadataProps;
  error?: string;
};

export const initialState: State = {
  people: [],
  person: null,
  loading: false,
  error: undefined,
};

export const PEOPLE_REQUESTED = 'PEOPLE_REQUESTED';
export const PEOPLE_SUCCESS = 'PEOPLE_SUCCESS';
export const PEOPLE_BATCH_SUCCESS = 'PEOPLE_BATCH_SUCCESS';
export const PEOPLE_ERROR = 'PEOPLE_ERROR';
export const PEOPLE_BATCH_REQUESTED = 'PEOPLE_BATCH_REQUESTED';
export const CLEAR_PEOPLE = 'CLEAR_PEOPLE';
export const PERSON_REQUESTED = 'PERSON_REQUESTED';
export const PERSON_SUCCESS = 'PERSON_SUCCESS';
export const PERSON_ERROR = 'PERSON_ERROR';
export const EDIT_CONTACT_STATUS_REQUESTED = 'EDIT_CONTACT_STATUS_REQUESTED';
export const EDIT_CONTACT_STATUS_SUCCESS = 'EDIT_CONTACT_STATUS_SUCCESS';
export const EDIT_CONTACT_STATUS_ERROR = 'EDIT_CONTACT_STATUS_ERROR';

interface PeopleLoading {
  type: typeof PEOPLE_REQUESTED;
}
interface PeopleBatchLoading {
  type: typeof PEOPLE_BATCH_REQUESTED;
}
interface ClearPeople {
  type: typeof CLEAR_PEOPLE;
}
interface PeopleBatchSuccess {
  type: typeof PEOPLE_BATCH_SUCCESS;
  payload: PeopleResponseProps;
}
interface PeopleSuccess {
  type: typeof PEOPLE_SUCCESS;
  payload: PersonProps[];
}
interface PeopleError {
  type: typeof PEOPLE_ERROR;
  payload: string;
}
interface PersonLoading {
  type: typeof PERSON_REQUESTED;
}
interface PersonSuccess {
  type: typeof PERSON_SUCCESS;
  payload: PersonProps;
}
interface PersonError {
  type: typeof PERSON_ERROR;
  payload: string;
}
interface EditContactStatusRequested {
  type: typeof EDIT_CONTACT_STATUS_REQUESTED;
}
interface EditContactStatusSuccess {
  type: typeof EDIT_CONTACT_STATUS_SUCCESS;
  payload: string;
}
interface EditContactStatusError {
  type: typeof EDIT_CONTACT_STATUS_ERROR;
  payload: string;
}
export type PeopleActionTypes =
  | PeopleLoading
  | PeopleBatchLoading
  | ClearPeople
  | PeopleSuccess
  | PeopleError
  | PeopleBatchSuccess
  | PersonLoading
  | PersonSuccess
  | PersonError
  | EditContactStatusRequested
  | EditContactStatusSuccess
  | EditContactStatusError
  | RouteChangeProps;
