import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DarkTeal, secondQuartile, CoolGrey1C, secondaryNavBarHeight } from '../../theme';
const drawerWidth = 337;

const useStyles = makeStyles<Theme>((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.common.white,
    // borderBottom: `1px solid ${theme.palette.background.default}`,
    position: 'relative',
    zIndex: 2,
    width: '100%',
  },
  searchField: {
    position: 'relative',
    paddingLeft: '25px',
    paddingTop: '5px',
    width: '100%',
  },
  searchIcon: {
    position: 'absolute',
    paddingTop: '5px',
    marginTop: '6px',
  },
  outline: {
    width: '280px',
    border: '1px solid #EBEFF3',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    top: 10,
    minHeight: 500,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  drawerInner: {
    height: '100%',
    overflowX: 'hidden',
    position: 'relative',
  },
  paper: {
    left: 'auto',
    top: secondaryNavBarHeight,
    zIndex: 1,
    minHeight: `calc(100vh - ${secondaryNavBarHeight}px)`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.accent?.secondary,
    borderRightColor: theme.palette.background.default,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0),
    minHeight: 'auto',
  },
  headerGridItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawerHeaderTitle: {
    paddingLeft: '10px',
  },
  drawerHeaderTitleClose: {
    display: 'none',
  },
  content: {
    height: 'calc(100vh - 96px)',
    zIndex: 1,
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(3)}`,
    flexGrow: 1,
  },
  tableContainer: {
    height: 'calc(100vh - 150px)',
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  clickableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
  rowActive: {
    backgroundColor: theme.palette.background.default,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      cursor: 'default',
    },
  },
  patientPagination: {
    position: 'sticky',
    top: '0',
    zIndex: 10,
    marginLeft: theme.spacing(-3),
    // paddingLeft: theme.spacing(3),
    marginRight: theme.spacing(-1),
    // paddingRight: theme.spacing(3),
    backgroundColor: CoolGrey1C,
    // width: `calc(100% + ${theme.spacing(4)}`,
  },
  patientName: {
    color: DarkTeal,
    // cursor: 'default',
  },
  dobHeader: {
    paddingLeft: theme.spacing(1),
  },
  dob: {
    // cursor: 'default',
  },
  notification: {
    boxSizing: 'border-box',
    height: '50px',
    width: '50px',
    border: '1px solid #EBEFF3',
    padding: '9px',
    backgroundColor: '#FFFFFF',
    marginRight: theme.spacing(-2.8),
  },
  starIcon: {
    height: '15px',
    width: '13px',
    marginTop: '7.5px',
    marginLeft: '5px',
    color: secondQuartile,
  },
  iconGroup: {
    padding: 0,
    boxSizing: 'border-box',
    height: '50px',
    width: '50px',
  },
  disabledIcon: {
    color: '#E4EBF0',
  },
  border: {
    color: '#E4EBF0',
  },
  title: {
    padding: 0,
    marginLeft: theme.spacing(2),
    fontSize: '1.5rem',
  },
  container: {
    flexWrap: 'nowrap',
  },
  header: {
    position: 'sticky',
    top: '96px',
    paddingBottom: 10,
    paddingLeft: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  headerLabel: {
    color: '#535353',
    fontSize: '10px',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  headerRow: {
    color: '#24265B',
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: theme.spacing(1.5),
  },
  headerEntry: {
    flexDirection: 'row',
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  GreyText: {
    color: '#E4EBF0',
  },
  skeletonTitle: {
    marginLeft: '1rem',
  },
}));

// 42 + 37 * rows - 1

export default useStyles;
