import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '12px',
    textTransform: 'uppercase',
  },
  dataRow: {
    display: 'block',
    width: '145x',
    paddingTop: '5px',
    justifyContent: 'space-between',
    paddingRight: '32px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // },
  },
}));
export interface IToolTipProps {
  title: string;
  fieldValue: string | number | null;
}

const JvionToolTip: FunctionComponent<IToolTipProps> = (props: IToolTipProps) => {
  const { title, fieldValue } = props;
  const dataRef = useRef<any>(null);

  const [allowTooltip, setAllowTooltip] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    return setAllowTooltip(dataRef.current.offsetWidth < dataRef.current.scrollWidth);
  }, []);

  return (
    <>
      <Typography className={classes.label}>{title}</Typography>
      <Tooltip title={allowTooltip && fieldValue !== null ? fieldValue : ''} placement="top-start">
        <Typography ref={dataRef} className={classes.dataRow}>
          {fieldValue}
        </Typography>
      </Tooltip>
    </>
  );
};

export default JvionToolTip;
