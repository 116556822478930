import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100%',
  },
  bolder: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
