import { format as formatDateFns, addMinutes } from 'date-fns';

// constants for time formating
const seconds = 1000;
const minutes = seconds * 60;
const hours = minutes * 60;
const days = hours * 24;
const weeks = days * 7;
export const defaultDateFormat = 'MM/dd/yyyy';

interface DateTimeProps {
  dateTime: Date;
  format?: string;
}

interface DateFormatterProps {
  date: string;
}

export interface Suffixes {
  seconds?: string;
  minutes?: string;
  hours?: string;
  days?: string;
  weeks?: {
    day?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    year?: 'numeric' | '2-digit';
  };
}

interface DateTimeLapseProps {
  dateTime: number;
  suffixes?: Suffixes;
}

export const formatDateTimeObject = ({ dateTime, format = defaultDateFormat }: DateTimeProps) => {
  return formatDateFns(new Date(dateTime), format);
};

export const parseDateString = (date: string) => {
  // Because Time Zone could be different based on the date being passed in,
  //  i.e. Could have a 4 or 5 hour difference
  // use the Time Zone Offset based on date being passed in.
  const fDate = new Date(date);
  return addMinutes(fDate.getTime(), fDate.getTimezoneOffset());
};

export const dateFormatter = ({ date }: DateFormatterProps) => {
  return formatDateFns(parseDateString(date), defaultDateFormat);
};

export const getTimeDiff = (attrs: DateTimeLapseProps) => {
  const { dateTime } = attrs;
  const now = new Date().getTime();
  const updated = new Date(dateTime).getTime();

  return now - updated;
};

export const getTimeUpdateLapse = (attrs: DateTimeLapseProps) => {
  const diff = getTimeDiff(attrs);

  switch (true) {
    case diff < hours:
      return { setTimeout: Math.floor(diff % minutes), setInterval: minutes };
    case diff < days:
      return { setTimeout: Math.floor(diff % hours), setInterval: hours };
    default:
      return false;
  }
};

export const formatTimeLapse = (attrs: DateTimeLapseProps) => {
  const { dateTime, suffixes } = attrs;
  const diff = getTimeDiff(attrs);

  switch (true) {
    case diff < minutes:
      return suffixes && suffixes.seconds ? suffixes.seconds : '<1m';
    case diff < hours:
      return Math.floor(diff / minutes) + (suffixes && suffixes.minutes ? suffixes.minutes : 'm');
    case diff < days:
      return Math.floor(diff / hours) + (suffixes && suffixes.hours ? suffixes.hours : 'h');
    case diff < weeks:
      return Math.floor(diff / days) + (suffixes && suffixes.days ? suffixes.days : 'd');
    default:
      return new Date(dateTime).toLocaleDateString(
        'en-US',
        suffixes && suffixes.weeks
          ? suffixes.weeks
          : {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            },
      );
  }
};
