import { ReactNode, FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { ListItem, ListItemIcon, Typography } from '@mui/material';
import useStyles from './styles';

interface SideNavItemProps {
  icon: ReactNode;
  label: string;
  link: string;
  altActivePaths?: string[];
}

const SideNavItem: FunctionComponent<SideNavItemProps> = (props: SideNavItemProps) => {
  const classes = useStyles();
  const { label, link, icon, altActivePaths } = props;
  const locationFullPath = useLocation().pathname;
  // this is to protect us in the case params are passed
  const locationBase =
    locationFullPath.lastIndexOf('/') > 0
      ? locationFullPath.slice(0, locationFullPath.lastIndexOf('/'))
      : locationFullPath;

  return (
    <Link
      to={link}
      className={clsx(classes.sideNavItem, {
        [classes.sideNavItemActive]: locationBase === link || (altActivePaths && altActivePaths.includes(locationBase)),
      })}
      data-testid="menu-item"
    >
      <ListItem button key={label}>
        <ListItemIcon className={classes.sideNavItemIcon}>{icon}</ListItemIcon>
        <Typography className={classes.sideNavItemLabel}>{label}</Typography>
      </ListItem>
    </Link>
  );
};

export default SideNavItem;
