import { FunctionComponent } from 'react';
import { SwipeableDrawer } from '@mui/material';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { primaryNavBarHeight } from '../../theme';

// Imports only the drawer component, not the items

// Drawer contents
import DrawerContent from '../../Components/DrawerContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DrawerRoot: {
      height: `calc(100% - ${primaryNavBarHeight + 2}px)`,
      top: primaryNavBarHeight + 2,
    },
  }),
);

interface PageHeaderDrawerProps {
  isOpen: boolean;
  toggleDrawer: any;
}

const PageHeaderDrawer: FunctionComponent<PageHeaderDrawerProps> = (props: PageHeaderDrawerProps) => {
  const { isOpen, toggleDrawer } = props;
  const classes = useStyles();

  return (
    <div>
      <SwipeableDrawer
        elevation={0}
        anchor="right"
        open={isOpen}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          className: classes.DrawerRoot,
        }}
      >
        <DrawerContent closeDrawer={toggleDrawer(false)} />
      </SwipeableDrawer>
    </div>
  );
};

export default PageHeaderDrawer;
