import { useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { Tenant } from './tenant-switching-state';

interface ModalSelectorI {
  open: boolean;
  handleClose: () => void;
  options: Tenant[];
  currentTenant?: string;
  selectedTenant: (selected: Tenant) => void;
}

const ModalSelector = (props: ModalSelectorI) => {
  const { open, handleClose, options, currentTenant, selectedTenant } = props;

  const handleChange = (e: any) => {
    const selected: Tenant = options.find((o) => o.tenant_id === e.target.value)!;
    selectedTenant(selected);
  };

  const MenutItems = useMemo(() => {
    return options.map((o, i) => {
      return (
        <MenuItem data-testid="tenant-option" key={[o.tenant_id, i].join('-')} value={o.tenant_id}>
          {o.tenant_name} - <em>{o.tenant_id}</em>
        </MenuItem>
      );
    });
  }, [options]);

  return (
    <div>
      <Dialog data-testid="tenant-switching-modal" fullScreen open={open} onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div>
            <DialogContent>
              <DialogTitle>Choose your client.</DialogTitle>
              <FormControl style={{ width: '240px' }}>
                <InputLabel id="tenant-select-label">Select Client</InputLabel>
                <Select
                  labelId="tenant-select-label"
                  value={currentTenant || ''}
                  id="tenant-select"
                  label="Tenant"
                  onChange={handleChange}
                >
                  <MenuItem data-testid="tenant-option" value="">
                    <em>Default Client</em>
                  </MenuItem>
                  {MenutItems}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" disabled={currentTenant === undefined} onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </div>
        </div>
        <Typography style={{ margin: '10px auto' }} color={'primary'}>
          This page is visible only to Jvion employees
        </Typography>
      </Dialog>
    </div>
  );
};

export default ModalSelector;
