import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

import { formatTimeLapse, getTimeUpdateLapse, Suffixes } from '../DateTimeRender';
import { RootStore } from '../../configureStore';
import { UserMiniProps } from '../../Store/users/types';

import useStyles from './styles';
import { RecommendationProps } from '../../Store/recommendations/types';

export interface HistoryRecordWrapperProps {
  text?: string;
  type: 'COMMENT' | 'RECOMMENDATION';
  entity?: 'RECOMMENDATION' | 'PERSON';
  user: UserMiniProps;
  updated_dttm: number;
  product_id?: string;
  recommendation?: RecommendationProps;
  recommendationDescription?: string;
}

const HistoryRecordWrapper: FunctionComponent<HistoryRecordWrapperProps> = (props: HistoryRecordWrapperProps) => {
  const classes = useStyles();
  const products = useSelector((state: RootStore) => state.products.products);
  const [productName, setProductName] = useState<string>('');
  const [actionText, setActionText] = useState<string | React.ReactElement>('');
  const [updatedDTTMDiff, setUpdatedDTTMDiff] = useState<string>('');
  const { text, entity, user, product_id, recommendation, recommendationDescription, updated_dttm, type } = props;
  const suffixes: Suffixes = useMemo(
    () => ({
      seconds: 'less than a minute ago',
      minutes: ' minute(s) ago',
      hours: ' hour(s) ago',
      days: ' day(s) ago',
      weeks: {
        day: 'numeric',
        year: 'numeric',
        month: 'short',
      },
    }),
    [],
  );

  useEffect(() => {
    const product = products.find((prod) => prod.id === product_id);
    if (type === 'COMMENT') {
      setActionText('commented');
    } else if (type === 'RECOMMENDATION') {
      // there is an assumption here that recommendation status changed because that is all that
      // is available to change in the ui. In the future a comparison between historical objects
      // should be made to figure out what changed to what from what but maybe this is overkill ...
      setActionText(
        <>
          updated status to <strong>{recommendation?.status}</strong>
        </>,
      );
    }
    if (product) setProductName(product.name);
  }, [products, product_id, type, entity, recommendation]);

  useEffect(() => {
    setUpdatedDTTMDiff(formatTimeLapse({ dateTime: updated_dttm, suffixes }));
  }, [updated_dttm, suffixes]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | false = false;
    let intervalId: ReturnType<typeof setInterval> | false = false;

    const timeLapse = getTimeUpdateLapse({ dateTime: updated_dttm });

    if (timeLapse) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(
          () => setUpdatedDTTMDiff(formatTimeLapse({ dateTime: updated_dttm, suffixes })),
          timeLapse.setInterval,
        );
      }, timeLapse.setTimeout);
    }

    return () => {
      if (timeoutId !== false) clearTimeout(timeoutId);
      if (intervalId !== false) clearInterval(intervalId);
    };
  }, [updated_dttm, suffixes]);

  return (
    <Box marginBottom="20px" data-testid="history-record-wrapper">
      <div className={classes.container}>
        {user && user.given_name && user.family_name && (
          <Avatar className={classes.avatar}>{[user.given_name[0], user.family_name[0]].join('')}</Avatar>
        )}
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            {/*The name of the user who posted this note*/}
            <Typography className={classes.nameFontStyle}>
              {user.given_name} {user.family_name}
            </Typography>
          </Grid>
          <Grid item>
            {/*How many seconds/minutes/days ago the comment was posted, and the date.*/}
            <Typography>
              {actionText} {updatedDTTMDiff}
            </Typography>
          </Grid>
          <Grid item>
            {(entity === 'RECOMMENDATION' || type === 'RECOMMENDATION') && recommendationDescription && (
              <Chip
                data-testid="recommendation-chip"
                label={
                  recommendationDescription.length > 60 ? (
                    <Tooltip title={recommendationDescription}>
                      <Typography className={classes.tooltipText}>
                        {recommendationDescription.slice(0, 60).trim()}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    recommendationDescription
                  )
                }
              />
            )}
          </Grid>
          <Grid item>
            {productName !== undefined &&
              productName !== '' &&
              (entity === 'RECOMMENDATION' || type === 'RECOMMENDATION') && <Chip label={productName}></Chip>}
          </Grid>
        </Grid>
      </div>
      {text && (
        <Box className={classes.overflowClass}>
          {/*NOTES TEXT LIVES HERE*/}
          <Typography sx={{ whiteSpace: 'pre-line' }}>{text}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default HistoryRecordWrapper;
