import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  RecommendationLabelsActionTypes,
  RECOMMENDATION_LABEL_REQUESTED,
  RECOMMENDATION_LABELS_REQUESTED,
  RECOMMENDATION_LABEL_SUCCESS,
  RECOMMENDATION_LABELS_SUCCESS,
  RECOMMENDATION_LABEL_ERROR,
  RECOMMENDATION_LABELS_ERROR,
} from './types';
import { cancelReq } from './actions';

const recommendationLabelsReducer = (state: State = initialState, action: RecommendationLabelsActionTypes): State => {
  switch (action.type) {
    case RECOMMENDATION_LABEL_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RECOMMENDATION_LABELS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RECOMMENDATION_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendationLabels: action.payload,
      };
    case RECOMMENDATION_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendationLabel: action.payload,
      };
    case RECOMMENDATION_LABEL_ERROR:
      return {
        ...state,
        loading: false,
        recommendationLabel: undefined,
        error: action.payload,
      };
    case RECOMMENDATION_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        recommendationLabels: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default recommendationLabelsReducer;
