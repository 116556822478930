import { authorized } from '../../Utils/AxiosInstance';
import { Dispatch } from 'redux';

import merge from 'lodash/merge';

import { formatDateTimeObject } from '../../Components/DateTimeRender';
import {
  USER_REQUESTED,
  ADD_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  USER_ERROR,
  USERS_REQUESTED,
  USERS_SUCCESS,
  USERS_ERROR,
  UserData,
  UserProps,
  formatValueProps,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

import { store } from '../../configureStore';

export let cancelReq: CancelTokenSource | undefined = undefined;

function formatValue({ column, user }: formatValueProps) {
  switch (column) {
    case 'blocked':
      let val: string = 'Active';
      if (user.blocked === true) {
        val = 'Inactive';
      } else if (user.logins_count === 0) {
        val = 'Created';
      }
      return val;
    case 'last_login':
      return user.last_login === null || user.last_login === undefined
        ? '-'
        : formatDateTimeObject({ dateTime: user.last_login });
    case 'full_name':
      if (user.family_name && user.given_name) {
        return [user.family_name, user.given_name].join(', ');
      } else if (user.family_name || user.given_name) {
        return user.family_name ? user.family_name : user.given_name;
      } else {
        return user.email;
      }
    default:
      return '-';
  }
}
export const createUser =
  (
    token: string,
    userBody: {
      picture: string;
      given_name: string;
      family_name: string;
      email: string;
      security_groups?: (number | string)[];
      patient_groups?: (number | string)[];
      roles?: (number | string)[];
      verify_email?: boolean;
    },
  ) =>
  (dispatch: Dispatch) => {
    dispatch({ type: USER_REQUESTED });
    const { email, given_name, family_name, picture, security_groups, patient_groups, roles, verify_email } = userBody;
    authorized(token)
      .post('/users', {
        email,
        given_name,
        family_name,
        picture,
        security_groups,
        patient_groups,
        roles,
        verify_email,
      })
      .then((resp) => {
        const payload = resp.data as UserData;

        payload.full_name = formatValue({ column: 'full_name', user: payload })!;
        payload.status = formatValue({ column: 'blocked', user: payload })!;
        payload.formattedLastLogin = formatValue({ column: 'last_login', user: payload }) as string;
        dispatch({ type: ADD_USER_SUCCESS, payload });
      })
      .catch((error) => {
        console.error(error);
        let msg: any;
        if (error.response?.data) {
          msg = error.response.data.details || error.response.data.message;
        }
        // FIXME
        // At times a 500 error could come back with an empty data object or string
        //    from server.  If so, return standard message
        if (msg === undefined || msg === null) msg = error.message;
        dispatch({ type: USER_ERROR, payload: msg });
      });
  };

export const getUsers =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: USERS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    // #JCP-692
    // If user is NOT a Jvion user, then filter out all Jvion. based users.
    const isJvionUser = store.getState().current_user.whoami?.email.match(/@jvion.com$/) || false;
    if (!isJvionUser) options = merge({}, options, { params: { 'email[not_ilike]': '%jvion.com' } });

    authorized(token)
      .get('/users', options)
      .then((resp) => {
        // resp.data has metadata for things like pagination
        //               AND data with the array of Roles
        dispatch({
          type: USERS_SUCCESS,
          payload: resp.data.data.map((u: UserData) => {
            u.full_name = formatValue({ column: 'full_name', user: u })!;
            u.status = formatValue({ column: 'blocked', user: u })!;
            u.formattedLastLogin = formatValue({ column: 'last_login', user: u }) as string;
            return u;
          }),
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- users request cancelled');
        }

        dispatch({ type: USERS_ERROR, payload: e.message });
      });
  };

export const editUser = (token: string, user: UserProps) => (dispatch: Dispatch) => {
  dispatch({ type: USER_REQUESTED });
  const { id, email, given_name, family_name, picture, security_groups, patient_groups, roles, verify_email, blocked } =
    user;

  authorized(token)
    .put(`/users/${id}`, {
      given_name,
      family_name,
      picture,
      security_groups,
      patient_groups,
      email,
      roles,
      verify_email,
      blocked,
    })
    .then((resp) => {
      let payload: UserData = {
        ...(user as UserData),
        email,
        given_name,
        family_name,
        picture,
        security_groups,
        patient_groups,
        blocked,
      };
      payload.full_name = formatValue({ column: 'full_name', user: payload })!;
      dispatch({ type: EDIT_USER_SUCCESS, payload });
    })
    .catch((error) => {
      console.error(error);
      let msg: any;
      if (error.response?.data) {
        msg = error.response.data.details || error.response.data.message;
      }
      // FIXME
      // At times a 500 error could come back with an empty data object or string
      //    from server.  If so, return standard message
      if (msg === undefined || msg === null) msg = error.message;
      dispatch({ type: USER_ERROR, payload: msg });
    });
};
