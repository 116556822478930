import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { PatientGroupProps } from '../patient_groups/types';
import {
  PatientGroupsActionTypes,
  PATIENT_SEARCH_REQUESTED,
  PATIENT_SEARCH_SUCCESS,
  PATIENT_SEARCH_ERROR,
  CLEAR_PATIENT_SEARCH,
} from './types';

import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const clearPatientSearch =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_PATIENT_SEARCH });
  };

export const searchMyPatientGroups =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<PatientGroupsActionTypes>) => {
    // let patientGroupIds: Array<string | undefined> = [];
    dispatch({ type: PATIENT_SEARCH_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/my_patient_groups`, {
        ...options,
        cancelToken: cancelReq.token,
        params: { sort_by: 'is_default_group(desc),name(asc)' },
      })
      .then((resp) => {
        const rslt = resp.data.data.map((r: PatientGroupProps) => {
          r.patients = -1;
          return r;
        });
        dispatch({
          type: PATIENT_SEARCH_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- my patient group request cancelled');
        }
        dispatch({ type: PATIENT_SEARCH_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
