import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { authorized } from '../../Utils/AxiosInstance';
import { useSelector } from 'react-redux';
import { RootStore } from '../../configureStore';

import { PatientGroupProps } from '../../Store/patient_groups/types';
import { TenantFilterProps } from '../../Store/tenant_filters/types';
import { PatientAndSecurityGroupFilter } from '../../Store/patient_groups/types';
import JvionMultiSelectField from '../../Components/JvionMultiSelectField';

import useStyles from './styles';

// "{'filters': [{'model': 'Person', 'field': 'first_name', 'op': 'ilike', 'value': 'john'}]}",
/*
is_null
is_not_null
==, eq
!=, ne
>, gt
<, lt
>=, ge
<=, le
like
ilike
not_ilike
in
not_in
any
not_any
*/

interface GroupEditFilterProps {
  TenantFilter: TenantFilterProps;
  PatientGroupObject?: PatientGroupProps;
  filter: PatientAndSecurityGroupFilter;
  isOpen: boolean;
  onChange?: (newVal: PatientAndSecurityGroupFilter) => void;
}

interface GroupFilterStateI {
  loading: boolean;
  error: undefined | string;
  data: string[];
  hasFetched: boolean;
}

const GroupEditFilter: FunctionComponent<GroupEditFilterProps> = (props: GroupEditFilterProps) => {
  const classes = useStyles();
  const products = useSelector((store: RootStore) => store.products.products);
  const { TenantFilter, PatientGroupObject, filter, onChange, isOpen } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [selected, setSelected] = useState<string | string[]>(filter.value || []);
  const [state, setState] = useState<GroupFilterStateI>({
    loading: false,
    error: undefined,
    data: [],
    hasFetched: false,
  });

  const fetchOptions = useCallback(async () => {
    setState((prevState) => {
      return { ...prevState, loading: true, error: undefined, hasFetched: true };
    });
    // This exception is for the product filter options on patient groups which
    // should respect product_type. If further exceptions come into play purhaps
    // the /portal/entity_metadata/ should be extended to support filtering and
    // the tenant_filter endpoint should include columns to support dynamic filtering
    // at this point that is overkill,
    if (TenantFilter.entity_name.toLowerCase() === 'product' && PatientGroupObject) {
      setState((prevState) => {
        return {
          ...prevState,
          loading: false,
          // convert to set to insure unique values.
          data: products
            .filter((product) => product.product_type === PatientGroupObject.product_type)
            .map((product) => product.name),
        };
      });
    } else {
      try {
        const token = await getAccessTokenSilently();
        authorized(token)
          .get(`/portal/entity_metadata/${TenantFilter.entity_name}/unique_values/${TenantFilter.column_name}`)
          .then((resp) => {
            // filter to remove any NULL values returned from the API
            const data = resp.data.filter((n: any) => {
              return n != null;
            });
            setState((prevState) => {
              return { ...prevState, loading: false, data };
            });
          })
          .catch((err) => {
            setState((prevState) => {
              return { ...prevState, loading: false, error: err.message };
            });
          });
      } catch (error) {
        return console.error(error);
      }
    }
  }, [TenantFilter, products, PatientGroupObject, getAccessTokenSilently]);

  useEffect(() => {
    if (isOpen === true && state.hasFetched === false) {
      fetchOptions();
    }
  }, [isOpen, fetchOptions, state.hasFetched]);

  useEffect(() => {
    if (filter.value) setSelected(filter.value);
  }, [filter.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let newArr;
    if (typeof selected === 'string') {
      newArr = name;
    } else {
      newArr = [...selected];
      const idx = selected.indexOf(name);
      if (idx > -1) {
        newArr.splice(idx, 1);
      } else {
        newArr = newArr.concat(name);
      }
    }
    if (onChange) {
      onChange({ ...filter, value: newArr });
    }
  };

  const { loading, error, data } = state;

  // Return nothing if Wrapper is collapsed
  if (isOpen === false) return <></>;

  if (loading === true) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" data-testid="loading">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (error) {
    return (
      <div>
        <Typography color="error" data-testid="error-text">
          <b>There was an Error:</b> <br />
          {error}
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          data-testid="error-try-again"
          onClick={() => fetchOptions()}
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <JvionMultiSelectField
      placeholder="Search"
      OptionsClassName={classes.multiSelectOverride}
      fuzzy={true}
      options={data.map((item) => ({
        value: item,
        label: item,
      }))}
      handleCheckbox={handleChange}
      selected={Array.isArray(selected) ? selected : [selected]}
    />
  );
};

export default GroupEditFilter;
