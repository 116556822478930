import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  TenantFiltersActionTypes,
  TENANT_FILTERS_REQUESTED,
  TENANT_FILTERS_SUCCESS,
  TENANT_FILTERS_ERROR,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getTenantFilters =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<TenantFiltersActionTypes>) => {
    dispatch({ type: TENANT_FILTERS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/tenant_filters', { params: { enabled: 'true' }, cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('tenant filters response ----', resp);
        dispatch({
          type: TENANT_FILTERS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- tenant filters request cancelled');
        }
        dispatch({ type: TENANT_FILTERS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
