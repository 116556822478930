import { FunctionComponent, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TenantFilterProps } from '../../Store/tenant_filters/types';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { PatientAndSecurityGroupFilter } from '../../Store/patient_groups/types';
import GroupEditFilter from '../GroupEditFilter';

import useStyles from './styles';

interface FiltersWrapperProps {
  TenantFilter: TenantFilterProps;
  FilterObject?: PatientAndSecurityGroupFilter;
  PatientGroupObject?: PatientGroupProps;
  onAddFilter?: (filter: TenantFilterProps) => void;
  onRemoveFilter?: (filterId: string) => void;
  onChange?: (newVal: PatientAndSecurityGroupFilter) => void;
}

// Purpose of this component is to Have a Primary Wrapper around each Filter.
// Each Filter will be either Expanded or not, controlled here.
// Also, if there is Filter Being applied to the group, for example the bottom section
//  then don't render anything.
//
// Primary functionality here is to add and remove A filter.
const FilterWrapper: FunctionComponent<FiltersWrapperProps> = (props: FiltersWrapperProps) => {
  const classes = useStyles();
  const { TenantFilter, FilterObject, PatientGroupObject, onAddFilter, onRemoveFilter, onChange } = props;
  const [contentVisible, setContentVisible] = useState<boolean>(false);

  const handleAddFilter = () => {
    setContentVisible(false);
    onAddFilter && onAddFilter(TenantFilter);
  };

  const handleRemoveFilter = () => {
    setContentVisible(false);
    onRemoveFilter && onRemoveFilter(TenantFilter.id);
  };

  // This is where we will add the filter components that will be rendered
  const filterViewTypes = () => {
    switch (TenantFilter.filter_type) {
      case 'multiselect':
      case 'epoch_date_range_picker':
      case 'autocomplete':
        return FilterObject === undefined ? (
          <></>
        ) : (
          <GroupEditFilter
            isOpen={contentVisible}
            TenantFilter={TenantFilter}
            PatientGroupObject={PatientGroupObject}
            filter={FilterObject}
            onChange={(newVal: PatientAndSecurityGroupFilter) => {
              if (onChange) onChange(newVal);
            }}
          />
        );
      default:
        return <div data-testid="undefined-filter">{`Filter type ${TenantFilter.filter_type} not found`}</div>;
    }
  };

  return (
    <div className={classes.filterWrapper} data-testid={onAddFilter ? 'unselected-filter' : 'selected-filter'}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setContentVisible(!contentVisible)}
        className={classes.filterWrapperHeader}
        data-testid="filter-wrapper"
      >
        <Grid item>
          <Typography variant="h3">{TenantFilter.filter_label}</Typography>
        </Grid>
        <Grid item sm="auto">
          <IconButton className={classes.iconButton} size="large">
            {contentVisible ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          {filterViewTypes()}
        </Grid>
      </Grid>
      {contentVisible && (
        <Grid container>
          <Grid item sm={12}>
            {onAddFilter && (
              <Button onClick={handleAddFilter} variant="contained" color="primary" data-testid={'add-filter'}>
                Add Filter
              </Button>
            )}
            {onRemoveFilter && (
              <Button
                className={classes.removeFilterBtn}
                onClick={handleRemoveFilter}
                color="primary"
                data-testid={'remove-filter'}
              >
                Remove Filter
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default FilterWrapper;
