import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DarkTeal, tb9InputFieldOutlineColor, MidPurple, CoolGrey1C, CoolGrey11C, DarkPurple } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  statusDropdown: {
    visibility: 'hidden',
    width: '100%',
    height: 0,
  },
  statusLabelContainer: {
    display: 'flex',
    lineHeight: '10px',
    alignItems: 'center',
  },
  statusLabel: {
    marginLeft: theme.spacing(0.5),
  },
  completed: {
    color: DarkTeal,
  },
  selectOptions: {
    padding: 0,
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  recommendationsContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  recommendationTitle: {
    color: DarkPurple,
  },
  gridHeaderContainer: {
    ...theme.components!.MuiTableCell!.styleOverrides,
    borderBottom: 'none',
    padding: `0 ${theme.spacing(1)}`,
  },
  // ^^^^ May need to fix line 38...
  gridHeaderItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderBottom: 'none',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  headerExpandMore: {
    paddingLeft: theme.spacing(1.5),
  },
  statusIcon: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  commentIcon: {
    color: DarkTeal,
    height: '1.125rem',
    width: 'auto',
  },
  commentButton: {
    padding: theme.spacing(1),
  },
  description: {
    color: DarkPurple,
  },
  avatar: {
    height: '22px',
    width: '22px',
    fontSize: '0.625rem',
    backgroundColor: CoolGrey1C,
    color: CoolGrey11C,
    cursor: 'pointer',
  },
  recommendationWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '#000 1px 1px 5px -4px',
  },
  recommendationRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  recommendationStatusDropdown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-start',
    whiteSpace: 'nowrap',
  },
  recommendationNotesContainer: {
    minWidth: 58,
  },
  recommendationStepsActions: {
    backgroundColor: theme.palette.background.default,
  },
  checkBox: {
    // padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '.MuiCheckbox-colorPrimary.Mui-checked': {
      color: DarkTeal,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  action: {
    color: tb9InputFieldOutlineColor,
  },
  expandMoreIcon: {
    color: MidPurple,
  },
  expandIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiButtonBase-root': {
      '&.MuiIconButton-root': {
        color: MidPurple,
      },
    },
  },
  headerCircleIcon: {
    '& > svg': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default useStyles;
