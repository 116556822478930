import { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCommentIcon from '@mui/icons-material/AddComment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RecommendationStepItem from './recommendationStepItem';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import clsx from 'clsx';
// import CheckBoxIcon from '@mui/icons-material/CheckBox'; // for complete all functionality

import { formatTimeLapse, getTimeUpdateLapse } from '../DateTimeRender';
import RecommendationStatusDropdown from './recommendationStatusDropdown';
import { RecommendationProps } from '../../Store/recommendations/types';
import { RecommendationLabelProps } from '../../Store/recommendation_labels/types';
import { RecommendationStepProps } from '../../Store/recommendation_steps/types';
import useStyles from './styles';
import Avatar from '@mui/material/Avatar';

interface RecommendationGridItemProps {
  recommendation: RecommendationProps;
  comments: number;
  recommendationLabels: RecommendationLabelProps[];
  recommendationSteps: RecommendationStepProps[];
  onChangeStatus: (newStatus: string) => void;
  onAddComment: () => void;
  onRecommendationCommentClick: (recommendationId: string) => void;
}

const RecommendationGridItem: FunctionComponent<RecommendationGridItemProps> = (props: RecommendationGridItemProps) => {
  const classes = useStyles();
  const {
    recommendation,
    recommendationLabels,
    recommendationSteps,
    onChangeStatus,
    onAddComment,
    comments,
    onRecommendationCommentClick,
  } = props;
  const [actionsOpen, setActionsOpen] = useState<boolean>(false);
  const [updatedDTTMDiff, setUpdatedDTTMDiff] = useState<string>('');

  useEffect(() => {
    setUpdatedDTTMDiff(formatTimeLapse({ dateTime: recommendation.updated_dttm }));
  }, [recommendation]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | false = false;
    let intervalId: ReturnType<typeof setInterval> | false = false;

    const timeLapse = getTimeUpdateLapse({ dateTime: recommendation.updated_dttm });

    if (timeLapse) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(
          () => setUpdatedDTTMDiff(formatTimeLapse({ dateTime: recommendation.updated_dttm })),
          timeLapse.setInterval,
        );
      }, timeLapse.setTimeout);
    }

    return () => {
      if (timeoutId !== false) clearTimeout(timeoutId);
      if (intervalId !== false) clearInterval(intervalId);
    };
  }, [recommendation]);

  return (
    <div className={classes.recommendationWrapper}>
      <Grid container key={recommendation.id} data-testid="recommendation-row" className={classes.recommendationRow}>
        <Grid item xs={1}>
          <IconButton
            data-testid="recommendation-expand"
            disableRipple
            onClick={() => setActionsOpen(!actionsOpen)}
            className={classes.expandIcon}
            size="large"
          >
            {actionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Grid>
        <Grid item xs>
          {recommendationLabels.length > 0 &&
          recommendationLabels.some(
            (recommendationLabel) => recommendationLabel.id === recommendation.recommendation_label_id,
          ) ? (
            <Typography>
              {
                recommendationLabels.filter(
                  (recommendationLabel) => recommendationLabel.id === recommendation.recommendation_label_id,
                )[0].description
              }
            </Typography>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={2} md={1} lg={1}>
          <Typography>{updatedDTTMDiff}</Typography>
        </Grid>
        <Grid item className={classes.headerCircleIcon} xs={1} md={1}>
          {recommendation.status === 'Completed' ? (
            <IconButton
              className={clsx(classes.statusIcon, classes.completed)}
              data-testid="recommendation-completed"
              size="large"
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          ) : (
            <Tooltip title="Click to update status to complete" placement="top">
              <IconButton
                onClick={() => onChangeStatus('Completed')}
                className={classes.statusIcon}
                data-testid="recommendation-complete"
                size="large"
              >
                <CheckCircleOutlineIcon color="disabled" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={4} md={3} lg={2} className={classes.recommendationStatusDropdown}>
          <RecommendationStatusDropdown onChangeStatus={onChangeStatus} selectedStatus={recommendation.status} />
        </Grid>
        <Grid item xs={2} md={2} lg={1} className={classes.recommendationNotesContainer}>
          <Grid container alignItems="center">
            <Avatar
              className={classes.avatar}
              onClick={() => (comments > 0 ? onRecommendationCommentClick(recommendation.recommendation_std_id) : null)}
              data-testid="recommendation-comments"
            >
              <Typography variant="subtitle1">{comments}</Typography>
            </Avatar>
            <Tooltip title="Add Note" placement="top">
              <IconButton
                className={classes.commentButton}
                onClick={onAddComment}
                data-testid="recommendation-add-comment"
                size="large"
              >
                <AddCommentIcon className={classes.commentIcon} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Collapse in={actionsOpen} timeout="auto" unmountOnExit>
            <Grid container className={classes.recommendationStepsActions}>
              <IndeterminateCheckBoxIcon className={classes.action} />
              <Typography> Actions</Typography>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12}>
                {recommendationSteps.length > 0 &&
                recommendationSteps.some(
                  (recommendationStep) => recommendationStep.recommendation_id === recommendation.id,
                )
                  ? recommendationSteps
                      .filter((recommendationStep) => recommendationStep.recommendation_id === recommendation.id)
                      .map((recommendationStep) => (
                        <RecommendationStepItem
                          key={recommendationStep.id}
                          recommendationStep={recommendationStep}
                          onChangeStep={(id: string, checked: boolean) =>
                            console.log(`update step: { id: ${id}, checked: ${checked} }`)
                          }
                        />
                      ))
                  : 'No Steps'}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default RecommendationGridItem;
