import { combineReducers } from 'redux';
import { History } from 'history';
import { RouterState, connectRouter } from 'connected-react-router';

// DO NOT IMPORT historyReducer > See the comments in file for reason it exists under Reducers.

// Reducers
import commentsReducer from './comments/reducer';
import encountersReducer from './encounters/reducer';
import insurancesReducer from './insurances/reducer';
import myPatientGroupsReducer from './my_patient_groups/reducer';
import patientSearchReducer from './patient_search/reducer';
import patientGroupsReducer from './patient_groups/reducer';
import peopleReducer from './people/reducer';
import productsReducer from './products/reducer';
import recommendationLabelsReducer from './recommendation_labels/reducer';
import recommendationsReducer from './recommendations/reducer';
import historicalRecommendationsReducer from './historical_recommendations/reducer';
import recommendationStepsReducer from './recommendation_steps/reducer';
import resultsReducer from './results/reducer';
import riskFactorsReducer from './risk_factors/reducer';
import rolesReducer from './roles/reducer';
import securityGroupsReducer from './security_groups/reducer';
import tenantSettingsReducer from './tenant_settings/reducer';
import tenantFiltersReducer from './tenant_filters/reducer';
import uiLabelsReducer from './ui_labels/reducer';
import usersReducer from './users/reducer';
import whoamiReducer from './whoami/reducer';

const rootReducer = (history: History) =>
  combineReducers({
    comments: commentsReducer,
    current_user: whoamiReducer,
    encounters: encountersReducer,
    historicalRecommendations: historicalRecommendationsReducer,
    insurances: insurancesReducer,
    my_patient_groups: myPatientGroupsReducer,
    patient_search: patientSearchReducer,
    patient_groups: patientGroupsReducer,
    people: peopleReducer,
    products: productsReducer,
    recommendation_labels: recommendationLabelsReducer,
    recommendation_steps: recommendationStepsReducer,
    recommendations: recommendationsReducer,
    results: resultsReducer,
    risk_factors: riskFactorsReducer,
    roles: rolesReducer,
    router: connectRouter(history),
    security_groups: securityGroupsReducer,
    tenant_settings: tenantSettingsReducer,
    tenant_filters: tenantFiltersReducer,
    ui_labels: uiLabelsReducer,
    users: usersReducer,
  });

export interface State {
  router: RouterState;
}

// export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
