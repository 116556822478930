// Being forced to use this because of BUG
//   https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
// Version 5 has a fix, but don't want to start with a un-official release
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';

import '@fontsource/barlow';
import '@fontsource/barlow/100.css';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/800.css';
import '@fontsource/barlow/900.css';

// Purples
export const DarkPurple = '#24265b';
export const MidPurple = '#6b45c7';
export const LightPurple = '#d770ef';
export const SelectedProductBorderColor = '#11101F';
export const DrawerLeftBG = '#2D2C42';
export const DrawerLeftSelectedBG = MidPurple;
export const DrawerRightBG = '#1B1B29';
export const DrawerRightSelectedBG = '#3B2C68';
export const DrawerFontColor = '#FFF';

// Teals
export const Teal = '#20d6d6';
export const DarkTeal = '#13a9a9';

// Blues
export const Blue = '#5186df';
export const LightBlue = '#61b5eb';
export const LighterBlue = '#dbe7f9';
export const DarkBlue = '#24265B';
export const MediumBlue = '#9BA8B3';
export const MediumDarkBlue = '#4A90E2'; //info icons

// Greys
export const CoolGrey11C = '#535353';
export const CoolGrey9C = '#646668';
export const CoolGrey5C = '#b1b1b1';
export const CoolGrey2C = '#ebeff3';
export const CoolGrey1C = '#f2f4f8';
export const MediumGrey = '#4A90E2';

const tb9FontColor1 = '#48535D';
const tb9FontColor2 = '#8598A9';
export const tb9InputFieldOutlineColor = '#B8C4CE';
// const warning = '#f5ad42';

export const thirdAndFourthQuartileFill = '#13A9A9';
export const secondQuartile = '#F87446';
export const topQuartile = '#DB504A';

// Static Heights
const spacing = 8;
const hoverOpacity = 0.04;
const selectedOpacity = 0.08;
export const primaryNavBarHeight = 48;
export const secondaryNavBarHeight = 48;

const theme = createMuiTheme({
  palette: {
    accent: {
      border: tb9InputFieldOutlineColor,
      primary: tb9FontColor1,
      secondary: tb9FontColor2,
      SelectedProductBorderColor: SelectedProductBorderColor,
    },
    background: {
      default: CoolGrey1C,
    },
    primary: {
      main: DarkPurple,
    },
    secondary: {
      main: DarkTeal,
    },
    text: {
      primary: CoolGrey11C,
      secondary: tb9FontColor2,
    },
  },
  typography: {
    fontFamily: 'Barlow, Helvetica, Arial, sans-serif',
    h1: {
      // Page Heading
      fontWeight: 300,
      fontSize: '1.5rem', // 24px
    },
    h2: {
      // Section Heading
      fontWeight: 400,
      textTransform: 'uppercase',
      fontSize: '0.875rem', // 14px
      letterSpacing: '0.0625rem', // 1px
      color: DarkPurple,
      opacity: 0.5,
    },
    h3: {
      // Emphasized Widget / Card Heading
      fontWeight: 600,
      fontSize: '0.875rem', // 14px
      color: DarkPurple,
    },
    h4: {
      // Widget / Card Heading
      fontWeight: 600,
      fontSize: '0.875rem', // 14px
      color: DarkTeal,
    },
    h5: {
      // Bread Crumb / Larger Text
      fontSize: '1rem', // 16px
    },
    subtitle1: {
      // Label - Basic
      fontSize: '0.625rem', // 10px
      textTransform: 'uppercase',
    },
    subtitle2: {
      // Label - Emphasized
      fontSize: '0.625rem', // 10px
      textTransform: 'uppercase',
      fontWeight: 600,
      color: DarkPurple,
    },
    body1: {
      // bigger paragraph
      fontSize: '0.875rem', // 14px
    },
    body2: {
      // paragraph
      fontSize: '0.75rem', // 12px, BUG https://github.com/mui-org/material-ui/issues/17100 > Fixed in V5
    },
  },
  shape: {
    borderRadius: 3, // Change border radius globally
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            color: CoolGrey11C,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: 'initial',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
        colorSecondary: {
          color: MediumDarkBlue,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          padding: `${spacing}px ${spacing * 3}px`,
          fontSize: '0.6875rem', // 11px
        },
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          // padding: `${spacing}px ${spacing * 3}px`,
          fontSize: '0.6875rem', // 11px
        },
        // label: {
        //   fontSize: '0.6875rem', // 11px
        //   letterSpacing: '0.0156rem', // 0.25px
        // },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: tb9FontColor1,
          fontSize: '10px',
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'right',
          fontSize: '10px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: alpha(DarkTeal, selectedOpacity),
            '&:hover': {
              backgroundColor: alpha(DarkTeal, selectedOpacity + hoverOpacity),
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: spacing * 1,
          '&:first-child': {
            paddingLeft: spacing * 3,
          },
        },
        head: {
          paddingTop: spacing * 0.5,
          paddingBottom: spacing * 0.5,
          backgroundColor: CoolGrey1C,
          borderBottom: `1px solid ${CoolGrey2C}`,
          fontSize: '0.75rem',
          fontWeight: 'bold',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          opacity: '0.25',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: DarkTeal,
          fontWeight: 'bold',
          textDecoration: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: `0 0 10px rgba(200,200,200,0.45)`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: LighterBlue,
          fontWeight: 'bold',
          paddingBottom: '2px',
          height: '28px',
        },
        icon: {
          marginRight: '-18px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${spacing * 1}px ${spacing * 4}px`,
          '&:first-child': {
            paddingTop: spacing * 4,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: spacing * 3,
          paddingBottom: spacing * 4,
        },
      },
    },
  },
});

// console.log('--- theme: ', theme);

export default theme;
