import React, { FunctionComponent, ReactElement, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.grey[100],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {},
    },
  },
}))(MenuItem);

interface UsersBulkActionsI {
  selected: string[];
  send_invites: (event: React.MouseEvent) => void;
  send_password_reset: (event: React.MouseEvent) => void;
}

const UsersBulkActions: FunctionComponent<UsersBulkActionsI> = (props: UsersBulkActionsI) => {
  const { selected, send_invites, send_password_reset } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [icon, setIcon] = useState<ReactElement>(<ExpandMoreIcon />);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIcon(<ExpandLessIcon />);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setAnchorEl(null);
    setIcon(<ExpandMoreIcon />);
  };
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        data-testid="users-bulk-actions"
        disabled={selected.length === 0}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Actions {icon}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledMenuItem
          onClick={(event: React.MouseEvent) => {
            send_invites(event);
            handleClose(event);
          }}
          data-testid="send-invites"
        >
          <ListItemText primary={'Send Invite'} />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={(event: React.MouseEvent) => {
            send_password_reset(event);
            handleClose(event);
          }}
          data-testid="send-password-resets"
        >
          <ListItemText primary={'Reset Password'} />
        </StyledMenuItem>
      </Menu>
    </div>
  );
};

export default UsersBulkActions;
