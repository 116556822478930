import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { FunctionComponent } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import ProductIcon from './productIcon';
import Divider from '@mui/material/Divider';
import { ResultProps } from '../../Store/results/types';
import clsx from 'clsx';

import ordinalText from '../../Utils/ordinalText';

import useStyles from './styles';

export interface IProduct {
  id: string;
  name: string;
  product_type: string;
  description: string;
}

export interface IProductCard {
  product: IProduct;
  results: ResultProps[];
  isSelected: boolean;
}

const ProductCard: FunctionComponent<IProductCard> = (props: IProductCard) => {
  const { product, results, isSelected } = props;
  const classes = useStyles();

  // Parse once for each option
  // instead of searching 3+ times if string matches a value.
  // This way, we only check if string is in 1 of the 3 possible options.
  const categoricalParser = (str: string) => {
    const reg = new RegExp(/^(high)?(medium)?(low)?/i);
    // Given the string is "LOW_RISK"
    // reponse would look like > ["LOW", undefined, undefined, "LOW", index: 0, input: "LOW_RISK", groups: undefined]
    // Where index 0 = the match
    //        index 1 = If it was HIGH
    //        index 2 = If it was MEDIUM
    //        index 3 = If it was LOW (which it was)
    // If index 1, 2 and 3 are undefined, then NO Match
    return str.match(reg);
  };

  const scoreCategoricalColor = (scoreCategorical: string) => {
    const parsed = categoricalParser(scoreCategorical) as RegExpMatchArray;
    // No match
    if (parsed[0] === undefined) return;
    // HIGH_RISK
    if (parsed[1] !== undefined) return classes.highRiskColor;
    // MEDIUM_RISK
    if (parsed[2] !== undefined) return classes.mediumRiskColor;
    // LOW_RISK
    if (parsed[3] !== undefined) return classes.lowRiskColor;
  };

  const percentileColor = (quartile: string | undefined) => {
    if (quartile === '4th Quartile' || quartile === '3rd Quartile') return classes.thirdAndFourthQuartileColor;

    if (quartile === '2nd Quartile') return classes.secondQuartileColor;
    if (quartile === 'Top Quartile') return classes.topQuartileColor;
  };

  const renderRiskLevelIcon = (scoreCategorical: string | undefined) => {
    // return nothing if undefined
    if (scoreCategorical === undefined) return;
    const parsed = categoricalParser(scoreCategorical) as RegExpMatchArray;
    if (parsed[0] === undefined) return;
    if (parsed[1] !== undefined) return classes.arrowUp;
    if (parsed[2] !== undefined) return classes.arrowRight;
    if (parsed[3] !== undefined) return classes.arrowStandard;
  };

  const renderQuartileGauge = (quartile: string | undefined, score: number | undefined) => {
    const scorePer = `${score || 0}%`;

    const tickerColor = (quartile: string | undefined) => {
      if (quartile === '4th Quartile' || quartile === '3rd Quartile') return classes.thirdAndFourthQuartileBG;

      if (quartile === '2nd Quartile') return classes.secondQuartileBG;
      if (quartile === 'Top Quartile') return classes.topQuartileBG;
    };

    return (
      <div className={clsx(classes.gaugeWrapper, isSelected ? classes.gaugeWrapperSelected : '')}>
        <div style={{ left: scorePer }} className={clsx(classes.gaugeTicker, tickerColor(quartile))}></div>
        <div className={clsx(classes.gauge, quartile?.includes('4th') ? classes.thirdAndFourthQuartileBG : '')}></div>
        <div className={clsx(classes.gauge, quartile?.includes('3rd') ? classes.thirdAndFourthQuartileBG : '')}></div>
        <div className={clsx(classes.gauge, quartile?.includes('2nd') ? classes.secondQuartileBG : '')}></div>
        <div className={clsx(classes.gauge, quartile?.includes('Top') ? classes.topQuartileBG : '')}></div>
      </div>
    );
  };

  return (
    <CardContent className={isSelected ? classes.productSelected : classes.cardContent}>
      <Grid className={classes.tooltip} container>
        {/* change tooltip value to product decsription once they are populated with data */}
        <Tooltip title={product.description === undefined ? product.description : product.name}>
          <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
        </Tooltip>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.cardText}>
        {results
          .filter((result) => product.id === result.product_id)
          .map((result) => (
            <div key={result.id}>
              <Grid className={classes.riskCategory} container>
                <Grid item className={result.risk_level && scoreCategoricalColor(result.risk_level)}>
                  <ArrowDropDownCircleOutlinedIcon className={renderRiskLevelIcon(result.risk_level)} />
                </Grid>
                <Grid item className={result.risk_level && scoreCategoricalColor(result.risk_level)}>
                  <Typography className={classes.riskScore}>{result.risk_level} RISK</Typography>

                  <ProductIcon productName={product.name} />
                </Grid>
              </Grid>
              <Typography className={classes.productName} gutterBottom data-testid="productName">
                {product.name}
              </Typography>
              <Grid>
                <Grid item xs={12}>
                  <p className={classes.percentileText}>
                    <span className={percentileColor(result.percentile_quartile_text)}>{result.risk_percentile}</span>
                    <span className={isSelected ? classes.percentileOrdinalSelected : classes.percentileOrdinal}>
                      {ordinalText(result.risk_percentile)} Percentile
                    </span>
                  </p>
                </Grid>
                <Grid container>{renderQuartileGauge(result.percentile_quartile_text, result.risk_percentile)}</Grid>
              </Grid>
            </div>
          ))}
      </div>
    </CardContent>
  );
};

export default ProductCard;
