import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  skeletonSpaced: {
    marginBottom: '0.2rem',
  },
}));

export default useStyles;
