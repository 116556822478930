import { RouteChangeProps } from '../history/reducer';

export interface ResultProps {
  encounter_id: string;
  id: string;
  person_id: string;
  product_id: string;
  risk_factors?: string[];
  recommendations?: string[];
  comments?: string[];
  risk_level: string;
  risk_percentile: number;
  run_dttm: number;
  updated_dttm: number;
  percentile_quartile_text?: string;
}

export type State = {
  results: ResultProps[];
  result?: ResultProps;
  loading: boolean;
  error?: string;
};

export const initialState: State = {
  results: [],
  result: undefined,
  loading: false,
  error: undefined,
};

export const RESULTS_REQUESTED = 'RESULTS_REQUESTED';
export const RESULTS_SUCCESS = 'RESULTS_SUCCESS';
export const RESULTS_ERROR = 'RESULTS_ERROR';

interface ResultsLoading {
  type: typeof RESULTS_REQUESTED;
}
interface ResultsSuccess {
  type: typeof RESULTS_SUCCESS;
  payload: ResultProps[];
}
interface ResultsError {
  type: typeof RESULTS_ERROR;
  payload: string;
}

export type ResultsActionTypes =
  | ResultsLoading
  | ResultsSuccess
  | ResultsError
  | RouteChangeProps
  | { type: 'INITIAL' };
