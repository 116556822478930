import { FunctionComponent, useState, useEffect } from 'react';
import { Search, Clear } from '@mui/icons-material';
import { Box, Input, IconButton, InputAdornment, Grid } from '@mui/material';

// Styles
import useStyles from './styles';

// State management stuff
import { useSelector } from 'react-redux';
import { RootStore } from '../../configureStore';

// Drawer contents
import StartSearch from './startSearch';
import ResultHeaders from './resultHeaders';
import { PatientGroupProps } from '../../Store/patient_groups/types';

interface PatientSearchProps {
  closeDrawer: (someBool: boolean) => void;
}

const PatientSearch: FunctionComponent<PatientSearchProps> = (props: PatientSearchProps) => {
  const [search, setSearch] = useState<string>('');
  const classes = useStyles();

  const { closeDrawer } = props;

  const searchedPatientGroups = useSelector((state: RootStore) => state.patient_search);
  const [defaultPatientGroups, setDefaultPatientGroups] = useState<PatientGroupProps[]>([]);

  useEffect(() => {
    let filteredGroups = searchedPatientGroups.patientGroups.filter((mpg) => mpg.is_default_group === true).slice();
    setDefaultPatientGroups(filteredGroups);
  }, [searchedPatientGroups]);

  // MouseDown event for text-clearing button
  const handleMouseDownClearText = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box role="presentation" className={classes.headerDrawerBox}>
      <Grid container className={classes.headerDrawerContents} direction="row" spacing={0} justifyContent="center">
        <Grid item className={classes.searchbarGridWidth}>
          <Input
            className={classes.search}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            autoFocus
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment onClick={() => setSearch('')} position="end">
                <IconButton
                  aria-label="Clear contents of search field"
                  onMouseDown={handleMouseDownClearText}
                  onClick={() => {
                    setSearch('');
                  }}
                  size="large"
                >
                  <Clear fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.headerDrawerContents}
        justifyContent="space-evenly"
        style={{ paddingLeft: '1.5rem' }}
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12}>
          {search.length <= 2 ? (
            <StartSearch />
          ) : (
            <ResultHeaders search={search} groups={defaultPatientGroups} closeDrawer={closeDrawer} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientSearch;
