import { UILabelProps } from '../../Store/ui_labels/types';
import GetUILabelValue, { GetUILabelValueFunction } from './uiLabelValue';

/*
  This is another util for UILabels. It can filter on any parameter but does not take into 
  account default labels like the default util defined on index. This is because it is necessary
  to provide a column and model name to get default values.
*/

interface GetUILabelsArrayQueryParams {
  model_name?: string | string[];
  column_name?: string | string[];
  view_name?: string | string[];
  component_type?: string | string[];
  section_name?: string | string[];
  product_type?: string | string[];
}

interface GetUILabelsArrayParams extends GetUILabelsArrayQueryParams {
  tenantUILabels: UILabelProps[];
}

interface UILabelArrayProps extends UILabelProps {
  formatValue: GetUILabelValueFunction;
}

export default function GetUILabelsArray(params: GetUILabelsArrayParams): UILabelArrayProps[] {
  let { tenantUILabels, product_type, ...rest } = params;
  let filteredTenantUILabels: UILabelArrayProps[] = [];

  tenantUILabels.forEach((tenantUILabel) => {
    if (
      tenantUILabel.enabled &&
      Object.keys(rest).every((param) => {
        const key = param as keyof GetUILabelsArrayQueryParams;
        return params && params[key] && tenantUILabel[key] && Array.isArray(params[key])
          ? (params[key] as string[]).includes(tenantUILabel[key] as string)
          : params[key] === tenantUILabel[key];
      })
    ) {
      // Product Type could be optional on some fields
      // The label has a product type associate to it
      if (product_type && tenantUILabel.product_type) {
        // And the 2 don't match, go to next
        if (tenantUILabel.product_type !== product_type) {
          return;
        }
      }
      filteredTenantUILabels.push({ ...tenantUILabel, formatValue: GetUILabelValue(tenantUILabel) });
    }
  });

  return filteredTenantUILabels.sort((a, b) => (a.sort_order && b.sort_order && a.sort_order < b.sort_order ? -1 : 1));
}
