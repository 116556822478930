import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ROOT } from '../../Utils/constants';

import CircularProgressWithLabel from '../../Components/CircularProgressBarLabel';
import { PatientGroupProps } from '../../../src/Store/patient_groups/types';
import useStyles from './styles';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { DarkTeal, Teal, Blue } from '../../theme';
import { WarningRounded } from '@mui/icons-material';
import CircularIndeterminate from '../../Components/Loading';
import HorizMenu from '../../Components/HorizontalMenu';
import { useEffect, useState } from 'react';

interface PatientGroupContainerProps extends PatientGroupProps {
  value: number | null;
  maxValue: number | null;
  handleDelete: (id: string) => void;
}

interface ExportCSVParamsInterface {
  requested_model: string;
  file_name: string | null;
}

const PatientGroupContainer = (props: PatientGroupContainerProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { name, id, description, value, maxValue, is_default_group } = props;
  const [token, setToken] = useState<String>('');
  let color: string;

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setToken(token);
    });
  }, [getAccessTokenSilently]);

  // colors are assigned here
  if (value !== null && maxValue !== null) {
    switch (true) {
      case Math.floor((value * 100) / maxValue) >= 33 && Math.floor((value * 100) / maxValue) < 66:
        color = DarkTeal;
        break;
      case Math.floor((value * 100) / maxValue) >= 66:
        color = Blue;
        break;
      default:
        color = Teal;
    }
  } else {
    color = DarkTeal;
  }

  const classes = useStyles({ color });
  const requested_model = 'Recommendation,RecommendationLabel,RiskFactor';
  const url = API_ROOT + `/export/csv/${id}?access_token=${token}&requested_model=${requested_model}`;
  //Menu options
  const options = [
    {
      label: 'Export to CSV',
      onClick: async (custom_file_name: string = '') => {
        let params: ExportCSVParamsInterface = {
          requested_model: 'Recommendation,RecommendationLabel,RiskFactor',
          file_name: null,
        };
        if (custom_file_name !== '') {
          params.file_name = custom_file_name;
        }
        open(url);
      },
    },
  ];

  async function open(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function RenderCircularProgressWithLabel() {
    if (value !== null && maxValue !== null) {
      return <CircularProgressWithLabel value={value} maxValue={maxValue} color={color} />;
    }

    return (
      <Tooltip
        title="Math is hard! But you can help by double-checking the group's filters."
        data-testid="group-patient-count-error"
      >
        <WarningRounded className={classes.warning} />
      </Tooltip>
    );
  }

  return (
    <Grid item xs={12} sm={4}>
      <Paper className={classes.root}>
        <Box className={is_default_group ? classes.defaultGroupsContainerTop : classes.containerTop}>
          <Typography variant="h3" className={is_default_group ? classes.defaultGroupName : classes.name}>
            <TripOriginIcon className={classes.icon} />
            {name}
          </Typography>
          <HorizMenu options={options} iconButtonClassName={classes.horizMenu}></HorizMenu>
          {/* {!is_default_group && <HorizMenu options={options} iconButtonClassName={classes.menuButton} />} */}
        </Box>
        {!is_default_group && <Divider />}
        <Link className={classes.disablelinkcss} to={{ pathname: `/patient-groups/${id}` }}>
          <Grid container className={classes.containerBottom}>
            <Grid item sm={6} md={8} lg={9}>
              <Typography>{description}</Typography>
            </Grid>
            <Grid item sm={6} md={4} lg={3} className={classes.progressContainer}>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  tooltipPlacementRight: classes.placementRight,
                  tooltipPlacementLeft: classes.placementLeft,
                }}
                placement="right"
                arrow
                title={
                  value === null || maxValue === null ? (
                    ''
                  ) : (
                    <>
                      <Box pb={0.5}>
                        <Typography variant="body2">
                          <strong>Patients with 1+ recommendation</strong>
                        </Typography>
                      </Box>
                      <Typography variant="body2">
                        Each group shows a chart that represents the number of patients with at least one completed
                        recommendation
                      </Typography>
                    </>
                  )
                }
              >
                <Box>
                  {(value || maxValue) === -1 ? (
                    <CircularIndeterminate size={20} data-testid="group-patient-count-loading" />
                  ) : (
                    <RenderCircularProgressWithLabel />
                  )}
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Link>
      </Paper>
    </Grid>
  );
};

export default PatientGroupContainer;
