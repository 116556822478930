import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { FunctionComponent } from 'react';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    content: {
      textAlign: 'center',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      fontSize: '1.25rem',
      lineHeight: 2,
    },
    actions: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

interface ConfirmModalProps extends DialogProps {
  cancelButtonText?: string;
  cancelButtonClick?: () => void;
  confirmButtonText?: string;
  confirmButtonClick: () => void;
  onClose: () => void;
  errorMessage?: string;
  children: React.ReactElement;
  isSubmitting?: boolean;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = (props: ConfirmModalProps) => {
  const classes = useStyles();
  const {
    cancelButtonClick,
    cancelButtonText,
    children,
    confirmButtonClick,
    confirmButtonText,
    onClose,
    open,
    errorMessage,
    isSubmitting,
  } = props;

  const handleCancel = () => {
    if (cancelButtonClick) cancelButtonClick();
    onClose();
  };

  const handleConfirm = () => {
    confirmButtonClick();
  };

  return (
    <Dialog
      maxWidth="sm"
      PaperProps={{
        className: classes.dialog,
      }}
      open={open}
      onClose={() => onClose()}
      data-testid="confirm-modal"
    >
      <DialogContent className={classes.content}>
        {errorMessage && (
          <Alert severity="error" data-testid="form-alert">
            {errorMessage}
          </Alert>
        )}
        {children}
        <DialogActions className={classes.actions}>
          <Button data-testid="cancel" onClick={() => handleCancel()} disabled={isSubmitting}>
            {cancelButtonText || 'Cancel'}
          </Button>
          <Button
            data-testid="confirm"
            onClick={() => handleConfirm()}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
          >
            {confirmButtonText || 'Confirm'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
