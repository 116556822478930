import { useState, useEffect, ReactElement, useCallback } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import sortBy from 'lodash/sortBy';
import clsx from 'clsx';

import JvionTextField from '../JvionTextField';
import { MultiSelectOptions } from './multiSelectOptions';
import useStyles from './styles';

export interface OptionI {
  label: string;
  value: string;
}
export interface JvionMultiSelectFieldProps {
  label?: string;
  placeholder?: string;
  className?: string;
  OptionsClassName?: string;
  options: OptionI[];
  selected?: string[];
  selectOptions?: boolean;
  fuzzy?: boolean;
  showMore?: number;
  handleCheckbox?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBulkAdd?: () => void;
  handleAddUser?: (id: string) => void;
}

export default function JvionMultiSelectField(props: JvionMultiSelectFieldProps) {
  const classes = useStyles(props);
  const {
    label,
    placeholder,
    options,
    selected,
    handleCheckbox,
    handleBulkAdd,
    handleAddUser,
    showMore,
    className,
    fuzzy,
    selectOptions,
    OptionsClassName,
  } = props;

  let sortedOptions = sortBy(options, (o: OptionI) => o.label.toLowerCase());

  const [showMoreState, setShowMoreState] = useState<number | undefined>(showMore);
  const [searchValue, setSearchValue] = useState<string>('');
  const [state, setState] = useState<OptionI[]>(sortedOptions);
  // See useEffect below for why we are using this
  const [itemsToRender, setItemsToRender] = useState<ReactElement[]>([]);

  useEffect(() => {
    // update if options change and there is no search term
    if (options.length !== state.length && searchValue.length === 0) {
      setState(sortBy(options, (o: OptionI) => o.label.toLowerCase()));
    }
  }, [options, state, searchValue]);

  // Copied function from https://github.com/bevacqua/fuzzysearch/blob/master/index.js
  const fuzzysearch = (needle: string, haystack: string) => {
    var hlen = haystack.length;
    var nlen = needle.length;
    if (nlen > hlen) {
      return false;
    }
    if (nlen === hlen) {
      return needle === haystack;
    }
    outer: for (var i = 0, j = 0; i < nlen; i++) {
      var nch = needle.charCodeAt(i);
      while (j < hlen) {
        if (haystack.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let copied = sortedOptions;
    if (e.target.value !== '') {
      let eventValue = e.target.value.trim().toLowerCase();

      if (fuzzy) {
        copied = copied.filter((o: OptionI) => {
          return fuzzysearch(eventValue, o.label.toLowerCase());
        });
      } else {
        copied = copied.filter((o: OptionI) => {
          return o.label.toLowerCase().indexOf(eventValue) >= 0;
        });
      }
    }
    setState(copied);
    setSearchValue(e.target.value);
  };

  // Adds the tool tip if text is too long
  const createLabel = (option: OptionI) =>
    option.label.length > 40 ? (
      <Tooltip title={option.label}>
        <Typography variant="h5">{option.label.slice(0, 40).trim()}...</Typography>
      </Tooltip>
    ) : (
      <Typography variant="h5">{option.label}</Typography>
    );

  // Normally, options are Checkboxes but when rendering this component
  //    in GroupEdit > GroupEditUsers they are ROWS with ADD BUTTONS
  const createCheckbox = useCallback(
    (option: OptionI, idx: number) => {
      return (
        <Grid container key={option.value} alignItems="center" className={classes.checkboxContainer}>
          <Checkbox
            className={classes.checkbox}
            role="checkbox"
            color="secondary"
            name={option.value}
            checked={selected?.includes(option.value)}
            onChange={handleCheckbox}
            key={`${option.value}-${idx}`}
            data-testid="multi-select-filter-option"
          />
          <>{selected?.includes(option.value) ? <strong>{createLabel(option)}</strong> : createLabel(option)}</>
        </Grid>
      );
    },
    [classes.checkbox, classes.checkboxContainer, handleCheckbox, selected],
  );

  // For GroupEdit > GroupEditUsers
  // Requirement states to be Add Buttons
  // #JCP-1485
  const createAddRow = useCallback(
    (option: OptionI, idx: number) => {
      return (
        <Grid
          container
          key={option.value}
          alignItems="center"
          justifyContent="space-between"
          data-testid="multi-select-filter-option"
        >
          {selected?.includes(option.value) ? <strong>{createLabel(option)}</strong> : createLabel(option)}
          <Button
            color="primary"
            onClick={() => {
              return handleAddUser !== undefined ? handleAddUser(option.value) : undefined;
            }}
          >
            Add
          </Button>
        </Grid>
      );
    },
    [handleAddUser, selected],
  );

  // Instead of re-creating these input elements every time the component re-renders
  // Just check if state or showMore has changed
  // If so, then create the input elements.
  // Should reduce how many times these listeners are added / removed from the view
  useEffect(() => {
    let items = [];
    // This function is passed in for the GroupEditUsers
    // Else it defaults to Checkboxes
    if (typeof handleAddUser === 'function') {
      items = showMoreState ? state.map(createAddRow).slice(0, showMore) : state.map(createAddRow);
    } else {
      items = showMoreState ? state.map(createCheckbox).slice(0, showMore) : state.map(createCheckbox);
    }
    setItemsToRender(items);
  }, [createAddRow, createCheckbox, handleAddUser, showMore, showMoreState, state]);

  return (
    <Grid className={clsx(classes.root, className)}>
      <Grid item>
        <JvionTextField
          className={classes.search}
          placeholder={placeholder || 'Search'}
          fullWidth
          multiline
          label={label}
          inputProps={{
            'data-testid': 'multi-select-filter-input',
            sx: {
              height: '17px',
              lineHeight: '1rem',
            },
          }}
          value={searchValue}
          onChange={handleSearch}
        />
      </Grid>
      {selectOptions !== undefined && handleBulkAdd && (
        <MultiSelectOptions
          handleBulkAdd={() => {
            setSearchValue('');
            handleBulkAdd();
          }}
          selectedLength={options.length}
        />
      )}
      <Grid item className={OptionsClassName ? OptionsClassName : undefined}>
        <List>
          <ListItem className={classes.listItem} role="list">
            {itemsToRender}
          </ListItem>
        </List>
      </Grid>
      {showMoreState && showMore && state && state.length > showMore && (
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setShowMoreState(undefined)}
            data-testid="multi-select-filter-show-more"
          >
            Show More
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
