import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DarkTeal, CoolGrey2C } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    position: 'relative',
  },
  close: {
    padding: 0,
  },
  closeText: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
  },
  closeIcon: {
    color: DarkTeal,
    fontSize: '1.2rem',
  },
  header: {
    '& .MuiInput-input': {
      fontSize: '1.5rem',
    },
  },
  headerText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    opacity: 1,
  },
  headerTop: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.common.black,
  },
  headerBottom: {
    padding: theme.spacing(2),
  },
  content: {
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${CoolGrey2C}`,
    paddingTop: `${theme.spacing(3)}!important`, // had to use IMPORTANT because of new mui overrides this
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default useStyles;
