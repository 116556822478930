/*
 * IMPORTANT
 * DO NOT ADD THIS FILE TO THE ROOT REDUCER
 *
 *   This file exists here because it works like a reducer dispatching events
 *   but is different as it does not keep a real state.
 *
 *   What this file does is let's the other reducers know when a route change has
 *   been initiated letting them cancal any pending requests.
 */

import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';

export const ROUTE_CHANGE = 'ROUTE_CHANGE';

export interface RouteChangeProps {
  type: typeof ROUTE_CHANGE;
  payload: RouteComponentProps['location'];
}

export let prevPath: string = '';

export const RouteChange =
  (location: RouteComponentProps['location']) => async (dispatch: Dispatch<RouteChangeProps>) => {
    // We can put some logic here to see if the URL really changed
    // or we doing a HTML5 push updating the URL with query params
    //   but not wanting the page to reload.
    if (prevPath !== location.pathname) {
      // console.log('---- RouteChange location: ', location);
      prevPath = location.pathname;
      dispatch({ type: ROUTE_CHANGE, payload: location });
    }
  };
