import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import Results from './results';
import NotFound from './notFound';

// imports for GET request
import { useAuth0 } from '@auth0/auth0-react';
import { authorized } from '../../Utils/AxiosInstance';
import axios, { CancelTokenSource } from 'axios';

import useStyles from './styles';
import debounce from 'lodash/debounce';
import { PersonProps } from '../../Store/people/types';
import { dateFormatter } from '../../Components/DateTimeRender';

interface ResultHeaderProps {
  search: string;
  groups: PatientGroupProps[];
  closeDrawer: (someBool: boolean) => void;
}

interface DefaultGroupI {
  name: string;
  id: string;
  url: string;
  cancelToken: CancelTokenSource | undefined;
  count: number;
  people: PersonProps[];
  error: string | undefined;
}

let isLoaded: boolean = false;

const ResultHeaders: FunctionComponent<ResultHeaderProps> = (props: ResultHeaderProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState<boolean>(false);
  const [defaultGroups, setDefaultGroups] = useState<DefaultGroupI[]>([]);

  const { search, groups, closeDrawer } = props;

  // Have a generic way to create the Default Groups
  // Because it could be created when either component loads
  //  and the search has been triggered or when component loads and
  //  no search has been triggered.
  const generateGroups = useCallback(() => {
    return groups
      .filter((g) => g.is_default_group)
      .slice()
      .map((g) => {
        return {
          name: g.name,
          id: g.id,
          url: `/patient_groups/${g.id}/people`,
          cancelToken: undefined,
          count: 0,
          people: [],
          error: undefined,
        } as DefaultGroupI;
      });
  }, [groups]);

  useEffect(() => {
    let newGroups = generateGroups();
    setDefaultGroups(newGroups);
  }, [generateGroups, groups]);

  // Need make N number of API calls (1+)
  // We have an issue with letting THIS component
  //   know when the count is updated.  > Possible Race Issue
  // PERFECT
  // Get all the data from the Top Level so:
  //  - We know when things are loading
  //  - We know when all the Calls are done
  //  - We can make 1 big update and pass that information down

  const clearPendingRequests = useCallback(() => {
    defaultGroups.forEach((dg) => {
      if (dg.cancelToken) dg.cancelToken.cancel();
    });
  }, [defaultGroups]);

  useEffect(() => {
    if (isLoaded !== true) isLoaded = true;
    return () => {
      isLoaded = false;
      clearPendingRequests();
    };
  }, [clearPendingRequests]);

  // Parse the responses from the Promise All Settled call
  // but return if component has closed before Promise settled.
  // Edge case
  //  User closes component before setState triggered AND responses come back.
  const parseResponses = (
    responses: Array<{ status: 'fulfilled' | 'rejected'; value?: any; reason?: any }>,
    finishedGroup: DefaultGroupI[],
  ) => {
    // If component was unMounted then don't update state
    // #FIXME
    // Considering clearing the Cancel Token if we hit this
    if (!isLoaded) return;

    // Parse the responses
    responses.forEach((resp, index) => {
      if (resp.status === 'fulfilled') {
        // It was successful
        // Format the Date of Birth once vs each time component renders
        const people = resp.value.data.data.map((person: PersonProps) => {
          person.date_of_birth = dateFormatter({ date: person.date_of_birth });
          return person;
        });
        finishedGroup[index].people = people;
        finishedGroup[index].count = resp.value.data.metadata.total_records;
        finishedGroup[index].error = undefined;
      } else {
        // Error
        finishedGroup[index].people = [];
        finishedGroup[index].count = 0;
        finishedGroup[index].error = resp.reason;
      }
      // No matter what, clear the Cancel Token
      finishedGroup[index].cancelToken = undefined;
    });

    // Set the finished Results
    setDefaultGroups(finishedGroup);
    // Turn Loading Off now.
    setLoading(false);
  };

  // Pass the Search term in since debounce might have reference to
  //  previous state of Search
  const fetchData = useCallback(
    async (srch: string) => {
      // Because of Debounce
      // If component is closed, then just return and don't update states
      if (!isLoaded) return;

      // Because component initializes after 3 charachters
      // Search is triggered before the defaultGoup has been created.
      const workingGroup = defaultGroups.length ? defaultGroups.slice() : generateGroups();
      // Show the loading State
      setLoading(true);
      // Iterate through existing and check to see if pending request
      clearPendingRequests();

      // Get the token
      // #FIXME Potential Race condition here
      // While wating for the token, a new Search might have been entered.
      const token = await getAccessTokenSilently();
      // Create a clone of the defaultGroups
      let newGroups = workingGroup.slice();
      // Iterate 1 time, but return the Axios Promise.
      let apiCalls = workingGroup.map((dg, index) => {
        // Create 1 cancel Token per Pateient Group Request
        const canceler = axios.CancelToken.source();
        // Apply it to the state
        newGroups[index].cancelToken = canceler;
        // Return the Promise
        return authorized(token).get(dg.url, {
          params: { search: srch, page_size: 10 },
          cancelToken: canceler.token,
        });
      });

      // Add the API Calls for Cancel Token to the Default Group
      // So it can cancel if new change comes in.
      // This normally would happen after this state is saved
      // and a new status has been triggered.
      setDefaultGroups(newGroups);

      // The Data(s)
      const responses = await Promise.allSettled(apiCalls);
      parseResponses(responses, newGroups.slice());
    },
    [clearPendingRequests, defaultGroups, generateGroups, getAccessTokenSilently],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallBack = useMemo(() => debounce(fetchData, 350), []);

  useEffect(() => {
    debounceCallBack(search);
  }, [debounceCallBack, search]);

  let foundCount = defaultGroups.reduce((currentCount, group) => currentCount + group.count, 0);

  return (
    <div className={classes.resultsContent}>
      <Typography className={classes.patientCount}>Patients ({foundCount})</Typography>
      {defaultGroups.map((group, index) => (
        <Results
          groupId={group.id}
          name={group.name}
          patients={group.people}
          loading={loading}
          key={`${group.name}-${group.id}`}
          closeDrawer={closeDrawer}
        />
      ))}
      {foundCount === 0 && !loading && <NotFound />}
    </div>
  );
};

export default ResultHeaders;
