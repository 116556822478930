import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface GroupEditSaveDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

const GroupEditSaveDialog: FunctionComponent<GroupEditSaveDialogProps> = (props: GroupEditSaveDialogProps) => {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose} data-testid="confirm-modal">
      <DialogContent>
        <Typography>Your changes have been saved. Would you like to close?</Typography>
        <DialogActions>
          <Button onClick={onClose} color="inherit" data-testid="confirm-modal-back">
            CANCEL
          </Button>
          <Button onClick={onConfirm} variant="contained" color="primary" data-testid="confirm-modal-save">
            CLOSE
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default GroupEditSaveDialog;
