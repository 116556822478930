import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {},
  content: {},
  radioGroupWithTooltip: {
    alignItems: 'center',
  },
  radioLabelWithTooltip: {
    marginRight: 0,
  },
  radio: {
    padding: theme.spacing(0.5),
  },
  radioTooltip: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    height: theme.spacing(4),
  },
  selectGroupTypeContainer: {
    marginTop: theme.spacing(1.5),
  },
}));

export default useStyles;
