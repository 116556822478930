import { useEffect } from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RootStore } from './configureStore';

import Box from '@mui/material/Box';

import Routes from './Routes';
import HistoryListener from './HOC/HistoryListener';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Components/Loading';
import TopNavBar from './Containers/TopNavBar';
import { getWhoami } from './Store/whoami/actions';
import { getProducts } from './Store/products/actions';
import { getTenantFilters } from './Store/tenant_filters/actions';
import { getUILabels } from './Store/ui_labels/actions';
import { getTenantSettings } from './Store/tenant_settings/actions';
import { searchMyPatientGroups } from './Store/patient_search/actions';

import useTenantSwitching from './HOC/TenantSwitching/useTenantSwitching';

import IdleModal from './Containers/IdleWatcher';
import ErrorMessage from './Components/ErrorMessage';

const useStyles = makeStyles((theme: Theme) => ({
  fullView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    minHeight: '200px',
    minWidth: '450px',
  },
}));

// We need the Component because react-router needs it for the links to render after clicking on NavLink.

// Another item we can might want to look into is using React's Context Provider to pass things like "isAuthenticated"
//  to children components vs asking it from import Auth0 from different components / containers.
interface AppProps {
  history: History;
}

const App = ({ history }: AppProps) => {
  const {
    forcedLogout,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useSelector((state: RootStore) => state.current_user);
  const { loading: productsLoading, error: productsError } = useSelector((state: RootStore) => state.products);
  const { loading: settingsLoading, error: settingsError } = useSelector((state: RootStore) => state.tenant_settings);
  const { loading: filtersLoading, error: filtersError } = useSelector((state: RootStore) => state.tenant_filters);
  const { loading: labelsLoading, error: labelsError } = useSelector((state: RootStore) => state.ui_labels);
  const { loading: patientSearchLoading, error: patientSearchError } = useSelector(
    (state: RootStore) => state.patient_search,
  );
  const { isLoading, user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const { hasInitialized: tenantSwtitchingInitialized } = useTenantSwitching();

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (user && tenantSwtitchingInitialized) {
      // fetch tenant level settings and dispatch them to the store.
      getAccessTokenSilently().then((token) => {
        dispatch(getWhoami(token));
        dispatch(getProducts(token));
        dispatch(getTenantSettings(token));
        dispatch(getTenantFilters(token));
        dispatch(getUILabels(token));
        dispatch(
          searchMyPatientGroups(token, {
            params: { enabled: true, is_default_group: true, sort_by: 'is_default_group(desc)' },
          }),
        );
      });
    } else {
      // here we could clearHistory as user is undefined.
    }
  }, [user, tenantSwtitchingInitialized, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    if (forcedLogout && isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [forcedLogout, isAuthenticated, logout]);

  if (
    isLoading ||
    whoAmILoading ||
    productsLoading ||
    settingsLoading ||
    filtersLoading ||
    labelsLoading ||
    patientSearchLoading
  ) {
    return <Loading data-testid="loading" fullView={true} />;
  }

  if (
    whoAmIError ||
    productsError ||
    productsError ||
    settingsError ||
    filtersError ||
    labelsError ||
    patientSearchError
  ) {
    return (
      <Box className={classes.fullView}>
        <ErrorMessage />
      </Box>
    );
  }

  return (
    <ConnectedRouter history={history}>
      <HistoryListener>
        {isAuthenticated && (
          <>
            <IdleModal />
            <TopNavBar />
          </>
        )}
        <Routes />
      </HistoryListener>
    </ConnectedRouter>
  );
};

export default App;
