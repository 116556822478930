// Optional fields to pass in when using a Existing User
interface UserModelProps {
  given_name?: string | null;
  family_name?: string | null;
  email?: string;
  blocked: any;
}

// Regex for Email validation
//  What does not work is "john_smith@my_company.com" the underscore in the company name
const regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Function to test string string fields + error message
// FIXME
//  Why do I have to declare the types twice
interface StringValidationFields {
  field?: string;
  string?: string;
  length?: number;
}

type StringValidationFunction = (obj: StringValidationFields) => string | undefined;
function testStringField(obj: StringValidationFields) {
  const { field, string, length } = obj;
  if (field === undefined || string === undefined) return 'Both Field and String is needed';
  const len = length !== undefined ? length : 1;
  if (string !== undefined && string.length < len) return `Field is invalid.`.toString();
}

// Function to test Email field + error message
interface EmailValidationFields {
  emailString?: string;
}
type EmailValidationFunction = (obj: EmailValidationFields) => string | undefined;

const testEmailString = (obj: EmailValidationFields) => {
  if (obj.emailString === undefined) return 'No Email passed in';
  if (!regex.test(obj.emailString)) {
    return `Invalid Email`.toString();
  }
};

type ValidationType = StringValidationFunction | EmailValidationFunction;

interface DefaultFieldOptions {
  defaultValue: string;
  value: string;
  validation: ValidationType;
  errors: string[];
}

// Below is so we can loop through the User Object with Object.keys
// This and the User Model should have the same keys.
export type UserModelFields = 'given_name' | 'family_name' | 'email' | 'blocked';

interface UserModelFormProps {
  blocked: any;
  given_name: DefaultFieldOptions;
  family_name: DefaultFieldOptions;
  email: DefaultFieldOptions;
}

// Function to create a new User or Set Default values for Existing User
// This will also be used to validate & keep track of Error Mesages for a field
const UserForm = (props: UserModelProps) => {
  const UserModelForm: UserModelFormProps = {
    given_name: {
      defaultValue: props.given_name || '',
      value: props.given_name || '',
      validation: testStringField,
      errors: [],
    },
    family_name: {
      defaultValue: props.family_name || '',
      value: props.family_name || '',
      validation: testStringField,
      errors: [],
    },
    email: {
      defaultValue: props.email || '',
      value: props.email || '',
      validation: testEmailString,
      errors: [],
    },
    blocked: {
      defaultValue: props.blocked,
      value: props.blocked,
      validation: () => {
        return;
      },
      errors: [],
    },
  };
  return UserModelForm;
};

export default UserForm;
