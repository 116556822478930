import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CoolGrey2C } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  filterWrapper: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${CoolGrey2C}`,
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  filterWrapperHeader: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  removeFilterBtn: {
    padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
  },
}));

export default useStyles;
