import { RouteChangeProps } from '../history/reducer';
import { PatientAndSecurityGroupFilters } from '../patient_groups/types';
import { PersonProps } from '../people/types';
import { MetadataProps, Modify } from '../Utils/types';

export interface SecurityGroupProps {
  id: string;
  description: string;
  enabled: boolean;
  name: string;
  patients: number;
  users?: string[];
  group_filters?: PatientAndSecurityGroupFilters;
  updated_dttm?: number;
}

export interface OrderedSecurityGroup
  extends Modify<
    SecurityGroupProps,
    {
      users: number;
    }
  > {}

export interface SecurityGroupTotalProps {
  id: string;
  numerator: number | null;
  denominator: number | null;
}

export interface SecurityGroupPeopleBatchResponseProps {
  data: PersonProps[];
  metadata: MetadataProps;
}

export type State = {
  securityGroups: SecurityGroupProps[];
  securityGroupsTotal: SecurityGroupTotalProps[];
  securityGroup?: SecurityGroupProps | undefined;
  loading: boolean;
  error: string | undefined;
  people: {
    metadata?: MetadataProps;
    people: PersonProps[];
  };
  group_filters?: object;
};

export const initialState: State = {
  securityGroups: [],
  securityGroupsTotal: [],
  securityGroup: undefined,
  people: {
    people: [],
  },
  loading: false,
  error: undefined,
};

export const SECURITY_GROUPS_REQUESTED = 'SECURITY_GROUPS_REQUESTED';
export const SECURITY_GROUPS_SUCCESS = 'SECURITY_GROUPS_SUCCESS';
export const SECURITY_GROUPS_ERROR = 'SECURITY_GROUPS_ERROR';
export const SECURITY_GROUP_REQUESTED = 'SECURITY_GROUP_REQUESTED';
export const SECURITY_GROUP_SUCCESS = 'SECURITY_GROUP_SUCCESS';
export const SECURITY_GROUP_ERROR = 'SECURITY_GROUP_ERROR';
export const ADD_SECURITY_GROUP_SUCCESS = 'ADD_SECURITY_GROUP_SUCCESS';
export const EDIT_SECURITY_GROUP_SUCCESS = 'EDIT_SECURITY_GROUP_SUCCESS';
export const SECURITY_GROUP_DELETE_SUCCESS = 'SECURITY_GROUP_DELETE_SUCCESS';
export const SECURITY_GROUP_PEOPLE_RESET = 'SECURITY_GROUP_PEOPLE_RESET';
export const SECURITY_GROUP_PEOPLE_BATCH_REQUESTED = 'SECURITY_GROUP_PEOPLE_BATCH_REQUESTED';
export const SECURITY_GROUP_PEOPLE_BATCH_SUCCESS = 'SECURITY_GROUP_PEOPLE_BATCH_SUCCESS';
export const SECURITY_GROUP_PEOPLE_BATCH_ERROR = 'SECURITY_GROUP_PEOPLE_BATCH_ERROR';
export const SECURITY_GROUPS_EDIT_TOTALS_REQUESTED = 'SECURITY_GROUPS_EDIT_TOTALS_REQUESTED';
export const SECURITY_GROUPS_EDIT_TOTALS_SUCCESS = 'SECURITY_GROUPS_EDIT_TOTALS_SUCCESS';
export const SECURITY_GROUPS_EDIT_TOTALS_ERROR = 'SECURITY_GROUPS_EDIT_TOTALS_ERROR';

interface SecurityGroupsLoading {
  type: typeof SECURITY_GROUPS_REQUESTED;
}
interface SecurityGroupLoading {
  type: typeof SECURITY_GROUP_REQUESTED;
}
interface SecurityGroupsSuccess {
  type: typeof SECURITY_GROUPS_SUCCESS;
  payload: SecurityGroupProps[];
}
interface SecurityGroupSuccess {
  type: typeof SECURITY_GROUP_SUCCESS;
  payload: SecurityGroupProps;
}
interface AddSecurityGroupSuccess {
  type: typeof ADD_SECURITY_GROUP_SUCCESS;
  payload: SecurityGroupProps;
}
interface EditSecurityGroupSuccess {
  type: typeof EDIT_SECURITY_GROUP_SUCCESS;
  payload: SecurityGroupProps;
}
interface DeleteSecurityGroupSuccess {
  type: typeof SECURITY_GROUP_DELETE_SUCCESS;
  payload: string; // ID
}
interface SecurityGroupsError {
  type: typeof SECURITY_GROUPS_ERROR;
  payload: string;
}
interface SecurityGroupError {
  type: typeof SECURITY_GROUP_ERROR;
  payload: string;
}
interface PeopleReset {
  type: typeof SECURITY_GROUP_PEOPLE_RESET;
  payload: string;
}
interface SecurityGroupPeopleBatchLoading {
  type: typeof SECURITY_GROUP_PEOPLE_BATCH_REQUESTED;
}
interface SecurityGroupPeopleBatchSuccess {
  type: typeof SECURITY_GROUP_PEOPLE_BATCH_SUCCESS;
  payload: SecurityGroupPeopleBatchResponseProps;
}
interface SecurityGroupPeopleBatchError {
  type: typeof SECURITY_GROUP_PEOPLE_BATCH_ERROR;
  payload: string;
}
interface SecurityGroupEditTotalsLoading {
  type: typeof SECURITY_GROUPS_EDIT_TOTALS_REQUESTED;
}
interface SecurityGroupEditTotalsSuccess {
  type: typeof SECURITY_GROUPS_EDIT_TOTALS_SUCCESS;
  payload: SecurityGroupTotalProps[];
}
interface SecurityGroupEditTotalsError {
  type: typeof SECURITY_GROUPS_EDIT_TOTALS_ERROR;
  payload: string;
  ids: Array<string | undefined>;
}
export type SecurityGroupsActionTypes =
  | SecurityGroupLoading
  | SecurityGroupsLoading
  | SecurityGroupSuccess
  | SecurityGroupsSuccess
  | AddSecurityGroupSuccess
  | DeleteSecurityGroupSuccess
  | SecurityGroupsError
  | SecurityGroupError
  | RouteChangeProps
  | EditSecurityGroupSuccess
  | PeopleReset
  | SecurityGroupPeopleBatchLoading
  | SecurityGroupPeopleBatchSuccess
  | SecurityGroupPeopleBatchError
  | SecurityGroupEditTotalsLoading
  | SecurityGroupEditTotalsSuccess
  | SecurityGroupEditTotalsError;
