import { FunctionComponent, useMemo } from 'react';
import Grid from '@mui/material/Grid';

import JvionToolTip from '../JvionToolTip';
import { PersonProps } from '../../Store/people/types';
import SectionWrapper from '../SectionWrapper';
import SectionHeader from '../SectionHeader';
import SectionPrimaryContent from '../SectionPrimaryContent';
import SectionShowMore from '../SectionShowMore';
// import getUILabels from '../../Utils/UILabels';
import getUILabelsArray from '../../Utils/UILabels/uiLabelsArray';
import { RootStore } from '../../configureStore';
import { useSelector } from 'react-redux';
import { ProductTypeEnum } from '../../Store/patient_groups/types';
import { EncounterProps } from '../../Store/encounters/types';

import useStyles from './styles';

export interface PatientInfoProps {
  patient: PersonProps;
  productType: ProductTypeEnum;
  encounter?: EncounterProps;
}

const PatientInfo: FunctionComponent<PatientInfoProps> = (props: PatientInfoProps) => {
  const { patient, productType, encounter } = props;
  // const [canContact, setCanContact] = useState('Can Contact');
  const classes = useStyles();
  const uiLabels = useSelector((state: RootStore) => state.ui_labels.uiLabels);

  // get priority<string> "1" insurance for the person object
  const insurancesState = useSelector((state: RootStore) => state.insurances);
  const insurance = insurancesState.insurances[0];

  const uiLabelsCollapseLabel: string = 'uiLabelsShowMoreCollapse';

  const tenantUILabelsShowMore = useMemo(() => {
    return getUILabelsArray({
      component_type: 'patient_info',
      section_name: 'show_more',
      product_type: productType,
      tenantUILabels: uiLabels,
    });
  }, [uiLabels, productType]);

  const tenantUILabelsConsistent = useMemo(() => {
    return getUILabelsArray({
      component_type: 'patient_info',
      section_name: 'show_consistent',
      product_type: productType,
      tenantUILabels: uiLabels,
    });
  }, [uiLabels, productType]);

  // This is how you wold get the labels in a way that supports default values
  // const uiLabelsByModel = useSelector((state: RootStore) => state.ui_labels.uiLabelsByModel);
  // const encounterShowConsistantNames = ['provider', 'servicing_facility'];

  // const encounterShowConsistantLabels = getUILabels(encounterShowConsistantNames, {
  //   model_name: 'Encounter',
  //   component_type: 'patient_info',
  //   section_name: 'show_consistent',
  //   tenantUILabels: uiLabelsByModel,
  // });

  // const insuranceLabels = getUILabels(['name'], {
  //   model_name: 'Insurance',
  //   component_type: 'patient_info',
  //   section_name: 'show_consistent',
  //   tenantUILabels: uiLabelsByModel,
  // });

  // const personShowMoreNames = ['home_phone', 'mobile_phone', 'state_province', 'postal_code', 'city', 'address_line_1'];

  // const personShowMoreLabels = getUILabels(personShowMoreNames, {
  //   model_name: 'Person',
  //   component_type: 'patient_info',
  //   section_name: 'show_more',
  //   tenantUILabels: uiLabelsByModel,
  // });

  // const handleChange = (event: any) => {
  //   setCanContact(event.target.value);
  // };

  return (
    <>
      <SectionWrapper collapseLabel={uiLabelsCollapseLabel}>
        <SectionHeader>PATIENT INFORMATION</SectionHeader>
        <SectionPrimaryContent>
          <Grid container item xs={12} className={classes.labelRow1}>
            {tenantUILabelsConsistent.map((tenantUILabel) => (
              <Grid key={tenantUILabel.id} item xs={3} data-testid="patient-info-field">
                {tenantUILabel.child_component && tenantUILabel.child_component === 'ContactStatus' ? (
                  tenantUILabel.formatValue({ Person: patient, Encounter: encounter, Insurance: insurance })
                ) : (
                  <JvionToolTip
                    title={tenantUILabel.label_text}
                    fieldValue={
                      tenantUILabel.formatValue({
                        Person: patient,
                        Encounter: encounter,
                        Insurance: insurance,
                      }) as string
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>

          {tenantUILabelsShowMore && tenantUILabelsShowMore.length && (
            <SectionShowMore>
              {' '}
              <Grid container item xs={12} spacing={2} className={classes.labelRow1}>
                {tenantUILabelsShowMore.map((tenantUILabel) => (
                  <Grid
                    key={tenantUILabel.id}
                    item
                    xs={3}
                    data-testid="patient-info-field"
                    className={classes.showMoreRows}
                  >
                    <JvionToolTip
                      title={tenantUILabel.label_text}
                      fieldValue={
                        tenantUILabel.formatValue({
                          Person: patient,
                          Encounter: encounter,
                          Insurance: insurance,
                        }) as string
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </SectionShowMore>
          )}
        </SectionPrimaryContent>
      </SectionWrapper>
    </>
  );
};

export default PatientInfo;
