import { EventEmitter } from 'events';
import debounce from 'lodash/debounce';

class TimerHelper extends EventEmitter {
  // For Events like API calls
  // Because we do many calls in certain views
  // like when app loads or fetching a Patient Info
  // DO NOT send multiple short updates.
  private deBounced = debounce((msg) => {
    document.dispatchEvent(msg);
  }, 150);

  public dispatchApiCall(): void {
    const evt = new CustomEvent('JVION-API-CALL', { cancelable: true, bubbles: true, detail: { time: Date.now() } });
    this.deBounced(evt);
  }
}

export default new TimerHelper();
