import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  RecommendationLabelsActionTypes,
  RECOMMENDATION_LABELS_REQUESTED,
  RECOMMENDATION_LABELS_SUCCESS,
  RECOMMENDATION_LABELS_ERROR,
  RECOMMENDATION_LABEL_REQUESTED,
  RECOMMENDATION_LABEL_SUCCESS,
  RECOMMENDATION_LABEL_ERROR,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getRecommendationLabels =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<RecommendationLabelsActionTypes>) => {
    dispatch({ type: RECOMMENDATION_LABELS_REQUESTED });
    if (cancelReq) cancelReq.cancel('canceled');
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/recommendation_labels', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('recommendation labels response ----', resp);
        dispatch({
          type: RECOMMENDATION_LABELS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- recommendation labels request cancelled');
        }
        dispatch({ type: RECOMMENDATION_LABELS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getRecommendationLabel =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<RecommendationLabelsActionTypes>) => {
    dispatch({ type: RECOMMENDATION_LABEL_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/recommendation_labels/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('recommendation label response ----', resp);
        dispatch({
          type: RECOMMENDATION_LABEL_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- recommendation labels request cancelled');
        }
        dispatch({ type: RECOMMENDATION_LABEL_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
