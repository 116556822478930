import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  InsurancesActionTypes,
  INSURANCES_REQUESTED,
  INSURANCES_SUCCESS,
  INSURANCES_ERROR,
  RESET_INSURANCES,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const resetInsurances = () => async (dispatch: Dispatch<InsurancesActionTypes>) => {
  // console.log('reset');
  dispatch({ type: RESET_INSURANCES });
};

export const getInsurances =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<InsurancesActionTypes>) => {
    dispatch({ type: INSURANCES_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/insurances', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('insurances response ----', resp);
        dispatch({
          type: INSURANCES_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- insurances request cancelled');
        }
        dispatch({ type: INSURANCES_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
