import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  PatientGroupsActionTypes,
  PATIENT_SEARCH_REQUESTED,
  PATIENT_SEARCH_SUCCESS,
  PATIENT_SEARCH_ERROR,
  CLEAR_PATIENT_SEARCH,
} from './types';
import { cancelReq } from './actions';

const patientSearchReducer = (state: State = initialState, action: PatientGroupsActionTypes): State => {
  switch (action.type) {
    case PATIENT_SEARCH_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroups: action.payload,
      };
    case PATIENT_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        patientGroups: [],
        error: action.payload,
      };
    case CLEAR_PATIENT_SEARCH:
      return {
        ...state,
        loading: false,
        patientGroups: [],
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default patientSearchReducer;
