import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  PeopleActionTypes,
  PEOPLE_REQUESTED,
  PEOPLE_SUCCESS,
  PEOPLE_ERROR,
  PEOPLE_BATCH_REQUESTED,
  PEOPLE_BATCH_SUCCESS,
  CLEAR_PEOPLE,
  PERSON_REQUESTED,
  PERSON_ERROR,
  PERSON_SUCCESS,
  EDIT_CONTACT_STATUS_REQUESTED,
  EDIT_CONTACT_STATUS_SUCCESS,
  EDIT_CONTACT_STATUS_ERROR,
} from './types';
import { cancelReq } from './actions';

const peopleReducer = (state: State = initialState, action: PeopleActionTypes): State => {
  switch (action.type) {
    case PEOPLE_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CLEAR_PEOPLE:
      return {
        ...state,
        loading: false,
        people: [],
        person: null,
      };
    case PEOPLE_BATCH_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PERSON_REQUESTED:
      return {
        ...state,
        person: null,
        loading: true,
        error: undefined,
      };
    case PERSON_SUCCESS:
      return {
        ...state,
        loading: false,
        person: action.payload,
      };
    case PEOPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        people: action.payload,
      };
    case PEOPLE_BATCH_SUCCESS:
      let people;
      if (
        state.people &&
        state.people.length > 0 &&
        state.metadata &&
        state.metadata.page_number !== null &&
        state.metadata.page_number + 1 === action.payload.metadata.page_number
      ) {
        people = [...state.people, ...action.payload.data];
      } else {
        people = action.payload.data;
      }
      return {
        ...state,
        metadata: action.payload.metadata,
        loading: false,
        people: people,
      };
    case PEOPLE_ERROR:
      return {
        ...state,
        loading: false,
        people: [],
        error: action.payload,
      };
    case PERSON_ERROR:
      return {
        ...state,
        loading: false,
        person: null,
        error: action.payload,
      };
    case EDIT_CONTACT_STATUS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case EDIT_CONTACT_STATUS_SUCCESS:
      // Written this way to comply with Typescript typing
      // because person could potentially be undefined
      if (state.person) {
        return {
          ...state,
          loading: false,
          person: {
            ...state.person,
            contact_status: action.payload,
          },
        };
      } else {
        return { ...state, loading: false };
      }
    case EDIT_CONTACT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default peopleReducer;
