import { Box } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { FunctionComponent } from 'react';

import useStyles from './styles';

interface CircularIndeterminateProps extends CircularProgressProps {
  fullView?: boolean;
}

const CircularIndeterminate: FunctionComponent<CircularIndeterminateProps> = (
  props: CircularIndeterminateProps = { fullView: false },
) => {
  const classes = useStyles();
  const { fullView, ...rest } = props;

  return (
    <>
      {fullView ? (
        <Box className={classes.fullView}>
          <CircularProgress color="secondary" {...rest} />
        </Box>
      ) : (
        <CircularProgress color="secondary" {...rest} />
      )}
    </>
  );
};

export default CircularIndeterminate;
