import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  moreInfoGrid: {
    display: 'flex',
  },
  moreInfoWrapper: {
    marginLeft: '27px',
    marginBottom: theme.spacing(1),
  },
  moreInfo: {
    fontSize: '0.6875rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

export default useStyles;
