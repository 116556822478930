import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  CommentProps,
  CommentsActionTypes,
  ADD_COMMENT_SUCCESS,
  COMMENTS_REQUESTED,
  COMMENTS_SUCCESS,
  COMMENTS_ERROR,
  COMMENT_REQUESTED,
  COMMENT_ERROR,
  COMMENT_SUCCESS,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getComment =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<CommentsActionTypes>) => {
    dispatch({ type: COMMENT_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get(`/comments/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('comment response ----', resp);
        dispatch({
          type: COMMENT_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- comment request cancelled');
        }
        dispatch({ type: COMMENT_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getComments =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<CommentsActionTypes>) => {
    dispatch({ type: COMMENTS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/comments', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('comment response ----', resp);
        dispatch({
          type: COMMENTS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- comment request cancelled');
        }
        dispatch({ type: COMMENTS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const createComment = (token: string, commentBody: Partial<CommentProps>) => (dispatch: Dispatch) => {
  dispatch({ type: COMMENT_REQUESTED });
  authorized(token)
    .post('/comments', commentBody)
    .then((resp) => {
      dispatch({ type: ADD_COMMENT_SUCCESS, payload: resp.data as CommentProps });
    })
    .catch((error) => {
      let msg: any;
      if (error.response?.data) {
        msg = error.response.data.details || error.response.data.message;
      }
      // FIXME
      // At times a 500 error could come back with an empty data object or string
      //    from server.  If so, return standard message
      if (msg === undefined || msg === null) msg = error.message;
      dispatch({ type: COMMENT_ERROR, payload: msg });
    });
};
