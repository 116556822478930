import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  TenantSettingsActionTypes,
  TENANT_SETTINGS_REQUESTED,
  TENANT_SETTINGS_SUCCESS,
  TENANT_SETTINGS_ERROR,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getTenantSettings =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<TenantSettingsActionTypes>) => {
    dispatch({ type: TENANT_SETTINGS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/tenant_settings', { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('tenant settings response ----', resp);
        dispatch({
          type: TENANT_SETTINGS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- tenant settings request cancelled');
        }
        dispatch({ type: TENANT_SETTINGS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
