import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  warning: {
    color: theme.palette.error.main,
  },
  icon: {
    fill: (color: { color: string }) => color.color,
    fontSize: '1.15rem',
    marginRight: theme.spacing(1.25),
  },
  name: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  defaultGroupName: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#ffffff',
  },
  containerTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  defaultGroupsContainerTop: {
    backgroundColor: '#3b2c68',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `1.2rem ${theme.spacing(3)}`,
  },
  containerBottom: {
    padding: theme.spacing(3),
  },
  menuButton: {
    padding: 0,
  },
  horizMenu: {
    padding: 'unset',
  },
  disablelinkcss: {
    textDecoration: 'none',
    color: 'inherit',
  },
  progressContainer: {
    minWidth: 50,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    boxShadow: '0px 0px 3px rgba(0,0,0,.25)',
    maxWidth: 220,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  placementRight: {
    '&.MuiTooltip-tooltipArrow .MuiTooltip-arrow': {
      color: theme.palette.common.white,
      width: '0.91rem',
      height: '1.2rem',
      marginLeft: '-0.85rem',
      '&:before': {
        boxShadow: '1px 1px 3px rgba(0,0,0,.15)',
      },
    },
  },
  placementLeft: {
    '&.MuiTooltip-tooltipArrow .MuiTooltip-arrow': {
      color: theme.palette.common.white,
      width: '0.91rem',
      height: '1.2rem',
      marginRight: '-0.85rem',
      '&:before': {
        boxShadow: '1px 1px 3px rgba(0,0,0,.15)',
      },
    },
  },
}));

export default useStyles;
