import { RouteChangeProps } from '../history/reducer';
import { ProductType } from '../products/types';

export interface UILabelProps {
  id: string | number;
  model_name: string;
  column_name: string;
  label_text: string;
  enabled: boolean;
  sort_order?: number;
  view_name?: string | null;
  product_type?: ProductType | null;
  component_type?: string | null;
  section_name?: string | null;
  child_component?: string | null;
  mask_string?: string | null;
  meta?: string | null;
  regex_string?: string | null;
  updated_dttm: number;
}

export interface UILabelDictionary {
  [column: string]: UILabelProps[];
}

export interface UILabelsByModel {
  [model: string]: UILabelDictionary;
}

export type State = {
  uiLabels: UILabelProps[];
  uiLabelsByModel: UILabelsByModel;
  loading: boolean;
  error?: string;
};

export const initialState: State = {
  uiLabels: [],
  uiLabelsByModel: {},
  loading: false,
  error: undefined,
};

export const UI_LABELS_REQUESTED = 'UI_LABELS_REQUESTED';
export const UI_LABELS_SUCCESS = 'UI_LABELS_SUCCESS';
export const UI_LABELS_ERROR = 'UI_LABELS_ERROR';

interface UILabelsLoading {
  type: typeof UI_LABELS_REQUESTED;
}
interface UILabelsSuccess {
  type: typeof UI_LABELS_SUCCESS;
  payload: UILabelProps[];
}
interface UILabelsError {
  type: typeof UI_LABELS_ERROR;
  payload: string;
}
export type UILabelsActionTypes = UILabelsLoading | UILabelsSuccess | UILabelsError | RouteChangeProps;
