import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// import history from './Utils/history';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './configureStore';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './Utils/constants';

import TenantSwitchingProvider from './HOC/TenantSwitching/tenant-switching-provider';

//
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`${AUTH0_DOMAIN}`}
      clientId={`${AUTH0_CLIENT_ID}`}
      redirectUri={window.location.origin}
      audience="jvion-api"
      onRedirectCallback={(appState) => {
        history.replace(appState?.returnTo || window.location.pathname);
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TenantSwitchingProvider>
            <Provider store={store}>
              <App history={history} />
            </Provider>
          </TenantSwitchingProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
