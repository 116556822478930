import { FunctionComponent } from 'react';
import UserFormButton from '../../Containers/UserFormButton';
import ActionAlerts from '../../Components/AlertBox';
import PageHeader from '../../Components/PageHeader';

import useStyles from './styles';
import { useState } from 'react';

const UsersViewTopBar: FunctionComponent = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    showSuccessMessage: false,
    isAddUserModalOpen: false,
  });
  const successAddUserMessage = 'User Added Successfully!';

  const handleCloseAddUser = () => {
    setState((prevState) => {
      return { ...prevState, isAddUserModalOpen: false };
    });
  };

  const setSuccessMessageTrigger = () => {
    setState((prevState) => {
      return { ...prevState, showSuccessMessage: true };
    });
  };
  const handleSuccessResp = () => {
    // console.log('resp.data.details', alertDialogProps);
    handleCloseAddUser();
    setSuccessMessageTrigger();
  };

  const successAlertBoxClose = () => {
    setState((prevState) => {
      return { ...prevState, showSuccessMessage: false };
    });
  };
  return (
    <>
      <PageHeader headText="Users" />
      <div className={classes.createUserBtnWrapper}>
        <UserFormButton variant={'create'} handleSuccessResp={handleSuccessResp} />
      </div>
      {state.showSuccessMessage && (
        <ActionAlerts
          alertSeverity="success"
          alertMessage={successAddUserMessage}
          alertBoxCloseTrigger={successAlertBoxClose}
        />
      )}
    </>
  );
};

export default UsersViewTopBar;
