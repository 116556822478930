import React, { FunctionComponent } from 'react';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import Collapse from '@mui/material/Collapse';

import { useSectionWrapper } from '../SectionWrapper/SectionWrapperContext';
import useStyles from './styles';

export interface SectionShowMoreI {
  children: React.ReactNode;
  showPreviousTag?: Boolean;
}
const SectionShowMore: FunctionComponent<SectionShowMoreI> = (props: SectionShowMoreI) => {
  const { children, showPreviousTag } = props;
  const { showMore, toggleShowMore } = useSectionWrapper();
  const classes = useStyles();

  return (
    <>
      <Collapse in={showMore}>{children}</Collapse>
      <Grid item className={classes.moreInfoGrid}>
        <div className={classes.moreInfoWrapper}>
          <Link
            className={classes.moreInfo}
            onClick={(e: any) => {
              e.preventDefault();
              toggleShowMore();
            }}
            data-testid="show-more"
            underline="none"
            href="#"
            color="secondary"
          >
            {showPreviousTag ? (showMore ? 'View Recent' : 'View Previous') : showMore ? 'Show Less' : 'Show More'}
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default SectionShowMore;
