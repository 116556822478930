import { RouteChangeProps } from '../history/reducer';
import { RoleProps } from '../roles/types';

export type WhoamiProps = {
  auth0_connection_id: string;
  auth0_id: string;
  blocked: boolean;
  email: string;
  family_name: string;
  given_name: string;
  id: string;
  last_ip: string;
  last_login: string;
  logins_count: number;
  picture: string;
  updated_dttm: number;
  roles: RoleProps[];
};

export type State = {
  whoami?: WhoamiProps;
  loading: boolean;
  error: string | undefined;
  forcedLogout: boolean;
  menuOpen: boolean;
};

export const initialState: State = {
  whoami: undefined,
  loading: false,
  error: undefined,
  forcedLogout: false,
  menuOpen: true,
};

export const WHOAMI_REQUESTED = 'WHOAMI_REQUESTED';
export const WHOAMI_SUCCESS = 'WHOAMI_SUCCESS';
export const WHOAMI_ERROR = 'WHOAMI_ERROR';
export const LOGOUT = 'LOGOUT';
export const FORCED_LOGOUT = 'FORCED_LOGOUT';
export const MENU_OPEN = 'MENU_OPEN';

interface WhoamiLoading {
  type: typeof WHOAMI_REQUESTED;
}
interface WhoamiSuccess {
  type: typeof WHOAMI_SUCCESS;
  payload: WhoamiProps;
}
interface WhoamiError {
  type: typeof WHOAMI_ERROR;
  payload: string;
}
export interface Logout {
  type: typeof LOGOUT;
}
export interface ForcedLogout {
  type: typeof FORCED_LOGOUT;
}
export interface MenuOpen {
  type: typeof MENU_OPEN;
  payload: boolean;
}
export type WhoamiActionTypes =
  | WhoamiLoading
  | WhoamiSuccess
  | WhoamiError
  | RouteChangeProps
  | Logout
  | ForcedLogout
  | MenuOpen;
