import { useState, useEffect, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WarningOutlined from '@mui/icons-material/WarningTwoTone';

// Styling imports
import { ThemeProvider } from '@mui/styles';
import theme from './../../theme';
import useStyles from './styles';

// This component is only rendered when User has logged in.
// When user has logged out or been forced to logout,
// Then this component is no longer in the view.

interface IdleModalProps {
  reset: () => void;
  getLastActiveTime: () => number;
  idleTriggered: number | undefined;
  setIdleTriggered: (val: number | undefined) => void;
  CountDownTimeInSeconds: number;
  TimeoutTimerInMilli: number;
}
const IdleModal = (props: IdleModalProps) => {
  const classes = useStyles();
  const { logout } = useAuth0();
  const { reset, getLastActiveTime, idleTriggered, setIdleTriggered, CountDownTimeInSeconds, TimeoutTimerInMilli } =
    props;

  const [open, setOpen] = useState<boolean>(false);
  const [timerObject, setTimerObject] = useState({
    active: false,
    time: CountDownTimeInSeconds,
    lastActive: 0,
  });

  // We use this so after redirect, we don't try to update state
  // after a setTimeout trigger.
  // Tests where complaining.
  let isMounted = useRef(true);

  // Because App renders this ONLY when user is logged in
  // We only have to worry about if timer has been reached.

  // This would normally trigger when computer wakes up from sleep mode.
  const hasPassedAllowedTime = useCallback(() => {
    return getLastActiveTime() + TimeoutTimerInMilli < +new Date();
  }, [TimeoutTimerInMilli, getLastActiveTime]);

  // Starts the logout process
  const handleLogout = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  useEffect(() => {
    if (idleTriggered !== undefined && open === false) {
      // In the case that computer went to sleep
      // And wakes up after the allowed time,
      // then just log them out vs starting the timer.
      // Example
      //  User has 10 min left, computer goes to sleep for 30 min,
      //  then wakes up, if this check is not here, it would start the
      //  60 sec timer extending allowed time.
      if (hasPassedAllowedTime()) {
        return handleLogout();
      }

      setTimerObject({
        active: true,
        time: CountDownTimeInSeconds,
        lastActive: getLastActiveTime(),
      });
      setOpen(true);
    }
  }, [CountDownTimeInSeconds, getLastActiveTime, handleLogout, hasPassedAllowedTime, idleTriggered, open, timerObject]);

  // This function is called for multiple reasons.
  // It's primary function is to reset the timer.
  // Times it is called:
  //  - When a API request with a token is used
  //  - When the Timeout modal is open, and user clicks CONTINUE
  //  - When a seperate tab has this modal open (user has multiple tabs open, and clicks CONTINUE in 1 of them)
  //     then it closes the tab on other when the useIdleTimer > onActive is triggered.
  const triggerReset = useCallback(() => {
    if (hasPassedAllowedTime()) {
      return handleLogout();
    }
    reset();
    if (idleTriggered !== undefined) setIdleTriggered(undefined);
    // If modal open, then close it.
    if (open === true) setOpen(false);

    // If countdown timer had already started
    // Then reset it.
    if (timerObject.time !== CountDownTimeInSeconds || timerObject.active) {
      // MUST use CountDownTimeInSeconds
      setTimerObject({
        active: false,
        time: CountDownTimeInSeconds,
        lastActive: 0,
      });
    }
  }, [
    CountDownTimeInSeconds,
    handleLogout,
    hasPassedAllowedTime,
    idleTriggered,
    open,
    reset,
    setIdleTriggered,
    timerObject,
  ]);

  // Every time an API call is made with a token, this function will trigger.
  // See Utils > AxiosInstance
  const apiRequested = useCallback(
    (event: CustomEventInit) => {
      triggerReset();
    },
    [triggerReset],
  );

  // When the component loads
  // Attach the listener
  useEffect(() => {
    document.addEventListener('JVION-API-CALL', apiRequested);

    return () => {
      isMounted.current = false;
      document.removeEventListener('JVION-API-CALL', apiRequested);
    };
    // We are disabling below
    // Because adding the "apiRequested" function on this useEffect
    // will cause it to remove and add again when the countdown timers has started.
    // So use it as a basic "onComponentWillMount and onComponentWillUNMOUNT"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This handles the countdown timer
  useEffect(() => {
    // Has the countdown started
    if (timerObject.active === true) {
      // Came out of sleep mode while counter was happening.
      if (hasPassedAllowedTime()) {
        return handleLogout();
      }
      // If user has multiple tabs open
      // And clicks Continue on another Tab
      // Library updates it's internal timing
      // And we compare the last Active with the latest Active
      // If it is newer, then reset the countdown and close the modal.
      if (getLastActiveTime() > timerObject.lastActive) {
        triggerReset();
      }
      if (timerObject.time > 0) {
        // If greater than 0, then keep counting
        // Since it's based on seconds, -1 second from remaining
        setTimeout(() => {
          if (isMounted.current) {
            setTimerObject((prev) => {
              return { ...prev, time: timerObject.time - 1 };
            });
          }
        }, 1000);
      } else {
        // If countdown 0 or less, log them out
        handleLogout();
      }
    }
  }, [timerObject, handleLogout, getLastActiveTime, triggerReset, hasPassedAllowedTime]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={open}
          onClose={handleLogout}
          aria-labelledby="responsive-dialog-title"
          data-testid="idle-modal"
        >
          <DialogContent>
            <Grid container>
              <Grid item>
                <WarningOutlined className={classes.warningIcon} />
              </Grid>
              <Grid item>
                <Typography>Your Session is about to expire.</Typography>
                <Typography data-testid="countdown-sentence">
                  You will be logged out in {timerObject.time} seconds.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button data-testid="idle-modal-logout" onClick={handleLogout} variant="outlined">
              Logout
            </Button>
            <Button data-testid="idle-modal-continue" onClick={triggerReset} color="primary" variant="contained">
              Continue Session
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default IdleModal;
