import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Modal from './Modal';

// This is how long the Modal will show for in Seconds.
// We use 61, because requirement states that the first number
// on the counter when it renders SHOULD be 60, not 59 seconds remaining.
const CountDownTimeInSeconds = 61;
// Default total time before user should be logged out.
const TimeoutInMinutes = 15;
// Turn total time out from minuetues to milli seconds.
const TimeoutTimerInMilli = TimeoutInMinutes * 60 * 1000;
// Turn Countdown timer from seconds to milli seconds.
const CountDownTimeInMilli = CountDownTimeInSeconds * 1000;
// Actual idle time allowed between last time user pinged API
// and when modal shows is Total Idle time - Modal countdown.
const TimeoutTimer = TimeoutTimerInMilli - CountDownTimeInMilli;

// This component is only rendered when User has logged in.
// When user has logged out or been forced to logout,
// Then this component is no longer in the view.
const IdleModal = () => {
  const [idleTriggered, setIdleTriggered] = useState<number | undefined>(undefined);

  // Because App renders this ONLY when user is logged in
  // We only have to worry about if timer has been reached.
  const { reset, getLastActiveTime } = useIdleTimer({
    events: [],
    timeout: TimeoutTimer,
    onIdle: () => {
      setIdleTriggered(+new Date());
    },
    // onActive: handleOnActive,
    debounce: 300,
    // startManually: false,
    // startOnMount: true,
    crossTab: {
      emitOnAllTabs: true,
      type: 'localStorage',
    },
  });

  return (
    <div>
      <Modal
        reset={reset}
        getLastActiveTime={getLastActiveTime}
        CountDownTimeInSeconds={CountDownTimeInSeconds}
        TimeoutTimerInMilli={TimeoutTimerInMilli}
        idleTriggered={idleTriggered}
        setIdleTriggered={setIdleTriggered}
      />
    </div>
  );
};

export default IdleModal;
