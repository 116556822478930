import { FunctionComponent } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const SidePadding = '5px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `calc(100% - ${SidePadding} - ${SidePadding})`,
  },
  inputWrapper: {
    marginTop: '1rem',
  },
  customInput: {
    border: `1px solid ${theme.palette.accent?.border}`,
    borderRadius: theme.shape.borderRadius,
    padding: `6px ${SidePadding} 7px`,
    '&:before': {
      borderBottom: `none`,
    },
  },
  borderWithError: {
    borderColor: theme.palette.error.main,
  },
  jvionCustomLabel: {
    width: '100%',
    // textTransform: 'uppercase',
    '&.MuiInputLabel-shrink': {
      transform: 'scale(1)',
      fontSize: '10px',
    },
    '& > span': {
      visibility: 'hidden',
      float: 'right',
      position: 'relative',
      fontWeight: 'normal',
      '&:after': {
        content: '" required"',
        visibility: 'visible',
        color: '#8598A9',
        textTransform: 'lowercase',
      },
    },
  },
}));

const JvionTextField: FunctionComponent<TextFieldProps> = (props: TextFieldProps) => {
  const classes = useStyles();
  let wrapperClasses = [classes.customInput];
  if (props.error !== undefined && props.error !== false) wrapperClasses.push(classes.borderWithError);
  // We need to extract the follow props and append to them.
  // If props does not exist, create them.  Remove the undifined problem
  // If the prop exists, and has information, then append or assign to the prop.
  let { inputProps } = props;
  if (inputProps === undefined) inputProps = {};
  if (inputProps.className !== undefined) wrapperClasses.push(inputProps.className);
  inputProps.className = wrapperClasses.join(' ');

  return (
    <div className={classes.root}>
      <TextField
        className={classes.inputWrapper}
        InputProps={{ disableUnderline: true }}
        inputProps={{ ...inputProps }}
        InputLabelProps={{ shrink: true, className: classes.jvionCustomLabel }}
        {...props}
        variant="standard"
      />
    </div>
  );
};

export default JvionTextField;
