import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  RiskFactorsActionTypes,
  RISK_FACTORS_REQUESTED,
  RISK_FACTORS_SUCCESS,
  RISK_FACTORS_ERROR,
} from './types';
import { cancelReq } from './actions';

const riskFactorsReducer = (state: State = initialState, action: RiskFactorsActionTypes): State => {
  switch (action.type) {
    case RISK_FACTORS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RISK_FACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskFactors: action.payload,
      };
    case RISK_FACTORS_ERROR:
      return {
        ...state,
        loading: false,
        riskFactors: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default riskFactorsReducer;
