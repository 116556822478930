import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  topSection: {
    display: 'flex',
  },
  topBarItems: {},
  alignCenter: {
    alignSelf: 'center',
  },
  createUserBtnWrapper: {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    textAlign: 'right',
  },
}));
export default useStyles;
