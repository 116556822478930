import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  EncountersActionTypes,
  ENCOUNTERS_REQUESTED,
  ENCOUNTERS_SUCCESS,
  ENCOUNTERS_ERROR,
  RESET_ENCOUNTERS,
} from './types';
import { cancelReq } from './actions';

const encountersReducer = (state: State = initialState, action: EncountersActionTypes): State => {
  switch (action.type) {
    case ENCOUNTERS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ENCOUNTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        encounters: action.payload,
      };
    case ENCOUNTERS_ERROR:
      return {
        ...state,
        loading: false,
        encounters: [],
        error: action.payload,
      };
    case RESET_ENCOUNTERS:
      return initialState;
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default encountersReducer;
