import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  EncountersActionTypes,
  ENCOUNTERS_REQUESTED,
  ENCOUNTERS_SUCCESS,
  ENCOUNTERS_ERROR,
  RESET_ENCOUNTERS,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const resetEncounters = () => async (dispatch: Dispatch<EncountersActionTypes>) => {
  // console.log('reset');
  dispatch({ type: RESET_ENCOUNTERS });
};

export const getEncounters =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<EncountersActionTypes>) => {
    dispatch({ type: ENCOUNTERS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/encounters', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('encounters response ----', resp);
        dispatch({
          type: ENCOUNTERS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- encounters request cancelled');
        }
        dispatch({ type: ENCOUNTERS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
