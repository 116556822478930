import PatientGroups from '../Views/PatientGroups';
import Maps from '../Views/Maps';
import MyGroups from '../Views/MyGroups';
import { JvionRouteProps } from '.';

export enum patientsPaths {
  PatientGroups = '/patient-groups/:id',
  Maps = '/maps',
  MyGroups = '/',
}

const patientsRoutes: JvionRouteProps[] = [
  {
    path: patientsPaths.MyGroups,
    component: MyGroups,
  },
  {
    path: patientsPaths.PatientGroups,
    component: PatientGroups,
  },
  {
    path: patientsPaths.Maps,
    component: Maps,
  },
];

export default patientsRoutes;
