import { FunctionComponent, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { PersonProps } from '../../Store/people/types';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import { RootStore } from '../../configureStore';
import { getPatientGroupPeopleBatch, getPatientGroupsEditTotals } from '../../Store/patient_groups/actions';
import { getSecurityGroupPeopleBatch } from '../../Store/security_groups/actions';
import { dateFormatter } from '../../../src/Components/DateTimeRender';

import useStyles from './styles';
import { Grid } from '@mui/material';

interface GroupEditFiltersPatientTableProps {
  group: PatientGroupProps | SecurityGroupProps;
  groupType: 'patient' | 'security';
}

const GroupEditFiltersPatientTable: FunctionComponent<GroupEditFiltersPatientTableProps> = (
  props: GroupEditFiltersPatientTableProps,
) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const patientGroupsTotal = useSelector((state: RootStore) => state.patient_groups.patientGroupsTotal);
  const patientGroupPeople = useSelector((state: RootStore) => state.patient_groups.people);
  const securityGroupPeople = useSelector((state: RootStore) => state.security_groups.people);
  const securityGroupsTotal = useSelector((state: RootStore) => state.security_groups.securityGroupsTotal);
  const [groupNumerator, setGroupNumerator] = useState<number | null>();
  const [groupDenominator, setGroupDenominator] = useState<number | null>();
  const [securityGroupDenominator, setSecurityGroupDenominator] = useState<number | null>();
  const { group, groupType } = props;

  useEffect(() => {
    if (groupType === 'patient') {
      getAccessTokenSilently().then((token) => {
        if (group.id) {
          dispatch(
            getPatientGroupPeopleBatch(token, group.id, {
              params: {
                sort_by: 'last_name(asc)',
                only_recent: true,
              },
            }),
          );
          dispatch(getPatientGroupsEditTotals(token, [group.id]));
        }
      });
    } else if (groupType === 'security') {
      getAccessTokenSilently().then((token) => {
        if (group.id) {
          dispatch(
            getSecurityGroupPeopleBatch(token, group.id, {
              params: {
                sort_by: 'last_name(asc)',
                only_recent: true,
              },
            }),
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, dispatch, groupType, group]);

  useEffect(() => {
    let patientGroupTotal = patientGroupsTotal.find((pgt) => {
      return pgt.id === group.id ? pgt : undefined;
    });
    setGroupNumerator(patientGroupTotal?.numerator);
    setGroupDenominator(patientGroupTotal?.denominator);
  }, [patientGroupsTotal, group]);

  useEffect(() => {
    let securityGroupTotal = securityGroupsTotal.find((pgt) => {
      return pgt.id === group.id ? pgt : undefined;
    });
    setSecurityGroupDenominator(securityGroupTotal?.denominator);
  }, [securityGroupsTotal, group]);

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.header}>
        <Typography variant="h2">Patient Preview</Typography>
        <Grid item>
          {(patientGroupPeople.metadata?.total_records !== undefined ||
            securityGroupPeople.metadata?.total_records !== undefined) && (
            <Grid container alignItems="baseline">
              <Typography variant="h4" className={classes.filteredPatients}>
                {groupType === 'patient'
                  ? `${groupNumerator !== undefined ? groupNumerator : 'U/K'}`
                  : `${
                      securityGroupPeople.metadata?.total_records !== undefined
                        ? securityGroupPeople.metadata.total_records
                        : 'U/K'
                    }`}
              </Typography>
              <Typography variant="subtitle1">
                {groupType === 'patient'
                  ? `out of ${groupDenominator} total`
                  : `out of ${securityGroupDenominator} total`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <TableContainer className={classes.tableContainer} data-testid="patient-table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>PATIENT</TableCell>
              <TableCell className={classes.dobHeader}>DOB</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupType === 'patient' ? (
              patientGroupPeople.people.length > 0 ? (
                patientGroupPeople.people.map((person: PersonProps) => (
                  <TableRow key={person.id} data-testid="patient-row">
                    <TableCell>
                      <Typography className={classes.patientName}>
                        {`${person.last_name}, ${person.first_name}`}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.dob}>{dateFormatter({ date: person.date_of_birth })}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow data-testid="no-patients-row">
                  <TableCell colSpan={2}>No patients meet the criteria.</TableCell>
                </TableRow>
              )
            ) : securityGroupPeople.people.length > 0 ? (
              securityGroupPeople.people.map((person: PersonProps) => (
                <TableRow key={person.id} data-testid="patient-row">
                  <TableCell>
                    <Typography className={classes.patientName}>
                      {`${person.last_name}, ${person.first_name}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.dob}>{dateFormatter({ date: person.date_of_birth })}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow data-testid="no-patients-row">
                <TableCell colSpan={2}>No patients meet the criteria.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default GroupEditFiltersPatientTable;
