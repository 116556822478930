import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CoolGrey9C } from '../../theme';

// other colors in design. How are these chosen? (Teal, Blue)

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    '& .CircularProgressbar-path': {
      stroke: (color: { color: string }) => color.color,
    },
    '& .CircularProgressbar-text': {
      fill: CoolGrey9C,
      fontSize: '0.85rem',
      fontWeight: 'bold',
    },
    '& .CircularProgressbar-value': {
      fill: (color: { color: string }) => color.color,
      fontSize: '1.7rem',
      letterSpacing: '-0.5px',
      fontWeight: 'normal',
    },
  },
}));

export default useStyles;
