import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CoolGrey2C } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(1),
    height: theme.spacing(3),
  },
  filterByHeader: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  filterByContainer: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${CoolGrey2C}`,
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  noFindWrapper: { color: theme.palette.error.main, marginBottom: theme.spacing(2) },
  noFindTitle: {
    fontWeight: 'bold',
  },
  noFindText: {
    fontStyle: 'italic',
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 5 rgba(0,0,0,0.05)',
    border: `1px solid ${CoolGrey2C}`,
  },
  contentRight: {
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  filteredPatients: {
    fontSize: '1.125rem',
    marginRight: theme.spacing(0.5),
  },
  dobHeader: {},
  patientName: {},
  dob: {},
}));

export default useStyles;
