import { FunctionComponent, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InProgressIcon from '../../Images/Icons/inProgressIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SvgIcon from '@mui/material/SvgIcon';
import { useSelector } from 'react-redux';

import { DarkTeal, CoolGrey9C, secondQuartile, LightBlue, LightPurple } from '../../theme';
import { RootStore } from '../../configureStore';
import getUILabelsArray from '../../Utils/UILabels/uiLabelsArray';

import useStyles from './styles';

interface RecommnedationStatusDropdownProps {
  selectedStatus: string | null;
  onChangeStatus: (newStatus: string) => void;
}

const RecommnedationStatusDropdown: FunctionComponent<RecommnedationStatusDropdownProps> = (
  props: RecommnedationStatusDropdownProps,
) => {
  const classes = useStyles();
  const { selectedStatus, onChangeStatus } = props;
  const [openStatusDropdown, setOpenStatusDropdown] = useState<boolean>(false);
  const uiLabels = useSelector((state: RootStore) => state.ui_labels.uiLabels);

  const tenantUILabels = getUILabelsArray({
    model_name: 'Recommendation',
    column_name: 'status',
    component_type: 'RecommendatonsStatusDropDown',
    section_name: 'Options',
    tenantUILabels: uiLabels,
  });

  const handleOpenStatusDropdown = () => setOpenStatusDropdown(true);
  const handleCloseStatusDropdown = () => setOpenStatusDropdown(false);
  const handleChangeStatusDropdown = (event: SelectChangeEvent<unknown>) =>
    onChangeStatus(event.target.value as string);

  const options: { [key: string]: { color: string; Icon: typeof SvgIcon } } = {
    'To do': {
      color: secondQuartile,
      Icon: HourglassEmptyIcon,
    },
    'In progress': {
      color: LightBlue,
      Icon: InProgressIcon,
    },
    Completed: {
      color: DarkTeal,
      Icon: CheckCircleOutlineIcon,
    },
    'Patient declined': {
      color: LightPurple,
      Icon: PanToolIcon,
    },
    'Not applicable': {
      color: CoolGrey9C,
      Icon: NotInterestedIcon,
    },
  };

  const getStatusLabel = (status: string | null, icon: boolean = true) => {
    const defaultStatus =
      tenantUILabels.length > 0 ? (status === null ? tenantUILabels[0].label_text : status) : undefined;
    if (defaultStatus && options[defaultStatus]) {
      const { color, Icon } = options[defaultStatus];
      return (
        <div className={classes.statusLabelContainer} style={{ color }}>
          {icon && <Icon />}
          <span className={classes.statusLabel}>{defaultStatus}</span>
        </div>
      );
    } else {
      return <>SETUP REQUIRED</>;
    }
  };

  return (
    <>
      <Button
        className={classes.selectOptions}
        onClick={handleOpenStatusDropdown}
        data-testid="recommendation-status-button"
      >
        {getStatusLabel(selectedStatus)}
      </Button>
      <FormControl className={classes.statusDropdown}>
        <Select
          open={openStatusDropdown}
          onClose={handleCloseStatusDropdown}
          onOpen={handleOpenStatusDropdown}
          value={selectedStatus}
          onChange={handleChangeStatusDropdown}
          inputProps={{
            'data-testid': 'recommendation-status',
          }}
        >
          {tenantUILabels.map((uiLabel) => (
            <MenuItem
              key={uiLabel.label_text}
              value={uiLabel.label_text}
              data-testid={`recommendation-status-${uiLabel.label_text}`}
            >
              {getStatusLabel(uiLabel.label_text, false)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default RecommnedationStatusDropdown;
