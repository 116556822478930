import { FunctionComponent } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { UserData } from '../../Store/users/types';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../configureStore';

import useStyles from '../GroupEditFilters/styles';

interface SelectedUsersTableProps {
  selectedUsers: UserData[];
  handleRemoveUsers: (users: UserData[]) => void;
  groups: PatientGroupProps[] | SecurityGroupProps[];
  groupType: 'patient' | 'security';
}

const SelectedUsersTable: FunctionComponent<SelectedUsersTableProps> = (props: SelectedUsersTableProps) => {
  const classes = useStyles();
  const { selectedUsers, handleRemoveUsers, groupType, groups } = props;
  const rolesState = useSelector((state: RootStore) => state.roles.roles);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>{groupType === 'patient' ? 'Patient Groups' : 'Security Groups'}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedUsers.length > 0 &&
            selectedUsers.map((user) => (
              <TableRow key={user.id} data-testid="user-row">
                <TableCell>{`${user.family_name}, ${user.given_name}`}</TableCell>
                <TableCell data-testid="user-roles">
                  {rolesState &&
                    user.roles &&
                    user.roles
                      .filter((id) => rolesState.some((role) => role.id === id))
                      .map((id) => rolesState.filter((role) => role.id === id)[0].name)
                      .join(', ')}
                </TableCell>
                <TableCell data-testid="user-groups">
                  {groupType === 'patient'
                    ? user.patient_groups &&
                      user.patient_groups
                        .filter((id) => groups.some((group) => group.id === id))
                        .map((id) => groups.filter((group) => group.id === id)[0].name)
                        .join(', ')
                    : user.security_groups &&
                      user.security_groups
                        .filter((id) => groups.some((group) => group.id === id))
                        .map((id) => groups.filter((group) => group.id === id)[0].name)
                        .join(', ')}
                </TableCell>
                <TableCell>
                  <Button data-testid="remove-user" onClick={() => handleRemoveUsers([user])} color="primary">
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedUsersTable;
