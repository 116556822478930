import { WhoamiProps } from '../Store/whoami/types';
import { RoleNames } from '../Store/roles/types';

// Give a user with roles
// See if the user has a role with a specific name.
export const hasPermissionFor = (user: WhoamiProps, permission: RoleNames) => {
  // First check if user has roles (a user can exist with no role)
  // Then check if permission being asked is 1 of the role names.
  return user.roles.length && user.roles.map((r) => r.name).indexOf(permission) > -1 ? true : false;
};
