import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';
import { PersonProps } from '../../Store/people/types';

interface ResultsProps {
  groupId: string;
  name: string;
  patients: PersonProps[];
  loading: boolean;
  closeDrawer: (someBool: boolean) => void;
}

const Results: FunctionComponent<ResultsProps> = (props: ResultsProps) => {
  const { groupId, name, patients, loading, closeDrawer } = props;

  const classes = useStyles();

  if (!loading) {
    return (
      <div>
        {patients.length > 0 && (
          <u>
            <Typography className={classes.patientCount}>{name}</Typography>
          </u>
        )}
        {
          <div className={classes.resultsContent}>
            {patients &&
              patients.map((patient, i) => (
                <Grid
                  container
                  className={classes.individualResult}
                  direction="row"
                  alignItems="center"
                  key={`patientid-${patient.id}-${i}-${groupId}`}
                  justifyContent="space-between"
                >
                  <Grid item md={6}>
                    <Link
                      to={{ pathname: `/patient-groups/${groupId}`, search: `?patient-id=${patient.id}` }}
                      onClick={() => {
                        closeDrawer(false);
                      }}
                    >
                      <Typography>
                        <strong>{`${patient.last_name}, ${patient.first_name}`}</strong>
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item md={6}>
                    <Typography align="right">{patient.date_of_birth}</Typography>
                  </Grid>
                </Grid>
              ))}
          </div>
        }
      </div>
    );
  } else {
    return (
      <div>
        <u>
          <Typography className={classes.patientCount}>{name}</Typography>
        </u>
        {Array(3)
          .fill('')
          .map((number, index) => (
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="flex-start"
              justifyContent="space-between"
              className={classes.skeletonLoader}
              key={`skeletonloader-${index}-${Math.floor(Math.random() * 500)}`}
            >
              <Grid item xs={6}>
                <Skeleton variant="text" width="50" height="5" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="text" width="50" height="5" />
              </Grid>
            </Grid>
          ))}
      </div>
    );
  }
};

export default Results;
