import React, { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 0,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

export default function CustomizedInputBase(props: any) {
  const classes = useStyles();
  const placeHolder = props.placeHolder || 'Search';
  const [inputText, setInputText] = useState('');
  const { updateSearchValue } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateSearchValue(inputText), [inputText]);
  const updateInputText = (event: any) => {
    // setAnchorEl(event.currentTarget);
    setInputText(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="search" size="large">
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={placeHolder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={updateInputText}
      />
    </Paper>
  );
}
