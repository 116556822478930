import { RouteChangeProps } from '../history/reducer';
import { Modify } from '../Utils/types';
import { MetadataProps } from '../Utils/types';
import { PersonProps } from '../people/types';

export interface PatientAndSecurityGroupFilter {
  model?: string;
  field: string;
  op: string;
  value?: string | Array<string>;
}

export interface PatientAndSecurityGroupFilters {
  filters: PatientAndSecurityGroupFilter[];
}

export type ProductTypeEnum = 'INPATIENT' | 'COMMUNITY' | undefined;

export interface editTotalsBodyProps {
  patient_groups: string[];
}

export interface PatientGroupTotalProps {
  id: string;
  numerator: number | null;
  denominator: number | null;
}

export interface PatientGroupProps {
  id: string;
  description: string;
  enabled: boolean;
  name: string;
  patients: number;
  users?: string[];
  group_filters?: PatientAndSecurityGroupFilters;
  updated_dttm?: number;
  is_default_group: boolean;
  private_group_auth0_id?: string | null;
  product_type: ProductTypeEnum;
}

export interface PatientGroupWithPatientsProps {
  id: string;
  name: string;
  patients?: PersonProps[];
}

export interface OrderedPatientGroup
  extends Modify<
    PatientGroupProps,
    {
      users: number;
    }
  > {}

export interface PatientGroupPeopleBatchResponseProps {
  data: PersonProps[];
  metadata: MetadataProps;
}

export type State = {
  patientGroups: PatientGroupProps[];
  patientGroupsTotal: PatientGroupTotalProps[];
  patientGroup?: PatientGroupProps;
  loading: boolean;
  error?: string;
  people: {
    metadata?: MetadataProps;
    people: PersonProps[];
  };
  group_filters?: object;
};

export const initialState: State = {
  patientGroups: [],
  patientGroupsTotal: [],
  patientGroup: undefined,
  people: {
    people: [],
  },
  loading: false,
  error: undefined,
};

export const PATIENT_GROUPS_REQUESTED = 'PATIENT_GROUPS_REQUESTED';
export const PATIENT_GROUPS_SUCCESS = 'PATIENT_GROUPS_SUCCESS';
export const PATIENT_GROUPS_ERROR = 'PATIENT_GROUPS_ERROR';
export const PATIENT_GROUP_REQUESTED = 'PATIENT_GROUP_REQUESTED';
export const PATIENT_GROUP_SUCCESS = 'PATIENT_GROUP_SUCCESS';
export const PATIENT_GROUP_ERROR = 'PATIENT_GROUP_ERROR';
export const ADD_PATIENT_GROUP_SUCCESS = 'ADD_PATIENT_GROUP_SUCCESS';
export const EDIT_PATIENT_GROUP_SUCCESS = 'EDIT_PATIENT_GROUP_SUCCESS';
export const PATIENT_GROUP_PEOPLE_BATCH_REQUESTED = 'PATIENT_GROUP_PEOPLE_BATCH_REQUESTED';
export const PATIENT_GROUP_PEOPLE_BATCH_SUCCESS = 'PATIENT_GROUP_PEOPLE_BATCH_SUCCESS';
export const PATIENT_GROUP_PEOPLE_BATCH_ERROR = 'PATIENT_GROUP_PEOPLE_BATCH_ERROR';
export const PATIENT_GROUP_PEOPLE_COUNT_REQUESTED = 'PATIENT_GROUP_PEOPLE_COUNT_REQUESTED';
export const PATIENT_GROUP_PEOPLE_COUNT_SUCCESS = 'PATIENT_GROUP_PEOPLE_COUNT_SUCCESS';
export const PATIENT_GROUP_PEOPLE_COUNT_ERROR = 'PATIENT_GROUP_PEOPLE_COUNT_ERROR';
export const DELETE_PATIENT_GROUP_SUCCESS = 'DELETE_PATIENT_GROUP_SUCCESS';
export const PATIENT_GROUP_PEOPLE_RESET = 'PATIENT_GROUP_PEOPLE_RESET';
export const PATIENT_GROUPS_EDIT_TOTALS_REQUESTED = 'PATIENT_GROUPS_EDIT_TOTALS_REQUESTED';
export const PATIENT_GROUPS_EDIT_TOTALS_SUCCESS = 'PATIENT_GROUPS_EDIT_TOTALS_SUCCESS';
export const PATIENT_GROUPS_EDIT_TOTALS_ERROR = 'PATIENT_GROUPS_EDIT_TOTALS_ERROR';

interface PeopleReset {
  type: typeof PATIENT_GROUP_PEOPLE_RESET;
}
interface PatientGroupsLoading {
  type: typeof PATIENT_GROUPS_REQUESTED;
}
interface PatientGroupLoading {
  type: typeof PATIENT_GROUP_REQUESTED;
}
interface PatientGroupPeopleBatchLoading {
  type: typeof PATIENT_GROUP_PEOPLE_BATCH_REQUESTED;
}
interface PatientGroupsSuccess {
  type: typeof PATIENT_GROUPS_SUCCESS;
  payload: PatientGroupProps[];
}
interface PatientGroupSuccess {
  type: typeof PATIENT_GROUP_SUCCESS;
  payload: PatientGroupProps;
}
interface PatientGroupSuccess {
  type: typeof PATIENT_GROUP_SUCCESS;
  payload: PatientGroupProps;
}
interface AddPatientGroupSuccess {
  type: typeof ADD_PATIENT_GROUP_SUCCESS;
  payload: PatientGroupProps;
}
interface DeletePatientGroupSuccess {
  type: typeof DELETE_PATIENT_GROUP_SUCCESS;
  payload: string;
}
interface EditPatientGroupSuccess {
  type: typeof EDIT_PATIENT_GROUP_SUCCESS;
  payload: PatientGroupProps;
}
interface PatientGroupPeopleBatchSuccess {
  type: typeof PATIENT_GROUP_PEOPLE_BATCH_SUCCESS;
  payload: PatientGroupPeopleBatchResponseProps;
}
interface PatientGroupsError {
  type: typeof PATIENT_GROUPS_ERROR;
  payload: string;
}
interface PatientGroupError {
  type: typeof PATIENT_GROUP_ERROR;
  payload: string;
}
interface PatientGroupPeopleBatchError {
  type: typeof PATIENT_GROUP_PEOPLE_BATCH_ERROR;
  payload: string;
}
interface PatientGroupEditTotalsLoading {
  type: typeof PATIENT_GROUPS_EDIT_TOTALS_REQUESTED;
}
interface PatientGroupEditTotalsSuccess {
  type: typeof PATIENT_GROUPS_EDIT_TOTALS_SUCCESS;
  payload: PatientGroupTotalProps[];
}
interface PatientGroupEditTotalsError {
  type: typeof PATIENT_GROUPS_EDIT_TOTALS_ERROR;
  payload: string;
  ids: Array<string | undefined>;
}
export type PatientGroupsActionTypes =
  | AddPatientGroupSuccess
  | DeletePatientGroupSuccess
  | EditPatientGroupSuccess
  | PatientGroupError
  | PatientGroupLoading
  | PatientGroupPeopleBatchError
  | PatientGroupPeopleBatchLoading
  | PatientGroupPeopleBatchSuccess
  | PatientGroupsError
  | PatientGroupsLoading
  | PatientGroupsSuccess
  | PatientGroupSuccess
  | RouteChangeProps
  | PeopleReset
  | PatientGroupEditTotalsLoading
  | PatientGroupEditTotalsSuccess
  | PatientGroupEditTotalsError;
