import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  TenantFiltersActionTypes,
  TENANT_FILTERS_REQUESTED,
  TENANT_FILTERS_SUCCESS,
  TENANT_FILTERS_ERROR,
} from './types';
import { cancelReq } from './actions';

const tenantFiltersReducer = (state: State = initialState, action: TenantFiltersActionTypes): State => {
  switch (action.type) {
    case TENANT_FILTERS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case TENANT_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantFilters: action.payload,
      };
    case TENANT_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        tenantFilters: [],
        error: action.payload,
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default tenantFiltersReducer;
