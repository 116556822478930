import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  skeletonSpaced: {
    marginBottom: '0.1rem',
  },
  spinnerContainer: {
    height: '40vh',
    width: '100%',
  },
}));

export default useStyles;
