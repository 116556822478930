import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
// const handleAlertClose = () => {
//   console.log('alert box closed');
// };
export default function ActionAlerts(props: any) {
  const classes = useStyles();
  const { alertSeverity, alertMessage, alertBoxCloseTrigger } = props;
  return (
    <div className={classes.root}>
      <Alert severity={alertSeverity} onClose={alertBoxCloseTrigger}>
        {alertMessage}
      </Alert>
    </div>
  );
}
