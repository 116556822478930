import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  SecurityGroupProps,
  SecurityGroupsActionTypes,
  ADD_SECURITY_GROUP_SUCCESS,
  EDIT_SECURITY_GROUP_SUCCESS,
  SECURITY_GROUP_DELETE_SUCCESS,
  SECURITY_GROUPS_REQUESTED,
  SECURITY_GROUPS_SUCCESS,
  SECURITY_GROUPS_ERROR,
  SECURITY_GROUP_REQUESTED,
  SECURITY_GROUP_ERROR,
  SECURITY_GROUP_PEOPLE_BATCH_REQUESTED,
  SECURITY_GROUP_PEOPLE_BATCH_SUCCESS,
  SECURITY_GROUP_PEOPLE_BATCH_ERROR,
  SECURITY_GROUPS_EDIT_TOTALS_REQUESTED,
  SECURITY_GROUPS_EDIT_TOTALS_SUCCESS,
  SECURITY_GROUPS_EDIT_TOTALS_ERROR,
  SecurityGroupTotalProps,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

import { dehydrate, rehydrate } from '../Utils/groupFiltersUtil';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getSecurityGroups =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<SecurityGroupsActionTypes>) => {
    dispatch({ type: SECURITY_GROUPS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/security_groups', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('security group response ----', resp);
        const rslt = resp.data.data.map((r: SecurityGroupProps) => {
          if (r.group_filters && r.group_filters.filters) {
            r.group_filters.filters = rehydrate(r.group_filters.filters);
          }
          r.patients = -1;
          return r;
        });
        dispatch({
          type: SECURITY_GROUPS_SUCCESS,
          payload: rslt,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- security groups request cancelled');
        }
        dispatch({ type: SECURITY_GROUPS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const createSecurityGroup =
  (token: string, SecurityGroupBody: Partial<SecurityGroupProps>) => (dispatch: Dispatch) => {
    dispatch({ type: SECURITY_GROUP_REQUESTED });
    const { name, description, users, enabled } = SecurityGroupBody;
    authorized(token)
      .post('/security_groups', { name, description, users, enabled })
      .then((resp) => {
        dispatch({ type: ADD_SECURITY_GROUP_SUCCESS, payload: resp.data as SecurityGroupProps });
      })
      .catch((error) => {
        console.error(error);
        let msg: any;
        if (error.response?.data) {
          msg = error.response.data.details || error.response.data.message;
        }
        // FIXME
        // At times a 500 error could come back with an empty data object or string
        //    from server.  If so, return standard message
        if (msg === undefined || msg === null) msg = error.message;
        dispatch({ type: SECURITY_GROUP_ERROR, payload: msg });
      });
  };

export const editSecurityGroup = (token: string, securityGroup: SecurityGroupProps) => (dispatch: Dispatch) => {
  dispatch({ type: SECURITY_GROUP_REQUESTED });
  const { updated_dttm, group_filters, patients, ...rest } = securityGroup;
  if (group_filters && group_filters.filters) {
    group_filters.filters = dehydrate(group_filters.filters);
  }

  authorized(token)
    .put(`/security_groups/${rest.id}`, { ...rest, group_filters })
    .then((resp) => {
      let rslt = { ...resp.data, patients };
      if (rslt.group_filters && rslt.group_filters.filters) {
        rslt.group_filters.filters = rehydrate(rslt.group_filters.filters);
      }
      dispatch({ type: EDIT_SECURITY_GROUP_SUCCESS, payload: rslt });
    })
    .catch((error) => {
      console.error(error);
      let msg: any;
      if (error.response?.data) {
        msg = error.response.data.details || error.response.data.message;
      }
      // FIXME
      // At times a 500 error could come back with an empty data object or string
      //    from server.  If so, return standard message
      if (msg === undefined || msg === null) msg = error.message;
      dispatch({ type: SECURITY_GROUP_ERROR, payload: msg });
    });
};

export const deleteSecurityGroup =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<SecurityGroupsActionTypes>) => {
    dispatch({ type: SECURITY_GROUP_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .delete(`/security_groups/${id}`, { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('security group response ----', resp);
        dispatch({
          type: SECURITY_GROUP_DELETE_SUCCESS,
          payload: id,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- security group request cancelled');
        }
        dispatch({ type: SECURITY_GROUP_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getSecurityGroupPeopleBatch =
  (token: string, id: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<SecurityGroupsActionTypes>) => {
    dispatch({ type: SECURITY_GROUP_PEOPLE_BATCH_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .get(`/security_groups/${id}/people`, { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('security group people response ----', resp);
        dispatch({
          type: SECURITY_GROUP_PEOPLE_BATCH_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- security group people request cancelled');
        }
        dispatch({ type: SECURITY_GROUP_PEOPLE_BATCH_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const getSecurityGroupsEditTotals =
  (token: string, editTotalsBody: Array<string | undefined>) =>
  async (dispatch: Dispatch<SecurityGroupsActionTypes>) => {
    dispatch({ type: SECURITY_GROUPS_EDIT_TOTALS_REQUESTED });
    cancelReq = axios.CancelToken.source();
    authorized(token)
      .post(
        '/portal/totals/security_groups_edit',
        { security_groups: editTotalsBody },
        { params: { only_recent: true } },
      )
      .then((resp) => {
        // console.log('security group response ----', resp);
        dispatch({
          type: SECURITY_GROUPS_EDIT_TOTALS_SUCCESS,
          payload: resp.data as SecurityGroupTotalProps[],
        });
      })
      .catch((e) => {
        console.log(e);
        if (axios.isCancel(e)) {
          return console.log(' ----- security group edit totals request cancelled');
        }
        dispatch({ type: SECURITY_GROUPS_EDIT_TOTALS_ERROR, payload: e.message, ids: editTotalsBody });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
