import { RouteChangeProps } from '../history/reducer';
import { UserMiniProps } from '../users/types';

export interface CommentProps {
  comment_entity: 'RECOMMENDATION' | 'PERSON';
  created_by_auth0_id: string;
  created_dttm: number;
  id: string;
  parent_id: string;
  person_id: string;
  product_id?: string;
  recommendation_id?: string;
  recommendation_std_id?: string;
  result_id?: string;
  text: string;
  updated_dttm: number;
  user: UserMiniProps;
}

export interface RecommendationCommentsProps {
  [reccomendationStdId: string]: CommentProps[];
}

export type State = {
  comments: CommentProps[];
  recommendationComments: RecommendationCommentsProps;
  comment?: CommentProps;
  loading: boolean;
  error?: string;
};

export const initialState: State = {
  comments: [],
  recommendationComments: {},
  comment: undefined,
  loading: false,
  error: undefined,
};

export const COMMENTS_REQUESTED = 'COMMENTS_REQUESTED';
export const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS';
export const COMMENTS_ERROR = 'COMMENTS_ERROR';
export const COMMENT_REQUESTED = 'COMMENT_REQUESTED';
export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';

interface CommentsLoading {
  type: typeof COMMENTS_REQUESTED;
}
interface CommentLoading {
  type: typeof COMMENT_REQUESTED;
}
interface CommentsSuccess {
  type: typeof COMMENTS_SUCCESS;
  payload: CommentProps[];
}
interface CommentSuccess {
  type: typeof COMMENT_SUCCESS;
  payload: CommentProps;
}
interface AddCommentSuccess {
  type: typeof ADD_COMMENT_SUCCESS;
  payload: CommentProps;
}
interface CommentsError {
  type: typeof COMMENTS_ERROR;
  payload: string;
}
interface CommentError {
  type: typeof COMMENT_ERROR;
  payload: string;
}
export type CommentsActionTypes =
  | CommentLoading
  | CommentsLoading
  | CommentSuccess
  | CommentsSuccess
  | AddCommentSuccess
  | CommentsError
  | CommentError
  | RouteChangeProps
  | { type: 'INITIAL' };
