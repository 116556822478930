import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import useStyles from './styles';

import { useSectionWrapper } from '../SectionWrapper/SectionWrapperContext';

export interface SectionHeaderI {
  children: React.ReactNode;
  className?: string;
  variant?: 'Typography' | 'span';
}

const SectionHeader: FunctionComponent<SectionHeaderI> = (props: SectionHeaderI) => {
  const classes = useStyles();
  const { className, children } = props;
  const { collapse, toggleCollapse } = useSectionWrapper();
  const { variant = 'Typography' } = props;
  const HeaderText =
    variant === 'Typography' ? (
      <Typography variant="body1" className={classes.title}>
        {children}
      </Typography>
    ) : (
      <span className={classes.title}>{children}</span>
    );
  return (
    <Grid className={clsx(classes.root, className)} item xs={12} container direction="row">
      {HeaderText}
      <IconButton
        className={classes.arrow}
        onClick={(e: any) => {
          toggleCollapse();
        }}
        size="large"
      >
        {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Grid>
  );
};

export default SectionHeader;
