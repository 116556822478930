import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useStyles from './styles';

export interface JvionAutocompleteOption {
  key: string | number;
  value: string;
}

interface JvionAutocompleteFieldPropsI {
  limit?: number;
  label: string;
  options: JvionAutocompleteOption[];
  selectedIds?: (number | string)[];
  onChangeSelected?: (ids: (number | string)[]) => void;
  [key: string]: any; // NOTE: this is a little suspect
}

export interface JvionAutocompleteFieldStateI {
  warning: {
    text: string;
    active: boolean;
  };
  chips: JvionAutocompleteOption[];
}

export default function JvionAutocompleteField(props: JvionAutocompleteFieldPropsI) {
  const { limit, label, selectedIds, options, onChangeSelected, ...otherProps } = props;

  const preSelectedOptions = options.filter((option) => selectedIds && selectedIds?.includes(option.key));

  const [state, setState] = useState<JvionAutocompleteFieldStateI>({
    chips: preSelectedOptions,
    warning: {
      text: limit ? `Limited to ${limit}` : '',
      active: preSelectedOptions.length === limit,
    },
  });
  const { chips, warning } = state;
  const classes = useStyles(state);

  // For JCP-1083
  // Ask was to have the Options Box for the Dropdown
  // to only show @ 5 options before scrolling starts
  // so the Dropdown will show under the Select Fields
  // more often.
  // This does not gaurantee that the Dropdown
  // options will be on the bottom on smaller monitors / lower res.
  // FOR PATIENT SEARCH
  // We will need to come up with a way to overrider this.

  // In the styles, we have a class name of "listbox" that is being
  // applied to make the above request happen.

  return (
    <Autocomplete
      multiple
      autoHighlight
      {...{ 'data-testid': 'autocomplete' }}
      classes={classes}
      options={options}
      getOptionLabel={(option) => option.value}
      value={chips}
      onChange={(event, value) => {
        event.preventDefault();
        const selectedOptions: JvionAutocompleteOption[] = value; // Because value is and array of options i.e. [{ key: 1, value: 'value'}, ...]
        if (limit) {
          if (chips.length < limit || chips.length > selectedOptions.length) {
            setState({
              chips: selectedOptions,
              warning: {
                ...warning,
                active: selectedOptions.length === limit && chips.length === limit - 1,
              },
            });
            if (onChangeSelected) {
              onChangeSelected(selectedOptions.map((v) => v.key));
            }
          }
        } else {
          setState({
            ...state,
            chips: selectedOptions,
          });
          if (onChangeSelected) {
            onChangeSelected(selectedOptions.map((v) => v.key));
          }
        }
      }}
      isOptionEqualToValue={(value, option) => value.key === option.key}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          helperText={warning.active ? warning.text : ''}
          inputProps={{ 'data-testid': 'input', ...params.inputProps }}
          InputLabelProps={{ shrink: true }}
          InputProps={{ disableUnderline: true, ...params.InputProps }}
          error={warning.active}
        />
      )}
      {...otherProps}
    />
  );
}
