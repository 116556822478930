import { RouteChangeProps } from '../history/reducer';

export interface InsuranceProps {
  id: string;
  name: string;
  person_id: string;
  priority: string; // will need to be updated to number when api fixes bad assignment
  updated_dttm: number;
}

export type State = {
  insurances: InsuranceProps[];
  insurance?: InsuranceProps;
  loading: boolean;
  error?: string;
};

export const initialState: State = {
  insurances: [],
  insurance: undefined,
  loading: false,
  error: undefined,
};

export const INSURANCES_REQUESTED = 'INSURANCES_REQUESTED';
export const INSURANCES_SUCCESS = 'INSURANCES_SUCCESS';
export const INSURANCES_ERROR = 'INSURANCES_ERROR';
export const RESET_INSURANCES = 'RESET_INSURANCES';

interface InsurancesReset {
  type: typeof RESET_INSURANCES;
}
interface InsurancesLoading {
  type: typeof INSURANCES_REQUESTED;
}
interface InsurancesSuccess {
  type: typeof INSURANCES_SUCCESS;
  payload: InsuranceProps[];
}
interface InsurancesError {
  type: typeof INSURANCES_ERROR;
  payload: string;
}

export type InsurancesActionTypes =
  | InsurancesReset
  | InsurancesLoading
  | InsurancesSuccess
  | InsurancesError
  | RouteChangeProps
  | { type: 'INITIAL' };
