import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  PatientGroupsActionTypes,
  PATIENT_GROUP_REQUESTED,
  PATIENT_GROUPS_REQUESTED,
  PATIENT_GROUP_PEOPLE_BATCH_REQUESTED,
  PATIENT_GROUPS_SUCCESS,
  PATIENT_GROUP_SUCCESS,
  ADD_PATIENT_GROUP_SUCCESS,
  EDIT_PATIENT_GROUP_SUCCESS,
  DELETE_PATIENT_GROUP_SUCCESS,
  PATIENT_GROUP_PEOPLE_BATCH_SUCCESS,
  PATIENT_GROUP_ERROR,
  PATIENT_GROUPS_ERROR,
  PATIENT_GROUP_PEOPLE_BATCH_ERROR,
  PATIENT_GROUP_PEOPLE_RESET,
  PATIENT_GROUPS_EDIT_TOTALS_REQUESTED,
  PATIENT_GROUPS_EDIT_TOTALS_SUCCESS,
  PATIENT_GROUPS_EDIT_TOTALS_ERROR,
} from './types';
import { cancelReq } from './actions';

const patientGroupsReducer = (state: State = initialState, action: PatientGroupsActionTypes): State => {
  switch (action.type) {
    case PATIENT_GROUP_PEOPLE_RESET:
      return {
        ...state,
        patientGroup: undefined,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case PATIENT_GROUP_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PATIENT_GROUPS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        patientGroups: [],
      };
    case PATIENT_GROUP_PEOPLE_BATCH_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PATIENT_GROUPS_EDIT_TOTALS_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PATIENT_GROUP_PEOPLE_BATCH_SUCCESS:
      let people;
      if (
        state.people.people &&
        state.people.people.length > 0 &&
        state.people.metadata &&
        state.people.metadata.page_number !== null &&
        state.people.metadata.page_number + 1 === action.payload.metadata.page_number
      ) {
        people = [...state.people.people, ...action.payload.data];
      } else {
        people = action.payload.data;
      }
      return {
        ...state,
        loading: false,
        people: {
          people,
          metadata: action.payload.metadata,
        },
      };
    case PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroup: action.payload,
      };
    case PATIENT_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroups: action.payload,
      };
    case ADD_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroup: action.payload,
        patientGroups: [action.payload, ...state.patientGroups],
      };
    case DELETE_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroup: undefined,
        patientGroups: state.patientGroups.filter((pg) => pg.id && pg.id !== action.payload),
      };
    case EDIT_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        patientGroup: action.payload,
        patientGroups: state.patientGroups.map((pg) => {
          return pg.id === action.payload.id ? action.payload : pg;
        }),
      };
    case PATIENT_GROUPS_EDIT_TOTALS_SUCCESS:
      const patientGroups = state.patientGroups.map((pg) => {
        // Use find instead of filter
        // No need to loop through the entire array each time
        // Just find the first match and stop
        const pt = action.payload.find((pt) => pt.id === pg.id);
        if (pt) {
          pg.patients = pt.numerator === null ? -2 : pt.numerator;
        }
        return pg;
      });
      return {
        ...state,
        loading: false,
        patientGroups,
        patientGroupsTotal: action.payload,
      };
    case PATIENT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        patientGroup: undefined,
        error: action.payload,
      };
    case PATIENT_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        patientGroups: [],
        error: action.payload,
      };
    case PATIENT_GROUP_PEOPLE_BATCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        people: {
          people: [],
          metadata: undefined,
        },
      };
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    case PATIENT_GROUPS_EDIT_TOTALS_ERROR:
      // What was below for????
      // patientGroups: state.patienGroups.map((pg) => {
      //   return { ...pg, editTotals: undefined };
      // }),
      return {
        ...state,
        loading: false,
        patientGroups: state.patientGroups.map((pg) => {
          // If the Patient Group was part of a 500 error
          // Then mark it as a ERROR
          if (action.ids.indexOf(pg.id) > -1) {
            pg.patients = -2;
          }
          return pg;
        }),
        error: action.payload,
      };
    default:
      return state;
  }
};

export default patientGroupsReducer;
