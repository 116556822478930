import { ROUTE_CHANGE } from '../history/reducer';
import {
  State,
  initialState,
  RecommendationsActionTypes,
  RECOMMENDATION_REQUESTED,
  RECOMMENDATIONS_REQUESTED,
  RECOMMENDATIONS_SUCCESS,
  RECOMMENDATION_ERROR,
  RECOMMENDATIONS_ERROR,
  EDIT_RECOMMENDATION_SUCCESS,
  RESET_RECOMMENDATIONS,
} from './types';
import { cancelReq } from './actions';

const recommendationsReducer = (state: State = initialState, action: RecommendationsActionTypes): State => {
  switch (action.type) {
    case RECOMMENDATION_REQUESTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RECOMMENDATIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendations: action.payload,
      };
    case EDIT_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendation: action.payload,
        recommendations: state.recommendations.map((r) => {
          return r.id === action.payload.id ? action.payload : r;
        }),
      };
    case RECOMMENDATION_ERROR:
      return {
        ...state,
        loading: false,
        recommendation: undefined,
        error: action.payload,
      };
    case RECOMMENDATIONS_ERROR:
      return {
        ...state,
        loading: false,
        recommendations: [],
        error: action.payload,
      };
    case RESET_RECOMMENDATIONS:
      return initialState;
    case ROUTE_CHANGE:
      // We only care about route changes
      // that affect components waiting for a response
      if (cancelReq) {
        cancelReq.cancel();
        return initialState;
      }
      return state;
    default:
      return state;
  }
};

export default recommendationsReducer;
