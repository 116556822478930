import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import useStyles from './styles';

const StartSearch: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.startSearchContainer}>
      <Grid item>
        <AccountCircle className={classes.startSearchIcon} />
      </Grid>
      <Grid item>
        <Typography align="center" className={classes.startSearchHeader}>
          Patient Search
        </Typography>
        <Typography align="center" className={classes.startSearchBody}>
          <strong>NOTE:</strong> Search queries must have at least 3 characters.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StartSearch;
