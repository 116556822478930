import { useContext } from 'react';
import TenantSwitchintContext, { TenantSwitchingContextI } from './tenant-switching-context';

/**
 * ```js
 * const {
 *   // Tenant Switching state:
 *   error,
 *   hasInitialized,
 *   isLoading,
 *   isSelectingTenant,
 *   selectedTenant,
 *   tenants,
 *   // Tenant Switching methods:
 *   hasMultiTenants,
 *   switchTenant,
 * } = useTenantSwitching();
 * ```
 *
 * Use the `useTenantSwitching` hook in your components to access the auth state and methods.
 *
 */
const useTenantSwitching = (): TenantSwitchingContextI => useContext(TenantSwitchintContext) as TenantSwitchingContextI;

export default useTenantSwitching;
