import { FunctionComponent, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect';

const SidePadding = '5px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `calc(100% - ${SidePadding} - ${SidePadding})`,
    '& > .Mui-error': {
      '& > .MuiInput-input': {
        borderColor: theme.palette.error.main, // This line overrides the customInput border color
      },
    },
  },
  inputWrapper: {
    marginTop: '1rem',
  },
  customInput: {
    border: `1px solid ${theme.palette.accent?.border}`,
    borderRadius: theme.shape.borderRadius,
    padding: `6px ${SidePadding} 7px`,
    '&:before': {
      borderBottom: `none`,
    },
  },
  borderWithError: {
    borderColor: theme.palette.error.main,
  },
  jvionCustomLabel: {
    width: '100%',
    textTransform: 'uppercase',
    '&.MuiInputLabel-shrink': {
      transform: 'scale(1)',
      fontSize: '10px',
    },
    '& > span': {
      visibility: 'hidden',
      float: 'right',
      position: 'relative',
      fontWeight: 'normal',
      '&:after': {
        content: '" required"',
        visibility: 'visible',
        color: '#8598A9',
        textTransform: 'lowercase',
      },
    },
  },
}));

export interface SelectObject {
  label: string | number;
  value: string | number;
}

interface JvionSelectFieldProps extends NativeSelectProps {
  options: Array<SelectObject>;
  label: string;
  onChange: (e: any) => void;
  InputLabelProps?: InputLabelProps;
  FormControlProps?: FormControlProps;
  value?: string | number;
  helpText?: string;
  required?: boolean;
  error?: boolean;
}

const JvionSelectField: FunctionComponent<JvionSelectFieldProps> = (props: JvionSelectFieldProps) => {
  const classes = useStyles();
  const {
    InputLabelProps,
    FormControlProps,
    error,
    onChange,
    label,
    options,
    value,
    helpText,
    required,
    ...otherProps
  } = props;
  const renderedOptions = useMemo(() => {
    return options.map((opt: SelectObject) => (
      <option key={opt.value} value={opt.value} data-testid="select-option">
        {opt.label}
      </option>
    ));
  }, [options]);
  const formControlClassName = [classes.root, classes.inputWrapper];
  if (FormControlProps !== undefined && FormControlProps.className !== undefined) {
    formControlClassName.push(FormControlProps.className);
  }

  return (
    <FormControl {...FormControlProps} error={error} required={required} className={formControlClassName.join(' ')}>
      <InputLabel
        shrink
        className={classes.jvionCustomLabel}
        htmlFor={`jvion-select-field-label-placeholder-${props.label}`}
        {...InputLabelProps}
      >
        {props.label}
      </InputLabel>
      <NativeSelect
        value={props.value || options[2]}
        onChange={onChange}
        disableUnderline
        inputProps={{
          name: props.label,
          id: `jvion-select-field-label-placeholder-${props.label}`,
          className: classes.customInput,
        }}
        {...otherProps}
      >
        {renderedOptions}
      </NativeSelect>
      {helpText !== undefined && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

export default JvionSelectField;
