import { RouteChangeProps } from '../history/reducer';
import { UserMiniProps } from '../users/types';

export interface RecommendationProps {
  id: string;
  parent_id: string | null;
  person_id: string;
  product_id: string;
  result_id: string;
  recommendation_label_id: string;
  recommendation_rank: number;
  recommendation_std_id: string;
  status: string | null;
  status_reason: 'PatientDeclined' | 'NotClinicallyApplicable' | 'PatientCannotbeReached' | 'Deferred';
  comments?: string[];
  updated_dttm: number;
  created_dttm: number | null;
  updated_by_auth0_id?: number | null;
  user: UserMiniProps;
}

export type State = {
  recommendations: RecommendationProps[];
  recommendation?: RecommendationProps;
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  recommendations: [],
  recommendation: undefined,
  loading: false,
  error: undefined,
};

export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const RECOMMENDATIONS_REQUESTED = 'RECOMMENDATIONS_REQUESTED';
export const RECOMMENDATIONS_SUCCESS = 'RECOMMENDATIONS_SUCCESS';
export const RECOMMENDATIONS_ERROR = 'RECOMMENDATIONS_ERROR';
export const RECOMMENDATION_REQUESTED = 'RECOMMENDATION_REQUESTED';
export const RECOMMENDATION_ERROR = 'RECOMMENDATION_ERROR';
export const EDIT_RECOMMENDATION_SUCCESS = 'EDIT_RECOMMENDATION_SUCCESS';

interface RecommendationsReset {
  type: typeof RESET_RECOMMENDATIONS;
}

interface RecommendationsLoading {
  type: typeof RECOMMENDATIONS_REQUESTED;
}
interface RecommendationLoading {
  type: typeof RECOMMENDATION_REQUESTED;
}
interface RecommendationsSuccess {
  type: typeof RECOMMENDATIONS_SUCCESS;
  payload: RecommendationProps[];
}
interface EditRecommendationSuccess {
  type: typeof EDIT_RECOMMENDATION_SUCCESS;
  payload: RecommendationProps;
}
interface RecommendationsError {
  type: typeof RECOMMENDATIONS_ERROR;
  payload: string;
}
interface RecommendationError {
  type: typeof RECOMMENDATION_ERROR;
  payload: string;
}
export type RecommendationsActionTypes =
  | RecommendationLoading
  | RecommendationsLoading
  | RecommendationsSuccess
  | RecommendationsError
  | RecommendationError
  | RouteChangeProps
  | EditRecommendationSuccess
  | RecommendationsReset;
