import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import { ResultsActionTypes, RESULTS_REQUESTED, RESULTS_SUCCESS, RESULTS_ERROR } from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const getResults =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<ResultsActionTypes>) => {
    dispatch({ type: RESULTS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/results', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('results response ----', resp);
        dispatch({
          type: RESULTS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- results request cancelled');
        }
        dispatch({ type: RESULTS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
