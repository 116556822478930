import { FunctionComponent } from 'react';
import Avatar from '@mui/material/Avatar';
import useStyles from './styles';

/* Icons
AllCauseReadmission.svg
AvoidableAdmissions.svg
Depression.svg
OpiodMisuse.svg
SuicideSelfHarm.svg
*/

/* Products
30d Avoidable Admission
30d ER Visit
30d Mortality
30d Pain Management
6 month Depression
6 month Deterioration
All-cause readmission 
All-Cause Readmission (30-day)
Avoidable Admission
Avoidable Admission (30-day)
Avoidable Admission (Oncology)
Avoidable ED (Oncology, 30-day)
Depression 
Depression (Oncology)
Deterioration (Oncology, 6-month)
Mortality (Oncology, 30-day)
Oncology - 6M Deterioration 
Oncology - Avoidable Admission 
Oncology - Avoidable ED 
Oncology - Mortality 
Oncology - Pain 
Oncology - Readmission 
Onoclogy - Depression 
Opioid 
Opioid Misuse
Pain Management (Oncology)
Readmission
Readmission (Oncology, 30-day)
Suicide 
Suicide & Self-harm
Uncompensated Care
*/

export interface IProductIcon {
  productName: string;
}

const ProductIcon: FunctionComponent<IProductIcon> = (props: IProductIcon) => {
  const { productName } = props;
  const classes = useStyles();

  const images = {
    OncologyAvoidableAdmissions: '/OncologyAvoidableAdmissions.svg',
    OncologyAvoidableED: '/OncologyAvoidableED.svg',
    OncologyDepression: '/OncologyDepression.svg',
    OncologyDeterioration6m: '/OncologyDeterioration6m.svg',
    OncologyMortality: '/OncologyMortality.svg',
    OncologyPain: '/OncologyPain.svg',
    OncologyReadmissions: '/OncologyReadmissions.svg',
    AllCauseReadmission: '/AllCauseReadmission.svg',
    AvoidableAdmissions: '/AvoidableAdmissions.svg',
    Depression: '/Depression.svg',
    OpioidMisuse: '/OpioidMisuse.svg',
    SuicideSelfHarm: '/SuicideSelfHarm.svg',
    SocialVulnerabilityInsights: '/SDOH.svg',
  };

  const renderImage = (productName: string) => {
    // Trimming name because in DB, some products have trailing spaces from CSV
    switch (productName.trim()) {
      case '30d Mortality':
      case 'Mortality (Oncology, 30-day)':
      case 'Oncology - Mortality':
        return images.OncologyMortality;
      case 'Oncology - Readmission':
      case 'Readmission':
      case 'Readmission (Oncology, 30-day)':
      case 'Uncompensated Care':
        return images.OncologyReadmissions;
      case '6 month Depression':
      case 'Depression (Oncology)':
      case 'Onoclogy - Depression':
        return images.OncologyDepression;
      case '30d Avoidable Admission':
      case 'Oncology - Avoidable Admission':
      case 'Avoidable Admission (Oncology)':
        return images.OncologyAvoidableAdmissions;
      case '30d ER Visit':
      case 'Avoidable ED (Oncology, 30-day)':
      case 'Oncology - Avoidable ED':
        return images.OncologyAvoidableED;
      case '6 month Deterioration':
      case 'Deterioration (Oncology, 6-month)':
      case 'Oncology - 6M Deterioration':
        return images.OncologyDeterioration6m;
      case '30d Pain Management':
      case 'Oncology - Pain':
      case 'Pain Management (Oncology)':
        return images.OncologyPain;
      case 'All-cause readmission':
      case 'All-Cause Readmission (30-day)':
      case 'All-Cause Readmission (Post-discharge)':
        return images.AllCauseReadmission;
      case 'Avoidable Admission':
      case 'Avoidable Admission (30-day)':
        return images.AvoidableAdmissions;
      case 'Depression':
        return images.Depression;
      case 'Opioid':
      case 'Opioid Misuse':
        return images.OpioidMisuse;
      case 'Suicide':
      case 'Suicide & Self-harm':
        return images.SuicideSelfHarm;
      case 'Social Vulnerability Insights':
        return images.SocialVulnerabilityInsights;
    }
  };

  return (
    <div className={classes.productImageWrapper}>
      <Avatar className={classes.productIcon}>
        <img src={renderImage(productName)} alt="productIcon" />
      </Avatar>
    </div>
  );
};

export default ProductIcon;
