import { FunctionComponent, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import useStyles from './styles';

import { RecommendationStepProps } from '../../Store/recommendation_steps/types';

interface RecommendationStepItemProps {
  recommendationStep: RecommendationStepProps;
  onChangeStep: (id: string, checked: boolean) => void;
}

const RecommendationStepItem: FunctionComponent<RecommendationStepItemProps> = (props: RecommendationStepItemProps) => {
  const { recommendationStep, onChangeStep } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(recommendationStep.checked); // this will go away once we update steps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked); // this will go away once we update steps
    onChangeStep(recommendationStep.id, event.target.checked);
  };

  return (
    <Grid container>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name={recommendationStep.name}
            className={classes.checkBox}
            disableRipple
            data-testid="recommendation-step"
          />
        }
        label={recommendationStep.name}
      />
    </Grid>
  );
};

export default RecommendationStepItem;
