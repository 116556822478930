import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DrawerLeftBG,
  DrawerFontColor,
  MediumGrey,
  MediumBlue,
  thirdAndFourthQuartileFill,
  secondQuartile,
  topQuartile,
  DarkPurple,
  CoolGrey11C,
} from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  productSelected: {
    backgroundColor: DrawerLeftBG,
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    padding: '1rem',
  },
  cardContent: {
    fontWeight: 'bold',
    color: DarkPurple,
    position: 'relative',
    padding: '1rem',
  },
  clickable: {
    '&:hover *': {
      cursor: 'pointer',
    },
  },
  container: {
    marginTop: theme.spacing(1),
    marginLeft: '0px',
  },
  cardText: {},
  ProductGridWrapper: {
    width: '200px',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    justifyContent: 'flex-end',
    height: '1rem',
    marginBottom: '7px',
  },
  riskTooltip: {
    height: '11px',
    width: '11px',
    whiteSpace: 'pre-line',
  },
  riskCategory: {
    marginTop: theme.spacing(1),
    fontSize: '.875rem',
    justifyContent: 'flex-end',
    cursor: 'default',
  },
  riskScore: {
    textTransform: 'uppercase',
    fontSize: '.875rem',
    fontWeight: 600,
  },
  productName: {
    fontWeight: 'bold',
    cursor: 'default',
    height: '2rem',
  },
  productImageWrapper: {
    position: 'absolute',
    left: '10px',
    top: '12px',
  },
  productIcon: {
    height: '45px',
    width: '45px',
    backgroundColor: DrawerFontColor,
  },
  divider: {
    paddingRight: '25px',
    marginLeft: '-25px',
    paddingLeft: '25px',
    marginRight: '-25px',
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    color: DarkPurple,
  },
  tableRow: {
    height: '63px',
    width: '834px',
    borderRadius: '8px',
    backgroundColor: DrawerFontColor,
    boxSshadow: '0 2px 4px 0 rgba(58,41,125,0.08)',
  },
  icon: {
    marginTop: '39px',
    marginLeft: '5px',
    color: MediumGrey,
  },
  infoIcon: {
    height: '11px',
    width: '12px',
    color: MediumBlue,
  },
  percentileText: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    cursor: 'default',
  },
  arrowUp: {
    marginTop: theme.spacing(0.5),
    fontSize: '.875rem',
    transform: 'rotate(180deg)',
  },
  arrowRight: {
    marginTop: theme.spacing(0.5),
    fontSize: '.875rem',
    transform: 'rotate(270deg)',
  },
  arrowStandard: {
    fontSize: '.875rem',
    marginTop: theme.spacing(0.5),
  },
  gaugeWrapper: {
    display: 'flex',
    position: 'relative',
  },
  gauge: {
    boxSizing: 'border-box',
    height: '9px',
    width: '41px',
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: '2px',
    backgroundColor: 'rgba(235,239,243,0.57)',
  },
  gaugeTicker: {
    zIndex: 1,
    top: 1,
    boxSizing: 'border-box',
    height: '16px',
    width: '4px',
    borderLeft: `1px solid ${theme.palette.common.white}`,
    borderRight: `1px solid ${theme.palette.common.white}`,
    marginTop: theme.spacing(-0.5),
    transform: 'scaleX(-1)',
    borderRadius: '1px',
    position: 'absolute',
  },
  gaugeWrapperSelected: {
    '& > div': {
      borderColor: theme.palette.accent.SelectedProductBorderColor,
    },
  },
  percentileOrdinal: {
    color: CoolGrey11C,
    fontSize: '.7rem',
    fontWeight: 600,
  },
  percentileOrdinalSelected: {
    color: DrawerFontColor,
    fontSize: '.7rem',
    fontWeight: 600,
  },
  thirdAndFourthQuartileBG: {
    backgroundColor: thirdAndFourthQuartileFill,
  },
  secondQuartileBG: {
    backgroundColor: secondQuartile,
  },
  topQuartileBG: {
    backgroundColor: topQuartile,
  },
  thirdAndFourthQuartileColor: {
    fontWeight: 500,
    color: thirdAndFourthQuartileFill,
  },
  secondQuartileColor: {
    fontWeight: 500,
    color: secondQuartile,
  },
  topQuartileColor: {
    fontWeight: 500,
    color: topQuartile,
  },
  lowRiskColor: {
    color: thirdAndFourthQuartileFill,
  },
  mediumRiskColor: {
    color: secondQuartile,
  },
  highRiskColor: {
    color: topQuartile,
  },
  skeletonSpaced: {
    marginBottom: '0.1rem',
  },
}));

export default useStyles;
