import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CoolGrey1C, DrawerFontColor, DarkBlue } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: DrawerFontColor,
    boxSshadow: '0 2px 4px 0 rgba(58,41,125,0.08)',
    flexGrow: 1,
  },
  table: {
    borderSpacing: '2px',
    borderCollapse: 'separate',
    width: '100%',
  },
  section: {
    marginTop: theme.spacing(4),
  },
  descriptionRow: {
    height: '32px',
  },
  description: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: DarkBlue,
  },
  riskDescriptionText: {
    margin: '5px 5px 5px 5px',
  },
  riskFactorTypes: {
    paddingRight: theme.spacing(3),
  },
  filterText: {
    fontSize: '10px',
    padding: `0 ${theme.spacing(0.5)}`,
  },
  arrow: {
    borderLeft: '.5px solid',
    borderRadius: 0,
  },
  sectionTitle: {
    color: DarkBlue,
  },
  tableRowHeader: {
    backgroundColor: theme.palette.background.default,
  },
  filterOptions: {
    width: 'auto',
    borderRadius: '16px',
    backgroundColor: CoolGrey1C,
    justifyContent: 'space-between',
    padding: '0 6px 0 6px',
    marginRight: theme.spacing(2),
    '& > button': {
      minWidth: 'unset',
      margin: `0 ${theme.spacing(0.5)}`,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(0.75, 1),
    },
  },
  filterOptionContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inactiveFilterOption: {
    backgroundColor: CoolGrey1C,
    color: DarkBlue,
  },
  activeFilterOption: {
    backgroundColor: DarkBlue,
    color: DrawerFontColor,
    '&:hover': {
      backgroundColor: DarkBlue,
    },
  },
}));

export default useStyles;
