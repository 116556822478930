import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: 'inherit',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    alignItems: 'center',
  },
  nameFontStyle: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.main,
  },
  tooltipText: {
    fontWeight: 600,
  },
  textStyle: {
    marginLeft: '30px',
  },
  overflowClass: {
    marginLeft: '33px',
    overflow: 'hidden',
  },
}));

export default useStyles;
