import { createRef, useState, FunctionComponent, ChangeEvent, KeyboardEvent } from 'react';
import clsx from 'clsx';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { Theme, ClickAwayListener } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Edit as EditIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { DarkTeal } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  customInput: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      '&:before': {
        borderBottom: 'none',
      },
    },
  },
  icon: {
    cursor: 'pointer',
    fontSize: '1rem',
    color: DarkTeal,
  },
}));

export interface InlineTextFieldProps extends StandardTextFieldProps {
  onSave: (newValue: string) => void;
}

const InlineTextField: FunctionComponent<InlineTextFieldProps> = (props: InlineTextFieldProps) => {
  const classes = useStyles();
  const { onSave, value: initialValue, className, ...otherProps } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue as string);
  const textField = createRef<HTMLInputElement>();

  // this allows the parent component to rehydrate the controlled value of
  // this component in the case of an error or any other value needing to be
  // passed.

  if (!editing && initialValue !== value) {
    setValue(initialValue as string);
  }

  const handleCancel = () => {
    setEditing(false);
    textField?.current?.blur();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.charCode === 13) {
      handleSave();
    }
  };
  const handleFocus = () => setEditing(true);
  const handleSave = () => {
    if (editing) {
      setEditing(false);
      onSave(value);
      textField?.current?.blur();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleSave}>
      <div className={clsx(classes.root, className)}>
        <TextField
          {...otherProps}
          className={classes.customInput}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          inputRef={textField}
          variant={'standard'}
        />
        {editing ? (
          <>
            <CheckIcon className={classes.icon} onClick={handleSave} data-testid="save" />
            <CloseIcon className={classes.icon} onClick={handleCancel} data-testid="cancel" />
          </>
        ) : (
          <EditIcon className={classes.icon} onClick={() => textField?.current?.focus()} data-testid="edit" />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default InlineTextField;
