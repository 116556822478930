import { RouteChangeProps } from '../history/reducer';
import { MetadataProps } from '../Utils/types';

export type ProductType = 'COMMUNITY' | 'INPATIENT';

export interface ProductProps {
  id: string;
  name: string;
  product_type: ProductType;
  description: string;
}

export interface ProductsProps {
  data: ProductProps[];
  metadata: MetadataProps;
}

export type State = {
  products: ProductProps[];
  loading: boolean;
  metadata?: MetadataProps;
  error?: string;
};

export const initialState: State = {
  products: [],
  loading: false,
  error: undefined,
};

export const PRODUCTS_REQUESTED = 'PRODUCTS_REQUESTED';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
// export const PRODUCTS_BATCH_SUCCESS = 'PRODUCTS_BATCH_SUCCESS';
export const PRODUCTS_ERROR = 'PRODUCTS_ERROR';
// export const PRODUCTS_BATCH_REQUESTED = 'PRODUCTS_BATCH_REQUESTED';

interface ProductsLoading {
  type: typeof PRODUCTS_REQUESTED;
}
// interface PeopleBatchSuccess {
//   type: typeof PRODUCTS_BATCH_SUCCESS;
//   payload: ProductsProps;
// }
interface ProductsSuccess {
  type: typeof PRODUCTS_SUCCESS;
  payload: ProductProps[];
}
interface ProductsError {
  type: typeof PRODUCTS_ERROR;
  payload: string;
}
export type ProductsActionTypes = ProductsLoading | ProductsSuccess | ProductsError | RouteChangeProps;
