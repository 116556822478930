import { RouteChangeProps } from '../history/reducer';

export interface RecommendationStepProps {
  checked: boolean;
  id: string;
  name: string;
  parent_id: string | null;
  person_id: string;
  product_id: string;
  recommendation_id: string;
  result_id: string;
  created_dttm: number;
  updated_dttm: number;
}

export type State = {
  recommendationSteps: RecommendationStepProps[];
  recommendationStep?: RecommendationStepProps;
  loading: boolean;
  error: string | undefined;
};

export const initialState: State = {
  recommendationSteps: [],
  recommendationStep: undefined,
  loading: false,
  error: undefined,
};

export const RECOMMENDATION_STEPS_REQUESTED = 'RECOMMENDATION_STEPS_REQUESTED';
export const RECOMMENDATION_STEPS_SUCCESS = 'RECOMMENDATION_STEPS_SUCCESS';
export const RECOMMENDATION_STEPS_ERROR = 'RECOMMENDATION_STEPS_ERROR';
export const RECOMMENDATION_STEP_REQUESTED = 'RECOMMENDATION_STEP_REQUESTED';
export const RECOMMENDATION_STEP_SUCCESS = 'RECOMMENDATION_STEP_SUCCESS';
export const RECOMMENDATION_STEP_ERROR = 'RECOMMENDATION_STEP_ERROR';
export const EDIT_RECOMMENDATION_STEP_SUCCESS = 'EDIT_RECOMMENDATION_STEP_SUCCESS';

interface RecommendationStepsLoading {
  type: typeof RECOMMENDATION_STEPS_REQUESTED;
}
interface RecommendationStepLoading {
  type: typeof RECOMMENDATION_STEP_REQUESTED;
}
interface RecommendationStepsSuccess {
  type: typeof RECOMMENDATION_STEPS_SUCCESS;
  payload: RecommendationStepProps[];
}
interface RecommendationStepSuccess {
  type: typeof RECOMMENDATION_STEP_SUCCESS;
  payload: RecommendationStepProps;
}
interface EditRecommendationStepSuccess {
  type: typeof EDIT_RECOMMENDATION_STEP_SUCCESS;
  payload: RecommendationStepProps;
}
interface RecommendationStepsError {
  type: typeof RECOMMENDATION_STEPS_ERROR;
  payload: string;
}
interface RecommendationStepError {
  type: typeof RECOMMENDATION_STEP_ERROR;
  payload: string;
}
export type RecommendationStepsActionTypes =
  | RecommendationStepLoading
  | RecommendationStepsLoading
  | RecommendationStepSuccess
  | RecommendationStepsSuccess
  | RecommendationStepsError
  | RecommendationStepError
  | RouteChangeProps
  | EditRecommendationStepSuccess;
