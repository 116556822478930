import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';

import { SideNavLayout } from '../../Containers/SideNavWrapper';

import useStyles from './styles';

const UserActivity: FunctionComponent = (props) => {
  const classes = useStyles();

  return (
    <SideNavLayout>
      <Typography className={classes.root}>User Activity</Typography>
    </SideNavLayout>
  );
};

export default UserActivity;
