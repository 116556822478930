import { AxiosResponse } from 'axios';

/*
//  Purpose of parser is that there are 2 endpoints with special status of 207
//  We will use this error parser to see if the error was:
//    - All Succeeded
//    - All Failed
//    - Some Succeeded and some failed
//    - Standard Server Errors
*/

export interface RespAlertsI {
  id: number | string;
  msg: string;
  severtity: 'success' | 'warning' | 'error' | 'info';
}

interface parseEmailRespProps {
  resp: AxiosResponse;
  selected: string[];
  setRespAlerts: (alerts: RespAlertsI[]) => void;
  setSelected: (newSelected: string[]) => void;
}

const parseEmailResp = (props: parseEmailRespProps) => {
  const { resp, selected, setRespAlerts, setSelected } = props;
  const alerts: RespAlertsI[] = [];
  // Start the new selection with a copy of the originally selected list.
  //  Default is all remain selected
  let newSelected: string[] = selected;
  switch (resp.data.code) {
    case 200:
      alerts.push({
        id: +new Date(),
        msg: 'Email sent to user(s).',
        severtity: 'success',
      });
      // If all passed, clear the selected list.
      newSelected = [];
      break;
    case 207:
      const { failed_ids, successful_ids } = resp.data;
      // Only keep the list of failed_ids
      newSelected = selected.filter((id) => failed_ids.indexOf(id) >= 0);
      alerts.push({
        id: +new Date() + '-1',
        msg: `Email sent to ${successful_ids.length} user(s).`,
        severtity: 'success',
      });
      alerts.push({
        id: +new Date() + '-2',
        msg: `Email failed to send to ${successful_ids.length} user(s).`,
        severtity: 'error',
      });
      break;
    case 400:
      alerts.push({
        id: +new Date() + '-3',
        msg: resp.data.details,
        severtity: 'error',
      });
      break;
    default:
      alerts.push({
        id: +new Date() + '-4',
        msg: 'There was a problem with the server.',
        severtity: 'error',
      });
      break;
  }
  setSelected(newSelected);
  setRespAlerts(alerts);
  return;
};

export default parseEmailResp;
