import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  RecommendationProps,
  RecommendationsActionTypes,
  EDIT_RECOMMENDATION_SUCCESS,
  RECOMMENDATIONS_REQUESTED,
  RECOMMENDATIONS_SUCCESS,
  RECOMMENDATIONS_ERROR,
  RECOMMENDATION_REQUESTED,
  RECOMMENDATION_ERROR,
  RESET_RECOMMENDATIONS,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

// Some patients don't have recommendations
// instead of loading all recommendations when recommendation_ids are an empty array
// just clear the recommendations array.
export const resetRecommendations = () => async (dispatch: Dispatch<RecommendationsActionTypes>) => {
  // console.log('reset');
  dispatch({ type: RESET_RECOMMENDATIONS });
};

export const getRecommendations =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<RecommendationsActionTypes>) => {
    dispatch({ type: RECOMMENDATIONS_REQUESTED });
    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/recommendations', { ...options, cancelToken: cancelReq.token })
      .then((resp) => {
        // console.log('recommendation response ----', resp);
        dispatch({
          type: RECOMMENDATIONS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- recommendation request cancelled');
        }
        dispatch({ type: RECOMMENDATIONS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };

export const editRecommendation =
  (token: string, recommendation: Partial<RecommendationProps>) => (dispatch: Dispatch) => {
    dispatch({ type: RECOMMENDATION_REQUESTED });
    // Only use the fields we need, exclude the others.
    let { id, updated_dttm, parent_id, created_dttm, updated_by_auth0_id, user, ...rest } = recommendation;

    if (id) {
      authorized(token)
        .put(`/recommendations/${id}`, rest)
        .then((resp) => {
          dispatch({ type: EDIT_RECOMMENDATION_SUCCESS, payload: resp.data });
        })
        .catch((error) => {
          console.error(error);
          let msg: any;
          if (error.response?.data) {
            msg = error.response.data.details || error.response.data.message;
          }
          // FIXME
          // At times a 500 error could come back with an empty data object or string
          //    from server.  If so, return standard message
          if (msg === undefined || msg === null) msg = error.message;
          dispatch({ type: RECOMMENDATION_ERROR, payload: msg });
        });
    } else {
      console.error('An id is required to edit a recommendation');
    }
  };
