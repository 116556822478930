import { FunctionComponent, useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import GroupEditModalHeader from './header';
import GroupEditSaveDialog from './saveDialog';
import GroupEditFilters from '../../Containers/GroupEditFilters';
import GroupEditUsers from '../../Containers/GroupEditUsers';
import { editPatientGroup } from '../../Store/patient_groups/actions';
import { editSecurityGroup } from '../../Store/security_groups/actions';
import { PatientGroupProps } from '../../Store/patient_groups/types';
import { SecurityGroupProps } from '../../Store/security_groups/types';
import { TenantFilterProps } from '../../Store/tenant_filters/types';

import { RootStore } from '../../configureStore';

import useStyles from './styles';

interface GroupEditModalProps {
  onClose: () => void;
  open: boolean;
  groupType: 'patient' | 'security';
  productType?: 'INPATIENT' | 'COMMUNITY';
  group: PatientGroupProps | SecurityGroupProps;
  groups: PatientGroupProps[] | SecurityGroupProps[];
}

interface GroupEditModalState {
  isSubmitting: boolean;
  apiError?: string;
  confirmCloseOpen: boolean;
  step: 1 | 2;
}

const GroupEditModal: FunctionComponent<GroupEditModalProps> = (props: GroupEditModalProps) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { onClose, open, group, groupType, groups, productType } = props;
  const securityGroupState = useSelector((state: RootStore) => state.security_groups);
  const patientGroupState = useSelector((state: RootStore) => state.patient_groups);
  // Keep track if App has been Initialized or not.
  //  Once Ininitialized, send the Loading down to the subComponents
  const [state, setState] = useState<GroupEditModalState>({
    isSubmitting: false,
    confirmCloseOpen: false,
    step: 1,
  });
  const { isSubmitting, confirmCloseOpen, apiError, step } = state;

  // Don't mutate group, but work from clone
  const clonedGroup = () => JSON.parse(JSON.stringify(group));

  const handleAddFilter = (filter: TenantFilterProps) => {
    // This is where we set the Operator and possible value
    let cloned: PatientGroupProps | SecurityGroupProps = clonedGroup();
    const { entity_name, column_name, filter_type } = filter;
    let currentFilters = cloned.group_filters?.filters ? cloned.group_filters.filters : [];
    let val: any = {
      model: entity_name,
      field: column_name,
    };
    switch (filter_type) {
      case 'autocomplete':
        val.op = 'in';
        val.value = [];
        break;

      default:
        break;
    }
    // If no group_filters or filters empty, then add it.
    if (!cloned.group_filters) cloned.group_filters = { filters: [] };
    // Order might exist but not filters
    if (!cloned.group_filters.filters) cloned.group_filters.filters = [];
    cloned.group_filters.filters = currentFilters.concat(val);

    handleEditGroup(cloned);
  };

  const handleRemoveFilter = (indexId: number) => {
    let cloned: PatientGroupProps | SecurityGroupProps = clonedGroup();
    cloned.group_filters?.filters.splice(indexId, 1);
    handleEditGroup(cloned);
  };

  const onAddUsers = (ids: string[]) => {
    let cloned: PatientGroupProps | SecurityGroupProps = clonedGroup();
    let newUsersArr = cloned.users ? [...cloned.users] : [];
    ids.forEach((id) => {
      // Prevent Duplicate IDs
      if (newUsersArr.indexOf(id) < 0) newUsersArr.push(id);
    });
    cloned.users = newUsersArr;
    handleEditGroup(cloned);
  };
  const onRemoveUsers = (removeIds: string[]) => {
    let cloned: PatientGroupProps | SecurityGroupProps = clonedGroup();
    let newUsersArr = cloned.users ? cloned.users : [];

    // Only keep the IDs that are not in the array
    newUsersArr = newUsersArr.filter((id) => removeIds.indexOf(id) < 0);
    cloned.users = newUsersArr;
    handleEditGroup(cloned);
  };
  // End state code to remove in the future

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      confirmCloseOpen: true,
    }));
  };

  const handleConfirm = () => {
    onClose();
    setState((prevState) => ({
      ...prevState,
      confirmCloseOpen: false,
      step: 1,
      apiError: undefined,
      isSubmitting: false,
    }));
  };

  const handleEditGroup = (newValue: Partial<PatientGroupProps> | Partial<SecurityGroupProps>) => {
    if (groupType === 'patient') {
      getAccessTokenSilently().then((token) => {
        dispatch(editPatientGroup(token, { ...group, ...newValue } as PatientGroupProps));
      });
    } else if (groupType === 'security') {
      getAccessTokenSilently().then((token) => {
        dispatch(editSecurityGroup(token, { ...group, ...newValue } as SecurityGroupProps));
      });
    }
  };

  const handleGoToStep = (step: 1 | 2) => {
    setState((prevState) => ({ ...prevState, isSubmitting: false, apiError: undefined, step }));
  };

  useEffect(() => {
    if (!isSubmitting && (patientGroupState.loading || securityGroupState.loading)) {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
    }
    if (
      isSubmitting &&
      ((groupType === 'patient' && !patientGroupState.loading) ||
        (groupType === 'security' && !securityGroupState.loading))
    ) {
      if (
        (groupType === 'patient' && patientGroupState.error) ||
        (groupType === 'security' && securityGroupState.error)
      ) {
        setState((prevState) => ({
          ...prevState,
          apiError: groupType === 'patient' ? patientGroupState.error : securityGroupState.error,
          isSubmitting: false,
        }));
      } else {
        const partialState = { apiError: undefined, isSubmitting: false };
        // Because either PatientGroupState or SecurityGroupState
        // triggered that isSubmitting is TRUE
        // Change it back to FALSE here since above does the same, but also looks for errors
        setState((prevState) => ({ ...prevState, ...partialState }));
      }
    }
  }, [groupType, patientGroupState, securityGroupState, isSubmitting]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: classes.dialog,
        }}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-group-form"
        data-testid="group-edit-modal"
      >
        <GroupEditModalHeader
          currentStep={state.step}
          allowContinue={(group.group_filters?.filters && group.group_filters.filters.length > 0) || false} //will go away with group.filters
          group={group}
          groupType={groupType}
          handleEditGroup={handleEditGroup}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          handleContinue={() => handleGoToStep(2)}
          handleBack={() => handleGoToStep(1)}
          isSubmitting={isSubmitting}
        />
        <DialogContent className={classes.content}>
          <Grid container>
            {apiError && (
              <Grid container justifyContent="center">
                <Alert
                  severity="error"
                  data-testid="form-alert"
                  onClose={() =>
                    setState((prevState) => {
                      return { ...prevState, apiError: undefined };
                    })
                  }
                >
                  {apiError}
                </Alert>
              </Grid>
            )}
            <Grid container>
              {step === 1 && (
                <GroupEditFilters
                  groupType={groupType}
                  updateGroup={handleEditGroup}
                  groupObject={group}
                  productType={productType}
                  addFilter={handleAddFilter}
                  removeFilter={handleRemoveFilter}
                />
              )}
              {step === 2 && (
                <GroupEditUsers
                  selectedUsersIds={group.users ? group.users : []} // will be group && group.users ? group.users : []
                  onAddUsers={onAddUsers}
                  onRemoveUsers={onRemoveUsers}
                  handleEditGroup={handleEditGroup}
                  groupType={groupType}
                  groups={groups}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <GroupEditSaveDialog
        open={confirmCloseOpen}
        onClose={() => setState((prevState) => ({ ...prevState, confirmCloseOpen: false }))}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default GroupEditModal;
