import { RouteChangeProps } from '../history/reducer';

export interface EncounterProps {
  admission_source: string;
  admission_timestamp: number;
  admitted: boolean;
  attending_physician: string;
  discharge_destination: string | null;
  discharge_disposition: string | null;
  discharge_timestamp: number | null;
  drg: string | null;
  drg_description: string | null;
  encounter_type: string;
  external_encounter_id: string;
  id: string;
  location_bed: string;
  location_room: string;
  location_unit: string;
  person_id: string;
  provider: string;
  servicing_facility: string;
  updated_dttm: number;
  visit_reason: string | null;
}

export type State = {
  encounters: EncounterProps[];
  encounter?: EncounterProps;
  loading: boolean;
  error?: string;
};

export const initialState: State = {
  encounters: [],
  encounter: undefined,
  loading: false,
  error: undefined,
};

export const ENCOUNTERS_REQUESTED = 'ENCOUNTERS_REQUESTED';
export const ENCOUNTERS_SUCCESS = 'ENCOUNTERS_SUCCESS';
export const ENCOUNTERS_ERROR = 'ENCOUNTERS_ERROR';
export const RESET_ENCOUNTERS = 'RESET_ENCOUNTERS';

interface EncountersReset {
  type: typeof RESET_ENCOUNTERS;
}
interface EncountersLoading {
  type: typeof ENCOUNTERS_REQUESTED;
}
interface EncountersSuccess {
  type: typeof ENCOUNTERS_SUCCESS;
  payload: EncounterProps[];
}
interface EncountersError {
  type: typeof ENCOUNTERS_ERROR;
  payload: string;
}

export type EncountersActionTypes =
  | EncountersReset
  | EncountersLoading
  | EncountersSuccess
  | EncountersError
  | RouteChangeProps
  | { type: 'INITIAL' };
