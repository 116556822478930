import { Dispatch } from 'redux';
import { authorized } from '../../Utils/AxiosInstance';
import {
  RiskFactorsActionTypes,
  RISK_FACTORS_REQUESTED,
  RISK_FACTORS_SUCCESS,
  RISK_FACTORS_ERROR,
  RESET_RISK_FACTORS,
} from './types';
import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';

export let cancelReq: CancelTokenSource | undefined = undefined;

export const resetRiskFactors = () => async (dispatch: Dispatch<RiskFactorsActionTypes>) => {
  // console.log('reset');
  dispatch({ type: RESET_RISK_FACTORS });
};

export const getRiskFactors =
  (token: string, options: AxiosRequestConfig = {}) =>
  async (dispatch: Dispatch<RiskFactorsActionTypes>) => {
    dispatch({ type: RISK_FACTORS_REQUESTED });
    // console.log('---- cancel: ', cancelReq);
    if (cancelReq) cancelReq.cancel('Canceled by next request');

    cancelReq = axios.CancelToken.source();

    authorized(token)
      .get('/risk_factors', { cancelToken: cancelReq.token, ...options })
      .then((resp) => {
        // console.log('risk factors response ----', resp);
        dispatch({
          type: RISK_FACTORS_SUCCESS,
          payload: resp.data.data,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return console.log(' ----- risk factors request cancelled');
        }
        dispatch({ type: RISK_FACTORS_ERROR, payload: e.message });
      })
      .finally(() => {
        cancelReq = undefined;
      });
  };
